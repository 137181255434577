import React from 'react';
import { Link } from 'react-router-dom';
import history from '../.../../../../history';

const GuestFinalImage = props => {
    const { image_url } = props.location.state.image;
    const  question_count  = props.location.state.question_count;
    const  question_total  = props.location.state.question_total;

    const { activity_type , question_key } = props.match.params;
 
    window.localStorage.setItem('setImage_url', image_url);

    const accessLocalStorageQuestion = JSON.parse(localStorage.getItem('selectedQuestion'));
    
    const handleNextStepResponseToQuestion = () => {
        history.push({
            pathname: `/guest-activity/response-page/${question_key}`,
        })
    };

    return (
        <div id="main">
            <div className="container mobtop">
                <div className="row">
                    <div className="col-md-12 back-nav">
                        <Link to={`/guest-activity/saved-images/${question_key}`} href="#" className="goto_back"><i></i>Back</Link>
                    </div>
                    <div className="progress-steps">
                        <span className="step step-1 active"></span>
                        <span className="step step-2 active"></span>
                        <span className="step step-3 active"></span>
                        <span className="step step-4 active"></span>
                        <span className="step step-5"></span>
                    </div>
                    <div className="form-padding-box">
                        <div className="form-wrap" style={{borderRadius: 5}}>
                            <div className="mb-3 font-weight-bold text-center">Question {question_count} of {question_total}</div>

                            <div className="small-title">{accessLocalStorageQuestion.content}</div>
                            <form action="response-page.html" className="finalimg-form entry-form" method="post">
                                <div className="fimg-holder" style={{textAlign: 'center'}}>
                                    <img src={image_url} style={{ width: 'auto'}} title="Final Image" className="final-image" alt="loading" />
                                </div>
                                <div className="button-wrap mt-10">
                                    <button onClick={handleNextStepResponseToQuestion} className="btn-primary btn-pass active">SELECT AS FINAL IMAGE</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default GuestFinalImage;
