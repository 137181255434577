import React, { Component } from 'react';
import { Router } from "react-router-dom";

import Header from '@components/main/Header';
import Body from '@components/main/Body';
import history from './history';
import Footer from '@components/main/Footer'

class App extends Component {

    render() {
        return (
            <Router history={history}>
                <div>
                    <Header />
                    <Body />
                    <Footer />
                </div>
            </Router>
        );
    };
};

export default App;
