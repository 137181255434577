import {
  UPLOAD_IMAGES_SUCCESS,
  UPLOAD_IMAGES_ERROR,
  DELETE_IMAGES_SUCCESS,
  DELETE_IMAGES_ERROR,
  DELETE_ALL_IMAGES_SUCCESS,
  DELETE_ALL_IMAGES_ERROR,
} from "@app/action-types";
import { toast } from "react-toastify";
import Swal from "sweetalert"
import "react-toastify/dist/ReactToastify.css";
toast.configure();

// export const getCollections = () => {
//   return async(dispatch, getState, { getFirebase, getFirestore }) => {
//     //   where("userId", "==", getState().firebase.auth.uid)
//     const firestore = getFirestore();
//     if ( getState().firebase.auth.email != 'zz' && getState().firebase.auth.email != 'consulting@theflux.com' ){


//         firestore.collection("group_images").get()
//         .then((snap) => {
//             dispatch({
//             type: "GET_COLLECTIONS",
//             payload: snap.docs,
//             });
//         });
//     }else{
//         firestore.collection("group_images").get()
//         .then((snap) => {
//             dispatch({
//             type: "GET_COLLECTIONS",
//             payload: snap.docs,
//             });
//         });
//     }
//   }
// }



export const getCollections = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    //   where("userId", "==", getState().firebase.auth.uid)
    const firestore = getFirestore();

    firestore.collection("group_images").orderBy("collectionName", "asc").get()
      .then((snap) => {
        dispatch({
          type: "GET_COLLECTIONS",
          payload: snap.docs.map((doc) => {
            return { ...doc.data(), id: doc.id }
          })
        });
      });

  }
}

export const getCollectionsAdmin = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    //   where("userId", "==", getState().firebase.auth.uid)
    const firestore = getFirestore();
    if (getState().firebase.auth.email != 'zz' && getState().firebase.auth.email != 'consulting@theflux.com') {
      firestore.collection("group_images").where("userId", "==", getState().firebase.auth.uid).get()
        .then((snap) => {
          dispatch({
            type: "GET_COLLECTIONS",
            payload: snap.docs.map((doc) => {
              return { ...doc.data(), id: doc.id }
            })
          });
        });
    } else {
      firestore.collection("group_images").get()
        .then((snap) => {
          dispatch({
            type: "GET_COLLECTIONS",
            payload: snap.docs.map((doc) => {
              return { ...doc.data(), id: doc.id }
            })
          });
        });
    }

  }
}

export const uploadImages = (images) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const storageRef = firebase.storage().ref();
    Array.from(images).forEach((image, index) => {
      const id =
        new Date().getTime() +
        "_" +
        Math.random()
          .toString(36)
          .substr(2);
      const ext = image.name.split(".").pop();
      const image_name = `images/${id}.${ext}`;
      firebase
        .storage()
        .ref(image_name)
        .put(image)
        .then(() => {
          let ref = storageRef.child(image_name);
          return ref.getDownloadURL().then((imageUrl) => {
            // firestore.collection("question_images").add({
            //   image_name: `${id}.${ext}`,
            //   image_ref: imageUrl,
            // }).then(() => {
            //   toast("Success! Image Uploaded Successfuly", {
            //     type: "success",
            //   });
            // });
            toast("Success! Image Uploaded Successfuly", {
              type: "success",
            });
          });
        })
        .then(() => {
          return dispatch({ type: UPLOAD_IMAGES_SUCCESS });
        })
        .catch((err) => {
          return dispatch({ type: UPLOAD_IMAGES_ERROR, err });
        });
    });
  };
};

export const uploadCollection = (images, name) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const storageRef = firebase.storage().ref();
    Array.from(images).forEach((image, index) => {
      const id =
        new Date().getTime() +
        "_" +
        Math.random()
          .toString(36)
          .substr(2);
      const ext = image.name.split(".").pop();
      const image_name = `${name}/${id}.${ext}`;
      firebase
        .storage()
        .ref(image_name)
        .put(image)
        .then(() => {
          let ref = storageRef.child(image_name);
          return ref.getDownloadURL().then((imageUrl) => {
            firestore.collection("group_images").add({
              collectionName: name,
              image_name: `${id}.${ext}`,
              image_ref: imageUrl,
              userId: getState().firebase.auth.uid,
            }).then(() => {
              toast("Success! Image Uploaded Successfuly", {
                type: "success",
              });
            })
          });
        })
        .then(() => {
          window.$('#selection_collection').val(name).trigger('change');
          return dispatch({ type: UPLOAD_IMAGES_SUCCESS });
        })
        .catch((err) => {
          return dispatch({ type: UPLOAD_IMAGES_ERROR, err });
        });
    });
  };
};

export const uploadUserImages = (images, uid) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const storageRef = firebase.storage().ref();
    Array.from(images).forEach((image, index) => {
      const id =
        new Date().getTime() +
        "_" +
        Math.random()
          .toString(36)
          .substr(2);
      const ext = image.name.split(".").pop();
      const image_name = `${uid}/${id}.${ext}`;
      firebase
        .storage()
        .ref(image_name)
        .put(image)
        .then(() => {
          let ref = storageRef.child(image_name);
          return ref.getDownloadURL().then((imageUrl) => {
            localStorage.setItem("UploadedImage", imageUrl);
            // firestore.collection(`question_images`).add({
            //   image_name: `${id}.${ext}`,
            //   image_ref: imageUrl,
            // }).then(() => {
            //   toast("Success! Image Uploaded Successfuly", {
            //     type: "success",
            //   });
            //   window.location.reload()
            // });

            toast("Success! Image Uploaded Successfuly", {
              type: "success",
            });
            window.location.reload()


          });
        })
        .then(() => {
          return dispatch({ type: UPLOAD_IMAGES_SUCCESS });
        })
        .catch((err) => {
          return dispatch({ type: UPLOAD_IMAGES_ERROR, err });
        });
    });
  };
};

export const deleteImages = (image) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .delete({ collection: "group_images", doc: image.id })
      .then(() => {
        return dispatch({ type: DELETE_IMAGES_SUCCESS });
      })
      .catch((err) => {
        return dispatch({ type: DELETE_IMAGES_ERROR, err });
      });
  };
};

export const deleteImagesFromCollection = (name) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    let img_collection_query = firestore.collection('group_images').where('collectionName', '==', name);
    img_collection_query.where("userId", "==", getState().firebase.auth.uid).get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        doc.ref.delete();
        // console.log(doc.data());
      });
    });

  };
};



export const deleteAllImages = (images) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    images.map((image, index) => {
      return firebase
        .storage()
        .ref("images/" + image.image_name)
        .delete()
        .then(() => {
          firestore.delete({ collection: "question_images", doc: image.id });
        })
        .then(() => {
          return dispatch({ type: DELETE_ALL_IMAGES_SUCCESS });
        })
        .catch((err) => {
          return dispatch({ type: DELETE_ALL_IMAGES_ERROR, err });
        });
    });
  };
};
