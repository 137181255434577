import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { signOut, updateUser } from '@actions/auth';
import { Navbar, Nav, NavItem, NavbarToggler, Collapse, Modal, ModalBody, DropdownToggle, Dropdown, UncontrolledDropdown, DropdownItem, DropdownMenu } from 'reactstrap';
import StripeCheckout from "react-stripe-checkout"
import { compose } from 'redux';
import axios from "axios"
import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import "./header.style.css"
import history from "../../history";
import firebase from 'firebase/app';
import moment from 'moment';

import { GrNotification } from "react-icons/gr";
import { reduce } from 'lodash';

toast.configure()

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            pathname: props.location.pathname,
            isPremium: false,
            admin: {}
        };
    }

    componentDidMount() {
        this.setState({
            admin: {
                price: 50
            }
        })



    }

    componentWillReceiveProps(nextProps) {
        this.unlisten = this.props.history.listen((location, action) => {
            this.setState({
                pathname: location.pathname
            });
        });

        if (this.props.auth.uid) {

            firebase.firestore().collection("notif").where("userId", "==", this.props.auth.uid).orderBy("createdAt", "desc").limit(7)
                .get()
                .then((querySnapshot) => {

                    if (querySnapshot.size > 0) {



                        querySnapshot.forEach((doc) => {

                            if (doc.data().status == 'unread')
                                window.$('.notif_sup').html("*");

                            var curdate = new Date(null);
                            curdate.setTime(doc.data().createdAt.seconds * 1000);
                            var response_date = curdate;
                            response_date = moment(response_date).fromNow();


                            if (doc.data().type == 'admin') {
                                window.$('.dropdown-menu').append("<div style='padding: 5px 10px; font-size: 14px; margin: 3px; background: #ffffff36; border-left:" + ((doc.data().status == 'unread') ? '3px solid goldenrod' : '') + "'><a style='color: #fff;' href='../../admin/groups" + "'>" + doc.data().notification + '<span style="font-size: 12px; display: block;  color: #d3d3d3;">' + response_date + "</span> </a></div>");

                            } else {
                                window.$('.dropdown-menu').append("<div style='padding: 5px 10px; font-size: 14px; margin: 3px; background: #ffffff36; border-left:" + ((doc.data().status == 'unread') ? '3px solid goldenrod' : '') + "'><a style='color: #fff;' href='../../results-team/view/" + doc.data().group_id + "'>" + doc.data().notification + '<span style="font-size: 12px; display: block; color: #d3d3d3;">' + response_date + "</span> </a></div>");

                            }




                        });
                    } else {
                        window.$('.dropdown-menu').append("<div style='padding: 5px 10px; font-size: 14px; margin: 1px; background: #ffffff36; color: #fff;'>No Notifications!</div>");
                    }

                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });

        }

    }


    componentWillUnmount() {
        this.unlisten();
    }

    async handleToken(token, price) {
        const { auth } = this.props;
        const response = await axios.post("https://us-central1-sparq-flux.cloudfunctions.net/user/checkout", {
            token,
            price
        })

        const { status } = response.data;
        if (status === "success") {
            this.setState({
                isOpen: false
            })
            this.props.updateUser(auth.uid)
            toast("Success! Check email for details", {
                type: "success"
            })
        } else {
            this.setState({
                isOpen: false
            })
            toast("Something went wrong", {
                type: "error"
            })
        }
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    signOut = () => {

        this.props.signOut();
        history.push("/sign-in");
        // window.location.href = "/";

    };

    render() {
        const { auth: { isLoaded, isEmpty }, profile } = this.props

        const normal_link = {
            marginLeft: "40px"
        }

        const selected_link = {
            color: '#2a3382',
            marginLeft: "40px"
        }

        const logOutButton = (
            <React.Fragment>
                <Link to="#" className="action-link" style={{ marginLeft: "41px" }} onClick={this.signOut}>Log out</Link>
            </React.Fragment>
        )

        const userNav = (
            // <React.Fragment>
            //     <Nav className="ml-auto" navbar>
            //         <NavItem style={{ textAlign: "right" }}>
            //             <Link to="/user/profile" style={this.state.pathname === '/user/profile' ? selected_link : normal_link} className="action-link">Profile</Link>
            //         </NavItem> 
            //         <NavItem>
            //             {logOutButton}
            //         </NavItem>
            //         {/*
            //          <NavItem>
            //             <React.Fragment>
            //                 <Link to="#" className="action-link" style={{ marginLeft: "20px" }} onClick={this.toggle}>Get Premium Access</Link>
            //             </React.Fragment>
            //         </NavItem>
            //         */}
            //     </Nav>
            // </React.Fragment>
            <React.Fragment>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="mx-auto order-0" navbar>

                    </Nav>
                    <Nav className="order-1" navbar>
                        <NavItem style={{ textAlign: "right" }}>

                            <Dropdown style={{ cursor: 'pointer' }} toggle={() => {

                                firebase.firestore().collection("notif").where("userId", "==", this.props.auth.uid)
                                    .get()
                                    .then(function (querySnapshot) {
                                        querySnapshot.forEach(function (doc) {
                                            doc.ref.update({ status: "read" })
                                        });
                                        window.$('.notif_sup').html("");
                                    })

                            }}>
                                <DropdownToggle
                                    data-toggle="dropdown"
                                    tag="span"
                                >
                                    <GrNotification /><sup style={{ color: 'red', fontWeight: 'bold' }} class="notif_sup"></sup>
                                </DropdownToggle>
                                <DropdownMenu style={{ minWidth: '265px', padding: '10px', background: 'linear-gradient(98deg, #19337f, #19627f)' }}>

                                    {/* <div style={{ padding: '5px 10px', cursor: 'pointer' }} onClick={function noRefCheck() { }}>
                                            <a onClick={() => { console.log('alert') }}>Group has a new response</a>
                                        </div>
                                        <div style={{ padding: '5px 10px', cursor: 'pointer' }} onClick={function noRefCheck() { }}>
                                            <a onClick={() => { console.log('alert') }}>Group has a new response</a>
                                        </div> */}

                                </DropdownMenu>
                            </Dropdown>
                        </NavItem>
                        <NavItem style={{ textAlign: "right" }}>
                            <Link to="/dashboard" style={this.state.pathname === '/dashboard' ? selected_link : normal_link} className="action-link">Dashboard</Link>
                        </NavItem>
                        <NavItem style={{ textAlign: "right" }}>
                            <Link to="/user/profile" style={this.state.pathname === '/user/profile' ? selected_link : normal_link} className="action-link">Profile</Link>
                        </NavItem>
                        <NavItem style={{ textAlign: "right" }}>
                            {logOutButton}
                        </NavItem>
                    </Nav>
                </Collapse>
            </React.Fragment>
        )

        const premiumNav = (
            <React.Fragment>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="mx-auto order-0" navbar>
                        <NavItem style={{ textAlign: "right" }}>
                            <Link to="/dashboard" style={this.state.pathname === '/dashboard' ? selected_link : normal_link} className="action-link">Dashboard</Link>
                        </NavItem>
                        <NavItem style={{ textAlign: "right" }}>
                            <Link to="/admin/questions" style={this.state.pathname === '/admin/questions' ? selected_link : normal_link} className="action-link">Questions</Link>
                        </NavItem>
                        <NavItem style={{ textAlign: "right" }}>
                            <Link to="/admin/images" style={this.state.pathname === '/admin/images' ? selected_link : normal_link} className="action-link">Images</Link>
                        </NavItem>
                        <NavItem style={{ textAlign: "right" }}>
                            <Link to="/admin/groups" style={this.state.pathname === '/admin/groups' ? selected_link : normal_link} className="action-link">Groups</Link>
                        </NavItem>
                        <NavItem style={{ textAlign: "right" }}>
                            <Link to="/admin/mosaic" style={this.state.pathname === '/admin/mosaic' ? selected_link : normal_link} className="action-link">Mosaic</Link>
                        </NavItem>
                    </Nav>
                    <Nav className="order-1" navbar>
                        <NavItem style={{ textAlign: "right" }}>

                            <Dropdown style={{ cursor: 'pointer' }} toggle={() => {



                                firebase.firestore().collection("notif").where("userId", "==", this.props.auth.uid)
                                    .get()
                                    .then(function (querySnapshot) {
                                        querySnapshot.forEach(function (doc) {
                                            doc.ref.update({ status: "read" })
                                        });
                                        window.$('.notif_sup').html("");
                                    })

                            }
                            }>
                                <DropdownToggle
                                    data-toggle="dropdown"
                                    tag="span"
                                >
                                    <GrNotification /><sup style={{ color: 'red', fontWeight: 'bold' }} class="notif_sup"></sup>
                                </DropdownToggle>
                                <DropdownMenu style={{ minWidth: '265px', padding: '10px', background: 'linear-gradient(98deg, #19337f, #19627f)' }}>

                                    {/* <div style={{ padding: '5px 10px', cursor: 'pointer' }} onClick={function noRefCheck() { }}>
                                        <a onClick={() => { console.log('alert') }}>Group has a new response</a>
                                    </div>
                                    <div style={{ padding: '5px 10px', cursor: 'pointer' }} onClick={function noRefCheck() { }}>
                                        <a onClick={() => { console.log('alert') }}>Group has a new response</a>
                                    </div> */}

                                </DropdownMenu>
                            </Dropdown>
                        </NavItem>

                        <NavItem style={{ textAlign: "right" }}>
                            <Link to="/user/profile" style={this.state.pathname === '/user/profile' ? selected_link : normal_link} className="action-link">Profile</Link>
                        </NavItem>
                        <NavItem style={{ textAlign: "right" }}>
                            {logOutButton}
                        </NavItem>
                    </Nav>
                </Collapse>
            </React.Fragment>
        )

        const adminNav = (
            <React.Fragment>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="mx-auto order-0" navbar>
                        <NavItem style={{ textAlign: "right" }}>
                            <Link to="/admin" style={this.state.pathname === '/admin' ? selected_link : normal_link} className="action-link">Users</Link>
                        </NavItem>
                        <NavItem style={{ textAlign: "right" }}>
                            <Link to="/admin/referral" style={this.state.pathname === '/admin/referral' ? selected_link : normal_link} className="action-link">Referrals</Link>
                        </NavItem>
                        <NavItem style={{ textAlign: "right" }}>
                            <Link to="/admin/questions" style={this.state.pathname === '/admin/questions' ? selected_link : normal_link} className="action-link">Questions</Link>
                        </NavItem>
                        <NavItem style={{ textAlign: "right" }}>
                            <Link to="/admin/images" style={this.state.pathname === '/admin/images' ? selected_link : normal_link} className="action-link">Images</Link>
                        </NavItem>
                        <NavItem style={{ textAlign: "right" }}>
                            <Link to="/admin/groups" style={this.state.pathname === '/admin/groups' ? selected_link : normal_link} className="action-link">Groups</Link>
                        </NavItem>
                        <NavItem style={{ textAlign: "right" }}>
                            <Link to="/admin/mosaic" style={this.state.pathname === '/admin/mosaic' ? selected_link : normal_link} className="action-link">Mosaic</Link>
                        </NavItem>
                    </Nav>
                    <Nav className="order-1" navbar>
                        <NavItem style={{ textAlign: "right" }}>
                            {/* <Link to="/user/profile" style={this.state.pathname === '/user/profile' ? selected_link : normal_link} className="action-link">Profile</Link> */}
                        </NavItem>
                        <NavItem style={{ textAlign: "right" }}>
                            {logOutButton}
                        </NavItem>
                    </Nav>
                </Collapse>
            </React.Fragment>

        )

        const authenticateLinks = (
            <React.Fragment>
                {profile.user_type === 'admin' ? adminNav : null}
                {profile.user_type === 'normal' ? userNav : null}
                {profile.user_type === 'premium' ? premiumNav : null}
            </React.Fragment>
        )

        const nav = (
            <React.Fragment>
                <Navbar color="none" light expand="xl" style={{ width: "100%" }}>
                    <Link to="/" id="logo"><img src="/assets/images/sparq_logo.png" alt="Sparq Logo" /></Link>
                    {isLoaded && !isEmpty ? authenticateLinks : null}
                </Navbar>
            </React.Fragment>
        )

        return (
            <header id="header" className="no-print">
                <div className="container">
                    <div className="row">
                        {nav}
                    </div>
                </div>
                {/* <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                    <ModalBody className="box-div">
                        <div className="box-div-black">
                            <h5>Get access to all premium features</h5>
                            <h4 className="box-price">At just ${this.state.admin.price}</h4>
                            <StripeCheckout
                                className="box-btn"
                                stripeKey="pk_test_XE5FPLp36Algteois5BWfEeV00SfULzKqI"
                                token={(token) => this.handleToken(token, this.state.admin.price)}
                                amount={this.state.admin.price * 100}
                            />
                        </div>
                    </ModalBody>
                </Modal> */}
            </header>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        updateUser: (data) => dispatch(updateUser(data))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(Header);
