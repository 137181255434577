import React, { useState } from 'react'
import { Modal, Button, Form ,Row , Col} from 'react-bootstrap';
import { Badge } from 'reactstrap';
import { connect } from "react-redux";
import { GrEdit } from "react-icons/gr"
import { withRouter } from "react-router"
import Editor from '../AddNewIdea/Editor/Editor';
import {onChangeEditIdea , onUpdateEditIdea } from '@actions/ideas'
 function EditIdea(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Button style={{ color: 'grey', border: '3px solid grey', padding: '0px', height: '34px', width: '75%', backgroundColor: 'white' }} onClick={handleShow}> <GrEdit style={{ color: 'grey' }} /> &nbsp; <b>Edit </b></Button>

      <Modal  size="lg" show={show} onHide={handleClose} >
        <Modal.Header closeButton style={{ padding: '10px', backgroundColor: 'whiteSmoke', borderRadius: '0px' }}>
          <Modal.Title>Edit idea</Modal.Title>
        </Modal.Header>
        <Modal.Body>  
        <Form>
              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="1"></Form.Label>
                <Form.Label column sm="2">
                    Your Idea &nbsp;&nbsp;
                    <Badge color="secondary">Required</Badge>    
                </Form.Label>
                <Col sm="8">
                  <Form.Control value={props.idea[0].idea} type="text" size="sm" placeholder="One sentence summary of the idea" name="idea" onChange={(e)=>{props.onChangeEditIdea({...props.idea[0],idea:e.target.value})}} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="1"></Form.Label>
                <Form.Label column sm="2">
                   Please Add more detail     
                </Form.Label>
                <Col sm="8">
                  <Editor des={props.idea[0].description } onChange={(e)=>{props.onChangeEditIdea({...props.idea[0],description:e})}} />            
                </Col>
            </Form.Group>
        </Form>
        </Modal.Body>
        <Modal.Footer style={{ padding: '10px', backgroundColor: 'whiteSmoke' }}>
          <Button variant="primary" onClick={()=>{props.onUpdateEditIdea({...props.idea[0]})}} style={{ color: 'white', padding: '0px', height: '30px', width: '13%', backgroundColor: '#0173cf' }}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
const mapStateToProps = ({firebase,ideas}) => {
  const { auth, profile } =firebase;
return {
    auth, 
    profile,
    ideas:ideas,
    idea:ideas.ideaEdit,

};
};
const mapDispatchToProps = (dispatch) => ({
  onChangeEditIdea:(data) => dispatch(onChangeEditIdea(data)),
  onUpdateEditIdea:(data) => dispatch(onUpdateEditIdea(data)),
});


export default connect(mapStateToProps,mapDispatchToProps)(withRouter( EditIdea));
