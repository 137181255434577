import React from "react";
import { createQuestion } from "@actions/question";
import { connect } from "react-redux";

class AddQuestionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      questionType: this.props.profile.user_type == "premium" ?"group" : "",
      type: props.questiontype.length === 1 ? "normal" : "default",
    };
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onSubmit(event) {
    const { user, profile, auth } = this.props;
    event.preventDefault();
    if (user) {
      this.props.createQuestion({
        content: this.state.question,
        type: this.state.type,
        question_type: "user",
      });
    } else {
      this.props.createQuestion({
        content: this.state.question,
        type: this.state.type,
        question_type: this.state.questionType,
        user_type: profile.user_type,
        userId : auth.uid
      });
    }
    localStorage.setItem("NewQuestion", this.state.question);
    localStorage.setItem("check", true);
    this.props.toggle();
    this.resetForm();
  }

  resetForm() {
    this.setState({
      editTextArea: "",
      question: "",
      questionType: "",
      type: "",
    });
  }

  render() {
    const { user , profile } = this.props;
    const { user_type } = profile
    return (
      <form onSubmit={this.onSubmit.bind(this)}>
        <div className="form-group">
          <label htmlFor="question">Question: </label>
          <textarea
            name="question"
            onChange={this.onChange.bind(this)}
            placeholder="Enter question ..."
            className="form-control"
            required
          ></textarea>
        </div>
        {  user_type == "premium" ?null : <div className="form-group">
          {user ? null : (
            <div>
              <label htmlFor="question-use">Type: </label>
              <select
                value={this.state.questionType}
                name="questionType"
                className="form-control"
                id="question-use"
                onChange={this.onChange}
                required
              >
                <option value="" disabled>
                  --Select Question Type
                </option>
                <option value="personal">Personal</option>
                <option value="group">Group</option>
              </select>
            </div>
          )}
        </div>}
       
        <div className="form-group text-right">
          <button
            className="btn btn-info"
            style={{ backgroundColor: "#2a3381", border: "none" }}
          >
            Submit
          </button>
        </div>
      </form>
    );
  }
}
const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;

  return {
      auth,
      profile,
  }
}

const mapDispatchToProps = (dispatch) => ({
  createQuestion: (question) => dispatch(createQuestion(question)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestionForm);
