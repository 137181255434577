import React from 'react';
import ModalImage from 'react-modal-image'
import { connect } from 'react-redux';
import { deleteImages } from '@actions/images';
import firebase from 'firebase/app';
import Swal from "sweetalert"

import './styles.css';

class Thumbnail extends React.Component {
    deleteImage = (event) => {
        event.preventDefault();
        console.log(firebase.auth().currentUser.uid)
        if (window.confirm("Do you want to delete this image?")) {
            if ( this.props.image.userId === firebase.auth().currentUser.uid ){
                //alert('Image Deleted Successfully')
                this.props.deleteImages({
                    name: this.props.image.image_name, 
                    id: this.props.image.id
                })
                // window.$("#"+this.props.image.id).hide();
                Swal("Image successfully deleted", "", "success");

            }
        }
    }

    render() {

        return (
            <React.Fragment>
                <div className="col-md-4" style={{ padding: "10px" }}>
                    {this.props.image.userId === firebase.auth().currentUser.uid &&
                        <button style={{ marginBottom: "8px" }} onClick={this.deleteImage} className="btn btn-danger">Delete Image</button>
                    }
                    <ModalImage
                      small={this.props.image.image_ref}
                      large={this.props.image.image_ref}
                      alt={this.props.image.image_name}
                      hideZoom={true}
                      hideDownload={true}
                    /> 
                </div>
            </React.Fragment>
        )
    }
}


const mapDispatchToProps = (dispatch) => ({
    deleteImages: (image) => dispatch(deleteImages(image)),
})

export default connect(null, mapDispatchToProps)(Thumbnail);
