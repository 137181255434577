const config = {
  apiKey: "AIzaSyDac0e2mW1BC1sy266aldxsaycbq7QWR7I",
  authDomain: "discoversparq.com",
  databaseURL: "https://sparq-flux.firebaseio.com",
  projectId: "sparq-flux",
  storageBucket: "sparq-flux.appspot.com",
  messagingSenderId: "573602978344",
  appId: "1:573602978344:web:a4b0f50949ef30712c731d",
  measurementId: "G-48WK236CF2",
};

export default config;
