import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { some, isEmpty } from "lodash";
import CustomFancyBox from "@components/main/CustomFancyBox";
import { getCollections } from '@actions/images';
import ImageCheckbox from "./ImageCheckbox";
import loading from "../../../resource/loading.gif";
import history from "../../../history";
import { toast } from "react-toastify";
import Uploader from "../../admin/Uploader";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import firebase from 'firebase/app';

import "./styles.css";
import { FaUpload } from "react-icons/fa";

import { lazyFetchImages, imageCount } from "@actions/question";


const $ = window.$;
const GridScrollFx = window.GridScrollFx;

class DemoImageActivity extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const path = location.pathname.split("/");
    const accessLocalStorageQuestion = JSON.parse(
      localStorage.getItem("selectedQuestion")
    );
    this.loadedCount = 0;
    this.scrollheight = 0;
    this.isSelected = 0;
    this.state = {
      question: accessLocalStorageQuestion,
      path: path[1],
      backPath: path[1],
      showFancyBox: false,
      fancyImageIndex: 0,
      imagesToSave: [],
      style: null,
      imageCount: null,
      checkedItems: new Map(),
      notEndOfImages: true,
      modal: false,
      question_count: 1,
      question_total: 1,
      image_loop: 10,
      btn_text: 'Loading...',
      btn_disabled: true,
      btn_show: false,
      modal: false,
      allow_upload: true
    };
  }

  handleClick = () => {
    console.log('clicked');
    this.setState({ image_loop: this.state.image_loop + 10 })
    this.setState({ btn_text: 'Loading...' })
    this.setState({ btn_disabled: true });

  };

  componentWillMount() {
    this.props.imageCount();
    document.body.style.paddingBottom = 0;


    if (localStorage.getItem('question_count')) {
      this.setState({
        question_count: localStorage.getItem('question_count')
      });

    }

    if (localStorage.getItem('question_total')) {
      this.setState({
        question_total: localStorage.getItem('question_total')
      });
    }
  }

  componentDidMount() {
    this.props.getCollections()
    // localStorage.removeItem("UploadedImage");
    this.props.lazyFetchImages();

    // redirect to response if image is uloaded
    const getImage = localStorage.getItem("UploadedImage");
    if (getImage) {

      window.localStorage.setItem('setImage_url', getImage);

      history.push({
        pathname: `/${this.state.path}/response-page/${this.state.question.key}`
      });

    }


    this.setState({ allow_upload: false });


  }

  componentWillUnmount() {
    $(".nicescroll-rails").remove();
    document.body.style.paddingBottom = `7rem`;
    document.getElementsByClassName("footer")[0].style.display = "block";
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  fb_checkbox_click(thisCheck) {
    var dataRef = $(thisCheck)
      .parent("label")
      .attr("data-ref");
    $(thisCheck)
      .parent("label")
      .toggleClass("active");
    $('label[data-id="' + dataRef + '"]')
      .find("input[type=checkbox]")
      .trigger("click");
  }

  dataFromChildThumbnail = (dataFromChild, image) => {


    const exists = some(this.state.imagesToSave, [
      "image_url",
      dataFromChild.image_url,
    ]);

    this.setState({
      imagesToSave: [...this.state.imagesToSave, dataFromChild],
    });

    // if (!exists) {
    //   this.setState({
    //     imagesToSave: [...this.state.imagesToSave, dataFromChild],
    //   });
    // } else {
    //   this.setState((prevState) => ({
    //     imagesToSave: prevState.imagesToSave.filter(
    //       (image) => image.image_url !== dataFromChild.image_url
    //     ),
    //   }));
    // }


  };

  handleNextStepSavedImages = (event) => {
    event.preventDefault();
    if (!isEmpty(this.state.imagesToSave)) {
      localStorage.setItem(
        "imagesData",
        JSON.stringify(this.state.imagesToSave)
      );
      // history.push({
      //   pathname: `/${this.state.path}/saved-images/${this.state.question.key}`,
      //   state: { imagesToSave: this.state.imagesToSave },
      // });

      // const { activity_type, question_key } = props.match.params;


      this.state.imagesToSave.map((image, index) => {
        //console.log(image.image_url)
        window.localStorage.setItem('setImage_url', image.image_url);

        history.push({
          pathname: `/${this.state.path}/response-page/${this.state.question.key}`
        });
      });

    }
  };

  onNextStep(event) {
    event.preventDefault();
  }

  handleSelectImage = (image, event) => {


    // show error for selecting more than 1 image
    // if (event.target.checked && this.isSelected == 1) {
    //   toast("You can only select a single image!", { type: "info" });
    // }

    // if (event.target.checked && this.isSelected == 0) {
    this.isSelected = 1;
    this.handleCheckboxIsChecked(event);

    this.dataFromChildThumbnail(
      {
        image_url: image.image_ref,
      },
      image
    );
    // }

    // if (!event.target.checked && this.isSelected == 1) {
    //   this.isSelected = 0;
    //   this.handleCheckboxIsChecked(event);

    //   this.dataFromChildThumbnail(
    //     {
    //       image_url: image.image_ref,
    //     },
    //     image
    //   );
    // }



    console.log(this.state.imagesToSave)



  };

  handleCheckboxIsChecked = (event) => {
    const item = event.target.name;
    const isChecked = event.target.checked;

    if (isChecked) {
      this.setState(() => ({
        checkedItems: new Map(),
      }));

      this.setState((prevState) => ({
        checkedItems: prevState.checkedItems.set(item, isChecked),
      }));
    }
    console.log(this.state.checkedItems)

  };


  handleScroll = (event) => {

    // console.log('scroll triggered')

    // console.log('Top ' + event.target.scrollTop)
    // console.log('Height ' + event.target.scrollHeight)
    // console.log('Offset ' + event.target.offsetHeight)



    // if (event.target.scrollHeight != this.scrollheight) {

    if (event.target.scrollTop + event.target.offsetHeight >= event.target.scrollHeight - 100 && this.scrollheight < event.target.scrollHeight) {


      console.log('down')
      this.scrollheight = event.target.scrollHeight


      this.setState({ btn_text: 'Loading...' })
      this.setState({ image_loop: this.state.image_loop + 10 })
      this.setState({ btn_disabled: true });

    }


  };


  onImgLoad(length) {
    this.loadedCount++;
    console.log(this.loadedCount)

    if (this.loadedCount % 10 === 0) {
      setTimeout(function () {
        this.setState({ btn_text: 'Load More' })
        this.setState({ btn_disabled: false });
      }.bind(this), 1000);
      this.setState({ btn_show: true });

    }

    //   this.setState({ image_loop: this.state.image_loop + 3 })


    if (this.loadedCount === length) {
      console.log('all loaded')

      this.setState({ isLoaded: true });
      this.setState({ imageCount: length });

      setTimeout(() => {
        if (GridScrollFx && document.getElementById("grid")) {
          new GridScrollFx(document.getElementById("grid"), {
            viewportFactor: 0,
          });

          $("#grid li").each(function () {
            if (!$(this).hasClass("shown")) {
              $(this).addClass("shown");
            }
          });

          setTimeout(() => {
            $(".gallery-scroll")
              .niceScroll({
                cursorcolor: "#888888",
                cursorwidth: "8px",
                background: "#d8d8d8",
                cursorborder: "1px solid #d8d8d8",
                autohidemode: false,
              })
              .resize();
          }, 100);
        }
      }, 100);
    }
  }

  showCustomFancyBox(index) {
    this.setState({
      showFancyBox: true,
      fancyImageIndex: index,
    });
  }

  closeCustomFancyBox() {
    this.setState({ showFancyBox: false });
  }

  fancyNext() {
    const fancyImageIndex =
      this.state.fancyImageIndex === this.props.question.images.length - 1
        ? 0
        : this.state.fancyImageIndex + 1;

    console.log(this.state.fancyImageIndex)
    if (this.state.fancyImageIndex < this.loadedCount - 1)
      this.setState({ fancyImageIndex });
  }

  fancyPrev() {
    const fancyImageIndex =
      this.state.fancyImageIndex === 0
        ? this.props.question.images.length - 1
        : this.state.fancyImageIndex - 1;
    console.log(this.state.fancyImageIndex)
    if (this.state.fancyImageIndex != '' && this.state.fancyImageIndex > 0)
      this.setState({ fancyImageIndex });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };


  render() {
    const { collections } = this.props

    let images = null;
    const group = JSON.parse(localStorage.getItem("group"))
    const data = [];
    if (collections && collections.length > 0) {
      collections.map(doc => {
        if (doc) {
          data.push(doc)
        }
        return doc
      })
    }
    if (group === null) {
      //   images = this.props.question.images
      images = data.filter(image => image.collectionName === 'New_Default_Spark_Set')
    } else {
      group.map(value => {
        if (value.collection) {
          if (value.collection === "default") {
            // console.log("Images", this.props.question)
            images = this.props.question.images
          } else {
            images = data.filter(image => image.collectionName === value.collection)
          }
        } else {
          images = data.filter(image => image.collectionName === 'New_Default_Spark_Set')
        }
        return value
      })
    }

    let count_total = images.length
    images = images.slice(0, this.state.image_loop)



    const loadingPadding = <div className="loading-padding"></div>;
    // onScroll={this.handleScroll}
    return (
      <div id="main" className="image-selection">
        {this.state.showFancyBox ? (
          <CustomFancyBox
            close={this.closeCustomFancyBox.bind(this)}
            prev={this.fancyPrev.bind(this)}
            next={this.fancyNext.bind(this)}
            images={images}
            index={this.state.fancyImageIndex}
            handleSelectImage={this.handleSelectImage.bind(this)}
            checked={this.state.checkedItems.get(
              `image-${this.state.fancyImageIndex}`
            )}
            name={`image-${this.state.fancyImageIndex}`}
          />
        ) : (
          ""
        )}
        <div className="container v-gallery">
          <div className="row">
            <div className="col-md-12 back-nav">
              <Link to={`/${this.state.path}/question`} className="goto_back">
                <i></i>Back
              </Link>
            </div>
            <div className="progress-steps">
              <span className="step step-1 active"></span>
              <span className="step step-2 active"></span>
              <span className="step step-3"></span>
            </div>
            <div className="col-md-12 images-box" >

              <div className="images-wrap" style={{ borderRadius: 5 }}>
                <div className="mb-3 font-weight-bold text-center">Question {this.state.question_count} of {this.state.question_total}</div>

                {this.state.question.content ? (
                  <div className="small-title">
                    {this.state.question.content}
                    <div className="guidingText">
                      Select image using the circle in the top right. Click on
                      the image for a larger view.
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="">

                </div>
                <form onSubmit={this.handleNextStepSavedImages} onScroll={this.handleScroll} >
                  <div className="view-gallery" style={{ overflow: 'auto', height: 'calc(100vh - 361px)' }}>
                    {!this.state.isLoaded && loadingPadding}
                    <section className="grid-wrap" >
                      <ul className="grid swipe-down" id="grid">


                        {this.state.allow_upload ? (

                          <li onClick={this.toggle}
                            style={{ cursor: "pointer", background: '#b6badf', height: '240px' }}
                            className="shown" key={Math.random()}>
                            <div style={{
                              padding: '47px 27px', height: '200px', margin: '20px', background: '#fff',
                              textAlign: 'center', fontSize: '30px', border: '1px dotted black'
                            }}>
                              <FaUpload /><div style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                Upload your own
                              </div>
                            </div>
                          </li>


                        ) : (
                          ""
                        )}



                        {images.map((image, index) => {
                          return (
                            <li key={index}>
                              <ImageCheckbox
                                name={`image-${index}`}
                                checked={this.state.checkedItems.get(
                                  `image-${index}`
                                )}
                                onChange={this.handleSelectImage.bind(
                                  this,
                                  image
                                )}
                              />
                              <img
                                onClick={this.showCustomFancyBox.bind(
                                  this,
                                  index
                                )}
                                onLoad={this.onImgLoad.bind(
                                  this,
                                  images.length
                                )}
                                src={image.image_ref}
                                alt={this.props.alt || null}
                                data-ref={this.state.image_loop}
                              />
                            </li>
                          );
                        })}
                      </ul>

                      {(count_total !== this.state.imageCount && this.state.btn_show) ? (
                        <button
                          disabled={this.state.btn_disabled}
                          onClick={this.handleClick}
                          className="text-center btn btn-default"
                          style={{ padding: `10px`, fontSize: 16, cursor: 'pointer', margin: 'auto', marginTop: '10px', marginBottom: '70px', background: '#f2f2f2', display: 'block', width: '98%' }}
                        >
                          {this.state.btn_text}
                          {/* <img src={loading} alt="loading..." /> */}
                        </button>
                      ) : (
                        ""
                      )}

                      {(!this.state.btn_show && count_total !== this.state.imageCount) ? (
                        <div
                          className="text-center"
                          style={{ padding: `10px` }}
                        >
                          <img src={loading} alt="loading..." />
                        </div>
                      ) : (

                        ""

                      )}


                    </section>
                  </div>
                  <div className="button-wrap next_step mt-20">
                    <button
                      className={
                        "btn-primary btn-gallery " +
                        (this.state.imagesToSave.length ? "active" : "inactive")
                      }
                    >
                      NEXT
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div >
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  const { image: { collections } } = state;
  return {
    question: state.question,
    collections
  };
};

const mapDispatchToProps = (dispatch) => ({
  lazyFetchImages: (limit, lastVisible) =>
    dispatch(lazyFetchImages(limit, lastVisible)),
  imageCount: () => dispatch(imageCount()),
  getCollections: () => dispatch(getCollections())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DemoImageActivity);
