import React, { Component } from 'react';

import { Switch } from "react-router-dom";

import UserPrivateRoute from '../private-route/UserPrivateRoute';
import AdminPrivateRoute from '../private-route/AdminPrivateRoute';
import GuestRoute from '../private-route/GuestRoute';
import SignIn from '../auth/SignIn.js';
import SignIn_2 from '../auth/SignIn_2.js';
import SignUp from '../auth/SignUp.js';
import SSO from '../auth/SSO.js';
import ForgotPassword from '../auth/ForgotPassword';
import ResetPassword from '../auth/ResetPassword';
import Dashboard from '../user/Dashboard';
import ActivityType from '../user/ActivityType';
import ActivityResult from '../user/ActivityResult';
import TeamActivity from '../user/TeamActivity';
import PersonalQuestionActivity from '../user/PersonalQuestionActivity';
import SelectImageActivity from '../user/SelectImageActivity';
import TeamQuestionActivity from '../user/TeamQuestionActivity';
import PersonalActivity from '../user/PersonalActivity';
import SparqFeatureRequest from '../user/SparqFeatureRequest';
import AddNewIdea from '../user/AddNewIdea';
import AdminDashboard from '../admin/AdminDashboard';
import ResultsTeam from '../user/Results/ResultsTeam';
import SavedImages from '../user/SavedImages';
import FinalImage from '../user/FinalImage';
import ResponsePage from '../user/ResponsePage';
import CurrentResult from '../user/CurrentResult';
import AdminGroups from '../admin/AdminGroups';
import AdminQuestions from '../admin/AdminQuestions';
import ResultsPersonal from '../user/ResultsPersonal';
import PersonalImage from '../user/ResultsPersonal/PersonalImage';
import AdminImages from '../admin/AdminImages';
import ThankYou from '../user/ThankYou';
import ResultsIndividual from '../user/Results/ResultsIndividual';
import ResultsPersonalTable from '../user/ResultsPersonal/ResultsPersonalTable';
import TeamMosaic from '../user/TeamMosaic';
import MosaicCreator from '../admin/MosaicCreator';
import CommentOnIdea from '../user/SparqFeatureRequest/CommentOnIdea';
import Profile from '../user/Profile';
import HomePage from '../user/HomePage';
import HomePage_2 from '../user/HomePage_2';
import Stripe from '../user/Stripe';
import AdminReferral from '../admin/AdminReferral';

import GuestImageActivity from '../user/GuestImageActivity/GuestImageActivity';
import GuestQuestionActivity from '../user/GuestQuestionActivity/GuestQuestionActivity';
import GuestPersonalQuestionActivity from '../user/GuestPersonalQuestionActivity/GuestPersonalQuestionActivity';
import Guest from '../user/RandomUser/RandomUser';
import RandomUserJoinGroup from '../user/RandomUserJoinGroup/RandomUserJoinGroup';
import GuestSavedImages from '../user/GuestSavedImages';
import GuestFinalImage from '../user/GuestFinalImage';
import GuestResponsePage from '../user/GuestResponsePage';

import DemoQuestionActivity from '../demo_activity/DemoQuestionActivity/DemoQuestionActivity';
import DemoImageActivity from '../demo_activity/DemoImageActivity/DemoImageActivity';
import DemoResponsePage from '../demo_activity/DemoResponsePage/DemoResponsePage';
import DemoThankYou from '../demo_activity/DemoThankYou/DemoThankYou';
import DemoDashboard from '../demo_activity/DemoDashboard/DemoDashboard';


class Body extends Component {

    render() {
        return (
            <React.Fragment>
                <Switch>
                    <GuestRoute path="/" exact component={HomePage} />

                    <GuestRoute path="/home" exact component={HomePage_2} />
                    <GuestRoute path="/sign-in" exact component={SignIn_2} />
                    {/* <GuestRoute path="/sign-in-2" exact component={SignIn_2} /> */}


                    <GuestRoute path="/sso/:jwt?" exact component={SSO} />
                    <GuestRoute path="/sign-up" exact component={SignUp} />
                    <GuestRoute path="/partner/:id?" exact component={SignUp} />
                    <GuestRoute path="/forgot-password" exact component={ForgotPassword} />
                    <GuestRoute path="/reset-password" exact component={ResetPassword} />

                    <GuestRoute path="/guest-activity/question" exact component={GuestQuestionActivity} />
                    <GuestRoute path="/guest-activity/question/:key/images" exact component={GuestImageActivity} />
                    <GuestRoute path="/guest-use/question" exact component={GuestPersonalQuestionActivity} />
                    <GuestRoute path="/guest-use/question/:key/images" exact component={GuestImageActivity} />
                    <GuestRoute path="/guest-activity" exact component={RandomUserJoinGroup} />
                    <GuestRoute path="/guest-activity/saved-images/:question_key" exact component={GuestSavedImages} />
                    <GuestRoute path="/guest-activity/:question_key/final-image" exact component={GuestFinalImage} />
                    <GuestRoute path="/guest-activity/response-page/:question_key" exact component={GuestResponsePage} />
                    <GuestRoute path="/public" exact component={RandomUserJoinGroup} />

                    <GuestRoute path="/demo-use" exact component={DemoDashboard} />
                    <GuestRoute path="/demo-use/question" exact component={DemoQuestionActivity} />
                    <GuestRoute path="/demo-use/question/:key/images" exact component={DemoImageActivity} />
                    <GuestRoute path="/demo-use/response-page/:question_key" exact component={DemoResponsePage} />
                    <GuestRoute path="/demo-use/thank-you" exact component={DemoThankYou} />


                    <UserPrivateRoute path="/stripe" exact component={Stripe} />
                    <UserPrivateRoute path="/dashboard" exact component={Dashboard} />
                    <UserPrivateRoute path="/personal-use" exact component={PersonalActivity} />
                    <UserPrivateRoute path="/personal-use/question" exact component={PersonalQuestionActivity} />
                    <UserPrivateRoute path="/personal-use/question/:key/images" exact component={SelectImageActivity} />
                    <UserPrivateRoute path="/team-activity/question/:key/images" exact component={SelectImageActivity} />
                    <UserPrivateRoute path="/team-activity/question" exact component={TeamQuestionActivity} />
                    <UserPrivateRoute path="/team-mosaic/:groupKey" exact component={TeamMosaic} />
                    <UserPrivateRoute path="/team-mosaic/:status/:groupKey" exact component={TeamMosaic} />
                    <UserPrivateRoute path="/ideas" exact component={SparqFeatureRequest} />
                    <UserPrivateRoute path="/ideas/:id" exact component={CommentOnIdea} />
                    <UserPrivateRoute path="/add-new-idea" component={AddNewIdea} />
                    <UserPrivateRoute path="/select-activity" exact component={ActivityType} />
                    <UserPrivateRoute path="/team-activity" exact component={TeamActivity} />
                    <UserPrivateRoute path="/:activity_type/saved-images/:question_key" exact component={SavedImages} />
                    <UserPrivateRoute path="/:activity_type/:question_key/final-image" exact component={FinalImage} />
                    <UserPrivateRoute path="/:activity_type/response-page/:question_key" exact component={ResponsePage} />
                    <UserPrivateRoute path="/view-results-team" exact component={ResultsTeam} />
                    <UserPrivateRoute path="/results-individual/:view_type/:group_id" exact component={ResultsIndividual} />
                    <UserPrivateRoute path="/results-personal" exact component={ResultsPersonal} />
                    <UserPrivateRoute path="/personal-image" exact component={PersonalImage} />
                    <UserPrivateRoute path="/current-result" exact component={CurrentResult} />
                    <UserPrivateRoute path="/result" exact component={ActivityResult} />
                    <UserPrivateRoute path="/thank-you" exact component={ThankYou} />
                    <UserPrivateRoute path="/view-user-results" exact component={ResultsPersonalTable} />
                    <UserPrivateRoute path="/results-team/:view_type/:group_id" exact component={ResultsTeam} />
                    <UserPrivateRoute path="/user/profile" exact component={Profile} />
                    <AdminPrivateRoute path="/admin" exact component={AdminDashboard} />
                    <AdminPrivateRoute path="/admin/referral" exact component={AdminReferral} />
                    <AdminPrivateRoute path="/admin/groups" exact component={AdminGroups} />
                    <AdminPrivateRoute path="/admin/questions" exact component={AdminQuestions} />
                    <AdminPrivateRoute path="/admin/images" exact component={AdminImages} />
                    <AdminPrivateRoute path="/admin/mosaic" exact component={MosaicCreator} />
                    <AdminPrivateRoute path="/results-team-admin/:view_type/:group_id" exact component={ResultsTeam} />
                    <AdminPrivateRoute path="/results-individual-admin/:view_type/:group_id" exact component={ResultsIndividual} />
                </Switch>
            </React.Fragment>
        );
    };
};

export default Body;
