import React, { Component, Fragment } from "react";
import Modal from "react-responsive-modal";

import ShareButtons from "../../main/ShareButtons";
import EmailRecipientsModal from "../EmailRecipientsModal";
import history from "../../../history";
import { connect } from "react-redux";
import moment from 'moment';

class ResultsPersonalTableRow extends Component {
  state = {
    openResultsModal: false,
    openEmailRecepientModal: false,
  };

  onResultsClick = () => {
    this.setState({ openResultsModal: !this.state.openResultsModal });
  };

  onResultsClickGroup = () => {
    history.push({
      pathname: `/results-team/view/${this.props.data.activities.group_id}`,
    });
  };

  onMosaicClickGroup = () => {
    history.push({
      pathname: `/team-mosaic/view/${this.props.data.activities.group_id}`,
    });
  };

  handlePrint = () => {
    window.$('footer').hide();
    window.$('iframe#launcher-frame').hide();
    window.print();
    window.$('footer').show();
    window.$('iframe#launcher-frame').show();

  };

  handleRecipientModal = (openEmailRecepientModal) => {
    this.setState({ openEmailRecepientModal, openResultsModal: false });
  };

  render() {
    const { openResultsModal } = this.state;
    const modalStyle = {
      modal: {
        maxWidth: "800px",
        width: "100%",
      },
    };

    const buttonstyleResults = {
      backgroundImage: "linear-gradient(95deg, #2a3382, #2a3382)",
      border: "none",
      padding: "10px 10px",
      color: "white",
      marginBottom: "5px",
      borderRadius: "5px",
    };

    const buttonstyleMosaic = {
      backgroundImage: "linear-gradient(98deg, #19347f, #19347f)",
      border: "none",
      padding: "10px 11px",
      marginBottom: "5px",
      color: "white",
      borderRadius: "5px",
    };

    let tempQuestion = this.props.data.activities.question;

    if (this.props.data.activities.multipleQuestionResponse) {
      tempQuestion = this.props.data.activities.multipleQuestionResponse.answers.map((ans, index) => {
        return <p>{ans.question}</p>
      });
    }

    return (
      <Fragment>
        <tr
          className=""
          style={
            !this.props.data.activities.group_id
              ? { backgroundColor: "rgb(199 220 255)" }
              : { backgroundColor: "#aadade" }
          }
        >
          <td style={{ verticalAlign: 'middle' }}>
            {this.props.data.activities.group_id
              ? this.props.data.activities.group_id
              : `-`}
          </td>
          <td style={{ verticalAlign: 'middle' }}>{tempQuestion}</td>
          <td>
            <img src={this.props.data.activities.image_url} alt="table" style={{ width: "150px" }} />
          </td>
          <td style={{ verticalAlign: 'middle' }}>{moment(this.props.data.date.toLocaleDateString("en-US")).format('MMMM D, YYYY')}</td>


          <td>
            <button
              style={buttonstyleResults}
              onClick={
                !this.props.data.activities.group_id
                  ? this.onResultsClick
                  : this.onResultsClickGroup
              }
            >
              {"  "}
              View Results
            </button>
            {this.props.data.activities.group_id && (
              <button
                onClick={this.onMosaicClickGroup}
                style={buttonstyleMosaic}
              >
                {"    "}
                View Mosaic{"    "}
              </button>
            )}
          </td>
        </tr>
        <Modal
          open={openResultsModal}
          onClose={this.onResultsClick}
          styles={modalStyle}
        >
          <div id="main">
            <div className="form-padding-box">
              <div className="form-wrap">
                <div
                  id="to-print"
                  className="item"
                  style={{
                    transition: "opacity 250ms ease-in",
                  }}
                >
                  <div className="author-title">
                    {this.props.data.activities.question}
                  </div>
                  <div className="msg-title" style={{ marginBottom: "38px" }}>
                    {this.props.data.activities.response}{" "}
                  </div>
                  <div className="img-wrap" style={{ textAlign: "center" }}>
                    <img
                      style={{
                        width: "auto",
                        maxHeight: "40vh",
                      }}
                      src={this.props.data.activities.image_url}
                      className="final-image"
                      alt="alt-final"
                    />
                  </div>
                  <ShareButtons
                    noPrint="no-print"
                    question={this.props.data.activities.question}
                    imageUrl={this.props.data.activities.image_url}
                  />
                  <div className="no-print">
                    <div className="row mt-15">
                      <div className="col-md-6">
                        <button
                          onClick={() => this.handleRecipientModal(true)}
                          type="button"
                          className="btn-primary grey-bg"
                        >
                          EMAIL
                        </button>
                      </div>
                      <div className="col-md-6 sec-col">
                        <button
                          className="btn-primary grey-bg"
                          onClick={this.handlePrint}
                        >
                          PRINT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <EmailRecipientsModal
          data={this.props.data.activities}
          imageUrl={
            this.props.data.activities && this.props.data.activities.image_url
          }
          open={this.state.openEmailRecepientModal}
          openModal={this.handleRecipientModal.bind(this)}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { profile } = state.firebase;
  return {
    profile,
  };
};

export default connect(mapStateToProps)(ResultsPersonalTableRow);
