import {
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_ERROR,
  AUTHENTICATE_ACCOUNT_SUCCESS,
  AUTHENTICATE_ACCOUNT_ERROR,
  SIGNOUT_USER_SUCCES,
  AUTHENTICATE_EMAIL_FORGOT_PASSWORD_SUCCESS,
  AUTHENTICATE_EMAIL_FORGOT_PASSWORD_ERROR,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  RESET_CODE_VALIDATE_SUCCESS,
  RESET_CODE_VALIDATE_ERROR,
} from "@app/action-types";

import { UserModel } from "@app/models";
import history from "../history";
import { toast } from "react-toastify";

toast.configure();

export const refreshErrors = () => {
  return (dispatch, getState) => {
    dispatch({ type: "" });
  };
};

export const authenticateUser = (authenticate) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(authenticate.email, authenticate.password)
      .then(() => {
        window.localStorage.setItem("email", authenticate.email);
        dispatch({ type: AUTHENTICATE_ACCOUNT_SUCCESS });
      })
      .catch((err) => {
        dispatch({ type: AUTHENTICATE_ACCOUNT_ERROR, err });
      });
  };
};

export const signUp = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((res) => {
        return firestore
          .collection("users")
          .doc(res.user.uid)
          .set(UserModel(newUser));
      })
      .then(() => {
        dispatch({ type: SIGNUP_USER_SUCCESS });
      })
      .catch((err) => {
        dispatch({ type: SIGNUP_USER_ERROR, err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.localStorage.removeItem('email');
        dispatch({ type: SIGNOUT_USER_SUCCES });
      });
  };
};

export const updateUser = (email) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("users")
      .doc(email)
      .update({
        user_type: "premium",
      });
  };
};

export const getUserInfo = (uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const user = await firestore
      .collection("users")
      .doc(uid)
      .get();
    return user.data().name;
  };
};

export const validatePasswordResetCode = (code) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .verifyPasswordResetCode(code)
      .then((res) => {
        dispatch({ type: RESET_CODE_VALIDATE_SUCCESS });
      })
      .catch((err) => {
        dispatch({ type: RESET_CODE_VALIDATE_ERROR, err });
      });
  };
};

export const handleSendPasswordResetEmail = (email) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  const actionCodeSettings = {
    url: "https://discoversparq.com/",
    handleCodeInApp: true,
  };
  try {
    window.localStorage.setItem("resetEmail", email);
    const actionCreatorResponse = await firebase
      .auth()
      .sendPasswordResetEmail(email, actionCodeSettings);
    await actionCreatorResponse;
    dispatch({ type: AUTHENTICATE_EMAIL_FORGOT_PASSWORD_SUCCESS });
    toast("Success! Please check your email", { type: "success" });
    history.push("/sign-in");
  } catch (error) {
    toast("Email verification failed", { type: "error" });
    dispatch({ type: AUTHENTICATE_EMAIL_FORGOT_PASSWORD_ERROR, error });
  }
};

export const handleResetPassword = (credentials) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();

  try {
    firebase
      .auth()
      .confirmPasswordReset(credentials.code, credentials.password);
    dispatch({ type: PASSWORD_RESET_SUCCESS });
  } catch (err) {
    dispatch({ type: PASSWORD_RESET_ERROR, err });
  }
};

export const handleVerifyPasswordResetCode = (actionCode) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();

  try {
    const email = await firebase.auth().verifyPasswordResetCode(actionCode);
    await email;
  } catch (error) {
    history.push({
      pathname: "action-code-error",
      state: { error },
    });
  }
};

export const handleVerifyEmail = (actionCode, continueUrl) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();

  try {
    const response = await firebase.auth().applyActionCode(actionCode);
    await response;

    history.push("/");
  } catch (error) {
    history.push({
      pathname: "/action-code-expired",
      state: { error },
    });
  }
};
