import {
  UPLOAD_IMAGES_SUCCESS,
  UPLOAD_IMAGES_ERROR,
  DELETE_IMAGES_SUCCESS,
  DELETE_IMAGES_ERROR,
} from "@app/action-types";

const initialState = {
  images_url: [],
  imageError: null,
  imageUrl: null,
  collections: [],
};

const imageReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "GET_COLLECTIONS":
      return {
        ...state,
        collections: action.payload
      }
    case UPLOAD_IMAGES_SUCCESS:
      return {
        ...state,
        imageError: null,
      };
    case UPLOAD_IMAGES_ERROR:
      return {
        ...state,
        imageError: action.err,
      };
    case DELETE_IMAGES_SUCCESS:
      return {
        ...state,
        imageError: null,
      };
    case DELETE_IMAGES_ERROR:
      return {
        ...state,
        imageError: action.err,
      };
    default:
      return state;
  }
};

export default imageReducer;
