import React from 'react';

import './style.css';

class CustomFancyBox extends React.Component {

    onChange(image, event) {
        this.props.handleSelectImage(image, event);
    }
    
    render() {
        const { images, index, checked, name } = this.props;
        if (!images.length) {
            return <span>Loading</span>;
        }

        const image = images[index];

        return (
            <div className="custom-fancy-box">
                <div className="fancy-counter">
                    <label className="fancy-counter-label">{index + 1}/{images.length}</label>
                </div>
                <div className="fancy-close">
                    <button onClick={this.props.close} className="fancy-close-btn">&#215;</button>
                </div>
                <div className="fancy-image-container">
                    <div className="fancy-image-subcontainer">
                        <span className="fancy-image-wrapper">
                            <img onClick={this.onChange.bind(this, image)} src={image.image_ref} alt="Grid Item" />
                            <span className="fancy-checkbox view-gallery">
                                <label className={checked ? 'active' : ''} data-id="2">
                                    <input
                                        onChange={this.onChange.bind(this, image)}
                                        type="checkbox"
                                        name={name}
                                        className={"sub-checkbox check-" + this.props.index}
                                        checked={checked}
                                    />
                                </label>

                            </span>
                            <div className="mobile-buttons">
                                <div className="fancy-prev-btn fancy-action action-left">
                                    <button onClick={this.props.prev} className="next-btn">&#x276C;</button>
                                </div>
                                <div className="fancy-next-btn fancy-action action-right">
                                    <button onClick={this.props.next} className="prev-btn">&#x276D;</button>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div className="fancy-prev-btn fancy-action desktop-facy-btn">
                    <button onClick={this.props.prev} className="next-btn">&#x276C;</button>
                </div>
                <div className="fancy-next-btn fancy-action desktop-facy-btn">
                    <button onClick={this.props.next} className="prev-btn">&#x276D;</button>
                </div>
            </div>
        );
    }
}

export default CustomFancyBox;
