import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getGroupActivities } from '@actions/activity';

const $ = window.$;
const Freewall = window.Freewall;

class TeamMosaic extends React.Component {

    componentDidMount() {

        const script = document.createElement("script");
        script.src = "https://d3js.org/d3.v4.min.js";
        script.async = false;
        document.body.appendChild(script);
        
        this.props.getGroupActivities(this.props.match.params.groupKey, () => {
            const activities = this.calculateImageUsers(this.props.activity.activities);
            console.log('HERE',activities);
            this.displayImages(activities);
        });
    }

    getTotalUserCount(activities) {
        let total = 0;
        for (let i = 0; i < activities.length; i++) {
            total += activities[i].count;
        }
        return total;
    }

    displayImages(activities) {
        const total = this.getTotalUserCount(activities);
        let temp = "<div class='brick' style='width:{width}px;'><img src='{src}' width='100%'></div>";
        let w = 1, html = '', limitItem = activities.length;
        for (var i = 0; i < limitItem; ++i) {
            const percentage = activities[i].count / total;
            w = (1 + 7 * percentage << 0);
            // removing ' from url
            if ( activities[i].url ){
                activities[i].url = activities[i].url.replace(/'/g, '%27');
                html += temp.replace(/\{width\}/g, w * 180).replace("{src}", activities[i].url);
            }
        }

        html = `window.dataset = {
            "children":[`;

        for (var i = 0; i < limitItem; ++i) {
        
            if ( activities[i].url ){

                // removing ' from url
                activities[i].url = activities[i].url.replace(/'/g, '%27');  
                              

                // creating array of responses
                html += ` {"Name":"","Count":${activities[i].count},"image":"${activities[i].url}"}, `;

            }

        }

        html += ']};';

        $("#freewall").html(`<script>
                    
        ${html}
        
        </script>
        
        <script src="/assets/js/chart.js"></script>
        `);

        //$("#freewall").css('justify-content','center').css('align-items','center');
        /*
        var wall = new Freewall("#freewall");
        wall.reset({
            selector: '.brick',
            animate: true,
            cellW: w * 150,
            cellH: 'auto',
            gutterX: 0,
            gutterY: 0,
            onResize: function () {
                wall.fitWidth();
            }
        });
        var images = wall.container.find('.brick');
        images.find('img').load(function () {
            wall.fitWidth();
        });
        */
    }

    calculateImageUsers(activities) {
        
        let images = []; 

        activities.map((activity) =>{ 
            
            if(activity.multipleQuestionResponse && Array.isArray(activity.multipleQuestionResponse.answers)){
            
                
                activity.multipleQuestionResponse.answers.map((ans)=>{
                    console.log(ans.image_url);
                    images.push(ans.image_url);
                });

            }

            else{
                images.push(activity.image_url);
            }
            
            
        });

        console.log('images',images);
        images.sort();

        let current = null;
        let cnt = 0;

        let newObject = [];

        for (var i = 0; i < images.length; i++) {
            if (images[i] !== current) {
                if (cnt > 0) {
                    newObject.push({ url: current, count: cnt });
                }
                current = images[i];
                cnt = 1;
            } else { cnt++; }
        }
        if (cnt > 0) {
            newObject.push({ url: current, count: cnt });
        }

        return newObject;
    }

    render() {
        return (
            <div id="main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 back-nav">
                            <Link to={this.props.match.params.status === 'view' ? '/view-user-results' : '/current-result'} className="goto_back"><i></i>Back</Link>
                        </div>
                        <div className="col-md-12 images-box">
                            <div className="images-wrap">
                                <div className="small-title">TEAM MOSAIC</div>
                                <div id="freewall" className="mosaic-gallery" style={{ marginLeft: "30px", marginRight: "30px" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activity: state.activity
    };
};

const mapDispatchToProps = dispatch => ({
    getGroupActivities: (groupKey, callBack) => dispatch(getGroupActivities(groupKey, callBack))
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamMosaic);
