import {
  ADD_QUESTION,
  GET_QUESTION,
  EDIT_QUESTION,
  DELETE_QUESTION,
  SET_ALL_QUESTIONS,
  SET_QUESTION_IMAGES,
  SET_QUESTION_DEFAULT_SUCCESS,
  SET_QUESTION_DEFAULT_ERROR,
  SET_QUESTION_IMAGES_LAST_VISIBLE,
  SET_IMAGE_COUNT,
} from "@app/action-types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const formatQuestion = (question, dispatch) => {
  let payload = question.data();
  payload.key = question.id;
  return dispatch({ type: GET_QUESTION, payload });
};

export const getDefaultQuestion = (type) => {
  return (dispatch, getState, { getFirestore }) =>
    getFirestore()
      .collection("questions")
      .where("type", "==", type)
      .get()
      .then((question) => formatQuestion(question.docs[0], dispatch));
};

export const getQuestionByKey = (key) => {
  return (dispatch, getState, { getFirestore }) =>
    getFirestore()
      .collection("questions")
      .doc(key)
      .get()
      .then((question) => formatQuestion(question, dispatch));
};

export const getNextQuestion = (index) => {
  return (dispatch, getState, { getFirestore }) =>
    getFirestore()
      .collection("questions")
    //   .orderBy("index")
      .where("question_type", "==", "personal")
    //   .where("index", ">", index)
      .get()
      .then((question) => {
        if(question.size > 0){
            if ( window.localStorage.getItem('randQuestion') === null ) 
                window.localStorage.setItem('randQuestion', 0);

            var question_val = window.localStorage.getItem('randQuestion');

            if ( !question.docs[question_val] ){
                window.localStorage.setItem('randQuestion', 0);
                dispatch(getNextQuestion("default"));
            }else{
                formatQuestion(question.docs[question_val], dispatch)
                question_val++;
                window.localStorage.setItem('randQuestion', question_val);
            }
            
        }
        })
      .catch((error) => console.log("Error", error));

};

export const getAllQuestions = () => {
  return (dispatch, getState, { getFirestore }) =>
    getFirestore()
      .collection("questions")
      .orderBy("content")
      .get()
      .then(({ docs }) => {
        return dispatch({
          type: SET_ALL_QUESTIONS,
          payload: docs.map((question) => {
            return {
              ...question.data(),
              key: question.id,
            };
          }),
        });
      });
};
export const deleteQuestion = (key) => {
  return (dispatch, getState, { getFirestore }) =>
    getFirestore()
      .collection("questions")
      .doc(key)
      .delete()
      .then(() => {
        dispatch({ type: DELETE_QUESTION });
      })
      .catch((err) => { });
};

export const createQuestion = (data) => {
  return (dispatch, getState, { getFirestore }) =>
    getFirestore()
      .collection("questions")
      .add(data)
      .then((res) => {
        toast("Success! Question created", { type: "success" });
        dispatch({ type: ADD_QUESTION });
      });
};

export const editQuestion = (question) => {
  return (dispatch, getState, { getFirestore }) =>
    getFirestore()
      .collection("questions")
      .doc(question.key)
      .update({
        content: question.content,
        question_type: question.questionType,
      })
      .then(() => {
        dispatch({ type: EDIT_QUESTION });
      });
};

export const lazyFetchImages = (limit, lastVisible) => {
  return (dispatch, getState, { firebase, getFirestore }) => {
    let query;

    if (lastVisible) {
      query = getFirestore()
        .collection("question_images")
        .startAfter(lastVisible)
        .limit(limit);
    } else {
      query = getFirestore()
        .collection("question_images")
    }

    query.get().then((documentSnapshotsImages) => {
      const storageRef = firebase.storage().ref();
      var lastVisible =
        documentSnapshotsImages.docs[documentSnapshotsImages.docs.length - 1];
      dispatch({
        type: SET_QUESTION_IMAGES_LAST_VISIBLE,
        payload: lastVisible,
      });
      dispatch({
        type: SET_QUESTION_IMAGES,
        payload: documentSnapshotsImages.docs.map((image) => {
          return {
            ...image.data(),
            ref: storageRef.child("images/" + image.data().image_name),
          };
        }),
      });
    });
  };
};

export const imageCount = () => {
  return (dispatch, getState, { getFirestore }) => {
    getFirestore()
      .collection("question_images")
      .get()
      .then((snap) => {
        dispatch({
          type: SET_IMAGE_COUNT,
          payload: snap.size,
        });
      });
  };
};

export const setDefaultQuestion = (data) => {
  return (dispatch, getState, { getFirestore }) => {
    if (data.normalquestion.length > 0) {
      getFirestore()
        .collection("questions")
        .doc(data.normalquestion[0].id)
        .update({ type: "normal" })
        .then(() => {
          return getFirestore()
            .collection("questions")
            .doc(data.default_key)
            .update({ type: "default", index: "normal-1" });
        })
        .then(() => {
          dispatch({ type: SET_QUESTION_DEFAULT_SUCCESS });
        })
        .catch((err) => {
          dispatch({ type: SET_QUESTION_DEFAULT_ERROR, err });
        });
    } else {
      getFirestore()
        .collection("questions")
        .doc(data.default_key)
        .update({ type: "default", index: "normal-1" })
        .then(() => {
          dispatch({ type: SET_QUESTION_DEFAULT_SUCCESS });
        })
        .catch((err) => {
          dispatch({ type: SET_QUESTION_DEFAULT_ERROR, err });
        });
    }
  };
};
