import React, { Component } from 'react';
import Ideas from './Ideas';
import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import FeatureRequestHeader from './FeatureRequestHeader';
import "./sparqFeatureRequest.css"
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { signOut } from '@actions/auth';
import { addIdea, onFilterByCategory, onFilterByMy } from '@actions/ideas';
import { connect } from "react-redux";
import { FaSignOutAlt } from "react-icons/fa";
import { TiEdit } from "react-icons/ti"
import { ImCross} from 'react-icons/im'
import { Link ,Route} from 'react-router-dom'

    const filterStyle   =  {backgroundColor:'#2a3382',
                            color:'white',
                            borderRadius:'5px',
                            padding:'2px'}

            const style = {color:'#2a3382',
                        paddingLeft:'9px'}

            const btnStyle={ 
                            height:29,
                            width: 180,
                            padding:0 ,
                            border:'2px solid #2a3382',
                            color:'#2a3382',  
                            backgroundColor:"whitesmoke"}
class SparqFeatureRequest extends Component {

    constructor() {
        super();
    }
    openAddNewIdea = () => {
        this.props.history.push("/add-new-idea")
    }
    render() {
  
        const { email } = this.props.auth
        const {
            filtered,
            category_filter,
            status_filter,
            my_filter } = this.props.ideas

        return (
            <div style={{fontSize:'13px'}}>
                <FeatureRequestHeader />
                <Container>
                    <Row className="Container">
                        <Col xs={1}></Col>
                        <Col xs={2} className="leftContent">
                            <Button
                                style={btnStyle}
                                variant="outline-primary"
                                className="btn-primary"
                                onClick={this.openAddNewIdea}
                            >
                                Add a new idea
                            </Button>
                            
                            <div className="">
                                <ul className="List">
                                    <li>
                                        <br/>
                                        <div    class="d-flex justify-content-between align-content-center"  
                                                style={this.props.my_filter == 'myidea'?filterStyle:style} 
                                                onClick={() => { this.props.onFilterByMy("myidea") }}>
                                            <span class="flex-grow-2 "   >My Idea </span>
                                            <span> {this.props.my_filter == 'myidea' ?
                                                    <ImCross style={{fontSize:'10px'}}/> :
                                                    <b>{this.props.my_Ideas}</b>}
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="d-flex justify-content-between align-content-center" 
                                             style={this.props.my_filter == 'myvote'?filterStyle:style}  
                                             onClick={() => { this.props.onFilterByMy("myvote") }} >
                                            <span class="flex-grow-2" >My Vote </span>
                                            <span> {this.props.my_filter == 'myvote' ?
                                                    <ImCross style={{fontSize:'10px'}}/> 
                                                    :<b>{this.props.my_Vote}</b>}
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div    class="d-flex justify-content-between align-content-center" 
                                                style={this.props.my_filter == 'mysub'?filterStyle:style} 
                                                onClick={() => { this.props.onFilterByMy("mysub") }}>
                                                <span class="flex-grow-2"  >My Subscription </span>
                                               <span> {this.props.my_filter == 'mysub' ?<ImCross style={{fontSize:'10px'}}/> :<b>{this.props.my_Subs}</b>}</span>
                                        </div>
                                    </li>

                                </ul>
                                <p style={{ fontSize: 12, fontWeight: "bold" }}>FILTER BY STATUS</p>
                                <ul className="List"   style={{color:'#2a3382'}}  >
                                    <li>
                                        <a className="listByStatus">
                                            Already exists
                                        </a>
                                    </li>
                                    <li>
                                        <a className="listByStatus">
                                            Will not implement
                                        </a>
                                    </li>
                                    <li>
                                        <a className="listByStatus">
                                            Planned
                                        </a>
                                    </li>
                                    <li>
                                        <a className="listByStatus">
                                            Shipped
                                        </a>
                                    </li>
                                </ul>
                                <p style={{ fontSize: 12, fontWeight: "bold" }}>FILTER BY CATEGORY</p>
                                <ul className="List">
                                    <li>
                                        <div class="d-flex justify-content-between align-content-center" 
                                         style={category_filter == 2?filterStyle:style}
                                           onClick={() => { this.props.onFilterByCategory(this.props.ideas, 2) }}>
                                            <span class=" flex-grow-2"   > Admin &nbsp;<AiOutlineQuestionCircle /></span>
                                            <span> {category_filter == 2 ?
                                                    <ImCross style={{fontSize:'10px'}}/> :
                                                    <b>{this.props.a_IdeasCount}</b>}
                                            </span>
                                        </div>

                                    </li>
                                    <li>
                                        
                                        <div class="d-flex justify-content-between align-content-center"  
                                            onClick={() => { this.props.onFilterByCategory(this.props.ideas, 1) }}
                                            style={category_filter == 1?filterStyle:style}  >

                                            <span class="flex-grow-2"> General &nbsp;<AiOutlineQuestionCircle /></span>
                                            <span> {category_filter == 1 ?
                                                    <ImCross style={{fontSize:'10px'}}/> :
                                                    <b>{this.props.g_IdeasCount}</b>}
                                            </span>
                                        </div>

                                    </li>
                                    <li>
                                    <Link to="/" style={{color:'#2a3382',fontSize:'15px'}}>Login  /Signup</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Ideas />
                    </Row>
                </Container>
            </div>
        )
    }
}
const mapStateToProps = ({ firebase, ideas }) => {
    const { auth, profile } = firebase;
    return {
        auth,
        profile,
        ideas: ideas,
        my_filter:ideas.my_filter,
        my_Ideas: ideas.my_Ideas,
        my_Subs: ideas.my_Subs,
        my_Vote: ideas.my_Vote,
        g_IdeasCount: ideas.g_IdeasCount,
        a_IdeasCount: ideas.a_IdeasCount,

    };
};
const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(signOut()),
    onFilterByCategory: (isFilter, category) => dispatch(onFilterByCategory(isFilter, category)),
    onFilterByMy: (mytype) => dispatch(onFilterByMy(mytype))
});

export default connect(mapStateToProps, mapDispatchToProps)(SparqFeatureRequest);