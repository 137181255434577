import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { saveActivity } from "@actions/activity";
import { getUserInfo } from "@actions/auth";

import ResponsePageModal from "./ResponsePageModal";
import MaterialTextarea from "@components/main/MaterialTextarea";
import history from "../.../../../../history";
import firebase from 'firebase/app';

import "./style.css";

class ResponsePage extends Component {
  state = {
    response: "",
    name: "",
    user_id: this.props.uid,
    group_id: null,
    question: null,
    image_url: window.localStorage.getItem("setImage_url"),
    hideNameFromGroup: false,
    hideResponseFromGroup: false,
    multipleQuestionResponse: false,
    open: false,

    question_count: 1,
    question_total: 1

  };

  componentDidMount() {
    localStorage.removeItem("UploadedImage");

    if (!JSON.parse(localStorage.getItem("selectedQuestion"))) {
      history.push("/");
    } else {
      this.setState({
        question: JSON.parse(localStorage.getItem("selectedQuestion")).content,
      });
    }
  }

  componentWillMount = async () => {

    const name = await this.props.getUserInfo(this.props.uid);
    this.setState({ name });
    if (localStorage.getItem("activity_type") === "group") {
      this.setGroup();
    }

    if (localStorage.getItem('question_count')) {
      this.setState({
        question_count: localStorage.getItem('question_count')
      });

    }

    if (localStorage.getItem('question_total')) {
      this.setState({
        question_total: localStorage.getItem('question_total')
      });
    }

  };

  setGroup = () => {
    if (window.localStorage.getItem("group")) {
      this.setState({
        group_id: JSON.parse(window.localStorage.getItem("group"))[0].groupKey,
      });
    }
  };

  handleChange = (event) => {
    const { value, maxLength } = event.target;
    const message = value.slice(0, maxLength);

    this.setState({ [event.target.name]: message });
  };

  componentWillReceiveProps = (newProps) => {
    if (newProps.activityCreate) {
      this.handleSuccess();
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.saveActivity(this.state);


  };

  handleSuccess = () => {
    window.localStorage.removeItem("selectedQuestion");
    window.localStorage.removeItem("setImage_url");

    if (this.state.group_id == null) {

      localStorage.setItem("personalImage", this.state.image_url);

    }

    this.state.group_id == null
      ? history.push({
        pathname: "/thank-you",
        state: { activity: this.state },
      })
      : history.push({
        pathname: `/results-team/in-activity/${this.state.group_id}`,
      });
  };

  handleModal = (event) => {

    if (this.state.response) {
      event.preventDefault();

      //if it is a multiple question group; save data in local storage and send to next question
      if (Array.isArray(JSON.parse(window.localStorage.getItem("group"))[0].question_id)) {

        //store this questions data in local storage
        var this_question = JSON.parse(localStorage.getItem("selectedQuestion")).content;
        var this_question_id = JSON.parse(localStorage.getItem("selectedQuestion")).key;
        var this_image_url = window.localStorage.getItem("setImage_url");

        var thisQuestionResponse = {}

        thisQuestionResponse = {
          question_id: this_question_id,
          question: this_question,
          image_url: this_image_url,
          response: this.state.response
        }


        //get previous multipleQuestionResponse
        var multipleQuestionResponse = JSON.parse(localStorage.getItem("multipleQuestionResponse"));

        //check if all questions belong to this group; or it can saved from other group
        if (multipleQuestionResponse && Array.isArray(multipleQuestionResponse.answers)
          && multipleQuestionResponse.group_id == this.state.group_id) {

          multipleQuestionResponse.answers.push(thisQuestionResponse);

        } else {

          multipleQuestionResponse = {};
          multipleQuestionResponse.group_id = this.state.group_id;
          multipleQuestionResponse.answers = [];
          multipleQuestionResponse.answers.push(thisQuestionResponse);

        }

        //save response in local storage;
        localStorage.setItem("multipleQuestionResponse", JSON.stringify(multipleQuestionResponse));

        //check if all questions have responses
        var question_ids = JSON.parse(window.localStorage.getItem("group"))[0].question_id;

        question_ids.forEach(question_id => {

          var found = false;

          multipleQuestionResponse.answers.forEach((localAnswer) => {
            if (localAnswer.question_id == question_id) {
              found = true;
            }
          });

          //this question_id was not answered; send user for it's answer;
          if (!found) {
            history.push("/team-activity/question");
            return;
          }

        });

        //all questions are done. open save modal
        this.setState({ open: !this.state.open });

        if (this.state.group_id != null) {

          firebase.firestore().collection("groups").where("groupKey", "==", this.state.group_id)
            .get()
            .then((querySnapshot) => {

              if (querySnapshot.size > 0) {


                querySnapshot.forEach((document) => {

                  console.log('yes')


                  if (document.data().userId) {

                    var group_name = JSON.parse(window.localStorage.getItem("group"))[0].groupName;


                    firebase.firestore().collection("notif").doc().set({
                      userId: document.data().userId,
                      group_id: this.state.group_id,
                      group_name: group_name,
                      createdAt: new Date(),
                      type: 'admin',
                      status: 'unread',
                      notification: 'Your group <b>' + group_name + '</b> has a new response.',
                    })
                  }



                });
              } else {
                console.log('error')
              }

            })
            .catch((error) => {
              console.log("Error getting documents: ", error);
            });




          firebase.firestore().collection("activities").where("group_id", "==", this.state.group_id)
            .get()
            .then((querySnapshot) => {

              if (querySnapshot.size > 0) {


                querySnapshot.forEach((document) => {

                  console.log('yes')


                  if (document.data().user_id) {

                    var group_name = JSON.parse(window.localStorage.getItem("group"))[0].groupName;


                    firebase.firestore().collection("notif").doc().set({
                      userId: document.data().user_id,
                      group_id: this.state.group_id,
                      group_name: group_name,
                      createdAt: new Date(),
                      type: 'user',
                      notification: '<b>' + group_name + '</b> has a new response.',
                    })
                  }



                });
              } else {
                console.log('error')
              }

            })
            .catch((error) => {
              console.log("Error getting documents: ", error);
            });

        }

      } else { //it is not multiple question group; show save modal
        this.setState({ open: !this.state.open });

        if (this.state.group_id != null) {

          firebase.firestore().collection("groups").where("groupKey", "==", this.state.group_id)
            .get()
            .then((querySnapshot) => {

              if (querySnapshot.size > 0) {


                querySnapshot.forEach((document) => {

                  console.log('yes')


                  if (document.data().userId) {

                    var group_name = JSON.parse(window.localStorage.getItem("group"))[0].groupName;


                    firebase.firestore().collection("notif").doc().set({
                      userId: document.data().userId,
                      group_id: this.state.group_id,
                      group_name: group_name,
                      createdAt: new Date(),
                      type: 'admin',
                      status: 'unread',
                      notification: 'Your group <b>' + group_name + '</b> has a new response.',
                    })
                  }



                });
              } else {
                console.log('error')
              }

            })
            .catch((error) => {
              console.log("Error getting documents: ", error);
            });




          firebase.firestore().collection("activities").where("group_id", "==", this.state.group_id)
            .get()
            .then((querySnapshot) => {

              if (querySnapshot.size > 0) {


                querySnapshot.forEach((document) => {

                  console.log('yes')


                  if (document.data().user_id) {

                    var group_name = JSON.parse(window.localStorage.getItem("group"))[0].groupName;


                    firebase.firestore().collection("notif").doc().set({
                      userId: document.data().user_id,
                      group_id: this.state.group_id,
                      group_name: group_name,
                      createdAt: new Date(),
                      type: 'user',
                      notification: '<b>' + group_name + '</b> has a new response.',
                    })
                  }



                });
              } else {
                console.log('error')
              }

            })
            .catch((error) => {
              console.log("Error getting documents: ", error);
            });

        }

      }

    }
  };

  render() {
    const image_url = window.localStorage.getItem("setImage_url");
    const { activity_type, question_key } = this.props.match.params;
    return (
      <div id="main">
        <div className="container mobtop">
          <div className="row">
            <div className="col-md-12 back-nav">
              <Link
                to={`/${activity_type}/question/${question_key}/images/`}
                className="goto_back"
              >
                <i></i>Back
              </Link>
            </div>
            <div className="progress-steps">
              <span className="step step-1 active"></span>
              <span className="step step-2 active"></span>
              <span className="step step-3 active"></span>
            </div>
            <div className="buttons-padding-box padding-box">
              <div
                className="buttons-wrap box-wrap small-box"
                style={{ borderRadius: 5 }}
              >
                <div className="mb-3 font-weight-bold text-center">Question {this.state.question_count} of {this.state.question_total}</div>

                <div className="resp-title">
                  WHAT VISUALLY ATTRACTED YOU TO THIS IMAGE, AND EXPLAIN HOW THE
                  SELECTED IMAGE SYMBOLIZES THIS: {this.state.question}
                </div>
                <form className="resp-form entry-form check-form small-form">

                  <MaterialTextarea
                    label="Your Response"
                    onChange={this.handleChange}
                    required={true}
                    name="response"
                    maxLength="500"
                  />
                  <div className="fimg-holder" style={{ textAlign: "center" }}>
                    <img
                      src={image_url}
                      title="Final Image"
                      className="final-image response-image"
                      alt="loading"
                    />
                  </div>
                  {this.state.group_id ? (
                    <Fragment>
                      <div className="button-wrap">
                        <button
                          className="btn-primary active"
                          onClick={this.handleModal}
                        >
                          SUBMIT
                        </button>
                      </div>
                      <ResponsePageModal
                        open={this.state.open}
                        handleModal={this.handleModal}
                        responsePageState={this.state}
                        saveActivity={this.props.saveActivity}
                      />
                    </Fragment>
                  ) : (
                    <div className="button-wrap">
                      <button
                        className="btn-primary active"
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { firebase, activity } = state;

  return {
    uid: firebase.auth.uid,
    activityCreate: activity.activityCreate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveActivity: (activity) => dispatch(saveActivity(activity)),
    getUserInfo: (uid) => dispatch(getUserInfo(uid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsePage);
