import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import firebase from 'firebase/app';
import moment from 'moment';
import Swal from "sweetalert"
import ResultsPersonalTableRow from '../ResultsPersonal/ResultsPersonalTableRow';
import "./dashboard.css";
import secureLocalStorage from "react-secure-storage";


class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      openModal: true,
      donotShow: false,
    };

    // initialize Pendo
    let email = firebase.auth().currentUser.email;
    console.log(email)

    window.$('head').append(`
    
    <script>

            (function(apiKey){
                (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
                v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
                    o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                    y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
                    z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

                    // Call this whenever information about your visitors becomes available
                    // Please use Strings, Numbers, or Bools for value types.
                    pendo.initialize({
                        visitor: {
                            id: '${email}',   // Required if user is logged in
                            email: '${email}'       // Recommended if using Pendo Feedback, or NPS Email
                            // full_name:    // Recommended if using Pendo Feedback
                            // role:         // Optional

                            // You can add any additional visitor level key-values here,
                            // as long as it's not one of the above reserved names.
                        },

                        account: {
                            id: '${email}' // Highly recommended
                            // name:         // Optional
                            // is_paying:    // Recommended if using Pendo Feedback
                            // monthly_value:// Recommended if using Pendo Feedback
                            // planLevel:    // Optional
                            // planPrice:    // Optional
                            // creationDate: // Optional

                            // You can add any additional account level key-values here,
                            // as long as it's not one of the above reserved names.
                        }
                    });
            })('0096a035-e44b-447e-5148-94df7f0dd290');
        
    </script>

    
    `);

  }

  componentDidMount() {

    // firebase.auth().currentUser.unlink('twitter.com').then(() => {
    //   alert('yes')
    //   // Auth provider unlinked from account
    //   // ...
    // }).catch((error) => {
    //   alert(error)
    //   // An error happened
    //   // ...
    // });



    // store demo_activity data
    if (secureLocalStorage.getItem("demo_response") !== null && secureLocalStorage.getItem("demo_image_url") !== null && secureLocalStorage.getItem("demo_question") !== null) {
      const demo_response = secureLocalStorage.getItem("demo_response");
      const demo_image_url = secureLocalStorage.getItem("demo_image_url");
      const demo_question = secureLocalStorage.getItem("demo_question");

      firebase.firestore().collection("/activities").add({
        activity_type: 'individual',
        hideNameFromGroup: false,
        hideResponseFromGroup: false,
        group_id: null,
        user_id: firebase.auth().currentUser.uid,
        question: demo_question,
        response: demo_response,
        image_url: demo_image_url,
        name: firebase.auth().currentUser.displayName,
        createdAt: new Date(),
        source: 'demo_activity'
      })
        .then(() => {

          // remove localstorage after adding once
          secureLocalStorage.removeItem('demo_response')
          secureLocalStorage.removeItem('demo_image_url')
          secureLocalStorage.removeItem('demo_question')

        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });

    }


    // Subscription Status
    if (this.props.profile.sub_type && this.props.profile.sub_type !== '') {
      if (this.props.profile.sub_type === 'm')
        window.$('#sub_type').html('Subscription Type: Monthly');

      if (this.props.profile.sub_type === 'y')
        window.$('#sub_type').html('Subscription Type: Yearly');

      // window.$("#sub_type").after('<a class="btn btn-warning btn-sm mt-3" href="user/profile">Manage</a>');        

    }

    // Reminder Settings
    if (this.props.profile.reminder_type && this.props.profile.reminder_type !== 'no_reminder') {
      window.$('#reminder_type').html('Reminder Type: ' + this.props.profile.reminder_type.charAt(0).toUpperCase() + this.props.profile.reminder_type.slice(1));
      window.$("#reminder_type").after('<a class="btn-primary green-bg btn-sm" style="width: 90px;height: 39px;margin: auto;padding: 5px;" href="/user/profile">Update</a>');
    } else {
      window.$("#reminder_type").after('<a class="btn-primary green-bg btn-sm" style="width: 90px;height: 39px;margin: auto;padding: 5px;" href="/user/profile">Set</a>');
    }

    // Upgrade Subscription
    if (!this.props.profile.sub_type || this.props.profile.sub_type === '') {
      window.$("#sub_type").after('<a class="btn btn-warning btn-sm" style="height: 39px; line-height: 29px;" href="../stripe">Upgrade Now</a>');
    }

    window.$('.modal-content').css('max-width', '100% !important');

    // Check if subscription is remaining
    if (this.props.profile.sub_valid_till && this.props.profile.sub_valid_till !== '') {

      var d1 = new Date();
      var d2 = moment(this.props.profile.sub_valid_till, "DD.MM.YYYY").toDate();

      console.log(d1);
      console.log(d2);

      if (d1.getTime() > d2.getTime()) {
        firebase.firestore().collection("/users").doc(firebase.auth().currentUser.uid).update(
          {
            sub_valid_till: '',
            user_type: 'normal'
          }
        )

      }


    }

    const setOpenModal = localStorage.getItem("modalOpen");
    if (setOpenModal === "true") {
      this.setState({
        openModal: false,
      });
    } else {
      this.setState({
        openModal: true,
      });
    }

    // check app version
    let para = 'rand=' + Math.random();
    fetch('https://www.skilldivvy.com/dev/crons/firestore/app_version/get.php?' + para)
      .then(response => response.json())
      .then(data => {


        var app_version = data.app_version;

        if (window.localStorage.getItem("app_version") === null) {
          window.localStorage.setItem("app_version", app_version);
        }

        // if app version is not latest
        if (window.localStorage.getItem("app_version") !== null && window.localStorage.getItem("app_version") !== app_version) {

          // clear cache
          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then((names) => {
              for (const name of names) {
                caches.delete(name);
              }
            });
          }

          // show update prompt to user
          Swal("Update Available", data.msg, 'info', { button: "Update Now" })
            .then((value) => {
              if (`${value}` === 'true') {
                window.localStorage.setItem("app_version", app_version);
                window.location.reload();
              }
            });


        }


      });

    window.$('#group_results_container').hide();

    var groupKeys = new Array();
    // search for groups created by user for group results
    firebase.firestore().collection("groups").where("userId", "==", firebase.auth().currentUser.uid)
      .get()
      .then((querySnapshot) => {

        if (querySnapshot.size > 0) {

          querySnapshot.forEach((doc) => {
            groupKeys.push(doc.data().groupKey);
          });

          // reverse array to get latest groups; keep only 10 elements in array for query
          groupKeys = groupKeys.reverse().slice(0, 9);

          // get all latest group results
          firebase.firestore().collection("activities").where("group_id", "in", groupKeys).orderBy("createdAt", "desc").limit(4)
            .get()
            .then((querySnapshot) => {

              if (querySnapshot.size > 0) {

                querySnapshot.forEach((doc) => {

                  // adjust date
                  var response_date = moment(doc.data().createdAt.seconds, "DD.MM.YYYY").toDate();
                  var curdate = new Date(null);
                  curdate.setTime(doc.data().createdAt.seconds * 1000);
                  response_date = curdate;
                  response_date = moment(response_date).format('MMMM D, YYYY');

                  // adjust image
                  var response_image = doc.data().image_url;
                  response_image = response_image.replace(/'/g, '%27');

                  // append to table
                  window.$('#group_results').append("<tr> <td  style='text-align: left; vertical-align: middle !important;'>" + doc.data().group_id + "</td> <td style='text-align: left; vertical-align: middle !important;'>" + doc.data().name + "</td> <td><img src='" + response_image + "' width='100px' /></td>  <td style='vertical-align: middle !important;' title='" + doc.data().response + "'>" + doc.data().response.substring(0, 60) + "</td> <td style='vertical-align: middle !important;'>" + response_date + "</td>  </tr>");
                  window.$('#group_results_container').show();
                  window.$('td:first-child').each(function () {
                    window.$(this).css('width', '20%').css('word-break', 'break-all');
                  });
                  window.$('.data-table img').width("150px");


                });
              }

            })
            .catch((error) => {
              console.log("Error getting documents: ", error);
            });

        }

      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    localStorage.setItem("check", false);


  }



  toggle = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
    window.$('.modal-content').css('max-width', '100% !important');
  };
  handleModal = (e) => {
    console.log(e.target.checked);
    this.setState({
      donotShow: e.target.checked,
    });
    localStorage.setItem("modalOpen", e.target.checked);
  };
  enableModal = () => {
    const setOpenModal = localStorage.getItem("modalOpen");
    if (setOpenModal === "true") {
      let isTrueSet = setOpenModal === "true";
      this.setState({
        donotShow: isTrueSet,
      });
    } else {
      let isFalseSet = setOpenModal === "false";
      this.setState({
        donotShow: isFalseSet,
      });
    }
    this.setState({
      openModal: true,
    });
  };

  openSparqFeatureRequest = () => {
    this.props.history.push('/ideas');
  }

  loaded_javascript = () => {

    window.$('td:first-child').each(function () {
      window.$(this).css('width', '20%').css('word-break', 'break-all');
    });

  }

  render() {
    const { resultsPersonal } = this.props;
    let resultPersonalMappedArray = [];

    var total = 0;
    for (var key in resultsPersonal) {
      resultPersonalMappedArray.push(resultsPersonal[key]);
    };

    resultPersonalMappedArray.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.createdAt.seconds) - new Date(a.createdAt.seconds);
    });

    resultPersonalMappedArray = resultPersonalMappedArray.slice(0, 4);


    const template = resultPersonalMappedArray.map((activities, index) => {

      let date = new Date(activities.createdAt.seconds * 1000);

      const dataRow = {
        activities,
        date
      }

      return (
        <ResultsPersonalTableRow data={dataRow} key={index} />
      );
    })
    const { ordered } = this.props;
    const resultDisabled =
      !ordered.activities || (ordered.activities && !ordered.activities.length);



    return (
      <div id="main">
        <div className="row" style={{ maxWidth: "1200px", margin: "auto" }}>
          <div className="col-md-8">
            <div className="container">
              <div className="row">
                <div className="buttons-padding-box padding-box" style={{ maxWidth: '1000px', }}>
                  <div
                    className="buttons-wrap box-wrap"
                    style={{ borderRadius: 5 }}
                  >
                    <div className="small-title">HOW ARE YOU USING SPARQ?</div>
                    <Link
                      to="/personal-use/question"
                      className="btn-primary green-bg"
                    >
                      PERSONAL USE
                    </Link>
                    <Link to="/team-activity" className="btn-primary blue-bg mt-25">
                      AS PART OF A GROUP
                    </Link>
                    <p className="intro mt-5" onClick={this.enableModal}>
                      Want to see how it works?
                    </p>



                  </div>
                </div>
              </div>
            </div>

            <div className="container" id="group_results_container">
              <div className="row">
                <div className="form-padding-box padding-box data-tablebox" style={{ maxWidth: "1000px" }}>
                  <div className="form-wrap box-wrap" style={{ borderRadius: 5 }}>
                    <div className="small-title">Recent Created Group Results</div>
                    <p style={{ margin: "auto", display: "block", textAlign: "center", marginTop: "-18px" }}>Activites where people participated in groups created by you.</p>
                    <div className="table-holder">
                      <table className="data-table" style={{ marginTop: `20px`, maxWidth: "100%", overflow: "scroll" }}>
                        <thead>
                          <tr>
                            <th>Group ID</th>
                            <th>Name</th>
                            <th>Image</th>
                            <th>Response</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody id="group_results">


                        </tbody>
                      </table>
                    </div>
                    <Link
                      to="admin/groups"
                      id="view_results"
                      className=
                      "btn-primary blue-bg mt-25 "
                    >
                      VIEW ALL
                    </Link>
                  </div>

                </div>
              </div>
            </div>

            <div className={
              "container" +
              (resultDisabled ? "d-none" : "")
            }>
              <div className="row">
                <div className="form-padding-box padding-box data-tablebox" style={{ maxWidth: "1000px" }}>
                  <div className="form-wrap box-wrap" style={{ borderRadius: 5 }}>
                    <div className="small-title">Your Recent Sparqs</div>
                    <p style={{ margin: "auto", display: "block", textAlign: "center", marginTop: "-18px" }}>Activites you participated in.</p>
                    <div className="table-holder">
                      <table onLoad={this.loaded_javascript} className="data-table" style={{ marginTop: '20px', maxWidth: "100%", overflow: "scroll" }}>
                        <thead>
                          <tr>
                            <th style={{ maxWidth: "50px" }}>Group ID</th>
                            <th>Question</th>
                            <th>Image</th>
                            <th>Date</th>
                            <th>Option</th>
                          </tr>
                        </thead>
                        <tbody>
                          {template}
                        </tbody>
                      </table>
                    </div>
                    <Link
                      to="/view-user-results"
                      id="view_results"
                      className={
                        "btn-primary blue-bg mt-25 " +
                        (resultDisabled ? "inactive" : "")
                      }
                    >
                      VIEW ALL
                    </Link>
                  </div>

                </div>
              </div>
            </div>



          </div>
          <div className="col-md-4">
            <div className="container">
              <div className="row">
                <div className="buttons-padding-box padding-box text-center">
                  <div className="buttons-wrap">
                    <div className="small-title">Reminders</div>
                    <p id="reminder_type" style={{ textAlign: 'center', fontSize: "14px" }}>No Reminder Set!</p>

                    <div className="small-title" style={{ marginTop: "50px" }}>Subscription</div>
                    <p id="sub_type" style={{ textAlign: 'center', fontSize: "14px" }}>Subscription Type: Free</p>
                    {this.props.profile.user_type === "normal" &&
                      <p className="intro mt-5" style={{ color: "black" }} onClick={() => window.open("https://www.paypal.com/donate?hosted_button_id=PGCGN4HR6V3DJ", "_blank")}>
                        Donate to support Sparq!
                      </p>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="buttons-padding-box padding-box text-center">
                  <div className="buttons-wrap">
                    <p className="intro mt-2 mb-0" style={{ color: "black" }} onClick={() => {
                      window.open("https://forms.gle/gsX3AQEudU7uhadB6", "_blank", "toolbar=no,scrollbars=yes,resizable=yes,top=150,left=400,width=600,height=500");
                    }}>
                      <img width="40px" style={{ display: "block", margin: "auto" }} src="/img/idea.png" />
                      Submit feature idea
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.openModal} toggle={this.toggle} dialogClassName="modal-50w">
          <ModalHeader toggle={this.toggle} className="small-title text-center">
            How it works
          </ModalHeader>
          <ModalBody className="html-content">
            <ul>
              <li>
                You can choose a question from the default options, or create
                your own topic or question that you would like to reflect on.
              </li>
              <li>
                You will then browse through a series of images saving ones that
                you feel match up with your question or topic
              </li>
              <li>
                You will be asked to select a final image from your saved list
                that best represents your vision of the question or topic.
              </li>
              <li>
                If you are not able to find an image that you feel represents
                your question or topic appropriately, you will be able to upload
                and save your own image.
              </li>
              <li>
                Finally, you will briefly explain why the image chosen best
                represents your vision of your question or topic.
              </li>
              <li>
                Your Sparq account allows you to save all of your results and you
                can print and share your results if you wish!
              </li>
            </ul>

            <p style={{ textAlign: "center" }}>
              <span style={{ color: "red", fontSize: "14px", marginTop: "21px", display: "block" }}>NEW</span> You can set regular reminders for reflection from Profile page.
            </p>
            <div className="confirm-box">
              <input
                type="checkbox"
                id="accept_policy"
                name="accept_policy"
                checked={this.state.donotShow}
                onChange={this.handleModal}
              />
              <label htmlFor="accept_policy">
                Don't show this message again
              </label>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
              Start
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  const { ordered } = state.firestore;
  const { profile, auth } = state.firebase;
  return {
    ordered,
    profile,
    auth,
    resultsPersonal: ordered.activities
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "activities",
      where: [["user_id", "==", props.auth.uid]],
    },
  ])
)(Dashboard);
