import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import firebase from 'firebase/app';
import moment from 'moment';
import Swal from "sweetalert"
import "./dashboard.css";


class DemoDashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      openModal: false,
      donotShow: false,
    };




  }

  componentDidMount() {


    window.$('.modal-content').css('max-width', '100% !important');



    window.$('#header').html(`
    <div class="container">
      <div class="row">
        <nav class="navbar navbar-expand-xl navbar-light bg-none" style="width: 100%;">
          <a id="logo" href="/"><img src="/assets/images/sparq_logo.png" alt="Sparq Logo"></a><div class="collapse navbar-collapse">
            <ul class="order-1 navbar-nav">
              <li class="nav-item"><a class="action-link" href="/sign-up" style="margin-left: 41px; color: #2b347f;">Signup to enjoy full benefits of Sparq</a></li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    `);

    // check app version
    let para = 'rand=' + Math.random();
    fetch('https://www.skilldivvy.com/dev/crons/firestore/app_version/get.php?' + para)
      .then(response => response.json())
      .then(data => {


        var app_version = data.app_version;

        if (window.localStorage.getItem("app_version") === null) {
          window.localStorage.setItem("app_version", app_version);
        }

        // if app version is not latest
        if (window.localStorage.getItem("app_version") !== null && window.localStorage.getItem("app_version") !== app_version) {

          // clear cache
          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then((names) => {
              for (const name of names) {
                caches.delete(name);
              }
            });
          }

          // show update prompt to user
          Swal("Update Available", data.msg, 'info', { button: "Update Now" })
            .then((value) => {
              if (`${value}` === 'true') {
                window.localStorage.setItem("app_version", app_version);
                window.location.reload();
              }
            });


        }


      });

    if (localStorage.getItem("modalOpen") === null || localStorage.getItem("modalOpen") === 'false') {
      this.setState({
        openModal: true,
      });
    }


  }



  toggle = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
    window.$('.modal-content').css('max-width', '100% !important');
  };
  handleModal = (e) => {
    // e.preventDefault();
    console.log(e.target.checked);
    this.setState({
      donotShow: e.target.checked,
    });
    localStorage.setItem('modalOpen', e.target.checked);
  };

  enableModal = () => {
    const setOpenModal = localStorage.getItem("modalOpen");
    if (setOpenModal === "true") {
      let isTrueSet = setOpenModal === "true";
      this.setState({
        donotShow: isTrueSet,
      });
    } else {
      let isFalseSet = setOpenModal === "false";
      this.setState({
        donotShow: isFalseSet,
      });
    }
    this.setState({
      openModal: true,
    });
  };

  openSparqFeatureRequest = () => {
    this.props.history.push('/ideas');
  }


  render() {


    return (
      <div id="main">
        <div className="row" style={{ maxWidth: "1200px", margin: "auto" }}>
          <div className="col-md-8">
            <div className="container">
              <div className="row">
                <div className="buttons-padding-box padding-box" style={{ maxWidth: '1000px' }}>
                  <div
                    className="buttons-wrap box-wrap"
                    style={{ borderRadius: 5 }}
                  >
                    <div className="small-title">HOW ARE YOU USING SPARQ?</div>
                    <Link
                      to="/demo-use/question"
                      className="btn-primary green-bg"
                    >
                      PERSONAL USE
                    </Link>
                    <Link to="/guest-activity" className="btn-primary blue-bg mt-25">
                      AS PART OF A GROUP
                    </Link>
                    <p className="intro mt-5" onClick={this.enableModal}>
                      Want to see how it works?
                    </p>



                  </div>
                </div>
              </div>
            </div>

            <div className="container" id="group_results_container" style={{ display: 'block' }}>

              <div className="row">
                <div className="form-padding-box padding-box data-tablebox" style={{ maxWidth: '1000px' }}><div className="form-wrap box-wrap" style={{ borderRadius: '5px' }}>
                  {/* <div style={{ position: 'absolute', top: 0, left: 0, background: '#000', height: '100%', width: '100%' }}></div> */}

                  <div className="small-title"><div style={{ color: '#243282', textDecoration: 'underline' }}>DEMO DATA</div>Recent Created Group Results</div>
                  <p style={{ margin: '-18px auto auto', display: 'block', textAlign: 'center' }}>Activites where people participated in groups created by you.</p>
                  <div className="table-holder">

                    <table className="data-table" style={{ marginTop: '20px', maxWidth: '100%', overflow: 'scroll' }}>

                      <thead>
                        <tr>
                          <th>Group ID</th>
                          <th>Name</th>
                          <th>Image</th>
                          <th>Response</th>
                          {/* <th>Date</th> */}
                        </tr>
                      </thead>
                      <tbody id="group_results">
                        <tr>
                          <td style={{ width: '22%', wordBreak: 'break-all', verticalAlign: 'middle' }}>SparqGroup</td>
                          <td style={{ verticalAlign: 'middle' }} >Zohaib Shaheen</td>
                          <td style={{ verticalAlign: 'middle' }} ><img style={{ width: '110px', padding: '5px' }} src="https://firebasestorage.googleapis.com/v0/b/sparq-flux.appspot.com/o/Aho997qhycOYEVBO36Uq2Cqn0cw1%2F1667494356735_4w3os1sw03w.jpeg?alt=media&token=7f3b37d4-6a67-4de8-a300-95c5208bb8c7" width="110px" /></td>
                          <td style={{ verticalAlign: 'middle' }} >I believe together as a team we can achieve...</td>
                          {/* <td>11/03/2022</td> */}
                        </tr>
                        <tr>
                          <td style={{ width: '22%', wordBreak: 'break-all', verticalAlign: 'middle' }}>Work101</td>
                          <td style={{ verticalAlign: 'middle' }} >Curt Menhold</td>
                          <td style={{ width: '28%', wordBreak: 'break-all' }}><img style={{ width: '110px', padding: '5px' }} src="https://firebasestorage.googleapis.com/v0/b/sparq-flux.appspot.com/o/images%2F1616020778812_qwqr708b36.jpg?alt=media&token=f08c1855-b52f-462f-81a7-c7221bf40e06" width="150px" /></td>
                          <td style={{ verticalAlign: 'middle' }} >Life is a series of ups and downs around the journey...</td>
                          {/* <td>11/02/2022</td> */}
                        </tr>
                        <tr>
                          <td style={{ width: '22%', wordBreak: 'break-all', verticalAlign: 'middle' }}>ComPT</td>
                          <td style={{ verticalAlign: 'middle' }} >Dr. Foreman</td>
                          <td style={{ verticalAlign: 'middle' }} ><img style={{ width: '110px', padding: '5px' }} src="https://firebasestorage.googleapis.com/v0/b/sparq-flux.appspot.com/o/New_Default_Spark_Set%2F1616644281750_wds173s4gg.jpg?alt=media&token=f73f7489-8204-4857-8bed-0158a762e8a1" width="150px" /></td>
                          <td style={{ verticalAlign: 'middle' }} >This little girl inspires me that if we believe we can...</td>
                          {/* <td>12/26/2021</td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <a id="view_results" className="btn-primary blue-bg mt-25 " href="sign-up">SIGN UP TO VIEW YOUR DATA</a>
                </div>

                </div>

              </div>
            </div>




          </div>
          <div className="col-md-4">
            <div className="container">
              <div className="row">
                <div className="buttons-padding-box padding-box text-center">
                  <div className="buttons-wrap">
                    <div className="small-title">Reminders</div>
                    <p id="reminder_type" style={{ textAlign: 'center', fontSize: "14px" }}>No Reminder Set! <br /> Signup to set reminders.</p>
                    <a className="btn-primary green-bg btn-sm" style={{ width: '90px', height: '39px', margin: 'auto', padding: '5px' }}>Sign Up</a>
                    <div className="small-title" style={{ marginTop: "50px" }}>Subscription</div>
                    <p id="sub_type" style={{ textAlign: 'center', fontSize: "14px" }}>Subscription Type: Free</p>

                    <p className="intro mt-5" style={{ color: "black" }} onClick={() => window.open("https://www.paypal.com/donate?hosted_button_id=PGCGN4HR6V3DJ", "_blank")}>
                      Donate to support Sparq!
                    </p>

                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="buttons-padding-box padding-box text-center">
                  <div className="buttons-wrap">
                    <p className="intro mt-2 mb-0" style={{ color: "black" }} onClick={() => {
                      window.open("https://forms.gle/gsX3AQEudU7uhadB6", "_blank", "toolbar=no,scrollbars=yes,resizable=yes,top=150,left=400,width=600,height=500");
                    }}>
                      <img width="40px" style={{ display: "block", margin: "auto" }} src="/img/idea.png" />
                      Submit feature idea
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.openModal} toggle={this.toggle} dialogClassName="modal-50w">
          <ModalHeader toggle={this.toggle} className="small-title text-center">
            How it works
          </ModalHeader>
          <ModalBody className="html-content">
            <ul>
              <li>
                You can choose a question from the default options, or create
                your own topic or question that you would like to reflect on.
              </li>
              <li>
                You will then browse through a series of images saving one that
                you feel match up with your question or topic
              </li>
              <li>
                If you are not able to find an image that you feel represents
                your question or topic appropriately, you will be able to upload
                and save your own image (signed in users only).
              </li>
              <li>
                Finally, you will briefly explain why the image chosen best
                represents your vision of your question or topic.
              </li>
              <li>
                Your Sparq account allows you to save all of your results and you
                can print and share your results if you wish!
              </li>
            </ul>

            <p style={{ textAlign: "center" }}>
              <span style={{ color: "red", fontSize: "14px", marginTop: "21px", display: "block" }}></span> You can set regular reminders for reflection from Profile page (signed in users only).
            </p>
            <div className="confirm-box">
              <input
                type="checkbox"
                id="accept_policy"
                name="accept_policy"
                checked={this.state.donotShow}
                onChange={this.handleModal}
              />
              <label htmlFor="accept_policy">
                Don't show this message again
              </label>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
              Start
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

};

export default compose(
  connect(mapStateToProps),
)(DemoDashboard);
