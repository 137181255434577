import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
import {downloadImage} from '@helpers';
import EmailRecipientsModal from '@components/user/EmailRecipientsModal';
import loading from '../../../resource/loading.gif';

import './results-personal.css';
import {firestoreConnect} from 'react-redux-firebase';

class PersonalImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            openModal: false,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.isMobile.bind(this));
        this.isMobile();
    }

    isMobile = () => {
        this.setState({isMobile: window.innerWidth <= 1024});
    };

    handleDownload = () => {
        const {image_url} = this.props.activities[0];
        downloadImage(image_url);
    };

    handlePrint = () => {
        window.print();
    };

    openModal(openModal) {
        this.setState({openModal});
    }

    render() {
        let recent_activity;

        const {activities} = this.props;

        recent_activity = activities && activities[0];

        const return_path =
            window.localStorage.getItem('activity_type') === 'individual'
                ? '/thank-you'
                : 'current-result';

        const mobileButtons = (
            <Fragment>
                <div className="col-md-6">
                    <button
                        className="btn-primary active sec-col"
                        onClick={this.handleDownload}>
                        SAVE IMAGE
                    </button>
                </div>
            </Fragment>
        );

        const loading_image = (
            <div className="col-md-12">
                <div style={{textAlign: 'center', paddingTop: '200px'}}>
                    <img src={loading} alt="loading..." />
                </div>
            </div>
        );

        const activity_content = (
            <div className="row">
                <div className="col-md-12 back-nav no-print">
                    <Link
                        to={{pathname: return_path, activity: recent_activity}}
                        className="goto_back">
                        <i />
                        Back
                    </Link>
                </div>
                <div className="form-padding-box">
                    <div className="form-wrap">
                        <div className="small-title">Personal image</div>
                        <div className="person-box mt-25">
                            <div style={{textAlign: 'center'}}>
                                <img
                                    style={{maxHeight: '36vh', width: 'auto'}}
                                    src={
                                        recent_activity &&
                                        recent_activity.image_url
                                    }
                                    title="Final Image"
                                    className="final-image"
                                    alt="final"
                                />
                            </div>
                            <div className="button-wrap mt-40 no-print">
                                <div className="row">
                                    {this.state.isMobile ? mobileButtons : null}
                                </div>
                                <div className="row mt-15">
                                    <div className="col-md-6">
                                        <button
                                            onClick={this.openModal.bind(
                                                this,
                                                true,
                                            )}
                                            type="button"
                                            className="btn-primary grey-bg">
                                            EMAIL
                                        </button>
                                    </div>
                                    <div className="col-md-6 sec-col">
                                        <button
                                            className="btn-primary grey-bg"
                                            onClick={this.handlePrint}>
                                            PRINT
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <EmailRecipientsModal
                    data={recent_activity}
                    imageUrl={recent_activity && recent_activity.image_url}
                    open={this.state.openModal}
                    openModal={this.openModal.bind(this)}
                />
            </div>
        );

        return (
            <Fragment>
                <div className="container">
                    {recent_activity ? activity_content : loading_image}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({firebase: {auth}, firestore: {ordered}}) => {
    return {
        auth,
        activities: ordered.activities,
    };
};

export default compose(
    connect(
        mapStateToProps,
        {downloadImage},
    ),
    firestoreConnect(props => [
        {
            collection: 'activities',
            where: [
                ['user_id', '==', props.auth.uid],
                ['activity_type', '==', 'individual'],
            ],
            orderBy: ['createdAt', 'desc'],
            limit: 1,
        },
    ]),
)(PersonalImage);
