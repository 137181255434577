export const AUTHENTICATE_ACCOUNT_SUCCESS = 'AUTHENTICATE_ACCOUNT_SUCCESS';
export const AUTHENTICATE_ACCOUNT_ERROR = 'AUTHENTICATE_ACCOUNT_ERROR';

export const SIGNUP_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const SIGNUP_USER_ERROR = 'CREATE_USER_ERROR';

export const SIGNOUT_USER_SUCCES = 'SIGNOUT_USER_SUCCES';
export const SIGNOUT_USER_ERROR = 'SIGNOUT_USER_ERROR';

export const AUTHENTICATE_THROUGH_EMAIL_ERROR = 'AUTHENTICATE_THROUGH_EMAIL_ERROR';
export const AUTHENTICATE_THROUGH_EMAIL_SUCCESS = 'AUTHENTICATE_THROUGH_EMAIL_SUCCESS';

export const AUTHENTICATE_EMAIL_FORGOT_PASSWORD_SUCCESS = 'AUTHENTICATE_EMAIL_FORGOT_PASSWORD_SUCCESS';
export const AUTHENTICATE_EMAIL_FORGOT_PASSWORD_ERROR = 'AUTHENTICATE_EMAIL_FORGOT_PASSWORD_ERROR';

export const RESET_CODE_VALIDATE_SUCCESS = 'RESET_CODE_VALIDATE_SUCCESS';
export const RESET_CODE_VALIDATE_ERROR = 'RESET_CODE_VALIDATE_ERROR';

export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';

export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_ERROR = 'CREATE_GROUP_ERROR';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR';

export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_ERROR = 'GET_GROUPS_ERROR';

export const FETCH_USERS = 'FETCH_USERS';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const GET_USER_WITH_EMAIL_SUCCESS = 'GET_USER_WITH_EMAIL_SUCCESS';
export const GET_USER_WITH_EMAIL_ERROR = 'GET_USER_WITH_EMAIL_ERROR';

export const UPDATE_A_GROUP = 'UPDATE_A_GROUP';

export const GET_GROUP_BY_ID_SUCCESS = 'GET_GROUP_BY_ID_SUCCESS';
export const GET_GROUP_BY_ID_ERROR = 'GET_GROUP_BY_ID_ERROR';

export const SET_PERSONAL_USE_CONFIG = 'SET_PERSONAL_USE_CONFIG';
export const CREATE_PERSONAL_USE_CONFIG = 'CREATE_PERSONAL_USE_CONFIG';

export const ADD_QUESTION           = 'QUESTION:ADD_QUESTION';

export const GET_QUESTION           = 'QUESTION:GET_QUESTION';

export const EDIT_QUESTION          = 'QUESTION:EDIT_QUESTION';

export const DELETE_QUESTION        = 'QUESTION:DELETE_QUESTION';

export const SET_ALL_QUESTIONS      = 'QUESTION:SET_ALL_QUESTIONS';

export const SET_QUESTION_IMAGES    = 'QUESTION:SET_QUESTION_IMAGES';

export const SET_QUESTION_DEFAULT_SUCCESS = 'SET_QUESTION_DEFAULT_SUCCESS';
export const SET_QUESTION_DEFAULT_ERROR = 'SET_QUESTION_DEFAULT_ERROR';

export const SAVE_ACTIVITY_SUCCESS = 'SAVE_ACTIVITY_SUCCESS';
export const SAVE_ACTIVITY_ERROR = 'SAVE_ACTIVITY_ERROR';

export const SET_ACTIVITY = 'SET_ACTIVITY';
export const SET_ACTIVITIES = 'SET_ACTIVITIES';

export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_ERROR  = 'UPDATE_GROUP_ERROR';

export const UPDATE_GROUP_QUESTION_SUCCESS = 'UPDATE_GROUP_QUESTION_SUCCESS';
export const UPDATE_GROUP_QUESTION_ERROR = 'UPDATE_GROUP_QUESTION_ERROR';

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const DELETE_GROUP_RESPONSE = "DELETE_GROUP_RESPONSE"

export const UPLOAD_IMAGES_SUCCESS = 'UPLOAD_IMAGES_SUCCESS';
export const UPLOAD_IMAGES_ERROR = 'UPLOAD_IMAGES_ERROR';
export const DELETE_IMAGES_SUCCESS = 'DELETE_IMAGES_SUCCESS';
export const DELETE_IMAGES_ERROR = 'DELETE_IMAGES_ERROR';
export const DELETE_ALL_IMAGES_SUCCESS = 'DELETE_ALL_IMAGES_SUCCESS';
export const DELETE_ALL_IMAGES_ERROR = 'DELETE_ALL_IMAGES_ERROR';

export const GET_GROUP_ACTIVITY_BY_GROUP_ID = "GET_GROUP_ACTIVITY_BY_GROUP_ID";
export const GET_PERSONAL_IMAGE = "GET_PERSONAL_IMAGE";

export const SET_QUESTION_IMAGES_LAST_VISIBLE = 'SET_QUESTION_IMAGES_LAST_VISIBLE';
export const SET_IMAGE_COUNT = 'SET_IMAGE_COUNT';


export const GET_IDEAS_LIST="GET_LIST_OF_ALL_IDEAS"
export const ON_CHANGE_FILTET="ON_FILTETR_LIST_ITEMS"
export const FILTER_BY_CATG="FILTETR_LIST_ITEMS_BY_CATEGORY"
export const FILTER_BY_MY="FILTETR_LIST_ITEMS_BY_MY_IDEAS_VOTE_SUBS"
export const ON_CHANGE_EDIT_IDEA="ON_CHANGE_EDIT_IDEA"
export const EDIT_IDEA="EDIT_IDEA"
export const RELATED_IDEAS= "RELATED_IDEAS"
export const MY_COUNT= "MY_COUNT"
export const ON_VOTING = 'ON_VOTING'
export const ON_SUBSCRIBING = "ON_SUBSCRIBING"
export const SET_COMMENT = "SET_COMMENT"
export const SET_TAB = "SET_TAB"
export const  GET_USERS = ' GET_USERS' 
export const GET_ACTIVITIES = 'GET_ACTIVITIES'