import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserWithEmail } from '@actions/user';
import { handleResetPassword, validatePasswordResetCode, refreshErrors } from '@actions/auth';
import MaterialInput from '@components/main/MaterialInput';

class ResetPassword extends Component {
    constructor(props) {
        super(props)
        const query = new URLSearchParams(props.location.search);
        this.state = {
            email: '',
            reset_email: window.localStorage.getItem('resetEmail'),
            password: '',
            confirm_password: '',
            oobCode: query.get('oobCode'),
            mode: query.get('mode'),
            continueUrl: query.get('continueUrl'),
            passwordFocused: false
        }
    }

    componentWillMount = () => {
        let query = new URLSearchParams(this.props.location.search);
        this.props.validatePasswordResetCode(query.get('oobCode'));
    }

    componentWillUnmount() {
        this.props.refreshErrors()
    }

    componentWillReceiveProps = (newProps) => {
        const { userExist } = newProps
        if (userExist.length) {
            window.localStorage.setItem('resetEmail', this.state.email);
            this.setState({
                reset_email: this.state.email
            })
        }
    }

    CheckPasswordStrength = () => {

        let password = document.getElementById('password').value;
 
        //TextBox left blank.
        if (password.length === 0) {
            document.getElementById("low-upper-case").classList.remove('text-valid');
            document.getElementById("one-number").classList.remove('text-valid');
            document.getElementById("one-special-char").classList.remove('text-valid');
            document.getElementById("eight-character").classList.remove('text-valid');
            return;
        }
 
        //If password contains both lower and uppercase characters, increase strength value.
        if (/([a-z].*[A-Z])|([A-Z].*[a-z])/.test(password)) {
            document.getElementById("low-upper-case").classList.add('text-valid');
        } else {
            document.getElementById("low-upper-case").classList.remove('text-valid');
        }
        
        //If it has numbers and characters, increase strength value.
        if (/([a-zA-Z])/.test(password) && /([0-9])/.test(password)){
            document.getElementById("one-number").classList.add('text-valid');
        } else {
            document.getElementById("one-number").classList.remove('text-valid');
        }
        
        //If it has one special character, increase strength value.
        if (/([!,%,&,@,#,$,^,*,?,_,~])/.test(password)) {
            document.getElementById("one-special-char").classList.add('text-valid');
        } else {
            document.getElementById("one-special-char").classList.remove('text-valid');
        }
		
        //Validate for length of Password.
        if ( password.length > 5) {
            document.getElementById("eight-character").classList.add('text-valid');
        }else{
            document.getElementById("eight-character").classList.remove('text-valid');
        }
    }

    passwordFocus = () => {
        this.setState({ passwordFocused: true});
    }

    passwordBlur = () => {
        this.setState({ passwordFocused: false});
    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.handleCustomValidation();
        this.CheckPasswordStrength();
    }

    handleInputChangeEmail = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleCustomValidation = () => {
        let confirm_password = document.getElementById('confirm_password');
        let password = document.getElementById('password');

        if (password.value && confirm_password.value && confirm_password.value !== password.value) {
            confirm_password.setCustomValidity('Password does not match the confirm password.');
        } else {
            confirm_password.setCustomValidity('');
        }
    }

    onResetEmailSubmit = (event) => {
        event.preventDefault();
        this.props.getUserWithEmail(this.state.email)
    }

    onResetPasswordSubmit = (event) => {
        event.preventDefault();
        this.props.handleResetPassword({
            password: this.state.password,
            code: this.state.oobCode
        })
    }

    render() {
        const { password, confirm_password, email, reset_email, mode } = this.state;
        const { resetCodeValid, passwordResetMessage } = this.props

        const invalidRequest = (
            <Fragment>
                <div style={{ textAlign: "center" }}>
                    {passwordResetMessage ?
                        <Fragment>
                            <h1 className="form-title">{passwordResetMessage}</h1>
                            <Link to="/" className="btn-primary green-bg" style={{ marginTop: '10px', }}>SIGN IN</Link>
                        </Fragment>
                        :
                        <Fragment>
                            <h1 className="form-title">Your Request is invalid</h1>
                            <div style={{ textAlign: "center" }} className="form-descp">{
                                !this.props.resetCodeValid ?
                                    'The link has already been used or expired'
                                    : 'The link you used is incorrect or corrupt.'}
                            </div>
                        </Fragment>
                    }
                </div>
            </Fragment>
        )

        const setResetEmailForm = (
            <Fragment>
                <h1 className="form-title">Please Enter Your Email to continue</h1>
                <div className="form-descp">Your Email was not detected on this machine.</div>
                <form onSubmit={this.onResetEmailSubmit} className="reset-form entry-form check-form" method="post">
                    <MaterialInput
                        label="email"
                        placeholder="Email"
                        type="email"
                        name="email"
                        autoComplete={false}
                        value={this.state.email}
                        required={true}
                        title="Enter Your Email."
                        id="email"
                        onChange={this.handleInputChangeEmail}
                    />
                    <div className="button-wrap">
                        <div className="button-wrap">
                            <button className={"btn-primary btn-reg " + (email ? "active" : "inactive")}>SUBMIT EMAIL</button>
                        </div>
                    </div>
                </form>
            </Fragment>
        )

        const resetPasswordForm = (
            <Fragment>
                <h1 className="form-title">Reset password</h1>
                <div className="form-descp">Please enter your new password below. </div>
                <form onSubmit={this.onResetPasswordSubmit} className="reset-form entry-form check-form" method="post">
                    <MaterialInput
                        label="password"
                        placeholder="Password"
                        type="password"
                        name="password"
                        autoComplete={false}
                        required={true}
                        value={this.state.password}
                        title="Enter a password between 6 and 12 characters, containing at least one letter and one number."
                        id="password"
                        onChange={this.handleInputChange}
                        pattern="^((?=.*[0-9])(?=.*[a-zA-Z])).{6,12}$"
                        onFocus={this.passwordFocus}
                        onBlur={this.passwordBlur}
                    />

                    <ul className={(this.state.password.length && this.state.passwordFocused) ? '' : 'hidden'} id="password_strength">
                        <li id="low-upper-case">1 lowercase &amp; 1 uppercase</li>
                        <li id="one-number">1 number (0-9)</li>
                        <li id="one-special-char">1 special character (!@#$%^&*).</li>
                        <li id="eight-character">At least 6 characters</li>
                    </ul>

                    <MaterialInput
                        label="Confirm password"
                        placeholder="Confirm password"
                        type="password"
                        name="confirm_password"
                        autoComplete={false}
                        required={true}
                        title="Passwords must match."
                        id="confirm_password"
                        value={this.state.confirm_password}
                        onChange={this.handleInputChange}
                        pattern="^((?=.*[0-9])(?=.*[a-zA-Z])).{6,12}$"
                    />
                    <div className="button-wrap">
                        <div className="button-wrap">
                            <button className={"btn-primary btn-reg " + (password && confirm_password ? "active" : "inactive")}>CHANGE PASSWORD</button>
                        </div>
                    </div>
                </form>
            </Fragment>
        )

        const requested_form = (
            <Fragment>
                {reset_email ? resetPasswordForm : null}
                {!reset_email ? setResetEmailForm : null}
            </Fragment>
        )
        return (
            <Fragment>
                <div id="main">
                    <div className="container">
                        <div className="row">
                            <div className="form-padding-box padding-box">
                                <div className="form-wrap box-wrap">
                                    {mode === 'resetPassword' && resetCodeValid && !passwordResetMessage ? requested_form : invalidRequest}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

const mapStateToProps = state => {
    const { user: { userExist }, auth: { passwordResetMessage, passwordResetError, resetCodeValid } } = state

    return {
        userExist,
        passwordResetMessage,
        passwordResetError,
        resetCodeValid
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUserWithEmail: (email) => dispatch(getUserWithEmail(email)),
        handleResetPassword: (credentials) => dispatch(handleResetPassword(credentials)),
        validatePasswordResetCode: (code) => dispatch(validatePasswordResetCode(code)),
        refreshErrors: () => dispatch(refreshErrors())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
