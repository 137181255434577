import React from "react";
import { Link } from "react-router-dom";
//import HowItWorks from "../../main/HowItWorks";

class PersonalActivity extends React.Component {
  componentWillMount() {
    window.localStorage.setItem("activity_type", "personal");
  }

  render() {
    return (
      <div id="main">
        <div className="container">
          <div className="row">
            <div className="col-md-12 back-nav">
              <Link to="/select-activity" className="goto_back">
                <i></i>Back
              </Link>
            </div>
            <div className="buttons-padding-box padding-box">
              {/* <div className="buttons-wrap box-wrap" style={{borderRadius: 5}}>
                                <HowItWorks startLink="/personal-use/question" content={`
                                <ul>
                                    <li>
                                        You can choose a question from the default options, or create your own topic or question that you would like to reflect on.
                                    </li>
                                    <li>
                                        You will then browse through a series of images saving ones that you feel match up with your question or topic
                                    </li>
                                    <li>
                                        You will be asked to select a final image from your saved list that best represents your vision of the question or topic.
                                    </li>
                                    <li>
                                        If you are not able to find an image that you feel represents your question or topic appropriately, you will be able to upload and save your own image.
                                    </li>
                                    <li>
                                        Finally, you will briefly explain why the image chosen best represents your vision of your question or topic.
                                    </li>
                                </ul>
                                <p>
                                    Your Sparq account allows you to save all of your results and you can print and share your results if you wish!
                                </p>
                                `} />
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PersonalActivity;
