import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import history from '../../../history';

import './results-personal.css';

class ResultsPersonal extends Component {
    render() {
        let personal_image_content;
        if (!this.props.location.state) {
            history.push('/')
        } else {
            const { personal_image } = this.props.location.state;
            localStorage.setItem("personalImage", JSON.stringify(personal_image));
            personal_image_content = (
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 back-nav">
                            <Link to="/" className="goto_back">BACK</Link>
                        </div>
                        <div className="form-padding-box">
                            <div className="form-wrap" style={{borderRadius: 5}}>
                                <div className="item" style={{ transition: 'opacity 250ms ease-in' }}>
                                    <div className="msg-title">{personal_image.response} </div>
                                    <div className="author-title">{personal_image.name}</div>
                                    <div className="img-wrap" style={{ textAlign: 'center' }}>
                                        <img style={{ width: 'auto', maxHeight: '47vh' }} src={personal_image.image_url} className="final-image" alt="alt-final" />
                                    </div>
                                </div>
                                <div className="button-wrap mt-25">
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <Link to={{ pathname: "/thank-you", activity: personal_image }} className="btn-primary green-bg">FINISH</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div id="main">
                {personal_image_content}
            </div>
        );
    };
};

export default ResultsPersonal;
