import React from 'react'
import { withRouter } from "react-router"
import { connect } from "react-redux";
import {getIdeas , onVoting ,onEditIdea} from '@actions/ideas'
import parse from 'html-react-parser';
import { ImBubbles2 } from "react-icons/im";
import {filterIdeas} from './Filters/ideasFilter.utils';
const IdeasList = ( props ) => {
  
    const [loadindex,setIndex] = React.useState(null)
    const {auth , ideas ,history}= props 
    const comment=(id)=>{
        props.onEditIdea(id)
      history.replace(`ideas/${id}`)
  }
  React.useEffect( ()=>{
      if(ideas.ideas.length==0 ){
       props.getIdeas()
      }
  },[])
  
  return (
    <React.Fragment>
    {filterIdeas(auth , ideas).map((idea ,index)=>{
        const{voted,idea_id , vote_count ,category_id}=idea
        let ctg=category_id==1?"General":category_id==2?"Admin":""
      return <ul className="tabStyle" key={index}>
      <li className="tab" >
          <div className="custom-vote" style={{color:!voted?null:'#2a3382'}} onClick={()=> {props.onVoting(voted,idea_id,vote_count) ;setIndex(index)}}>
              <a className="btn-vote"  style={{borderColor:!voted?null:'#2a3382'}}>
                  <span className="vote-count">{vote_count}</span>
                  <span className="vote-status"style={{backgroundColor:!voted?null:'#2a3382'}}>{ loadindex==index && props.voting?" Voting...":voted?"Voted":"Vote"}</span>
              </a>
          </div>
          <div className="heading-vote" onClick={()=>{ comment( idea_id ) }}>
              <h3>{idea.idea}</h3>
              <div className="description">
                 {parse(idea.description)}
              </div>
              <div className="vote-meta">
                  <span className="avatar">
                      <img alt="no" src="https://secure.gravatar.com/avatar/b94f5d7746ea188246dd2d7caffb0a33?default=mm&rating=G&secure=true&size=32"
                          width="32" height="32"></img>
                  </span>
                  <div className="vote-meta-created">
                      <div className="vote-meta-details">
                          Created 20 Oct 14:23
                          by Guest
                      </div>
                      <div className="vote-meta-details">
                        <p>{ctg}</p>
                      </div>
                  </div>
                     <span className="vote-comment-count">{idea.comment_count}&nbsp;&nbsp;<ImBubbles2/></span>
              </div>
          </div>
      </li>
  </ul>
    }) }
      </React.Fragment>

  )
}



const mapStateToProps = ({firebase,ideas}) => {
    const { auth, profile } =firebase;
  return {
      auth, 
      profile,
      ideas:ideas,
      voting:ideas.voting,
  };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    onEditIdea:(id)=>dispatch(onEditIdea(id)),
    getIdeas:()=>dispatch(getIdeas()),
    onVoting:(isVoted,data,count)=>dispatch(onVoting(isVoted,data,count))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter( IdeasList));
  