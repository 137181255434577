import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserWithEmail } from "@actions/user";
import { handleSendPasswordResetEmail, refreshErrors } from "@actions/auth";
import MaterialInput from "@components/main/MaterialInput";

import "./forgot-password.css";

class ForgotPassword extends Component {
  state = {
    email: "",
    emailValid: true,
    success: "",
    error: "",
  };

  componentWillReceiveProps = (newProps) => {
    const { success, error, userExist } = newProps;
    if (userExist) {
      this.setState({
        emailValid: userExist.length === 1 ? true : false,
      });
    }
    this.setState({
      error,
      success,
    });
  };

  componentWillUnmount() {
    this.props.refreshErrors();
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (
      /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/.test(
        event.target.value
      )
    ) {
      this.props.getUserWithEmail(event.target.value);
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.state.emailValid) {
      this.props.handleSendPasswordResetEmail(this.state.email);
    }
  };

  render() {
    const error_style = {
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "1.71",
      letterSpacing: "normal",
      color: "#ef2727",
      position: "relative",
      bottom: "14px",
    };

    return (
      <React.Fragment>
        <div id="main">
          <div className="container">
            <div className="row">
              <div className="form-padding-box padding-box">
                <div className="form-wrap box-wrap">
                  <h1 className="form-title">Forgot your password?</h1>
                  <div className="form-descp">
                    Just enter your email and we'll send you a link to reset
                    your password.
                  </div>
                  <form
                    onSubmit={this.handleSubmit}
                    className="forgot-form entry-form check-form"
                    method="post"
                  >
                    <MaterialInput
                      label="email address"
                      autocomplete="false"
                      name="email"
                      id="email"
                      type="email"
                      placeholder="Email address"
                      required={true}
                      pattern="^.{0,64}$"
                      title="Enter an email address with a maximum of 64 characters."
                      value={this.state.email}
                      error={
                        !this.state.emailValid && this.state.email
                          ? "has error"
                          : null
                      }
                      onChange={this.handleInputChange}
                    />
                    {!this.state.emailValid && this.state.email ? (
                      <div style={error_style}>
                        Couldn't find your Sparq account.
                      </div>
                    ) : null}
                    <div className="button-wrap">
                      <button
                        className={
                          "btn-primary btn-pass " +
                          (this.state.email && this.state.emailValid
                            ? "active"
                            : "inactive")
                        }
                      >
                        RESET PASSWORD
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    user: { userExist },
    auth: { authEmailForgotPasswordSuccess, authEmailForgotPasswordError },
  } = state;
  return {
    error: authEmailForgotPasswordError.map((err) => err.message),
    success: authEmailForgotPasswordSuccess,
    userExist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserWithEmail: (email) => dispatch(getUserWithEmail(email)),
    handleSendPasswordResetEmail: (email) =>
      dispatch(handleSendPasswordResetEmail(email)),
    refreshErrors: () => dispatch(refreshErrors()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
