import React, { Component } from 'react';
import { connect } from 'react-redux';
import { filter } from 'lodash';
import { Link } from 'react-router-dom';
import ReactCSSTransitionReplace from 'react-css-transition-replace';

import { getGroupActivtyByGroupId } from '@actions/activity';
import EmailRecipientsModal from '../EmailRecipientsModal';
import ActionButtons from './ActionButtons';

class ResultsIndividual extends Component {
    constructor(props) {
        super(props);
        const { state } = props.location;

        this.state = {
            teamResults: [],
            current: [],
            counter: state.startCounter,
            openEmailRecepientModal: false,
        };
    }

    componentDidMount() {
        this.fetchGroupRes();
        
    }

    fetchGroupRes = () => {
        this.props.getGroupActivtyByGroupId(
            this.props.match.params.group_id,
            () => {
                this.setState({
                    // teamResults: this.filterTeamReultsByViewType(
                    //     this.props.teamResults,
                    // ),
                    teamResults: this.props.teamResults,
                });
            },
        );
    };

    filterTeamReultsByViewType = resultsArray => {
        const filterHideResponseFromGroupFalse = filter(
            resultsArray,
            res =>
                !res.hideResponseFromGroup ||
                res.user_id === this.props.authUserUid,
        );
        return filterHideResponseFromGroupFalse;
    };

    handleNext = event => {
        event.preventDefault();
        if (this.state.counter === this.state.teamResults.length - 1) {
            this.setState({ counter: 0 });
        } else {
            this.setState(prevState => ({
                counter: prevState.counter + 1,
            }));
        }
    };

    handlePrint = () => {
        
        window.$('a.no-print').hide();
        window.$('footer').hide();
        window.$('iframe#launcher-frame').hide();
        window.print();
        window.$('footer').show();
        window.$('iframe#launcher-frame').show();
        window.$('a.no-print').show();

    };
    handleRecipientModal(openEmailRecepientModal) {
        this.setState({ openEmailRecepientModal });
    }

    handlePrevious = event => {
        event.preventDefault();
        if (this.state.counter === 0) {
            this.setState({ counter: this.state.teamResults.length - 1 });
        } else {
            this.setState(prevState => ({
                counter: prevState.counter - 1,
            }));
        }
    };



    render() {
        const { counter, teamResults } = this.state;
        const { view_type, group_id } = this.props.match.params;

        const team_images = teamResults.map((images, index) => {

                 
        if(images.multipleQuestionResponse && Array.isArray(images.multipleQuestionResponse.answers)){
            
        return(

            <>
            {
            images.multipleQuestionResponse.answers.map((ans, indexAns)=>

                <div key={index+indexAns} className="item border p-3 mb-3">
                    <h3 className="font-weight-bold">Question: {ans.question}</h3>
                    <div className="msg-title">
                        {images.hideResponseFromGroup &&
                            this.props.authUserUid !== images.user_id
                            ? ''
                            : ans.response}{' '}
                    </div>
                    <div className="author-title">
                        {images.hideNameFromGroup &&
                            this.props.authUserUid !== images.user_id
                            ? ''
                            : images.name}
                    </div>
                  
                    <div
                        className="img-wrap"
                        style={{ textAlign: 'center', margin: 'auto' }}>
                        <img
                            style={{ maxHeight: '36vh', width: 'auto' }}
                            src={ans.image_url}
                            className="final-image"
                            alt="loading..."
                        />
                    </div>

                    {this.props.authUserUid === images.user_id
                    ?
                    <div className="no-print">
                        <ActionButtons
                            {...ans}
                            handleRecipientModal={() => this.handleRecipientModal(true)}
                            handlePrint={this.handlePrint}
                        />
                        <EmailRecipientsModal
                            data={ans}
                            imageUrl={
                                ans &&
                                ans.image_url
                            }
                            open={this.state.openEmailRecepientModal}
                            openModal={this.handleRecipientModal.bind(this)}
                        />
                    </div>
                    : ''
                    }
                    
                    
                    
                </div>

                
                
        
                
            )
            }
            
            </>
        )

      }else{
            return (
                <div key={index} className="item">
                    <div className="msg-title">
                        {images.hideResponseFromGroup &&
                            this.props.authUserUid !== images.user_id
                            ? ''
                            : images.response}{' '}
                    </div>
                    <div className="author-title">
                        {images.hideNameFromGroup &&
                            this.props.authUserUid !== images.user_id
                            ? ''
                            : images.name}
                    </div>
                    <div
                        className="img-wrap"
                        style={{ textAlign: 'center', margin: 'auto' }}>
                        <img
                            style={{ maxHeight: '36vh', width: 'auto' }}
                            src={images.image_url}
                            className="final-image"
                            alt="loading..."
                        />
                    </div>
                    {
                        this.props.authUserUid === images.user_id
                            ?
                            <div className="no-print">
                                <ActionButtons
                                    {...images}
                                    handleRecipientModal={() => this.handleRecipientModal(true)}
                                    handlePrint={this.handlePrint}
                                />
                                <EmailRecipientsModal
                                    data={images}
                                    imageUrl={
                                        images &&
                                        images.image_url
                                    }
                                    open={this.state.openEmailRecepientModal}
                                    openModal={this.handleRecipientModal.bind(this)}
                                />
                            </div>
                            : ''
                    }
                </div>
            );
        }
    });

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12 back-nav no-print">
                        <Link
                            to={
                                view_type === 'admin'
                                    ? `/results-team-admin/${view_type}/${group_id}`
                                    : `/results-team/${view_type}/${group_id}`
                            }
                            className="goto_back">
                            <i />
                            Back
                        </Link>
                    </div>
                    <div className="form-padding-box">
                        <div className="form-wrap">
                            <ReactCSSTransitionReplace
                                transitionName="fade-wait"
                                transitionEnterTimeout={1000}
                                transitionLeaveTimeout={400}>
                                {team_images[counter]}
                            </ReactCSSTransitionReplace>
                            <div className="button-wrap mt-25">
                                {team_images.length > 1 ? (
                                    <div className="row">
                                        <div className="col-md-6 col-6">
                                            <a
                                                href="/"
                                                className="btn-primary grey-bg owl-prev no-print"
                                                onClick={this.handlePrevious}>
                                                PREVIOUS
                                            </a>
                                        </div>
                                        <div className="col-md-6 col-6">
                                            <a
                                                href="/"
                                                className="btn-primary green-bg owl-next no-print"
                                                onClick={this.handleNext}>
                                                NEXT
                                            </a>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authUserUid: state.firebase.auth.uid,
        teamResults: state.activity.activityByGroupId,
    };
};

export default connect(
    mapStateToProps,
    { getGroupActivtyByGroupId },
)(ResultsIndividual);