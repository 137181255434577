import React from 'react';
import { connect } from 'react-redux';
import { editQuestion } from '@actions/question';

import './styles.css';

class EditQuestionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: props.question.id,
            questionType: props.question.question_type,
            content: props.question.content
        };
    }

    resetForm() {
        this.setState({
            editTextArea: '',
            question: '',
            questionType: '',
            type: 'normal'
        });
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onEditSubmit = (event) => {
        event.preventDefault();
        this.props.editQuestion(this.state);
        this.resetForm();
        this.props.toggle();
    }

    render() {
        const {profile } = this.props;
        const { user_type } = profile
        return (
            <form onSubmit={this.onEditSubmit}>
                <div className="form-group">
                    <textarea value={this.state.content} name="content" onChange={this.onChange} placeholder="Enter question ..." className="form-control"></textarea>
                </div>
                {  user_type == "premium" ?null :  
                    <div className="form-group">
                        <label htmlFor="question-use">Type: </label>
                        <select value={this.state.questionType} disabled={this.props.question.type === 'default'? true : false} name="questionType" className="form-control" id="question-use" onChange={this.onChange} required>
                            <option value="" disabled>--Select Question Type</option>
                            <option value="personal">Personal</option>
                            <option value="group">Group</option>
                        </select>
                    </div>
                 }
              
                <div className="form-group text-right">
                    <button className="btn btn-info" style={{backgroundColor: "#2a3381", border: "none"}}>Submit</button>
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    const { auth, profile } = state.firebase;
  
    return {
        auth,
        profile,
    }
  }
const mapDispatchToProps = dispatch => ({
    editQuestion: (question, newContent) => dispatch(editQuestion(question, newContent))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditQuestionForm);
