import React from 'react';
import  { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { getUserName, updateUserName, updateUserEmail } from "@actions/user";
import Swal from "sweetalert";
import firebase from 'firebase/app';
import './styles.css';


class HomePage extends React.Component {

    constructor(props) {
        super(props);
        window.location.replace("/home/index.html");
    }
    


    render() {

        return (
            //  <Redirect to='/home/'  />
            <div></div>

        )
    };
}

const mapStateToProps = state => ({
    activity: state.activity,
    groups: state.group.groups,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
