import React from 'react';
import FeatureRequestHeader from './FeatureRequestHeader';
import { Container, Row, Col, Tab, Tabs, Breadcrumb, Dropdown, Button } from 'react-bootstrap';
import { withRouter } from "react-router"
import { Link, Route } from 'react-router-dom'
import { IoHome, IoSearch } from "react-icons/io5";
import { connect } from "react-redux";
import { getIdeas, onVoting, onSubscribing, onEditIdea, onComment, getComment, onSearchedData } from '@actions/ideas'
import parse from 'html-react-parser';
import { ImBubbles2 } from "react-icons/im";
import EditIdea from './EditIdea';
import Editor from '../AddNewIdea/Editor/Editor';
import { ImCross } from 'react-icons/im'

const linkStyle = { color: '#2a3382', fontSize: '14px' }
const btnStyle = {
  height: 29,
  width: 130,
  padding: 0,
  border: '2px solid #2a3382',
  color: '#2a3382',
  backgroundColor: "whitesmoke"
}
const btn2Style = {
  color: 'grey',
  border: '3px solid grey',
  padding: '0px',
  height: '34px',
  width: '75%',
  backgroundColor: 'white'
}
const CommentOnIdea = (props) => {

  const { match: { params: { id } }, ideasList, auth } = props
  const [tabKey, setTabKey] = React.useState('comment')
  const [image, setImage] = React.useState(null)
  const [isSearch, setSearch] = React.useState(false)
  const [value, setValue] = React.useState('')
  const [comment, setComment] = React.useState('')
  const [file, setFile] = React.useState(null)
  const styles = { color: '#2a3382', fontSize: '15px' }

  React.useEffect(() => {
    if (ideasList.length == 0) {
      props.history.push('/ideas')
    }
    props.getComment(id)
  }, [])

  const onFileUpload = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]))
    setFile(event.target.files[0] )
  }
  const onSearch = (e) => {
    if (e.keyCode == 13) {
      setSearch(true)
      props.onSearchedData(e.target.value)
      props.history.push('/ideas')
    }
  }

  return (
    <div>
      <FeatureRequestHeader />
      <Container>
        <Row>
          <Col xs={0} sm={1}>
          </Col>
          <Col xs={12} sm={8} >

            <Breadcrumb className="breadcrumb" >
              <div className="d-flex align-items-center" >
                <Breadcrumb.Item  >
                  <Link to="/ideas" style={styles}>  <IoHome /> &nbsp;Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item  >
                  <Link to="/ideas" style={styles}> {`SPRAQ-I-`}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item >
                  <Link to="/add-new-idea" style={styles}>New Idea</Link>
                </Breadcrumb.Item>
              </div>
            </Breadcrumb>
            {/* Idea */}
            <React.Fragment>

              {props.idea.map((idea, index) => {
                const { voted,
                  idea_id,
                  vote_count, category_id } = idea
                let ctg = category_id == 1 ? "General" : category_id == 2 ? "Admin" : ""

                return <React.Fragment>
                  <ul className="tabStyle" key={index}>
                    <li className="tab" >
                      <div className="custom-vote" disabled style={{ color: !voted ? null : '#2a3382' }} onClick={() => props.onVoting(voted, idea_id, vote_count)}>
                        <a className="btn-vote" style={{ borderColor: !voted ? null : '#2a3382' }}>
                          <span className="vote-count">{vote_count}</span>
                          <span className="vote-status" style={{ backgroundColor: !voted ? null : '#2a3382' }}>{props.voting ? " Voting..." : voted ? "Voted" : "Vote"}</span>
                        </a>
                      </div>
                      <div className="heading-vote" >
                        <h3>{idea.idea}</h3>
                        <div className="description">
                          {parse(idea.description)}
                        </div>
                        <div className="vote-meta">
                          <span className="avatar">
                            <img alt="no" src="https://secure.gravatar.com/avatar/b94f5d7746ea188246dd2d7caffb0a33?default=mm&rating=G&secure=true&size=32"
                              width="32" height="32"></img>
                          </span>
                          <div className="vote-meta-created">
                            <div className="vote-meta-details">
                              Created 20 Oct 14:23
                              by Guest
                                                              </div>
                            <div className="vote-meta-details">
                              {ctg}
                            </div>
                          </div>
                          <span className="vote-comment-count">{idea.comment_count}&nbsp;&nbsp;<ImBubbles2 /></span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </React.Fragment>
              })}
            </React.Fragment>
            {/* Tabs */}
            <div style={{ marginLeft: '15%' }}>
              <Tabs
                id="controlled-tab-example"
                activeKey={tabKey}
                onSelect={(k) => setTabKey(k)}
              >
                <Tab eventKey="comment" title={`Comment (${props.idea[0].comment_count})`} calss="ml-3">
                  <Editor onFileChoose={onFileUpload} placeholder="Comment here ..." des={comment} onChange={(value) => { setComment(value) }} />
                  {image==null?null:<img style={{width:150,height:150}} src={image}/>}

                  <br />

                  {comment.length < 5 ?
                    <Button
                      disabled

                      variant="outline-primary"
                      className="btn-primary"
                      style={btnStyle}
                    >
                      <b>Post comment</b>
                    </Button> :
                    <Button
                      variant="outline-primary"
                      className="btn-primary"
                      style={btnStyle}
                      onClick={() => { props.onComment({ comment: comment, file:file, idea_id: id }); setComment('');setImage(null )}}>

                      <b>Post comment</b>

                    </Button>}
                  {props.commentList.map((comment) => {
                    return <div class="d-flex justify-content-between py-3">
                      <div >
                        <div class="d-flex justify-content-start  align-items-start">
                          <div> <img alt="no" src={`https://icotar.com/initials/${comment.commenter.name}`}
                            width="32" height="32"></img></div>
                          <div class="px-4">{`${comment.commenter.name}  commented`}{parse(comment.comment)} </div>
                         
                        </div>
                        {comment.file !==null?<div class="px-4"> <img alt="no" src={comment.file}
                            width="100" height="100"></img></div>:null}
                        
                      </div>

                      <div>{comment.created}</div>
                    </div>
                  })}
                </Tab>
                <Tab eventKey="vote" title={`Vote (${props.idea[0].vote_count})`}>

                </Tab>
              </Tabs>
            </div>
          </Col >
          <Col xs={12} sm={2}>
            <div className="search-field">
              <IoSearch className="iconStyle" />
              <input type="text" value={value} onChange={(e) => { setValue(e.target.value) }} onKeyDown={onSearch} name="search" placeholder="Search ideas" />
              {isSearch ? <ImCross className="crosStyle" onClick={() => { props.onSearchedData(false); setSearch(false); setValue('') }} /> : null}
            </div>
            <br />
            <hr />
            <Link to="/" style={linkStyle}>Login  /Signup</Link>
            <hr />

          {/* <EditIdea data={props.idea} />*/} 
            <Button
              variant="outline-primary"
              className="btn-primary"
              style={btn2Style}
              onClick={() => { props.onSubscribing(props.idea[0].subscribed, props.idea[0].idea_id) }}>

              {props.subscribing
                ? "..."
                : props.idea[0].subscribed
                  ? "Unsubscribe"
                  : "Subscribe"}

            </Button>
            <hr />
            <span>You're receiving notifications because you created this idea</span>
            <hr />
            <span style={{ fontSize: '19px' }}>Related Ideas</span>
            <br />
            {props.relatedIdeas.map((idea, index) => {
              return <span onClick={() => { props.onEditIdea(idea.idea_id); props.getComment(idea.idea_id) }}>
                <Link to={{
                  pathname: `/ideas/${idea.idea_id}`,
                  state: { fromDashboard: true }
                }}
                  key={index}
                  style={linkStyle}>
                  {idea.idea}
                </Link>
                <br />
              </span>
            })}
          </Col>
          <Col xs={0} sm={1}>
          </Col>
        </Row>
      </Container>
    </div>
  )

}


const mapStateToProps = ({ ideas, firebase }) => {
  return {
    idea: ideas.ideaEdit,
    relatedIdeas: ideas.relatedIdeas,
    ideasList: ideas.ideas,
    auth: firebase.auth,
    voting: ideas.voting,
    subscribing: ideas.subscribing,
    commentList: ideas.commentList
  };
};

const mapDispatchToProps = (dispatch) => ({
  onEditIdea: (id) => dispatch(onEditIdea(id)),
  getIdeas: () => dispatch(getIdeas()),
  onVoting: (isVoted, data, count) => dispatch(onVoting(isVoted, data, count)),
  onSubscribing: (isVoted, idea_id) => dispatch(onSubscribing(isVoted, idea_id)),
  onComment: (data) => dispatch(onComment(data)),
  getComment: (ideaId) => dispatch(getComment(ideaId)),
  onSearchedData: (value) => dispatch(onSearchedData(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CommentOnIdea));