import React, {Fragment} from 'react';
import {
    FacebookShareButton,
    TwitterShareButton,
    PinterestShareButton,
} from 'react-share';
import ReactGA from 'react-ga';
import { useState } from 'react';



const ShareButtons = ({question, imageUrl, noPrint}) => {

    const [token, setToken] = useState('');


    
    const onClickFacebook = () => {
        ReactGA.event({
            category: 'social',
            action: 'share',
            label: 'facebook',
        });
    };

    const onClickPinterest = () => {
        ReactGA.event({
            category: 'social',
            action: 'share',
            label: 'pinterest',
        });
    };

    const onClickTwitter = () => {
        ReactGA.event({
            category: 'social',
            action: 'share',
            label: 'twitter',
        });
    };


    const data = {
            link: imageUrl
        };

    if ( token === '' ){
        // POST request using fetch()
        fetch("https://www.skilldivvy.com/dev/crons/firestore/dynamic_link.php?"+Math.random()+"&link="+encodeURIComponent(data.link), {
            
            // Adding method type
            method: "GET",
            
            // Adding body or contents to send
            //body: JSON.stringify(data),

            
        
        })
        
        // Converting to JSON
        .then(response => response.json())
        
        // Displaying results to console
        .then(json => {
            imageUrl = json.link;
            setToken(imageUrl);
        });
    }


const description = `${question} 

${token}


    
I've just experienced SPARQ.

Visit https://discoversparq.com/sign-up to create a free account and experience Sparq for yourself!`;

    return (
        <Fragment>
            <div className={`share-box desktop ${noPrint}`}>
                <div className="row">
                    <div className="col-md-12 col-sm-12 share-col">
                        <FacebookShareButton url={imageUrl} quote={description}>
                            <a
                                href="/"
                                onClick={onClickFacebook}
                                className="share-btn facebook">
                                <i />
                                Facebook
                            </a>
                        </FacebookShareButton>
                    </div>
                </div>
                <div className="row mt-10">
                    <div className="col-md-6 col-sm-6 share-col sec-col">
                        <TwitterShareButton url="/" title={description}>
                            <a
                                href="/"
                                onClick={onClickTwitter}
                                className="share-btn twitter">
                                <i />
                                Twitter
                            </a>
                        </TwitterShareButton>
                    </div>
                    <div className="col-md-6 col-sm-6 share-col">
                        <PinterestShareButton
                            url={imageUrl}
                            description={description}
                            media={imageUrl}
                            windowWidth={1000}
                            windowHeight={730}>
                            <a
                                href="/"
                                onClick={onClickPinterest}
                                className="share-btn pinterest">
                                <i />
                                Pinterest
                            </a>
                        </PinterestShareButton>
                    </div>
                </div>
            </div>
            <div className="share-box mobile">
                <a href="/" className="share-btn facebook">
                    <FacebookShareButton
                        className="inline-block"
                        url={imageUrl}
                        quote={description}>
                        <i />
                    </FacebookShareButton>
                </a>
                <a href="/" className="share-btn twitter">
                    <TwitterShareButton
                        className="inline-block"
                        url="/"
                        title={description}>
                        <i />
                    </TwitterShareButton>
                </a>
                <a href="/" className="share-btn pinterest">
                    <PinterestShareButton
                        className="inline-block"
                        url={imageUrl}
                        description={description}
                        media={imageUrl}
                        windowWidth={1000}
                        windowHeight={730}>
                        <i />
                    </PinterestShareButton>
                </a>
            </div>
        </Fragment>
    );
};

export default ShareButtons;
