import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

class HowItWorks extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="small-title">How it works</div>
        <div
          dangerouslySetInnerHTML={{ __html: this.props.content }}
          className="info-text"
        ></div>
        <Link
          to={this.props.startLink}
          id="personal_ques"
          className="btn-primary green-bg mt-40"
        >
          START
        </Link>
      </React.Fragment>
    );
  }
}

export default HowItWorks;
