import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import "./activity-type.css";

const ActivityType = (props) => {
  useEffect(() => {
    localStorage.setItem("check", false);
  });
  return (
    <div id="main">
      <div className="container">
        <div className="row">
          <div className="col-md-12 back-nav">
            <Link to="/dashboard" className="goto_back">
              <i></i>Back
            </Link>
          </div>
          <div className="buttons-padding-box padding-box">
            <div className="buttons-wrap box-wrap" style={{ borderRadius: 5 }}>
              <div className="small-title">
                How are you using this activity?
              </div>
              <Link
                to="/personal-use/question"
                className="btn-primary green-bg"
              >
                PERSONAL USE
              </Link>
              <Link to="/team-activity" className="btn-primary blue-bg mt-25">
                AS PART OF A GROUP
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityType;
