import React from 'react';

import './styles.css';

class MaterialTextarea extends React.Component {
    constructor() {
        super();
        this.state = {
            textCount: 0,
        };
    }

    componentDidMount() {
        const $ = window.$;
        if($('.check-form .input-field').length > 0) {
            $('.check-form .input-field').on('keyup keypress input propertychange', function(){
                if($(this).val() === ''){
                    $(this).parent('.field-wrap').parent('.input-wrap').removeClass('success');
                    $(this).parent('.field-wrap').parent('.input-wrap').find('label').removeClass('active');
                }else{
                    $(this).parent('.field-wrap').parent('.input-wrap').addClass('success');
                    $(this).parent('.field-wrap').parent('.input-wrap').find('label').addClass('active');
                }
            });	
            
            $('.check-form .input-field').bind('blur', function(){
                if($(this).val() === ""){
                    $(this).parent('.field-wrap').parent('.input-wrap').find('label').removeClass('active');
                }
            });

            $('.check-form .input-field').bind('focus', function(){
                $(this).parent('.field-wrap').parent('.input-wrap').find('label').addClass('active');
            });
            
            $('.check-form .input-label').bind('click', function(){
                if(!$(this).hasClass('active')){
                    $(this).next('.field-wrap').find('input.input-field').trigger('focus');
                }
            });
            
            $('.check-form .input-field').each(function(){
                if($(this).val() !== ""){
                    $(this).parent('.field-wrap').parent('.input-wrap').addClass('success');
                    $(this).parent('.field-wrap').parent('.input-wrap').find('label').addClass('active');
                }
            });
        }
    }

    onChange(event) {
        const textCount = event.target.value.length;

        this.setState({ textCount });

        if (textCount > (parseInt(this.props.maxLength) + 30)) {
            return;
        }

        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    onInput(event) {
        if (this.props.onInput) {
            this.props.onInput(event);
        }
    }

    render() {
        return (
            <div className="input-wrap">
                <label className="input-label">{this.props.label}</label>
                <div className="field-wrap placeholder">
                    <textarea
                        className="input-field"
                        placeholder={this.props.placeholder}
                        type={this.props.type}
                        name={this.props.name}
                        autoComplete={this.props.autoComplete || "false"}
                        required={this.props.required || false}
                        pattern={this.props.pattern || null}
                        title={this.props.title || null}
                        onChange={this.onChange.bind(this)}
                        onInput={this.onInput.bind(this)}
                        value={this.props.value}
                        id={this.props.id || null}
                        rows={this.props.rows || 2}
                        maxLength={(parseInt(this.props.maxLength) + 30) || 1000}
                    ></textarea>
                    <div style={this.state.textCount > 350  ? {  color: "red" } : null} className="char-limit">
                            <span id="chars">
                                {parseInt(this.props.maxLength) - this.state.textCount}
                            </span> characters remaining
                        </div>
                </div>
            </div>
        )
    }
};

export default MaterialTextarea;
