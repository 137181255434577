import React from "react";
import { connect } from "react-redux";
import { shareEmail } from "@actions/send-grid";
import loading from "../../../resource/loading.gif";
import "./styles.css";
import firebase from 'firebase/app';
import { toast } from "react-toastify";
class EmailRecipientsModal extends React.Component {
    constructor() {
        super();

        this.state = {
            listOfEmail: [],
            email: "",
            message: "",
            name: "",
            loading: false,
            count: 0
        };
    }

    onSubmit = event => {

        event.preventDefault();
        let listOfEmail = this.state.listOfEmail;
        if (listOfEmail.indexOf(this.state.email) < 0) {
            listOfEmail.push(this.state.email);
        }
        this.setState({
            email: "",
            listOfEmail
        });
    };

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    closeModa() {
        this.props.openModal(false);
    }

    removeItem = email => {
        const listOfEmail = this.state.listOfEmail.filter(
            item => item !== email
        );
        this.setState({ listOfEmail });
    };

    sendEmail = () => {

        // console.log(this.props.data);
        // return;
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).get().then((docRef) => {
            // console.log(docRef.data());

            this.setState({ loading: true });
            //let promises = [];
            this.state.listOfEmail.forEach(email => {
                // promises.push(
                //     this.props.shareEmail({
                //         email,
                //         message: this.state.message,
                //         name: docRef.data().name,
                //         imageUrl: this.props.data.image_url,
                //         question: this.props.data.question,
                //         response: this.props.data.response,
                //         signupPage: `${window.location.origin}/sign-up`
                //     })
                // );

                let para = 'message=' + encodeURIComponent(this.state.message) + '&name=' + encodeURIComponent(docRef.data().name) + '&email=' + email + '&response=' + encodeURIComponent(this.props.data.response) + '&question=' + encodeURIComponent(this.props.data.question) + '&image=' + encodeURIComponent(this.props.data.image_url) + '&rand=' + Math.random()
                fetch('https://www.skilldivvy.com/dev/crons/firestore/share_reflection/send.php?' + para)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(response.statusText)
                        }
                        console.log(response.json())
                        this.state.count = this.state.count + 1

                        console.log('total ' + this.state.listOfEmail.length)
                        console.log(this.state.count)
                        if (this.state.listOfEmail.length == this.state.count) {

                            this.setState({ count: 0, loading: false, listOfEmail: [] });
                            this.props.openModal(false);

                            toast("Email Successfully Sent!", {
                                type: "success"
                            })

                        }

                    }).catch(err => {
                        console.log(err)
                    })


            });



            // Promise.all(promises).then(() => {
            //     this.setState({ loading: false, listOfEmail: [] });
            //     this.props.openModal(false);
            // });

        }).catch((error) => {
            console.log(error);
        })

    };

    render() {
        if (!this.props.open) {
            return <span />;
        }
        return (
            <div id="email-recipient-modal">
                <div className="em-modal-body-wrap">
                    <div className="em-modal-body">
                        <div className="em-modal-header">
                            <button
                                onClick={this.closeModa.bind(this)}
                                type="button"
                                className="close-btn"
                            >
                                &#215;
                            </button>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-dark">
                                <h6>Enter email address and click plus button to add to recipient list</h6>
                            </div>
                        </div>
                        <ul className="email-list">
                            {this.state.listOfEmail.map(email => (
                                <li key={email}>
                                    {email}
                                    <button
                                        type="button"
                                        onClick={this.removeItem.bind(
                                            this,
                                            email
                                        )}
                                        className="em-remove-item"
                                    >
                                        &#215;
                                    </button>
                                </li>
                            ))}
                        </ul>
                        <form onSubmit={this.onSubmit.bind(this)}>
                            <div className="row">
                                <div className="col-sm-8">
                                    <input
                                        name="email"
                                        onChange={this.onChange.bind(this)}
                                        value={this.state.email}
                                        type="email"
                                        className="large-input"
                                        placeholder="Enter email address"
                                        required
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <button className="btn-primary active plus-btn">
                                        &#x2b;
                                    </button>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <p className="font-weight-bold text-dark font-italic mb-2">Personalize your message below. It will be included within the email that recipients receive.</p>
                                    <textarea name="message" onChange={this.onChange.bind(this)} className="form-control mt-0 border-dark" maxLength="500"></textarea>
                                </div>
                                <div className="col-sm-12">
                                    <button
                                        type="button"
                                        className={
                                            "btn-primary send-email-btn " +
                                            (this.state.listOfEmail.length
                                                ? "active"
                                                : "inactive")
                                        }
                                        onClick={this.sendEmail}
                                    >
                                        {this.state.loading ? (
                                            <React.Fragment>
                                                <img
                                                    src={loading}
                                                    alt="loading..."
                                                />{" "}
                                                Send Email
                                            </React.Fragment>
                                        ) : (
                                            "Send Email"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        shareEmail: data => dispatch(shareEmail(data))
    };
};

export default connect(
    null,
    mapDispatchToProps
)(EmailRecipientsModal);
