import React,{Component} from 'react'
import PropTypes from 'prop-types';
import * as ReactQuill from 'react-quill';
import { MdAttachFile } from 'react-icons/md'
import './editor.css'
import 'react-quill/dist/quill.snow.css';

export default class Editor extends Component {
 constructor(props) {
   super(props)
   this.state = {
      image:null
   }
 }
 
  onPreview =(e) =>{
    this.setState({image:URL.createObjectURL(e.target.files[0])})
  }
  render () {

    return (
      <div>
        <ReactQuill 
          name='description'
          theme={'snow'}
          onChange={this.props.onChange}
          value={this.props.des}
          formats={Editor.formats}
          modules={Editor.modules}
          bounds={'.app'}
          placeholder={this.props.placeholder || "Why is it useful, who would benefit from it, how should it work?"}
         />
          <label style={{cursor:'pointer'}}>   
            <MdAttachFile/>
            Attach files
            <input style={{display:'none'}} 
                    type="file"
                     onChange={ (e) =>{
                                this.props.onFileChoose(e);
                                this.onPreview(e)
                                }}/>
          </label> 
        <br/>
       </div>
     )
  }
}


Editor.modules = {
  toolbar: [
    [{ 'header': ['1','2','3','4','5','6']}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['clean']
  ],
  clipboard: {
    matchVisual: false,
  }
}

Editor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]

Editor.propTypes = {
  placeholder: PropTypes.string,
}
