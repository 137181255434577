import React, { Fragment } from 'react';
import ShareButtons from '../../main/ShareButtons';

const GuidingText = ({ hideNameFromGroup, hideResponseFromGroup }) => {
    let guidingText;

    if (hideNameFromGroup && hideResponseFromGroup) {
        guidingText = 'Your name and response is only visible to you.';
    } else if (hideNameFromGroup && !hideResponseFromGroup) {
        guidingText = 'Your name is only visible to you.';
    } else if ( !hideNameFromGroup && hideResponseFromGroup){
        guidingText = 'Your response is only visible to you.';
    } else {
        guidingText = 'Your name and response is visible in group display.';
    }

    return (
        
        <div className="guidingText no-print" style={{
            textAlign: 'center',
            marginTop: '12px',
        }}>
            {guidingText}
        </div>

    )
};

 
   

const ActionButtons = ({ handleRecipientModal, openEmailRecepientModal, handlePrint, ...props }) => {

    
    return (
        <Fragment>
            <GuidingText
                hideNameFromGroup={props.hideNameFromGroup}
                hideResponseFromGroup={props.hideResponseFromGroup}
            />
            <ShareButtons
                noPrint="no-print"
                question={props.question}
                imageUrl={props.image_url}
            />
            <div className="row mt-15">
                <div className="col-md-6">
                    <button
                        onClick={handleRecipientModal}
                        type="button"
                        className="btn-primary grey-bg">
                        EMAIL
                    </button>
                </div>
                <div className="col-md-6 sec-col">
                    <button
                        className="btn-primary grey-bg"
                        onClick={handlePrint}>
                        PRINT
                    </button>
                </div>
            </div>
        </Fragment>
    )
};

export default ActionButtons;