import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import './activity-result.css';

class ActivityResult extends React.Component {
    render () {
        return (
            <Container>
                <Link to="/dashboard" className="back-link">BACK</Link>
                <div style={{ textAlign: 'center' }}>
                    <div className="activity-selection-container">
                        <p className="activity-selection-label">Currently Testing</p>
                    </div>
                </div>
            </Container>
        )
    }
}
const mapStateToProps = (state) => {
    const { profile } = state.firebase;
    return {
        profile,
    }
}

export default connect(mapStateToProps)(ActivityResult);
