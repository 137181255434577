import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import AddQuestionForm from "../admin/AddQuestionForm";
import EditQuestionForm from "../admin/EditQuestionForm";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import {
  Container,
  CardBody,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  deleteQuestion,
  setDefaultQuestion,
  getAllQuestions,
} from "@actions/question";
import ReactTable from "react-table";

class AdminQuestions extends Component {
  constructor() {
    super();
    this.state = {
      question: {},
      showAddForm: false,
      showEditForm: false,
      showUploadImageForm: false,
      modal: false,
    };
  }

  toggle = () => {
    this.setState({
      modal: false,
      showEditForm: false,
      showAddForm: false,
      showUploadImageForm: false,
    });
  };

  setAsDefault = (event) => {
    if (window.confirm("Do you want to make this question the default?")) {
      this.props.setDefaultQuestion({
        default_key: event.target.dataset.key,
        normalquestion: this.props.questions.filter((data) => {
          return data.type === "default";
        }),
      });
    }
  };

  deleteQuestion = (event) => {
    if (window.confirm("Do you want to delete this queston?")) {
      this.props.deleteQuestion(event.target.dataset.key);
    }
  };

  showAddQuestionForm = () => {
    this.setState((prevState) => ({
      question: {},
      showAddForm: !prevState.showAddForm,
      showEditForm: false,
      showUploadImageForm: false,
      modal: true,
    }));
  };

  showEditQuestionForm = (event) => {
    this.setState({
      question: JSON.parse(event.target.dataset.question),
      showEditForm: true,
      showAddForm: false,
      showUploadImageForm: false,
      modal: true,
    });
  };

  displayEditForm() {
    if (this.state.showEditForm) {
      return (
        <EditQuestionForm
          question={this.state.question}
          close={() => this.setState({ showEditForm: false })}
          toggle={this.toggle}
        />
      );
    }
  }

  displayAddForm() {
    const { profile, auth } = this.props
    if (this.state.showAddForm) {
      return (
        <AddQuestionForm
          toggle={this.toggle}
          profile={profile}
          auth= {auth}
          questiontype={this.props.questions.filter((data) => {
            return data.type === "default";
          })}
        />
      );
    }
  }

  render() {
    const { profile, questions, auth } = this.props
    
    let subAdminQuestion = []
    if (questions) {
      subAdminQuestion = questions.filter(question => question.user_type === "premium" && question.userId === auth.uid || question.index === "default-0")
    }
    
    const columns = [
      {
        Header: "Question",
        accessor: "content",
        filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id
            return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true
        },
      },
     
      {
       
        id: "type",
        show: profile.user_type == 'premium'?false:true, 
        Header: "Type",
        Cell: (question) =>
          question.original.type + "-" + question.original.question_type,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          if (filter.value === "true") {
            return (
              row["_original"].type + "-" + row["_original"].question_type ===
              "normal-group"
            );
          }
          if (filter.value === "false") {
            return (
              row["_original"].type + "-" + row["_original"].question_type ===
              "normal-personal"
            );
          }
          if (filter.value === "default") {
            return (
              row["_original"].type + "-" + row["_original"].question_type ===
              "default-personal"
            );
          }
          return (
            row["_original"].type + "-" + row["_original"].question_type ===
            "normal-user"
          );
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            <option value="true">Normal-Group</option>
            <option value="user">Normal-User</option>
            <option value="false">Normal-Personal ( Personal Activity Questions )</option>
            <option value="default">Default  ( Personal Activity Default Question )</option>
          </select>
        ),
      },
      {
        id: "select_option",
        Header: "Select Question",
        filterable: false,
        Cell: (question) => (
          <Fragment>
            <button
              style={{
                marginLeft: "10px",
                backgroundColor: "#2a3381",
                border: "none",
              }}
              data-question={JSON.stringify(question.original)}
              onClick={this.showEditQuestionForm}
              className="btn btn-info btn-sm"
            >
              Edit
            </button>
            {question.original.type === "normal" ? (
              <React.Fragment>
                <button
                  style={{ marginLeft: "10px" }}
                  data-key={question.original.id}
                  onClick={this.deleteQuestion}
                  className="btn btn-danger btn-sm"
                >
                  Delete
                </button>
                {profile.user_type == 'premium'?null:
                question.original.question_type === "personal" ? (
                  <button
                    style={{ marginLeft: "10px" }}
                    data-key={question.original.id}
                    onClick={this.setAsDefault}
                    className="btn btn-warning btn-sm"
                  >
                    Make Default
                  </button>
                ) : null}
            
              </React.Fragment>
            ) : null}
          </Fragment>
        ),
      },
    ];

    const modal = (
      <Fragment>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Add Question</ModalHeader>
          <ModalBody>
            {this.displayAddForm()}
            {this.displayEditForm()}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </Fragment>
    );

    return (
      <Container>
        <Card style={{ marginTop: "30px" }}>
          <CardBody>
            <div className="form-group">
              <button
                onClick={this.showAddQuestionForm.bind(this)}
                style={{ backgroundColor: "#2a3381", border: "none" }}
                className="btn btn-info btn-sm"
              >
                Add Question
              </button>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            {modal}
            {profile.user_type === "premium" ? (
              <ReactTable
                filterable
                defaultFilterMethod={(filter, row, column) => {
                  const id = filter.pivotId || filter.id;
                  if (typeof filter.value === "object") {
                    return row[id] !== undefined
                      ? filter.value.indexOf(row[id]) > -1
                      : true;
                  } else {
                    return row[id] !== undefined
                      ? String(row[id]).indexOf(filter.value) > -1
                      : true;
                  }
                }}
                data={subAdminQuestion}
                columns={columns}
                className="-striped -highlight"
              />
            ) : (
                <ReactTable
                  filterable
                  defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id;
                    if (typeof filter.value === "object") {
                      return row[id] !== undefined
                        ? filter.value.indexOf(row[id]) > -1
                        : true;
                    } else {
                      return row[id] !== undefined
                        ? String(row[id]).indexOf(filter.value) > -1
                        : true;
                    }
                  }}
                  data={this.props.questions}
                  columns={columns}
                  className="-striped -highlight"
                />
              )}
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { firestore } = state;
  const { profile, auth } = state.firebase
  return {
    questions: firestore.ordered.questions,
    profile,
    auth
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllQuestions: () => dispatch(getAllQuestions()),
  deleteQuestion: (key) => dispatch(deleteQuestion(key)),
  setDefaultQuestion: (key) => dispatch(setDefaultQuestion(key)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: "questions" }])
)(AdminQuestions);
