import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { authenticateUser, refreshErrors } from '@actions/auth';
import { connect } from 'react-redux';
import MaterialInput from '@components/main/MaterialInput';
import firebase from 'firebase/app';

class SSO extends Component {

    constructor(props) {
        super(props);
        
        if( typeof this.props.match.params.jwt !== 'undefined' && this.props.match.params.jwt !== '' ){

            firebase.auth().signInWithCustomToken(this.props.match.params.jwt)
                .then((userCredential) => {
                    // Signed in
                    var user = userCredential.user;
                    console.log(userCredential);
                })
                .catch((error) => {
                    console.log( error.message );
                });

        }

    }

    state = {
        name: '',
        email: '',
        password: '',
        confirm_password: '',
        authSignInError: '',
        authEmailForgotPasswordSuccess: ''
    }

    componentWillReceiveProps(nextProps) {
        const { authSignInError, authEmailForgotPasswordSuccess } = nextProps

        this.setState({
            authSignInError,
            authEmailForgotPasswordSuccess
        })
    }

    componentWillUnmount() {
        this.props.refreshErrors()
    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.authenticateUser(this.state);
    }

    validateEmail(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {

        const error_style = {
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "normal",
            fontStyle: "normal",
            fontStretch: "normal",
            lineHeight: "1.71",
            letterSpacing: "normal",
            color: "#ef2727",
            position: "relative",
            bottom: "14px",
        }
        const success_style = {
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "normal",
            fontStyle: "normal",
            fontStretch: "normal",
            lineHeight: "1.71",
            letterSpacing: "normal",
            color: "#0092ac",
            position: "relative",
            bottom: "14px",
        }
        return (
            <React.Fragment>
                <div id="main">
                    <div className="container">
                        <div className="row">
                            <div className="form-padding-box padding-box">
                                <div className="form-wrap box-wrap" style={{borderRadius: 5}}>
                                    <h1 className="form-title">Welcome back!</h1>
                                    <div className="form-descp">Signing in with SSO. Please wait</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
};

const mapStateToProps = (state) => {
    const { auth: { authSignInError, customMessage, authEmailForgotPasswordSuccess } } = state
    return {
        authSignInError,
        customMessage,
        authEmailForgotPasswordSuccess
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authenticateUser: (creds) => dispatch(authenticateUser(creds)),
        refreshErrors: () => dispatch(refreshErrors())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SSO);
