import  { randomizeArray } from '../helpers';

import {
    ADD_QUESTION,
    GET_QUESTION,
    EDIT_QUESTION,
    DELETE_QUESTION,
    SET_ALL_QUESTIONS,
    SET_QUESTION_IMAGES,
    SET_QUESTION_DEFAULT_SUCCESS,
    SET_QUESTION_DEFAULT_ERROR,
    SET_QUESTION_IMAGES_LAST_VISIBLE,
    SET_IMAGE_COUNT,
} from '@app/action-types';

const initialState = {
    question: {},
    questions: [],
    images: [],
    setDefaulError: null,
    lastVisible: null,
    imageCount: null,
};


const questionReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_QUESTION:
            return {
                ...state,
                question: action.payload
            };
        case SET_ALL_QUESTIONS:
            return {
                ...state,
                questions: action.payload
            };
        case DELETE_QUESTION:
            return {
                ...state,
                questions: state.questions.filter(question => (question.key !== action.payload))
            };
        case ADD_QUESTION:
            return {
                ...state,
            };
        case EDIT_QUESTION:
            return {
                ...state,
                questions: state.questions.map(question => {
                    if (action.payload.key === question.key) {
                        question.content = action.payload.content;
                    }
                    return question;
                })
            };
        case SET_QUESTION_IMAGES:
            const images = state.images.concat(randomizeArray(action.payload));
            return {
                ...state,
                images,
            };
        case SET_QUESTION_IMAGES_LAST_VISIBLE:
            return {
                ...state,
                lastVisible: action.payload,
            }
        case SET_IMAGE_COUNT:
            return {
                ...state,
                imageCount: action.payload,
            }
        case SET_QUESTION_DEFAULT_SUCCESS:
            return {
                ...state,
                setDefaulError: null 
            };
        case SET_QUESTION_DEFAULT_ERROR:
            return {
                ...state,
                setDefaulError: action.err 
            };
        default:
            return state;
    }
};

export default questionReducer;
