import {  GET_IDEAS_LIST,
         ON_CHANGE_FILTET, 
         FILTER_BY_CATG ,
         FILTER_BY_MY ,
         ON_CHANGE_EDIT_IDEA ,
         EDIT_IDEA ,
         RELATED_IDEAS,
         MY_COUNT , 
         ON_VOTING ,
         ON_SUBSCRIBING,
         SET_COMMENT,
         SET_TAB
        } from '@app/action-types.js';
const initialState = {
  ideas: [],
  ideaEdit:[{description:'',idea:''}],
  relatedIdeas:[],
  filtered: false,
  category_filter: 0,
  status_filter: null,
  my_filter: null,
  my_Ideas:0,
  my_Subs:0,
  my_Vote:0,
  g_IdeasCount:0,
  a_IdeasCount:0,
  voting:false,
  subscribing:false,
  trending:true,
  commentList:[]
}
const ideasReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IDEAS_LIST:
      return {
        ...state,
        ideas: action.payload
      }
    case ON_CHANGE_FILTET:
      return {
        ...state,
        filtered :action.payload
      }
    case FILTER_BY_CATG:
      return {
        ...state,
        category_filter: action.payload
      }
    case FILTER_BY_MY:
      return {
       ...state ,
      my_filter:action.payload
    }
    case EDIT_IDEA:
    return {
     ...state ,
    ideaEdit:action.payload
  }
  case ON_CHANGE_EDIT_IDEA:
    return {
      ...state,
      ideaEdit:[action.payload]
    }
  case RELATED_IDEAS:
    return{
    ...state ,
    relatedIdeas:action.payload
  }
  case MY_COUNT:
    return{
    ...state ,
    my_Ideas:action.payload.myIdea,
    my_Subs:action.payload.mySubs,
    my_Vote:action.payload.myVote,
    g_IdeasCount:action.payload.generalIdeasCount,
  a_IdeasCount:action.payload.adminIdeasCount
  }
  case ON_VOTING:
    return {
      ...state,
      voting: action.payload
    }
    case ON_SUBSCRIBING:
    return {
      ...state,
      subscribing: action.payload
    }
    case SET_TAB:
      return {
        ...state,
        trending: action.payload
      }
    case SET_COMMENT:
      return{
      ...state,
      commentList:action.payload
    }
    default:
      return state;
  }
};

export default ideasReducer;