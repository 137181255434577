import React, { Component, Fragment } from 'react';

import { Link } from "react-router-dom";
import { signUp, refreshErrors } from '@actions/auth';
import { connect } from 'react-redux';
import PrivacyModal from './PrivacyModal';
import TermsModal from './TermsModal';
import MaterialInput from '@components/main/MaterialInput';

class SignUp extends Component {
    state = {
        name: '',
        email: '',
        password: '',
        confirm_password: '',
        accept_occasion: false,
        referral_code: !this.props.match.params.id ? '' : this.props.match.params.id,
        authSignUpError: '',
        passwordFocused: false,
        privacy_modal_open: false,
        terms_modal_open: false
    }

    componentWillReceiveProps(nextProps) {
        const { authSignUpError } = nextProps

        this.setState({
            authSignUpError
        })
    }

    componentWillUnmount() {
        this.props.refreshErrors()
    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.handleCustomValidation();
        this.CheckPasswordStrength();
    }

    handleCheckMarketing = event => {
        this.setState({
            [event.target.name]: event.target.checked,
        })
    }

    CheckPasswordStrength = () => {
        let password = document.getElementById('password').value;

        //TextBox left blank.
        if (password.length === 0) {
            document.getElementById("low-upper-case").classList.remove('text-valid');
            document.getElementById("one-number").classList.remove('text-valid');
            document.getElementById("one-special-char").classList.remove('text-valid');
            document.getElementById("eight-character").classList.remove('text-valid');
            return;
        }

        //If password contains both lower and uppercase characters, increase strength value.
        if (/([a-z].*[A-Z])|([A-Z].*[a-z])/.test(password)) {
            document.getElementById("low-upper-case").classList.add('text-valid');
        } else {
            document.getElementById("low-upper-case").classList.remove('text-valid');
        }

        //If it has numbers and characters, increase strength value.
        if (/([a-zA-Z])/.test(password) && /([0-9])/.test(password)) {
            document.getElementById("one-number").classList.add('text-valid');
        } else {
            document.getElementById("one-number").classList.remove('text-valid');
        }

        //If it has one special character, increase strength value.
        if (/([!,%,&,@,#,$,^,*,?,_,~])/.test(password)) {
            document.getElementById("one-special-char").classList.add('text-valid');
        } else {
            document.getElementById("one-special-char").classList.remove('text-valid');
        }

        //Validate for length of Password.
        if (password.length > 5) {
            document.getElementById("eight-character").classList.add('text-valid');
        } else {
            document.getElementById("eight-character").classList.remove('text-valid');
        }
    }

    handleCustomValidation = () => {
        let confirm_password = document.getElementById('confirm_password');
        let password = document.getElementById('password');

        if (password.value && confirm_password.value && confirm_password.value !== password.value) {
            confirm_password.setCustomValidity('Password does not match the confirm password.');
        } else {
            confirm_password.setCustomValidity('');
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.signUp(this.state);
    }

    passwordFocus = (passwordFocused) => {
        this.setState({ passwordFocused });
    }

    togglePrivacyModal = () => {
        this.setState({ privacy_modal_open: !this.state.privacy_modal_open });
    }

    toggleTermsModal = () => {
        this.setState({ terms_modal_open: !this.state.terms_modal_open });
    }

    render() {
        const { authSignUpError, name, email, password, confirm_password } = this.state;

        const validForm = name && email && password && confirm_password;

        const error_style = {
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "normal",
            fontStyle: "normal",
            fontStretch: "normal",
            lineHeight: "1.71",
            letterSpacing: "normal",
            color: "#ef2727",
            position: "relative",
            bottom: "14px",
            marginTop: "-23px",
            marginBottom: "23px"
        }

        return (
            <Fragment>
                <div id="main">
                    <PrivacyModal
                        privacyModalOpen={this.state.privacy_modal_open}
                        togglePrivacyModal={this.togglePrivacyModal}
                    />
                    <TermsModal
                        termsModalOpen={this.state.terms_modal_open}
                        toggleTermsModal={this.toggleTermsModal}
                    />
                    <div className="container">
                        <div className="row">
                            <div className="form-padding-box padding-box">
                                <div className="form-wrap box-wrap" style={{ borderRadius: 5 }}>
                                    <h1 className="form-title">Create Your Sparq Account</h1>
                                    <div className="form-descp">Please enter your name, email and password.</div>
                                    <form onSubmit={this.handleSubmit} action="home.html" className="reg-form entry-form check-form" method="post">
                                        <MaterialInput
                                            label="Name"
                                            placeholder="Name"
                                            type="text"
                                            name="name"
                                            id="name"
                                            autoComplete={false}
                                            required={true}
                                            title="First name is required."
                                            error={authSignUpError}
                                            value={this.state.name}
                                            onChange={this.handleInputChange}
                                        />
                                        <input value={this.state.referral_code} type="hidden" name="referral_code" onChange={this.handleInputChange} />
                                        <MaterialInput
                                            label="your email address"
                                            placeholder="Email address"
                                            type="email"
                                            name="email"
                                            id="email"
                                            autoComplete={false}
                                            required={true}
                                            title="Enter an email address with a maximum of 64 characters."
                                            onChange={this.handleInputChange}
                                            value={this.state.email}
                                            error={authSignUpError}
                                            pattern="^.{0,64}$"
                                        />
                                        <MaterialInput
                                            label="password"
                                            placeholder="Password"
                                            type="password"
                                            name="password"
                                            autoComplete={false}
                                            required={true}
                                            title="Enter a password between 6 and 12 characters, containing at least one letter and one number."
                                            id="password"
                                            value={this.state.password}
                                            onChange={this.handleInputChange}
                                            error={authSignUpError}
                                            pattern="^((?=.*[0-9])(?=.*[a-zA-Z])).{6,12}$"
                                            onFocus={() => { this.passwordFocus(true) }}
                                            onBlur={() => { this.passwordFocus(false) }}
                                        />

                                        <ul className={(this.state.password.length && this.state.passwordFocused) ? '' : 'hidden'} id="password_strength">
                                            <li id="low-upper-case">1 lowercase &amp; 1 uppercase</li>
                                            <li id="one-number">1 number (0-9)</li>
                                            <li id="one-special-char">1 special character (!@#$%^&*).</li>
                                            <li id="eight-character">At least 6 characters</li>
                                        </ul>

                                        <MaterialInput
                                            label="Confirm password"
                                            placeholder="Confirm password"
                                            type="password"
                                            name="confirm_password"
                                            autoComplete={false}
                                            required={true}
                                            title="Passwords must match."
                                            id="confirm_password"
                                            onChange={this.handleInputChange}
                                            value={this.state.confirm_password}
                                            error={authSignUpError}
                                            pattern="^((?=.*[0-9])(?=.*[a-zA-Z])).{6,12}$"
                                        />
                                        {authSignUpError ? <div style={error_style} className="auth-signup-error">{authSignUpError}</div> : ''}
                                        <div className="confirm-box">
                                            <input required type="checkbox" id="accept_policy" name="accept_policy" />
                                            <label htmlFor="accept_policy">I consent for the SPARQ to store and process the personal information submitted above. I confirm that I had read and accepted the <a target="_blank" className="green-link underline" href="https://www.iubenda.com/privacy-policy/43247323">Privacy Policy</a></label>
                                        </div>
                                        <div className="confirm-box">
                                            <input type="checkbox" id="accept_occasion" name="accept_occasion" onChange={this.handleCheckMarketing} />
                                            <label htmlFor="accept_occasion">I agree to receive occasional information and updates from the SPARQ, LLC.</label>
                                        </div>
                                        <div className="button-wrap">
                                            <button className={"btn-primary btn-reg " + (validForm ? "active" : "inactive")}>SIGN UP USING EMAIL</button>
                                        </div>
                                    </form>
                                    <div style={{ textAlign: 'center', fontSize: '20px', margin: '10px', color: '#000', fontWeight: 'bold' }}>OR</div>
                                    <div className="button-wrap mt-2" >
                                        <Link to="/sign-in" style={{ backgroundColor: '#d22121', color: '#fff' }} className={"btn-primary btn-login "}>LOGIN WITH YOUR SOCIAL ACCOUNTS</Link>
                                    </div>
                                    {/* <div className="form-footer">
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <Link to="/sign-in" className="redirect-link green-link">SIGN IN <i className="green-arr"></i></Link>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        authSignUpError: state.auth.authSignUpError
    }
}

const mapDispatchProps = (dispatch) => {
    return {
        signUp: (user) => dispatch(signUp(user)),
        refreshErrors: () => dispatch(refreshErrors())
    }
}

export default connect(mapStateToProps, mapDispatchProps)(SignUp);
