import { toast } from "react-toastify";

export const shareEmail = data => {
    return () => {
        fetch('https://us-central1-sparq-flux.cloudfunctions.net/user/activity_email',{
                headers: {
                    mode: 'no-cors'
                },
                method: "POST",
                body: JSON.stringify(data)
            }
        )
        .then(res => {
            console.log(res);
            toast("Email successfully sent.", {
                type: "success"
            })

        })
                              
    };
}; 
 