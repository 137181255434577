import {
    SAVE_ACTIVITY_SUCCESS,
    SAVE_ACTIVITY_ERROR,
    SET_ACTIVITY,
    SET_ACTIVITIES,
    GET_GROUP_ACTIVITY_BY_GROUP_ID,
    GET_PERSONAL_IMAGE,
} from '@app/action-types';

const initialState = {
    activity: null,
    activityCreate: false,
    activityError: null,
    activities: [],
    activityByGroupId: [],
    personal_image: [],
};

const activityReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SAVE_ACTIVITY_SUCCESS:
            return { 
                ...state,
                activityCreate: true,
                activityError: null 
            }
        case SAVE_ACTIVITY_ERROR:
            return {
                ...state,
                activityCreate: false, 
                activityError: action.err 
            }
        case SET_ACTIVITY:
            return {
                ...state,
                activity: action.payload
            }
        case SET_ACTIVITIES:
            return {
                ...state,
                activities: action.payload
            }

        case GET_GROUP_ACTIVITY_BY_GROUP_ID:
        return {
            ...state,
            activityByGroupId: action.payload,
        }
        case GET_PERSONAL_IMAGE:
        return {
            ...state,
            personal_image: action.payload,
        }
        default:
            return {
                ...state,
                activityCreate: false, 
            }
    }
};

export default activityReducer;
