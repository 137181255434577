export const UserModel = formData => {
    const { name, email, accept_occasion, referral_code } = formData;

    return {
        name,
        email,
        createdAt: new Date(),
        user_type: 'normal',
        accept_occasion,
        referral_code,
        group_key: null,
    }
}

export const GroupModel = request => {
    const { key, name, question_id, user_type, collection ,userId, disable_upload, allow_public_response, is_multiple_question } = request;

    return {
        userId,
        groupKey: key,
        groupName: name,
        user_type,
        is_multiple_question,
        question_id, //for multiple questions; it can be array
        collection,
        disable_upload,
        allow_public_response,
        createdAt: new Date(),
    }
};

export const ActivityModel = activity => {
    const {
        user_id,
        group_id,
        question,
        response,
        image_url,
        name,
        hideNameFromGroup,
        hideResponseFromGroup,
        multipleQuestionResponse
    } = activity;

    window.localStorage.setItem('activity_type', group_id ? 'group' : 'individual');
    
    

    return {
        user_id,
        activity_type: group_id ? 'group' : 'individual',
        name,
        question,
        group_id,
        response,
        image_url,
        hideNameFromGroup,
        hideResponseFromGroup,
        multipleQuestionResponse,
        createdAt: new Date(),
    }
};
