import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import firebase from 'firebase/app';
import moment from 'moment';
import Swal from "sweetalert"
import ResultsPersonalTableRow from '../ResultsPersonal/ResultsPersonalTableRow';

class Guest extends React.Component {
  constructor() {
    super();
    this.state = {
      openModal: true,
      donotShow: false,
    };

  }

  componentDidMount() {

    const setOpenModal = localStorage.getItem("modalOpen");
    if (setOpenModal === "true") {
      this.setState({
        openModal: false,
      });
    } else {
      this.setState({
        openModal: true,
      });
    }

    var groupKeys = new Array();
    // search for groups created by user for group results
    firebase.firestore().collection("groups")
    .get()
    .then((querySnapshot) => {
        if ( querySnapshot.size > 0 ){
            querySnapshot.forEach((doc) => {
                groupKeys.push(doc.data().groupKey);
            });
            firebase.firestore().collection("activities").where("group_id", "in", groupKeys).orderBy("createdAt","desc").limit(4)
            .get()
            .then((querySnapshot) => {
                if ( querySnapshot.size > 0 ){
                    querySnapshot.forEach((doc) => {


                        // adjust date
                        var response_date = moment(doc.data().createdAt.seconds, "DD.MM.YYYY").toDate();
                        var curdate = new Date(null);
                        curdate.setTime(doc.data().createdAt.seconds*1000);
                        response_date = curdate;
                        response_date = moment(response_date).format('MM/DD/YYYY');

                        // adjust image
                        var response_image = doc.data().image_url;
                        response_image = response_image.replace(/'/g, '%27');

                        // append to table
                        window.$('#group_results').append("<tr> <td>"+doc.data().group_id+"</td> <td>"+doc.data().name+"</td> <td><img src='"+response_image+"' width='100px' /></td>  <td title='"+doc.data().response+"'>"+doc.data().response.substring(0, 60)+"</td> <td>"+response_date+"</td>  </tr>");
                        window.$('#group_results_container').show();


                    });
                }
                
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
        }
        
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });

  }



  toggle = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
    window.$('.modal-content').css('max-width','100% !important');
  };
  handleModal = (e) => {
    console.log(e.target.checked);
    this.setState({
      donotShow: e.target.checked,
    });
    localStorage.setItem("modalOpen", e.target.checked);
  };
  enableModal = () => {
    const setOpenModal = localStorage.getItem("modalOpen");
    if (setOpenModal === "true") {
      let isTrueSet = setOpenModal === "true";
      this.setState({
        donotShow: isTrueSet,
      });
    } else {
      let isFalseSet = setOpenModal === "false";
      this.setState({
        donotShow: isFalseSet,
      });
    }
    this.setState({
      openModal: true,
    });
  };

  openSparqFeatureRequest = () => {
    this.props.history.push('/ideas');
  }
  render() {
    const { resultsPersonal } = this.props;
    const resultPersonalMappedArray = [];

    var total = 0;
    for (var key in resultsPersonal) {
        resultPersonalMappedArray.push(resultsPersonal[key]);
          if (total === 3) { break; }
        total++;
    };

    const template = resultPersonalMappedArray.map((activities, index) => {
        const date = new Date(activities.createdAt.seconds * 1000);
        const dataRow = {
            activities,
            date
        }

        return (
            <ResultsPersonalTableRow data={dataRow} key={index} />
        );
    })
    const { ordered } = this.props;
    const resultDisabled =
      !ordered.activities || (ordered.activities && !ordered.activities.length);

    return (
      <div id="main">
          <div className="row" style={{ maxWidth: "1000px", margin: "auto" }}>
              <div className="col-md-8">
                <div className="container">
                    <div className="row">
                        <div className="buttons-padding-box padding-box">
                            <div
                                className="buttons-wrap box-wrap"
                                style={{ borderRadius: 5 }}
                            >
                                <div className="small-title">HOW ARE YOU USING SPARQ?</div>
                                <Link
                                    to="/guest-use/question"
                                    className="btn-primary green-bg"
                                >
                                    PERSONAL USE
                                </Link>
                                <Link to="/guest-activity" className="btn-primary blue-bg mt-25">
                                    AS PART OF A GROUP
                                </Link>
                                <p className="intro mt-5" onClick={this.enableModal}>
                                Want to see how it works?
                                </p>



                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        
        <Modal isOpen={this.state.openModal} toggle={this.toggle} dialogClassName="modal-50w">
          <ModalHeader toggle={this.toggle} className="small-title text-center">
            How it works
          </ModalHeader>
          <ModalBody className="html-content">
            <ul>
              <li>
                You can choose a question from the default options, or create
                your own topic or question that you would like to reflect on.
              </li>
              <li>
                You will then browse through a series of images saving ones that
                you feel match up with your question or topic
              </li>
              <li>
                You will be asked to select a final image from your saved list
                that best represents your vision of the question or topic.
              </li>
              <li>
                If you are not able to find an image that you feel represents
                your question or topic appropriately, you will be able to upload
                and save your own image.
              </li>
              <li>
                Finally, you will briefly explain why the image chosen best
                represents your vision of your question or topic.
              </li>
              <li>
                Your Sparq account allows you to save all of your results and you
                can print and share your results if you wish!
              </li>
            </ul>
            
            <p style={{ textAlign:"center" }}>
              <span style={{ color:"red", fontSize:"14px", marginTop:"21px", display:"block" }}>NEW</span> You can set regular reminders for reflection from Profile page. 
            </p>
            <div className="confirm-box">
              <input
                type="checkbox"
                id="accept_policy"
                name="accept_policy"
                checked={this.state.donotShow}
                onChange={this.handleModal}
              />
              <label htmlFor="accept_policy">
                Don't show this message again
              </label>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
              Start
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { ordered } = state.firestore;
  const { auth } = state.firebase;
  return {
    ordered,
    auth,
    resultsPersonal: ordered.activities
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "activities",
    },
  ])
)(Guest);
