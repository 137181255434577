import React, { Fragment } from 'react';
import { updateUser } from '@actions/user';
import { connect } from 'react-redux';
import { toast } from "react-toastify";

import './styles.css';

class UpdateUserForm extends React.Component {
    constructor(props) {
        super(props);
        const { user } = props
        this.state = {
            uid: user.id,
            email: user.email,
            name: user.name,
            affiliate_partner_code: user.affiliate_partner_code,
            accept_occasion: !user.accept_occasion ? false : user.accept_occasion,
            user_type: user.user_type
        };
    }

    onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onChange_AffiliateInput = (event) => {
        event.target.value = event.target.value.replace(/[^a-z0-9]/gi, '');
        this.setState({
            [event.target.name]: event.target.value
        });
        window.$("#copy_publicURL").val("https://discoversparq.com/partner/" + event.target.value);

    }

    resetForm = () => {
        this.setState({
            uid: '',
            email: '',
            user_type: '',
            accept_occasion: '',
            affiliate_partner_code: ''
        });
    }

    onSubmit = (event) => {
        event.preventDefault();
        if (this.state.affiliate_partner_code === undefined) {
            this.state.affiliate_partner_code = '';
        }

        const request = {
            uid: this.state.uid,
            email: this.state.email,
            name: this.state.name,
            affiliate_partner_code: this.state.affiliate_partner_code !== null ? this.state.affiliate_partner_code : '',
            accept_occasion: this.state.accept_occasion === 'true' ? true : false,
            user_type: this.state.user_type,
        }
        this.props.updateUser(request);
        this.props.toggle();
        this.resetForm();
        toast("Updated Successfully", { type: "success" });

    }


    render() {

        function handleClick_copyURL(e) {
            e.preventDefault();
            var value = window.$("#copy_publicURL").val();
            var $temp = window.$("<input>");
            window.$("body").append($temp);
            $temp.val(value).select();
            document.execCommand("copy");
            $temp.remove();
            toast("Copied to clipboard", { type: "info" });

        }


        return (
            <Fragment>
                <form onSubmit={this.onSubmit.bind(this)}>
                    <div className="form-group">
                        <label style={{ fontWeight: "bold" }}>Email: </label>
                        <input style={{ marginBottom: "10px" }} defaultValue={this.state.email} name="email" onChange={this.onChange} placeholder="Email" className="form-control" />
                        <label style={{ fontWeight: "bold" }}>Name: </label>
                        <input style={{ marginBottom: "10px" }} defaultValue={this.state.name} name="name" onChange={this.onChange} placeholder="Name" className="form-control" />
                        <label style={{ fontWeight: "bold" }}>Affiliate Partner Code: </label>
                        <input style={{ marginBottom: "10px" }} defaultValue={this.state.affiliate_partner_code} name="affiliate_partner_code" onChange={this.onChange_AffiliateInput} placeholder="Affiliate Partner Code" className="form-control" />
                        {this.state.affiliate_partner_code &&
                            <div className="form-check p-0 mt-1 mb-3 show_publicURL" style={{ display: "block" }}>
                                <label htmlFor="groupId" style={{ margin: "0px" }}>Use the button beside the URL to copy the address and share with others. </label>
                                <div className="row">
                                    <div className="col-10 pr-0">
                                        <input
                                            type="text"
                                            id="copy_publicURL"
                                            name="groupId"
                                            className="form-control"
                                            defaultValue={"https://discoversparq.com/partner/" + this.state.affiliate_partner_code}
                                            style={{ marginTop: "10px" }}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-2 pl-1">
                                        <a onClick={handleClick_copyURL} style={{ marginTop: "10px", backgroundColor: "#3d3d40", border: "none", color: "#fff", height: "37px", lineHeight: "29px", width: "100%" }} className="btn btn-info btn-sm">Copy</a>
                                    </div>
                                </div>
                            </div>
                        }
                        <label style={{ fontWeight: "bold" }}>Marketing: </label>
                        <select style={{ marginBottom: "10px" }} defaultValue={this.state.accept_occasion} className="form-control" name="accept_occasion" onChange={this.onChange}>
                            <option value="true">YES</option>
                            <option value="false">NO</option>
                        </select>
                        <label style={{ fontWeight: "bold" }}>User Type: </label>
                        <select style={{ marginBottom: "10px" }} defaultValue={this.state.user_type} className="form-control" name="user_type" onChange={this.onChange}>
                            <option value="premium"> Premium</option>
                            <option value="normal">Normal</option>
                            {/* <option value="admin">Admin</option> */}
                        </select>
                        <button className="btn btn-success" style={{ backgroundColor: "#2a3381", border: "none" }}>Update</button>
                    </div>
                </form>
            </Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    updateUser: question => dispatch(updateUser(question)),
})

export default connect(null, mapDispatchToProps)(UpdateUserForm);
