import React, { Fragment } from 'react';

const ImageCheckbox = ({ type = 'checkbox', name, checked = false, onChange }) => {
    return (
        <Fragment>
           <label className={checked ? 'active' : ''} data-id="2">
                <input
                    onChange={onChange}
                    type={type}
                    name={name}
                    checked={checked}
                />
             </label>
         </Fragment>
    )
}

export default ImageCheckbox;