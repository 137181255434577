import React from "react";
import { connect } from "react-redux";
import { uploadCollection } from "@actions/images";
import "./styles.css";
import { toast } from "react-toastify";


class UploadCollection extends React.Component {
    constructor(props) {
        const { uid } = props;
        super(props);
        this.state = {
            uId: uid,
            images: [],
            collectionName: ''

        };

    }

    resetForm() {
        this.setState({
            images: [],
        });
    }

    onSubmit = (event) => {
        event.preventDefault();
        console.log("Name", this.state.collectionName)

        if (this.state.collectionName === "" || this.state.collectionName === "New_Default_Spark_Set" || this.state.collectionName.toUpperCase() === "DEFAULT" ) {
            alert('You cannot set this collection name');
        }else{
            console.log(this.state.images.length);
            if ( this.state.images.length > 45 ){
                alert('You cannot upload more than 45 images in a collection.');
            }else{
                this.props.uploadCollection(this.state.images, this.state.collectionName);
                toast("Upload started, please be patient!", {type: "info"});
                this.resetForm();
                this.props.toggle();
            }
           
        }

    };

    onChange = (event) => {
        this.setState({ images: event.target.files });
    };

    onChangeCollection = (event) => {
        this.setState({
            collectionName: event.target.value
        })
    }

    render() {
        return (
            <div className="uploader-component">
                <form onSubmit={this.onSubmit}>
                    <input
                        style={{ float: "left", width: "15px" }}
                        className="form-control mb-2"
                        type="checkbox"
                        required />
                    <label style={{ float: "left", padding: "8px" }}>I agree that I have permission to use the images I am uploading to Sparq.</label>
                    <br></br>
                    <input 
                        defaultValue={this.state.collectionName}
                        className="form-control mb-2"
                        id="collection_name"
                        onChange={this.onChangeCollection}
                        type="text"
                        required
                        placeholder="Enter collection name" />
                    <br></br>
                    <input 
                        style={{ height: "auto" }}
                        className="form-control"
                        onChange={this.onChange}
                        type="file"
                        accept="image/*"
                        required
                        multiple
                    />
                    <p>You can select multiple images ( upto a total of 45 images )</p>
                    <br />
                    <button
                        className="btn btn-info"
                        style={{ backgroundColor: "#2a3382", border: "none" }}
                    >
                        Upload
          </button>
                </form>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    uploadCollection: (images, name) => dispatch(uploadCollection(images, name)),
});

export default connect(null, mapDispatchToProps)(UploadCollection);
