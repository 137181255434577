import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getGroupForRandomUser } from "@actions/group";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import HowItWorks from "../../main/HowItWorks";
import MaterialInput from "../../main/MaterialInput";

class RandomUserJoinGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupKeyId: "",
      showAlert: false,
      showHowItWorks: false,
      groupItem: null,
      openModal: false,
    };
  }

  componentWillMount() {
    window.localStorage.setItem("activity_type", "group");

    if (localStorage.getItem("multipleQuestionResponse") !== null) {
      localStorage.removeItem("multipleQuestionResponse");
    }
    if (localStorage.getItem("question_count") !== null) {
      localStorage.removeItem("question_count");
    }
    if (localStorage.getItem("question_total") !== null) {
      localStorage.removeItem("question_total");
    }
    if (localStorage.getItem("selectedQuestion") !== null) {
      localStorage.removeItem("selectedQuestion");
    }
    if (localStorage.getItem("guest_name") !== null) {
      localStorage.removeItem("guest_name");
    }

  }

  componentWillReceiveProps = (newProps) => {
    if (newProps) {
      this.setState({
        showAlert: newProps.error !== null ? true : false,
        showHowItWorks:
          newProps.group[0] && newProps.error == null ? true : false,
      });
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.getGroupForRandomUser(this.state.groupKeyId);
  };

  toggle = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
    window.$('.modal-content').css('max-width', '100% !important');
  };

  render() {
    const error_style = {
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "1.71",
      letterSpacing: "normal",
      color: "#ef2727",
      position: "relative",
      bottom: "14px",
    };

    const howItWorks = (
      <div className="buttons-padding-box padding-box">
        <div className="buttons-wrap box-wrap">
          <HowItWorks
            startLink="/guest-activity/question"
            content={`
                        <p>
                            You will be presented with a question that we will ask you to reflect on. 
                            You will then look through a series of photos selecting the ones that match up with your reflection question.
                        </p>
                        <p>
                            Once you have viewed all the images, you will be asked to select a final image from your saved list that best represents your vision of the question.
                            <br/><br/>
                            We will then ask you to briefly explain why the image chosen best represents your vision of the selected question.
                        </p>
                        <p>Once completed, you will be able to share your result, and view the results of your entire team.</p> 
                    `}
          />
        </div>
      </div>
    );

    const setGroupId = (

      <div className="form-padding-box padding-box">
        <div className="col-md-12 back-nav" style={{ margin: '10px 0px 30px 0px' }}>
          <Link
            to={`/demo-use`}
            className="goto_back"
          >
            <i></i>Back
          </Link>
        </div>
        <div className="form-wrap box-wrap" style={{ borderRadius: 5 }}>
          <h1 className="form-title">Group Activity!</h1>
          <div class="form-descp">
            You can complete the activity as a guest, but if you would like to be able to save and share your results, as well as see results of others in the group, you can create a free account and then complete the activity as a registered user. If you are already a registered Sparq user, <a href="/sign-in"> sign in</a>
            <br /><br />
            Enter the group ID you received in the field below.
          </div>

          <form
            onSubmit={this.handleSubmit}
            className="team-form entry-form check-form small-form mt-3"
          >
            <MaterialInput
              type="text"
              name="groupKeyId"
              value={this.state.groupKeyId}
              id="groupKeyId"
              onChange={this.handleChange}
              required={true}
              label="Group ID"
              error={this.state.showAlert}
              placeholder="Group ID"
            />
            {this.state.showAlert && (
              <div style={error_style}>{this.props.error}</div>
            )}
            <div className="button-wrap">
              <button
                className={
                  "btn-primary btn-team " +
                  (this.state.groupKeyId ? "active" : "inactive")
                }
              >
                Complete As Guest
              </button>
            </div>
          </form>
          <Link
            to="/sign-up"
            id="new_ques"
            className="btn-primary-bdr mt-20"
          >
            Sign Up
          </Link>
          {/* <p className="intro mt-5 mb-2" onClick={this.toggle}>
            Want to see how it works?
            </p> */}
        </div>
      </div>
    );

    return (
      <div id="main">
        <div className="container">
          <div className="row">
            {this.state.showHowItWorks ? howItWorks : setGroupId}
          </div>
        </div>
        <Modal isOpen={this.state.openModal} toggle={this.toggle} dialogClassName="modal-50w">
          <ModalHeader toggle={this.toggle} className="small-title text-center">
            How it works
          </ModalHeader>
          <ModalBody className="html-content">
            <ul>
              <li>
                You will be presented with a question to reflect on.
              </li>
              <li>
                You will then browse through a series of images saving ones that
                you feel match up with your question or topic
              </li>
              <li>
                You will be asked to select a final image from your saved list
                that best represents your vision of the question or topic.
              </li>
              <li>
                Finally, you will briefly explain why the image chosen best
                represents your vision of the question or topic.
              </li>
            </ul>

          </ModalBody>

        </Modal>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  const { group, error } = state.group;
  return {
    group,
    error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getGroupForRandomUser: (groupKeyId) => dispatch(getGroupForRandomUser(groupKeyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RandomUserJoinGroup);
