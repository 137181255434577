import {  ON_VOTING, 
          GET_IDEAS_LIST ,
          ON_CHANGE_FILTET, 
          FILTER_BY_CATG ,
          FILTER_BY_MY,
          ON_CHANGE_EDIT_IDEA ,
          ON_SUBSCRIBING,
          MY_COUNT, 
          SET_COMMENT ,
          SET_TAB
        } from '@app/action-types.js';

import moment from 'moment';

export const getIdeas = (id=null,trendeing=true) => {
  return async (dispatch, getState, { getFirestore }) => {
    const { auth } = getState().firebase
    const {ideas } = getState()

    const voter_id = auth.uid
    let ideasList = []
    const firestore = getFirestore()
    let ideasDoc ;

    
    if(ideas.trending){
      ideasDoc = await firestore
                       .collection("ideas")
                       .orderBy("vote_count", "desc")
                       .get();
    }else{
      ideasDoc = await firestore.collection("ideas").get();
    }
    const ideasIdList = [];

    const voteDoc = await firestore.collection("idea_vote")
                                   .where("voter_id", "in", [voter_id])
                                   .get();
    const subsDoc = await firestore.collection("idea_subscribe")
                                   .where("subscriber_id", "in", [voter_id])
                                   .get()

    let voterList=voteDoc.docs.map((voter) => {
       return {...voter.data(),voter_doc_id:voter.id}
    })

     const subscriberList=subsDoc.docs.map((subscriber) => {
       return {...subscriber.data(),subscriber_doc_id:subscriber.id}
    })
    
    let myIdeasTotalCount=0;
    let myVoteTotalCount=0;
    let adminIdeasCount=0;
    let generalIdeasCount=0;

    if(voterList.length==0){
      ideasDoc.docs.map(  (idea) => {
        if( idea.data().creator_id  ==  voter_id ){
             myIdeasTotalCount++
        }
        if( idea.data().category_id == 1 ){
          generalIdeasCount++
        }
        if( idea.data().category_id == 2 ){
          adminIdeasCount++
        }
          ideasList.push( { 
              ...idea.data(), 
              idea_id: idea.id, 
              voted:false,
              subscribed:false,
            })
      })
    }
    else {
      ideasDoc.docs.map((idea) => {
        let isVoted=false
        if( idea.data().creator_id == voter_id ){
          myIdeasTotalCount++
        }
        if(idea.data().category_id==1){
          generalIdeasCount++
        }if(idea.data().category_id==2){
          adminIdeasCount++
        }
        let v_list=voterList;
         v_list.forEach(( voter , index) => {
          if(idea.id == voter.idea_id && voter_id==voter.voter_id){
            
            isVoted = true
            myVoteTotalCount++

            ideasList.push({
              ...idea.data(), 
              idea_id: idea.id, 
              voted:voter.voter_doc_id,
              subscribed:false,
             })
             voterList=voterList.filter((voter ,v_index)=> index!=v_index)
          } 
        });
        if(!isVoted){
            ideasList.push({ 
              ...idea.data(), 
              idea_id: idea.id, 
              voted:false ,
              subscribed:false,
            })
        }
      })
    }
    //Filter for Subscription and my subs count
    let myTotalsubscriptionCount=0
    if(subscriberList.length !=0){
      ideasList.map((ideas ,index)=>{
        subscriberList.forEach(subscriberDoc => {
          if(subscriberDoc.idea_id == ideas.idea_id && subscriberDoc.subscriber_id == voter_id){
            myTotalsubscriptionCount++
            ideasList[index].subscribed=subscriberDoc.subscriber_doc_id
          }
        });
      })
    }
    dispatch({
      type:GET_IDEAS_LIST,
      payload:ideasList
    })
    dispatch({
      type:MY_COUNT,
      payload:{
        myIdea:myIdeasTotalCount,
        myVote:myVoteTotalCount,
        mySubs:myTotalsubscriptionCount,
        adminIdeasCount:adminIdeasCount,
        generalIdeasCount:generalIdeasCount
      }
    })
    setTimeout(()=>{
      dispatch({
        type:ON_SUBSCRIBING,
        payload:false
      })
      dispatch({
        type:ON_VOTING ,
        payload:false
      },2000)
    })
    if(id != null){
      dispatch(onEditIdea(id))
    }
  }
};

export const addIdea = (data) => {
  return async (dispatch, getState, { getFirestore }) => {
    const { auth } = getState().firebase
    const creator_id = auth.uid
    const firestore = getFirestore()
    let fileUrl;
     if(data.file !=null){
      fileUrl= await dispatch( await uploadFile(data.file))
     }
    firestore
    .collection("ideas")
    .add({
      ...data,
      file:fileUrl,
      vote_count:0,
      comment_count:0,
      creator_id:creator_id,
      created: moment().format('MMMM Do YYYY, h:mm:ss a')
    })
    .then((res) => {
      dispatch(getIdeas())
    })

  }
};

export const onVoting = (isVoted,ideaId,count) => {
  return async (dispatch, getState, { getFirestore }) => {
    const { auth } = getState().firebase
    const voter_id = auth.uid
    const firestore = getFirestore()
    
    dispatch({
      type:ON_VOTING ,
      payload:true
    })

    if(!isVoted){
      firestore.collection("idea_vote")
      .add({voter_id:voter_id,idea_id:ideaId})
      .then( async(res) => {
         firestore
         .collection('ideas')
         .doc(ideaId)
         .update({vote_count:count+1})
         .then(()=>{
          dispatch(getIdeas(ideaId))
         })
        })
    }
    else{

      firestore
      .collection('idea_vote')
      .doc(isVoted)
      .delete()
      .then(() => {
            firestore
            .collection('ideas')
            .doc(ideaId)
            .update({vote_count:count-1})
            .then((res) => {
              dispatch(getIdeas(ideaId))
            })
      })
    }
  }
}
export const onSubscribing = (isSusbcribed,ideaId) => {
  return async (dispatch, getState, { getFirestore }) => {
    const { auth } = getState().firebase
    const subscriber = auth.uid
    const firestore = getFirestore()
    
    dispatch({
      type:ON_SUBSCRIBING,
      payload:true
    })
    if(!isSusbcribed){
      firestore.collection("idea_subscribe")
      .add({subscriber_id:subscriber,idea_id:ideaId})
      .then( (res) => {
        dispatch(getIdeas(ideaId))

        })
      .catch( (err) => {  })
    }
    else{
      firestore
      .collection('idea_subscribe')
      .doc(isSusbcribed)
      .delete()
      .then((res) => {
        dispatch(getIdeas(ideaId))
      })
    }
  }
}
export const onFilterByCategory = (filtersStatusObject,category) => {
  return  (dispatch, getState, { getFirestore }) => {
    const { auth } = getState().firebase
    const {
      filtered,
      category_filter,
      status_filter,
      my_filter }= filtersStatusObject
      if(!filtered){

        dispatch({
          type:ON_CHANGE_FILTET,
          payload:true
        })
        dispatch({
          type:FILTER_BY_CATG,
          payload:category
        })
      }
     else if( category_filter == category ){

        dispatch({
          type:FILTER_BY_CATG,
          payload:0
        })

        if( status_filter == null && my_filter == null) {
           dispatch({
          type:ON_CHANGE_FILTET,
          payload:false
        })
        }
     }
     else if( category_filter !== category ){

      dispatch({
        type:FILTER_BY_CATG,
        payload:category
      })
      
   }
  }
}
export const onFilterByMy = (category) => {
  return  (dispatch, getState, { getFirestore }) => {
    const { ideas } = getState()
    const {
      filtered,
      category_filter,
      status_filter,
      my_filter }= ideas
         if(!filtered){
        dispatch({
          type:ON_CHANGE_FILTET,
          payload:true
        })
        dispatch({
          type:FILTER_BY_MY,
          payload:category
        })
      }
      else if(  my_filter == category){
        dispatch({
          type:FILTER_BY_MY,
          payload:null
        })
        if( status_filter == null && category_filter == 0) {
          dispatch({
         type:ON_CHANGE_FILTET,
         payload:false
       })
       }

      }else{
        dispatch({
          type:FILTER_BY_MY,
          payload:category
        })
      }
    
  }
}
export const onEditIdea =  (id )=>{
  return  (dispatch, getState, { getFirestore }) => {
    const {ideas ,ideaEdit}=getState().ideas
    const filterdIdea=  ideas.filter((idea)=>idea.idea_id==id)
    dispatch({
      type:'EDIT_IDEA',
      payload:filterdIdea
    })
    let c_id
    if(ideaEdit[0].description==''){
      c_id=filterdIdea[0].creator_id
    }else{
      c_id=ideaEdit[0].creator_id
    }
    const relatedItems=ideas.filter((idea)=> idea.idea_id!=id && idea.creator_id == c_id)
    dispatch({
      type:'RELATED_IDEAS',
      payload:relatedItems
    })
  }
}
export const onChangeEditIdea = (data)=>{
  return  (dispatch, getState, { getFirestore }) => {
    dispatch({
      type:ON_CHANGE_EDIT_IDEA,
      payload:data
    })
  }
}
export const onUpdateEditIdea = ( data ) => {
  return  (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore()
    const{
          category_id,
          creator_id,
          description,
          file,
          idea,
          vote_count,
          idea_id}=data

    firestore
            .collection('ideas')
            .doc(idea_id)
            .update({description:description,idea:idea,file:file})
            .then((res) => {
              dispatch(getIdeas(idea_id))
            })
  }
} 
export const onComment = ( data ) => {

  return async (dispatch, getState, { getFirestore }) => {
    const date=  moment().format('MMMM Do YYYY, h:mm:ss a')
    const firestore = getFirestore()
    const { auth,profile } = getState().firebase
    const {ideas } = getState()
    const {photoURL , uid ,displayName} =auth
    const {commentList }=ideas
    let fileUrl=null;
     if(data.file!=null){
      fileUrl= await dispatch( await uploadFile(data.file))
     }
    const commentData={
      ...data,
      file:fileUrl,
      created:date,
        commenter:{
            commenter_id:uid,
            name:profile.name,
            profile_image:photoURL
        }
      }
      firestore.collection("idea_comment")
      .add(commentData)
      .then( (res) => {
        //update 
        firestore
         .collection('ideas')
         .doc(data.idea_id)
         .update({comment_count:commentList.length+1})
         .then(()=>{
          dispatch(getIdeas(data.idea_id))
          dispatch(getComment(data.idea_id))
         })
        })
      .catch( (err) => {
      })
  }
} 
export const getComment = ( ideaId ) => {
  return  async (dispatch, getState, { getFirestore }) => {
    const date=moment().format('MMMM Do YYYY, h:mm:ss a')
    const firestore = getFirestore()
    const { auth } = getState().firebase

   const commentDoc= await firestore.collection("idea_comment")
                    .where("idea_id", "==", ideaId)
                    .get();
    const commentList=commentDoc.docs.map((comment)=>{
      return {...comment.data(),comment_id:comment.id}
    })
    dispatch({
      type:SET_COMMENT,
      payload:commentList
    })
  }
} 
export const onChangeTab = (tab) =>{
  return (dispatch,getState)=>{
    const {ideas} =getState()
    let key=tab=='recent'?false:true
    dispatch({
      type:SET_TAB,
      payload:key
    })
    if(ideas.trending !== key){
      dispatch(getIdeas())
    }
  }
}
export const onSearchedData = (searched) =>{
  return (dispatch,getState)=>{
    if(!searched){
      return dispatch(getIdeas())
    }
    const {ideas} =getState()
    const filterideasList=ideas.ideas.filter(  (idea) => {
      let str = idea.idea;
      if(str.match(new RegExp(searched,'gi'))!= null){
        return true
      }else {
        return false
      }
    })
    dispatch({
      type:GET_IDEAS_LIST,
      payload:filterideasList
    })
  }
}

export const uploadFile =  (file) =>{
  return async(dispatch,getState,{ getFirebase, getFirestore })=>{
    const firestore = getFirestore();
    const firebase = getFirebase();
    // Points to the root reference
    const storageRef = firebase.storage().ref(); 
    // Points to 'images'
    const imagesRef = storageRef.child(`ideas/${file.name}`);
    await imagesRef.put(file)
    return await imagesRef.getDownloadURL()
  }}