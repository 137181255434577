import React from 'react';

import './styles.css';

class MaterialInput extends React.Component {
    state = {
        inputClasses: '',
        labelClasses: '',
        error: ''
    }

    componentWillReceiveProps = (nextProps) => {
        const { value, error, id } = nextProps

        const material_input = document.getElementById(id);

        if (!value) {
            this.setState({
                labelClasses: '',
                inputClasses: '',
            })
        }

        if (value && !error) {
            this.setState({
                labelClasses: 'active',
                inputClasses: 'success',
                error: ''
            })
        }

        if (value && error) {
            this.setState({
                labelClasses: 'active',
                inputClasses: 'error',
                error
            })
        }

        if (value && !material_input.checkValidity()) {
            this.setState({
                labelClasses: 'active',
                inputClasses: 'error',
                error: 'there is an error'
            })
        }
    }

    onChange(event) {
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    onInput(event) {
        if (this.props.onInput) {
            this.props.onInput(event);
        }
    }

    onFocus() {
        this.setState({
            labelClasses: 'active'
        })
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    }

    _onClick() {
        this.setState({
            labelClasses: 'active'
        })
    }

    onBlur() {
        if (!this.props.value) {
            this.setState({
                labelClasses: ''
            })
        }
        if (this.props.onBlur && !this.state.error) {
            this.props.onBlur();
        }
    }

    render() {
        return (
            <div className="input-wrap">
                <label className={"input-label " + this.state.labelClasses}>{this.props.label}</label>
                <div className={"field-wrap placeholder " + this.state.inputClasses}>
                    <input
                        className="input-field"
                        placeholder={this.props.placeholder}
                        type={this.props.type}
                        name={this.props.name}
                        autoComplete={this.props.autoComplete || "off"}
                        required={this.props.required || false}
                        pattern={this.props.pattern || null}
                        title={this.props.title || null}
                        onChange={this.onChange.bind(this)}
                        onFocus={this.onFocus.bind(this)}
                        onBlur={this.onBlur.bind(this)}
                        onClick={this._onClick.bind(this)}
                        onInput={this.onInput.bind(this)}
                        value={this.props.value}
                        id={this.props.id || null}
                    />
                    {/* { <i className="user-icon"></i> } */}
                    {this.state.error ? <i className="invalid-icon"></i> : <i className="valid-icon"></i>}
                </div>
            </div>
        )
    }
};

export default MaterialInput;
