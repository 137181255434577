import {
    SAVE_ACTIVITY_SUCCESS,
    SAVE_ACTIVITY_ERROR,
    SET_ACTIVITY,
    SET_ACTIVITIES,
    GET_GROUP_ACTIVITY_BY_GROUP_ID,
    GET_PERSONAL_IMAGE,
} from '@app/action-types';


import { ActivityModel } from '@app/models';

export const saveActivity = (activity) => {
    return (dispatch, getState, { getFirestore, getFirebase }) => {
        const firestore = getFirestore();
        const activity_model = ActivityModel(activity);
        // console.log(activity_model);
        firestore.add({ collection: 'activities' }, activity_model
        ).then((res) => {

            if (localStorage.getItem("multipleQuestionResponse") !== null) {
                localStorage.removeItem("multipleQuestionResponse");
            }
            if (localStorage.getItem("question_count") !== null) {
                localStorage.removeItem("question_count");
            }
            if (localStorage.getItem("question_total") !== null) {
                localStorage.removeItem("question_total");
            }
            if (localStorage.getItem("selectedQuestion") !== null) {
                localStorage.removeItem("selectedQuestion");
            }


            dispatch({ type: SAVE_ACTIVITY_SUCCESS, activity_model });
        }).catch((err) => {
            dispatch({ type: SAVE_ACTIVITY_ERROR, err });
        });
    };
};

export const getUserActivity = (groupId) => {
    return (dispatch, getState, { getFirestore, getFirebase }) => {
        const uid = getFirebase().auth().currentUser.uid;
        return getFirestore().collection('activities').where('group_id', '==', groupId).where('user_id', '==', uid).limit(1).get().then(res => {
            dispatch({
                type: SET_ACTIVITY,
                payload: res.docs[0] ? res.docs[0].data() : null
            });
        });
    };
};

export const addGroupKeyToUser = (groupKey, getFirestore, getFirebase) => {
    const uid = getFirebase().auth().currentUser.uid;
    getFirestore().collection('users').doc(uid).update({ group_key: groupKey }).catch(err => { console.log(err); })
}

export const getGroupActivities = (groupKey, callBack) => {
    return (dispatch, getState, { getFirestore }) => {
        return getFirestore().collection('activities').where('group_id', '==', groupKey).get().then(documents => {
            dispatch({
                type: SET_ACTIVITIES,
                payload: documents.docs ? documents.docs.map(doc => doc.data()) : []
            });
            callBack();
        });
    };
}

export const getMultipleGroupActivities = groupKeys => {
    return (dispatch, getState, { getFirestore }) => {
        const promises = groupKeys.map(key => {
            return getFirestore().collection('activities').where('group_id', '==', key).get();
        });
        return Promise.all(promises);
    };
}

export const getGroupActivtyByGroupId = (groupId, callBack) => {
    return (dispatch, getState, { getFirestore }) => {
        return getFirestore().collection("activities")
            .where("group_id", "==", groupId)
            .where("activity_type", "==", "group").get()
            .then(querySnapshot => {
                dispatch({
                    type: GET_GROUP_ACTIVITY_BY_GROUP_ID,
                    payload: querySnapshot.docs.map(doc => doc.data())
                })
                callBack();
            })
    }
}

export const getPersonalImage = () => {
    return (dispatch, getState, { getFirestore }) => {
        return getFirestore().collection("activities")
            .where("activity_type", "==", "individual")
            .where("user_id", "==", getState().firebase.auth.uid)
            .orderBy("createdAt", "desc")
            .limit(1)
            .get()
            .then(querySnapshot => {
                dispatch({
                    type: GET_PERSONAL_IMAGE,
                    payload: querySnapshot.docs.map(doc => doc.data()),
                })
            })
    }
}
