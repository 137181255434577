import React from 'react';
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import firebase from 'firebase/app';
import { connect } from 'react-redux';
import Swal from "sweetalert"
import { updateSubscription } from "@actions/user";

const formDataToJson = (formData) => {
    const obj = {};
    formData.forEach((value, key) => { 
        obj[key] = value
    });
    return JSON.stringify(obj);
}

const CheckoutForm = () => {

    console.log(  );

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    window.$('button[type="submit"]').prop('disabled',true);
    window.$('button[type="submit"]').html('Processing');

    
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details:{
          email: firebase.auth().currentUser.email
      }
    });

    if (error) {
        console.log('[error]', error);
        Swal("",error.message,"warning")
        window.$('button[type="submit"]').prop('disabled',false);
        window.$('button[type="submit"]').html('Pay');
    } else {

        console.log('[PaymentMethod]', paymentMethod);

        var payment_method_id = paymentMethod.id;

        var data = new FormData();
        data.append( "email", firebase.auth().currentUser.email );
        data.append( "payment_method", payment_method_id );
        data.append( "sub_type", window.$("[name='sub_type']").val() );

        try{

            // send payment method to server and process there 
            fetch('https://us-central1-sparq-flux.cloudfunctions.net/user/pay',{
                method: "POST",
                body: formDataToJson(data),
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(response => response.json())
            .then(data => {
                
                console.log(data);

                if( data.status == 'requires_action' ){

                    stripe
                    .confirmCardPayment(data.client_secret)
                    .then(function(result) {
                        if (result.error) {

                            // Show error to your customer
                            //showError(result.error.message);
                            Swal("", result.error.message, "warning");

                            window.$('button[type="submit"]').prop('disabled',false);
                            window.$('button[type="submit"]').html('Pay');

                            console.log(result.error.message)

                        } else {

                            // The payment succeeded!
                            //orderComplete(result.paymentIntent.id);
                            console.log(data.subscription.id);
                            console.log(result);
                            window.$('button[type="submit"]').html('Paid Successfully');

                            var today = new Date();
                            var dd = String(today.getDate()).padStart(2, '0');
                            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                            firebase.firestore().collection("/users").doc(firebase.auth().currentUser.uid).update(
                                {
                                    sub_id: data.subscription.id,
                                    sub_type: data.sub_type,
                                    sub_day: dd,
                                    sub_month: mm,
                                    user_type: 'premium'
                                }
                            )

                            var yyyy = today.getFullYear();
                            var renewal_date = dd + '/' + mm + '/' + yyyy;
                            let para = 'email='+firebase.auth().currentUser.email+'&sub_type='+data.sub_type+'&renewal_date='+renewal_date+'&rand='+Math.random();

                            fetch('https://www.skilldivvy.com/dev/crons/firestore/subscriptions/get.php?'+para)
                            .then(response => response.json())
                            .then(
                                    
                            );
                                
                            Swal("Congratulations","You have successfully subscribed. You can check your subscription status from Profile page.","success")

                        }
                    });


                }else{

                    // all done 
                    console.log(data.subscription.id);
                    window.$('button[type="submit"]').html('Paid Successfully');
                    var today = new Date();
                    var dd = String(today.getDate()).padStart(2, '0');
                    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                    firebase.firestore().collection("/users").doc(firebase.auth().currentUser.uid).update(
                        {
                            sub_id: data.subscription.id,
                            sub_type: data.sub_type,
                            sub_day: dd,
                            sub_month: mm,
                            user_type: 'premium',
                            sub_valid_till: ''

                        }
                    )


                    var yyyy = today.getFullYear();
                    var renewal_date = dd + '/' + mm + '/' + yyyy;
                    let para = 'email='+firebase.auth().currentUser.email+'&sub_type='+data.sub_type+'&renewal_date='+renewal_date+'&rand='+Math.random();

                    fetch('https://www.skilldivvy.com/dev/crons/firestore/subscriptions/get.php?'+para)
                    .then(response => response.json())
                    .then(
                            
                    );
                    
                    Swal("Congratulations","You have successfully subscribed. You can check your subscription status from Profile page.","success")


                }



                /*

                stripe
                .confirmCardPayment(data.client_secret, {
                payment_method: {
                    card: elements.getElement(CardElement)
                }
                })
                .then(function(result) {
                if (result.error) {
                    // Show error to you customer
                    //showError(result.error.message);

                    console.log(result.error.message)

                } else {
                    // The payment succeeded!
                    //orderComplete(result.paymentIntent.id);

                    console.log(result);
                }
                });

                */

            });


        }catch(e){

            console.log(e);
            window.$('button[type="submit"]').prop('disabled',false);
            window.$('button[type="submit"]').html('Pay');
            Swal("",e.message,"warning")


        
        }


    }

    /*

    fetch('https://us-central1-sparq-flux.cloudfunctions.net/user/pay?email='+firebase.auth().currentUser.email)
    .then(response => response.json())
    .then(data => {
        
        console.log(data);

        stripe
        .confirmCardPayment(data.client_secret, {
        payment_method: {
            card: elements.getElement(CardElement)
        }
        })
        .then(function(result) {
        if (result.error) {
            // Show error to your customer
            //showError(result.error.message);

            console.log(result.error.message)

        } else {
            // The payment succeeded!
            //orderComplete(result.paymentIntent.id);

            console.log(result);
        }
        });

    });

    */

  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe}>
        Pay
      </button>
    </form>
  );
};

const stripePromise = loadStripe('pk_live_51HDaUnF3jjQ311LtbeHk185LVidN5N7dQ8DyfdZNSuwv45iCcC7Pt7ZBPUP8ex1TWt7rdHLL50cFhsYtsX3RzeoT00Es5gMUTg');

class Stripe extends React.Component {

    constructor(props) {
        super(props);
        // alert(this.props.profile.sub_type);
        if ( this.props.profile.sub_id && this.props.profile.sub_id !== '' ) {
            window.$("body").html('');
            window.location.replace('user/profile');
        }

    }

    
    render() {


        return(
            <div>
                <div id="stripe_payment">
                    <div className="row mb-4">
                        <div className="col-md-6">
                            <img src="./img/sub_month.png" />
                        </div>
                        <div className="col-md-6">
                            <img src="./img/sub_year.png" />
                        </div>
                    </div>
                    Choose subscription:
                    <select className="form-control" name="sub_type" style={{ marginBottom: "15px" }}>
                        <option value="m">Monthly ( $10 per month )</option>
                        <option value="y">Annually ( $99 per year )</option>
                    </select>
                    Enter billing details:
                    <Elements stripe={stripePromise}>
                        <CheckoutForm />
                    </Elements>
                </div>
            </div>
        );

    }

}


const mapStateToProps = state => ({
    activity: state.activity,
    groups: state.group.groups,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    
});


const mapDispatchToProps = dispatch => ({
    updateSubscription:(uid) => dispatch (updateSubscription(uid))
});
// export default Stripe;
export default connect(mapStateToProps,mapDispatchToProps)(Stripe);
