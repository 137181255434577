import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loading from '../../resource/loading.gif';


const UserPrivateRoute = ({ component: Component, auth, profile, ...rest }) => {
    let route;

    if (profile.isLoaded) {
        route = <Route
            {...rest}
            render={props => auth.uid && (profile.user_type === 'normal' || profile.user_type === "premium")
                ? (<Component {...props} />)
                : (<Redirect to="/" />)}
        />
    } else {
        route = (
            <React.Fragment>
                <div className="container">
                    <div className="col-md-12">
                        <div style={{ textAlign: "center", paddingTop: "200px" }}>
                            <img src={loading} alt="loading..." />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (route);
};

UserPrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const { auth, profile } = state.firebase;

    return {
        auth,
        profile,
    }
}

export default connect(mapStateToProps)(UserPrivateRoute);
