import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { authenticateUser, refreshErrors } from '@actions/auth';
import { connect } from 'react-redux';
import MaterialInput from '@components/main/MaterialInput';
import firebase from 'firebase/app';


class SignIn extends Component {

    constructor(props) {
        super(props);


        if (typeof this.props.match.params.jwt !== 'undefined' && this.props.match.params.jwt !== '') {

            firebase.auth().signInWithCustomToken(this.props.match.params.jwt)
                .then((userCredential) => {
                    // Signed in
                    var user = userCredential.user;
                    console.log(userCredential);
                })
                .catch((error) => {
                    console.log(error.message);
                });

        }

    }

    state = {
        name: '',
        email: '',
        password: '',
        confirm_password: '',
        authSignInError: '',
        authEmailForgotPasswordSuccess: ''
    }

    componentWillReceiveProps(nextProps) {
        const { authSignInError, authEmailForgotPasswordSuccess } = nextProps

        this.setState({
            authSignInError,
            authEmailForgotPasswordSuccess
        })
    }

    componentWillUnmount() {
        this.props.refreshErrors()
    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.authenticateUser(this.state);
    }

    validateEmail(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {

        const error_style = {
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "normal",
            fontStyle: "normal",
            fontStretch: "normal",
            lineHeight: "1.71",
            letterSpacing: "normal",
            color: "#ef2727",
            position: "relative",
            bottom: "14px",
        }
        const success_style = {
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "normal",
            fontStyle: "normal",
            fontStretch: "normal",
            lineHeight: "1.71",
            letterSpacing: "normal",
            color: "#0092ac",
            position: "relative",
            bottom: "14px",
        }
        const { authSignInError, authEmailForgotPasswordSuccess } = this.state
        return (
            <React.Fragment>
                <div id="main">
                    <div className="container">
                        <div className="row">
                            <div className="form-padding-box padding-box">
                                <div className="form-wrap box-wrap" style={{ borderRadius: 5 }}>
                                    <h1 className="form-title">Welcome back!</h1>
                                    <div className="form-descp">Thank you for continuing to support Sparq. Enter your email and password to login.<br />If you need to create a new account, <a href="sign-up">click here</a>.</div>
                                    <form onSubmit={this.handleSubmit} name="loginForm" id="loginForm" action="home.html" className="login-form entry-form check-form" method="post">
                                        <MaterialInput
                                            placeholder="Email address"
                                            type="email"
                                            name="email"
                                            required={true}
                                            pattern="^.{0,64}$"
                                            title="Enter an email address with a maximum of 64 characters."
                                            label="Email address"
                                            id="email"
                                            onChange={this.validateEmail.bind(this)}
                                            error={this.state.authSignInError}
                                            value={this.state.email}
                                            icon="../../../../public/assets/images/user.svg"
                                        />
                                        <MaterialInput
                                            placeholder="Password"
                                            type="password"
                                            name="password"
                                            required={true}
                                            label="Password"
                                            onChange={this.validateEmail.bind(this)}
                                            error={this.state.authSignInError}
                                            id="password"
                                            value={this.state.password}
                                        />
                                        {authSignInError ? <div style={error_style}>{authSignInError}</div> : null}
                                        {authEmailForgotPasswordSuccess ? <div style={success_style}>{authEmailForgotPasswordSuccess}</div> : null}
                                        <div className="button-wrap">
                                            <button className={"btn-primary btn-login " + ((this.state.email && this.state.password) ? "active" : "inactive")}>SIGN IN</button>
                                        </div>
                                    </form>
                                    <div className="form-footer">
                                        <div className="row">
                                            <div className="col-md-6 col-sm-6 col-7">
                                                <Link to="/forgot-password" className="redirect-link grey-link">FORGOT PASSWORD?</Link>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-5 text-right">
                                                <Link to="/sign-up" className="redirect-link green-link">SIGN UP <i className="green-arr"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
};

const mapStateToProps = (state) => {
    const { auth: { authSignInError, customMessage, authEmailForgotPasswordSuccess } } = state
    return {
        authSignInError,
        customMessage,
        authEmailForgotPasswordSuccess
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authenticateUser: (creds) => dispatch(authenticateUser(creds)),
        refreshErrors: () => dispatch(refreshErrors())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
