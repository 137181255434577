import React from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';

export default function FeatureRequestHeader() {
  return (
    <React.Fragment>
      <Container>
      <Row className="nav">
        <Col xs={ 12 } sm={ 3 } className="logo">
          <Link to="/ideas">
          <img src="/assets/images/sparq.png" alt="Sparq Logo" width="200" height="80" />

          </Link>
        </Col>
        <Col xs={ 12 } sm={ 9 } className="navFont">Sparq Feature Request</Col>
      </Row>

    </Container>
            <div style={{ width: "100%", borderBottom: "2px solid #EAEAEA" }}></div>
    </React.Fragment>
    
  )
}
