import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { FacebookShareButton, TwitterShareButton, PinterestShareButton } from 'react-share';
import { getPersonalImage } from '@actions/activity';
import './thankyou.css';
import ReactGA from 'react-ga';
import { useState } from 'react';
import secureLocalStorage from "react-secure-storage";


class DemoThankYou extends Component {



    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem('setImage_url'),
            personal_image: localStorage.getItem('setImage_url'),
            response: localStorage.getItem('response'),
        };
    }

    componentWillMount() {
        ReactGA.initialize(process.env.REACT_APP_ANALYTICS);
    }

    componentDidMount() {
        // this.props.getPersonalImage();
        secureLocalStorage.setItem("demo_response", this.state.response);
        secureLocalStorage.setItem("demo_image_url", this.state.personal_image);

        const question_storage = JSON.parse(localStorage.getItem('selectedQuestion'));
        secureLocalStorage.setItem("demo_question", question_storage.content);


    }

    onClickFacebook = () => {
        ReactGA.event({
            category: 'social',
            action: 'share',
            label: 'facebook'
        });
    }

    onClickPinterest = () => {
        ReactGA.event({
            category: 'social',
            action: 'share',
            label: 'pinterest'
        });
    }

    onClickTwitter = () => {
        ReactGA.event({
            category: 'social',
            action: 'share',
            label: 'twitter'
        });
    }



    render() {
        let content;


        if (this.state.personal_image === undefined) {
            content = <div></div>
        } else {

            if (this.state.token === '' || true) {
                const data = {
                    link: this.state.personal_image
                };


                // POST request using fetch()
                fetch("https://www.skilldivvy.com/dev/crons/firestore/dynamic_link.php?" + "link=" + encodeURIComponent(data.link), {

                    // Adding method type
                    method: "GET",

                    // Adding body or contents to send
                    //body: JSON.stringify(data),



                })

                    // Converting to JSON
                    .then(response => response.json())

                    // Displaying results to console
                    .then(json => {
                        this.setState({ token: json.link });
                    });
            }

            const description = `${this.state.response} 
${this.state.token}
                                            
I've just experienced Sparq.

Visit https://discoversparq.com/sign-up to create a free account and experience Sparq for yourself!`;

            content = (
                <div id="main">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 back-nav">
                                {/* <Link to='/' className="goto_back"><i></i>Back</Link> */}
                            </div>
                            <div className="form-padding-box padding-box">
                                <div className="form-wrap box-wrap" style={{ borderRadius: 5 }}>
                                    <div style={{ textAlign: 'center', padding: '10px', margin: '10px 0px', borderBottom: '0px solid #f2f2f2', borderRadius: '0px' }}>
                                        <h3 style={{ fontSize: '25px', marginBottom: '10px', paddingBottom: '25px' }}>Your Reflection</h3>
                                        <img
                                            style={{ maxHeight: '300px', width: 'auto', borderRadius: '5px' }}
                                            src={this.state.personal_image}
                                            title="Selected Image"
                                            className="final-image"
                                            alt="final"
                                        />
                                        <h3 style={{ padding: '20px', textAlign: 'center', color: 'black', background: '#f2f2f2', borderRadius: '5px', margin: "20px" }}>
                                            {this.state.response}
                                        </h3>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 share-col small-title" style={{ textAlign: 'center' }}>
                                            Sign up now to save your reflection and enjoy all the features of Sparq!
                                        </div>
                                    </div>
                                    <div className="mt-10">
                                        <Link to="/sign-up" style={{ textTransform: 'uppercase' }} className="btn-primary-bdr bold">Sign up</Link>
                                    </div>
                                    <br />
                                    <div className="small-title" style={{ marginTop: '20px' }}>Thank you for using Sparq</div>
                                    <div className="content-txt mt-0" style={{ textAlign: 'center' }}>We encourage you to share your results and continue the conversation!</div>


                                    <div className="share-box desktop">
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 share-col">
                                                <FacebookShareButton url={this.state.personal_image} quote={description}>
                                                    <a href="/" style={{ borderRadius: 5 }} onClick={this.onClickFacebook} className="share-btn facebook"><i></i>Facebook</a>
                                                </FacebookShareButton>
                                            </div>
                                        </div>
                                        <div className="row mt-10">
                                            <div className="col-md-6 col-sm-6 share-col sec-col">
                                                <TwitterShareButton url="/" title={description}>
                                                    <a href="/" style={{ borderRadius: 5 }} onClick={this.onClickTwitter} className="share-btn twitter"><i></i>Twitter</a>
                                                </TwitterShareButton>
                                            </div>
                                            <div className="col-md-6 col-sm-6 share-col">
                                                <PinterestShareButton url={this.state.personal_image} description={description} media={this.state.personal_image} windowWidth={1000} windowHeight={730} >
                                                    <a href="/" style={{ borderRadius: 5 }} onClick={this.onClickPinterest} className="share-btn pinterest"><i></i>Pinterest</a>
                                                </PinterestShareButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="share-box mobile">
                                        <a href="/" className="share-btn facebook">
                                            <FacebookShareButton className="inline-block" url={this.state.personal_image} quote={description}>
                                                <i></i>
                                            </FacebookShareButton>
                                        </a>
                                        <a href="/" className="share-btn twitter">
                                            <TwitterShareButton className="inline-block" url="/" title={description}>
                                                <i></i>
                                            </TwitterShareButton>
                                        </a>
                                        <a href="/" className="share-btn pinterest">
                                            <PinterestShareButton className="inline-block" url={this.state.personal_image} description={description} media={this.state.personal_image} windowWidth={1000} windowHeight={730} >
                                                <i></i>
                                            </PinterestShareButton>
                                        </a>
                                    </div>
                                    <div className="ty-foot mt-30">
                                        {/* <div className="row">
                                            <div className="col-md-12">
                                                <Link to={{ pathname: '/personal-image', state: { activity: this.props.personal_image[0] } }} className="btn-primary green-bg">VIEW PERSONAL IMAGE</Link>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="container">
                {content}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        personal_image: state.activity.personal_image,
    }
};

export default connect(mapStateToProps, { getPersonalImage })(DemoThankYou)
