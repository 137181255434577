import authReducer from './auth-reducer';
import userReducer from './user-reducer';
import questionReducer from './question-reducer';
import groupReducer from './group-reducer';
import imageReducer from './image-reducer';
import activityReducer from './activity-reducer';
import ideasReducer from './ideas-reducer';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    group: groupReducer,
    question: questionReducer,
    activity: activityReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    image: imageReducer,
    ideas:ideasReducer
});

export default rootReducer;
