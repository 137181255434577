import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { filter } from "lodash";
import { getGroupActivtyByGroupId } from "@actions/activity";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import TagCloud from "react-tag-cloud";
import randomColor from "randomcolor";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pptxgen from "pptxgenjs";
import ReactWordcloud from 'react-wordcloud';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

import "./results.css";

const $ = window.$;
const GridScrollFx = window.GridScrollFx;

class ResultsTeam extends Component {
  state = {
    teamResults: [],
    modal: false,
    response: false,
  };

  componentDidMount() {
    this.fetchGroupRes();
  }

  componentWillMount() {
    $(".nicescroll-rails").removeClass("hidden");
    if (this.props.match.params.view_type === "in-activity") {
      document.body.style.paddingBottom = 0;
      document.getElementsByClassName("footer")[0].style.display = "none";
    }
  }

  componentWillUnmount() {
    $(".nicescroll-rails").addClass("hidden");
    document.body.style.paddingBottom = `7rem`;
    document.getElementsByClassName("footer")[0].style.display = "block";
    $(".nicescroll-rails").remove();
  }

  fetchGroupRes = () => {
    this.props.getGroupActivtyByGroupId(
      this.props.match.params.group_id,
      () => {
        this.setState({
        //   teamResults: this.filterTeamReultsByViewType(this.props.teamResults),
          teamResults: this.props.teamResults,
        });
      }
    );
  };

  filterTeamReultsByViewType = (resultsArray) => {
    const filterHideResponseFromGroupFalse = filter(
      resultsArray,
      (res) =>
        !res.hideResponseFromGroup || res.user_id === this.props.authUserUid
    );
    return filterHideResponseFromGroupFalse;
  };

  onImgLoad(length) {
    if (this.state.teamResults.length === length) {
      setTimeout(() => {
        $("#grid li").each(function () {
          document.getElementsByClassName("footer");
          if (!$(this).hasClass("shown")) {
            $(this).addClass("shown");
          }
        });

        if (GridScrollFx && document.getElementById("grid")) {
          new GridScrollFx(document.getElementById("grid"), {
            viewportFactor: 0,
          });

          $(".gallery-scroll")
            .niceScroll({
              cursorcolor: "#888888",
              cursorwidth: "8px",
              background: "#d8d8d8",
              cursorborder: "1px solid #d8d8d8",
              autohidemode: false,
            })
            .resize();
        }
      }, 200);
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  responseToggle = () => {
    this.setState({
      response: !this.state.response,
    });
  };
  downloadSlides = () => {
    const { teamResults } = this.state;
    const filename = "GroupSlides.pptx";
    const pptx = new pptxgen();
    teamResults.map((result) => {
      let str = result.response;
      if ( result.hideResponseFromGroup ){
        str = '';
      }
      if (str.length > 75) {
        str = str.substring(0, 75) 
        str = `${str} ...`
      }
      const slide = pptx.addSlide();
      if ( result.hideNameFromGroup ){
        result.name = '';
      }
      slide.addText(result.name, {
        x: 0.5,
        y: 0.7,
        w: 3,
        color: "000000",
        fontSize: 20,
      });
      slide.addText(str, {
        x: 0.5,
        y: 1.2,
        w: 10,
        color: "000000",
        fontSize: 16,
      });
      const img = new Image();
        img.src = result.image_url;
        let new_width = img.width/img.height * 3.5
        slide.addImage({ path: result.image_url, x: 3, y: 2, w: new_width, h: 3.5 });
      return result;
    });
    pptx.writeFile(filename);
  };

  downloadSlidesMultiple = () => {
    // toast("Downloading", {type: "info"});
    const { teamResults } = this.state;
    const filename = "GroupSlides.pptx";
    const pptx = new pptxgen();
    teamResults.map((result) => {

      if(result.multipleQuestionResponse && Array.isArray(result.multipleQuestionResponse.answers)){
      
        result.multipleQuestionResponse.answers.map((ans, indexAns)=>{

            let str = ans.response;
            if (str.length > 500) {
              str = str.substring(0, 500) 
              str = `${str} ...`
            }

          const slide = pptx.addSlide();

            let name = 'by '+result.name 
            slide.addText(name, {
              x: 3.8,
              y: 3.8,
              w: 5,
              color: "000000",
              fontSize: 14,
              fontFace : 'sans-serif'
            });

          
              const img = new Image();
              img.src = ans.image_url;
              // let new_width = img.width/img.height * 2;
              // slide.addImage({ path: result.image_url, x: 0.5, y: 1.3, w: new_width, h: 2 });

              let new_height = img.height/img.width * 3;

              if ( new_height <= 3.3 ){
                  slide.addImage({ path: ans.image_url, x: 0.5, y: 1, w: 3, h: new_height });
              }else{
                  let new_width = img.width/img.height * 3.3;
                  slide.addImage({ path: ans.image_url, x: 0.5, y: 1, w: new_width, h: 3.3 });
              }

              slide.addImage({ path: "/assets/images/sparq_logo.png", x: 0.5, y: 0.3, w: 1.2, h: 0.3 });
              
              let arrTextObjs2 = [
                { text: "https://discoversparq.com", options: { fontSize: 12, color: "FFFFFF" , align: "center", fontFace : 'sans-serif' } },
              ];
              slide.addText(arrTextObjs2, { x: 0, y: 4.8, w: 10, h: 0.8, fill: { color: "2a3381" } });
              
              let arrTextObjs3 = [
                { text: str, options: { fontSize: 14, color: "000000" , align: "center", fontFace : 'sans-serif' } },
              ];
              slide.addText(arrTextObjs3, { x: 3.9, y: 1, w: 5.5, h: 2.5, fill: { color: "f2f2f2" } });

          //      let response = 'Response: '+str 
          //   slide.addText(response, {
          //     x: 5.5,
          //     y: 2,
          //     w: 3,
          //     color: "000000",
          //     fontSize: 16,
          //   });
              // return result;

          //   const slide = pptx.addSlide();
          //   slide.addText(result.name, {
          //     x: 0.5,
          //     y: 0.7,
          //     w: 3,
          //     color: "000000",
          //     fontSize: 20,
          //   });
          //   slide.addText(str, {
          //     x: 0.5,
          //     y: 1.2,
          //     w: 10,
          //     color: "000000",
          //     fontSize: 16,
          //   });
          //     const img = new Image();
          //     img.src = result.image_url;
          //     let new_width = img.width/img.height * 3.5
          //     slide.addImage({ path: result.image_url, x: 3, y: 2, w: new_width, h: 3.5 });


          //   return result;
        
        })

      }else{
          let str = result.response;
          if (str.length > 500) {
            str = str.substring(0, 500) 
            str = `${str} ...`
          }

        const slide = pptx.addSlide();

          let name = 'by '+result.name 
          slide.addText(name, {
            x: 3.8,
            y: 3.8,
            w: 5,
            color: "000000",
            fontSize: 14,
            fontFace : 'sans-serif'
          });

        
            const img = new Image();
            img.src = result.image_url;
            // let new_width = img.width/img.height * 2;
            // slide.addImage({ path: result.image_url, x: 0.5, y: 1.3, w: new_width, h: 2 });

            let new_height = img.height/img.width * 3;

            if ( new_height <= 3.3 ){
                slide.addImage({ path: result.image_url, x: 0.5, y: 1, w: 3, h: new_height });
            }else{
                let new_width = img.width/img.height * 3.3;
                slide.addImage({ path: result.image_url, x: 0.5, y: 1, w: new_width, h: 3.3 });
            }

            slide.addImage({ path: "/assets/images/sparq_logo.png", x: 0.5, y: 0.3, w: 1.2, h: 0.3 });
            
            let arrTextObjs2 = [
              { text: "https://discoversparq.com", options: { fontSize: 12, color: "FFFFFF" , align: "center", fontFace : 'sans-serif' } },
            ];
            slide.addText(arrTextObjs2, { x: 0, y: 4.8, w: 10, h: 0.8, fill: { color: "2a3381" } });
            
            let arrTextObjs3 = [
              { text: str, options: { fontSize: 14, color: "000000" , align: "center", fontFace : 'sans-serif' } },
            ];
            slide.addText(arrTextObjs3, { x: 3.9, y: 1, w: 5.5, h: 2.5, fill: { color: "f2f2f2" } });

        //      let response = 'Response: '+str 
        //   slide.addText(response, {
        //     x: 5.5,
        //     y: 2,
        //     w: 3,
        //     color: "000000",
        //     fontSize: 16,
        //   });
            // return result;

        //   const slide = pptx.addSlide();
        //   slide.addText(result.name, {
        //     x: 0.5,
        //     y: 0.7,
        //     w: 3,
        //     color: "000000",
        //     fontSize: 20,
        //   });
        //   slide.addText(str, {
        //     x: 0.5,
        //     y: 1.2,
        //     w: 10,
        //     color: "000000",
        //     fontSize: 16,
        //   });
        //     const img = new Image();
        //     img.src = result.image_url;
        //     let new_width = img.width/img.height * 3.5
        //     slide.addImage({ path: result.image_url, x: 3, y: 2, w: new_width, h: 3.5 });


        //   return result;
      }
    });
    pptx.writeFile(filename);
  };


  generateImage = () => {

        const node = document.getElementById("wordcloud-wrapper");

        domtoimage.toBlob(node, {bgcolor:'white'}).then(dataUrl => {
            saveAs(dataUrl, 'WordCloud.png');
        }).catch(function (error) {
            console.error('oops, something went wrong!', error);
        });
    };

  render() {
    const { teamResults } = this.state;
    const { view_type, group_id } = this.props.match.params;

    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
 
    // const slideShowModal = (
    //   <Modal
    //     isOpen={this.state.modal}
    //     toggle={this.toggle}
    //     className="modal-width"
    //   >
    //     <ModalHeader toggle={this.toggle}>Slide Show</ModalHeader>
    //     <button
    //       style={{
    //         backgroundColor: "#2a3381",
    //         border: "none",
    //         width: "min-content",
    //       }}
    //       className="btn btn-info ml-2 mt-2"
    //       onClick={this.downloadSlides}
    //     >
    //       Download PPT
    //     </button>
    //     <ModalBody>
    //       <Slider {...settings}>
    //         {teamResults.map((result, index) => {
    //           return (
    //             <div key={index}>
    //               <span className="image-author">{ result.hideNameFromGroup ? '' : result.name }</span>
    //               <img
    //                 className="mx-auto"
    //                 src={result.image_url}
    //                 alt="slider"
    //                 height={450}
    //               />
    //             </div>
    //           );
    //         })}
    //       </Slider>
    //     </ModalBody>
    //   </Modal>
    // );

    const slideShowModal = () =>{


      if(teamResults[0] && teamResults[0].multipleQuestionResponse){
        
        var slides_arr = [];

        teamResults[0].multipleQuestionResponse.answers.map( (ans, ansIndex)=>{

          teamResults.map((result, index) => {

            var obj = {question: ans.question};

            obj.image_url = result.multipleQuestionResponse.answers[ansIndex].image_url;
            obj.userName = result.hideNameFromGroup ? '' : result.name;
            slides_arr.push(obj);

          });

        });
        
        return(
          <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-width"
          >
          <ModalHeader toggle={this.toggle}>Slide Show</ModalHeader>
          <button
            style={{
              backgroundColor: "#2a3381",
              border: "none",
              width: "min-content",
            }}
            className="btn btn-info ml-2 mt-2"
            onClick={this.downloadSlidesMultiple}
          >
            Download PPT
          </button>
          <ModalBody>
            <Slider {...settings}>
            {
              
              slides_arr.map( (slide, index) =>
              
                <div key={index}>

                  <p>Question : {slide.question}</p>

                  <span className="image-author">{slide.userName}</span>

                  <img
                    className="mx-auto"
                    src={slide.image_url}
                    alt="slider"
                    height={450}
                  />
                </div>
                    

              )

              
              
            }
            </Slider>
          </ModalBody>
          </Modal>
        )
      }else{
        return(
          <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-width"
        >
          <ModalHeader toggle={this.toggle}>Slide Show</ModalHeader>
          <button
            style={{
              backgroundColor: "#2a3381",
              border: "none",
              width: "min-content",
            }}
            className="btn btn-info ml-2 mt-2"
            onClick={this.downloadSlidesMultiple}
          >
            Download PPT
          </button>
          <ModalBody>
            <Slider {...settings}>
              

            {teamResults.map((result, index) => {
              return (
                <div key={index}>
                  <span className="image-author">{ result.hideNameFromGroup ? '' : result.name }</span>
                  <img
                    className="mx-auto"
                    src={result.image_url}
                    alt="slider"
                    height={450}
                  />
                </div>
              );
            })}
            </Slider>
          </ModalBody>
          </Modal>
        );
      }
     
    }
    const subStrings = ["uncertainty", "power", "activity"]
    let data = []
    teamResults.map((result) => {
      if (new RegExp(subStrings.join("|")).test(result.response)) {
        data.push(result.response)
      } else {
        console.log("No match using '" + result.response + "'");
      }
      return result
    })

     let words = []
    
    const options = {
        colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
        enableTooltip: false,
        deterministic: false,
        fontFamily: "impact",
        fontSizes: [5, 60],
        fontStyle: "normal",
        fontWeight: "normal",
        padding: 1,
        rotations: 3,
        rotationAngles: [0, 90],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 3000
    }

    function removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject  = {};

        for(var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }

        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }

    // const responseModal = (
    //   <Modal
    //     isOpen={this.state.response}
    //     toggle={this.responseToggle}
    //     className="modal-width"
    //   >
    //     <ModalHeader toggle={this.responseToggle}>Wordcloud</ModalHeader>
    //     <ModalBody className="modal-height">

    //       <div className="app-outer" style={{ alignItems: 'normal', padding: '0px' }}>
    //         <button onClick={this.generateImage} style={{ backgroundColor: "rgb(42, 51, 129)", border: "none", width: "min-content" }} className="btn btn-info ml-2 mt-2">Download</button>
    //         <div className="app-inner" id="wordcloud-wrapper" style={{ backgroundColor: "white"}} >

    //             {
    //                 teamResults.map((result, index) => {
    //                 var str = result.response;
                        
    //                 var new_val = str.split(" ");
                        

    //                         new_val.map((result_new, index_new) => {

    //                              // remove unwanted words
    //                             result_new = result_new.replaceAll(',','');
    //                             result_new = result_new.replaceAll('.','');
    //                             result_new = result_new.replaceAll('"','');
    //                             result_new = result_new.replaceAll('!','');
    //                             result_new = result_new.replaceAll(';','');
    //                             result_new = result_new.replaceAll(':','');
    //                             result_new = result_new.replaceAll('?','');
    //                             result_new = result_new.replaceAll('then','');
    //                             result_new = result_new.replaceAll('that','');
    //                             result_new = result_new.replaceAll('than','');
    //                             result_new = result_new.replaceAll('with','');
    //                             result_new = result_new.replaceAll('have’','');
    //                             result_new = result_new.replaceAll('has','');
    //                             result_new = result_new.replaceAll('this','');
    //                             result_new = result_new.replaceAll('that','');
    //                             result_new = result_new.replaceAll('what','');



    //                             if (result_new.length > 3){
    //                                 words.push({
    //                                     text: result_new,
    //                                     value: Math.round(Math.random() * 10) + 10
    //                                 });
                                  
                                    
    //                             }

                                

    //                         })


                    

    //                 }),
    //                 console.log(words),
                   



    //                 words = removeDuplicates(words, "text"),
    //                 console.log("uniqueArray is: " + JSON.stringify(words))

    //             }

               
    //             <ReactWordcloud words={words} options={options} />
              


    //           {/* <TagCloud
    //             className="tag-cloud"
    //             style={{
    //               fontFamily: "sans-serif",
    //               color: () =>
    //                 randomColor({
    //                   hue: "blue",
    //                 }),
    //               fontSize: () => Math.round(Math.random() * 50) + 16,
    //               padding: 5,
    //               width: "100%",
    //               height: "100%",
    //               flex: 1,
    //             }}
    //           >
    //             {data.map((result, index) => {
    //               var str = result;
    //               //if (str.length > 3500) str = str.substring(0, 25);
    //               return <div key={index}>{str}...</div>;
    //             })}
    //           </TagCloud> */}
    //         </div>
    //       </div>
    //     </ModalBody>
    //   </Modal>
    // );


    const responseModalRender = () => {
      
      
      if(teamResults[0] && teamResults[0].multipleQuestionResponse){
        
        var words_arrs = [];

        teamResults[0].multipleQuestionResponse.answers.map((ans, index)=>{

          var obj = {question: ans.question}

          var words = [];
          teamResults.map((result) => {


            var str = result.multipleQuestionResponse.answers[index].response;
            
            var new_val = str.split(" ");
      
            new_val.map((result_new) => {
                // remove unwanted words
                result_new = result_new.replaceAll(',','');
                result_new = result_new.replaceAll('.','');
                result_new = result_new.replaceAll('"','');
                result_new = result_new.replaceAll('!','');
                result_new = result_new.replaceAll(';','');
                result_new = result_new.replaceAll(':','');
                result_new = result_new.replaceAll('?','');
                result_new = result_new.replaceAll('then','');
                result_new = result_new.replaceAll('that','');
                result_new = result_new.replaceAll('than','');
                result_new = result_new.replaceAll('with','');
                result_new = result_new.replaceAll('have’','');
                result_new = result_new.replaceAll('has','');
                result_new = result_new.replaceAll('this','');
                result_new = result_new.replaceAll('that','');
                result_new = result_new.replaceAll('what','');
  
                if (result_new.length > 3){
                    
                    words.push({
                        text: result_new,
                        value: Math.round(Math.random() * 10) + 10
                    });; 
                }
            })
  
          });

          words = removeDuplicates(words, "text");
          // console.log("uniqueArray is: " + JSON.stringify(words))

          obj.words = words;

          words_arrs.push(obj);

        });

        return(
          <Modal
            isOpen={this.state.response}
            toggle={this.responseToggle}
            className="modal-width"
          >
            <ModalHeader toggle={this.responseToggle}>Wordcloud</ModalHeader>
            <ModalBody className="modal-height">
              <div className="app-outer mt-3" style={{ alignItems: 'normal', padding: '0px' }}>
                <button onClick={this.generateImage} style={{ backgroundColor: "rgb(42, 51, 129)", border: "none", width: "min-content" }} className="btn btn-info ml-2 mt-3">Download</button>

                <div className="app-inner" id="wordcloud-wrapper" style={{ backgroundColor: "white" }}>
                    
                  { words_arrs.map((elem, index)=>
                    <>
                      <h5 className="p-2">Question {index+1}: {elem.question}</h5>
                      <ReactWordcloud words={elem.words} options={options} />
                    </>
                  )  
                  }
                   
                </div>
              </div>
            </ModalBody>
          </Modal>
        )

      }else{
        return (
          <Modal
          isOpen={this.state.response}
          toggle={this.responseToggle}
          className="modal-width"
        >
          <ModalHeader toggle={this.responseToggle}>Wordcloud</ModalHeader>
          <ModalBody className="modal-height">
            <div className="app-outer mt-3" style={{ alignItems: 'normal', padding: '0px' }}>
              <button onClick={this.generateImage} style={{ backgroundColor: "rgb(42, 51, 129)", border: "none", width: "min-content" }} className="btn btn-info ml-2 mt-3">Download</button>

              <div className="app-inner" id="wordcloud-wrapper" style={{ backgroundColor: "white" }}>

                  {
                      teamResults.map((result, index) => {
                      var str = result.response;
                      var new_val = str.split(" ");
                          

                              new_val.map((result_new, index_new) => {

                                  // remove unwanted words
                                  result_new = result_new.replaceAll(',','');
                                  result_new = result_new.replaceAll('.','');
                                  result_new = result_new.replaceAll('"','');
                                  result_new = result_new.replaceAll('!','');
                                  result_new = result_new.replaceAll(';','');
                                  result_new = result_new.replaceAll(':','');
                                  result_new = result_new.replaceAll('?','');
                                  result_new = result_new.replaceAll('then','');
                                  result_new = result_new.replaceAll('that','');
                                  result_new = result_new.replaceAll('than','');
                                  result_new = result_new.replaceAll('with','');
                                  result_new = result_new.replaceAll('have’','');
                                  result_new = result_new.replaceAll('has','');
                                  result_new = result_new.replaceAll('this','');
                                  result_new = result_new.replaceAll('that','');
                                  result_new = result_new.replaceAll('what','');



                                  if (result_new.length > 3){
                                      
                                      words.push({
                                          text: result_new,
                                          value: Math.round(Math.random() * 10) + 10
                                      });;
                                    
                                      
                                  }

                                  

                              })


                      

                      }),



                      words = removeDuplicates(words, "text"),
                      console.log("uniqueArray is: " + JSON.stringify(words))

                      
                  }
                  
                  <ReactWordcloud words={words} options={options} />
    
              </div>
            </div>
          </ModalBody>
          </Modal>
        )
      }
    
    }

    const resultsTeamImageGrid = teamResults.map((results, index) => {
      let route =
        view_type === "admin"
          ? "results-individual-admin"
          : "results-individual";
      
      if(results.multipleQuestionResponse && Array.isArray(results.multipleQuestionResponse.answers)){

        return(
          // <li key={index} className="shown" style={{ marginBottom: 0 }}>
          // <Link
          //   to={{
          //     pathname: `/${route}/${view_type}/${group_id}`,
          //     state: {
          //       response: results.response,
          //       name: results.name,
          //       image_url: results.image_url,
          //       startCounter: index,
          //       ansIndex:0
          //     },
          //   }}
          // >
          //   <img
          //     // onLoad={this.onImgLoad.bind(this, teamResults.length)}
          //     src={results.image_url}
          //     alt="Post Card"
          //     onError={(error) => (error.target.style.display = "none")}
          //   />
          //   {this.props.authUserUid == results.user_id ? (
          //     <span className="image-caption">{results.name}</span>
          //   ) : (
          //       <span className="image-caption">
          //         {results.hideNameFromGroup ? "" : results.name}
          //       </span>
          //     )}
          // </Link>
          // </li>
         
        results.multipleQuestionResponse.answers.map((ans, indexAns)=>
          
            indexAns==0 ?
              <>
              <li key={index} className="shown" style={{ marginBottom: 0 }}>
              <Link
                to={{
                  pathname: `/${route}/${view_type}/${group_id}`,
                  state: {
                    response: results.response,
                    name: results.name,
                    image_url: ans.image_url,
                    startCounter: index,
                    ansIndex:indexAns
                  },
                }}
              >
                <img
                  onLoad={this.onImgLoad.bind(this, teamResults.length)}
                  src={ans.image_url}
                  alt="Post Card"
                  onError={(error) => (error.target.style.display = "none")}
                />
                {this.props.authUserUid == results.user_id ? (
                  <span className="image-caption">{results.name}</span>
                ) : (
                    <span className="image-caption">
                      {results.hideNameFromGroup ? "" : results.name}
                    </span>
                  )}
              </Link>
              </li>
              </>
            :''
          
        )
        );
          

      }else{

        return (
          <li key={index} className="shown" style={{ marginBottom: 0 }}>
            <Link
              to={{
                pathname: `/${route}/${view_type}/${group_id}`,
                state: {
                  response: results.response,
                  name: results.name,
                  image_url: results.image_url,
                  startCounter: index,
                  ansIndex:0
                },
              }}
            >
              <img
                onLoad={this.onImgLoad.bind(this, teamResults.length)}
                src={results.image_url}
                alt="Post Card"
                onError={(error) => (error.target.style.display = "none")}
              />
              {this.props.authUserUid == results.user_id ? (
                <span className="image-caption">{results.name}</span>
              ) : (
                  <span className="image-caption">
                    {results.hideNameFromGroup ? "" : results.name}
                  </span>
                )}
            </Link>
          </li>
        );
      }
    });

    return (
      <div id="main" className="image-selection">
        <div className="container">
          <div className="row">
            <div className="col-md-12 back-nav">
              <Link
                to={view_type === "in-process" ? "/" : "/view-user-results"}
                className="goto_back"
              >
                <i />
                Back
              </Link>
            </div>
            <div className="col-md-12 images-box">
              <div className="images-wrap">
                <div className="small-title">Results - Team</div>
                <div className="view-gallery tr-gallery gallery-scroll results-team-list">
                  {this.props.profile.profile.user_type === "premium" && (
                    <div style={{ display: "flex" }}>
                      <button
                        className="btn btn-primary w-auto mb-4"
                        onClick={this.toggle}
                      >
                        {"    "}
                        View SlideShow
                      </button>
                      <button
                        className="btn btn-primary w-auto mb-4 ml-3"
                        onClick={this.responseToggle}
                      >
                        {"    "}
                        View Wordcloud
                      </button>
                    </div>
                  )}
                  {slideShowModal()}
                  {responseModalRender()}
                  <ul className="grid swipe-down loaded" id="grid">
                    {resultsTeamImageGrid}
                  </ul>
                </div>
                {view_type === "in-activity" ? (
                  <div className="button-wrap next_step mt-20">
                    <Link
                      to={{
                        pathname: "/current-result",
                        state: { hello: "1" },
                      }}
                      className="btn-primary green-bg max-370"
                    >
                      FINISH
                    </Link>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUserUid: state.firebase.auth.uid,
    teamResults: state.activity.activityByGroupId,
    profile: state.firebase,
  };
};

export default connect(mapStateToProps, { getGroupActivtyByGroupId })(
  ResultsTeam
);
