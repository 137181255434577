import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getQuestionByKey, getDefaultQuestion } from '@actions/question';

class GuestQuestionActivity extends React.Component {

    state = {
        question_count: 1,
        question_total: 1
    }

    componentWillMount() {
        if (!Array.isArray(this.props.group.question_id)) {

            this.props.getQuestionByKey(this.props.group.question_id);

            this.setState({ question_count: 1, question_total: 1 });
            localStorage.setItem("question_count", 1);
            localStorage.setItem("question_total", 1);
        } else {

            var multipleQuestionResponse = JSON.parse(localStorage.getItem("multipleQuestionResponse"));
            if (multipleQuestionResponse && multipleQuestionResponse.group_id == this.props.group.groupKey) {

                var question_ids = this.props.group.question_id;

                question_ids.reverse().forEach(question_id => {

                    var found = false;

                    this.setState({
                        question_total: question_ids.length,
                        question_count: multipleQuestionResponse.answers.length + 1
                    });

                    localStorage.setItem("question_count", multipleQuestionResponse.answers.length + 1);
                    localStorage.setItem("question_total", question_ids.length);

                    multipleQuestionResponse.answers.forEach((localAnswer) => {
                        if (localAnswer.question_id == question_id) {
                            found = true;
                        }
                    });

                    //this question_id was not answered; send user for it's answer;
                    if (!found) {
                        this.props.getQuestionByKey(question_id);
                    }

                });



            } else {
                this.props.getQuestionByKey(this.props.group.question_id[0]);
                this.setState({ question_count: 1, question_total: this.props.group.question_id.length });

                localStorage.setItem("question_count", 1);
                localStorage.setItem("question_total", this.props.group.question_id.length);
            }
        }

    };

    componentWillReceiveProps(nextProps) {
        if (nextProps) {
            localStorage.setItem("selectedQuestion", JSON.stringify(nextProps.question.question));
        }
    };

    render() {
        const { question } = this.props.question;

        return (
            <div id="main">
                <div className="container mobtop">
                    <div className="row">
                        <div className="col-md-12 back-nav">
                            <Link to="/guest-activity" className="goto_back"><i></i>Back</Link>
                        </div>
                        <div className="progress-steps">
                            <span className="step step-1 active"></span>
                            <span className="step step-2 active"></span>
                            <span className="step step-3"></span>
                            {/* <span className="step step-4"></span>
                            <span className="step step-5"></span> */}
                        </div>
                        <div className="buttons-padding-box padding-box">
                            <div className="buttons-wrap box-wrap">
                                <div className="mb-3 font-weight-bold text-center">Question {this.state.question_count} of {this.state.question_total}</div>

                                {
                                    question.content
                                        ? <><div className="small-title">{question.content}</div><Link
                                            className="btn-primary green-bg"
                                            to={{
                                                pathname: `/guest-activity/question/${question.key}/images/`,
                                                // state : {question}
                                            }}
                                        >VIEW IMAGES</Link></>
                                        : ''
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = state => ({
    question: state.question,
    group: JSON.parse(window.localStorage.getItem('group'))[0]
});

const mapDispatchToProps = dispatch => ({
    getDefaultQuestion: type => dispatch(getDefaultQuestion(type)),
    getQuestionByKey: key => dispatch(getQuestionByKey(key)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestQuestionActivity);
