import React from "react";
import { connect } from "react-redux";
import { uploadImages, uploadUserImages } from "@actions/images";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles.css";

toast.configure();

class Uploader extends React.Component {
  constructor(props) {
    const { uid } = props;
    super(props);
    this.state = {
      uId: uid,
      images: [],
      collectionName: '',
      buttonText: 'Upload',
      buttonEnable: true
    };
  }

  resetForm() {
    this.setState({
      images: [],
    });
  }

  onSubmit = (event) => {
    event.preventDefault();
    if (this.state.uId) {
      this.props.uploadUserImages(this.state.images, this.state.uId);
      this.setState({ buttonText: 'Uploading...' });
      this.setState({ buttonEnable: false });
    } else {
      this.props.uploadImages(this.state.images);
    }
    this.resetForm();

    // this.props.toggle();
  };

  onChange = (event) => {
    this.setState({ images: event.target.files });
  };

  render() {
    return (
      <div className="uploader-component">
        <form onSubmit={this.onSubmit}>
          <input
            style={{ border: 0 }}
            className="form-control"
            onChange={this.onChange}
            type="file"
            accept="image/*"
            required
            multiple
          />
          <br />
          <button
            className="btn btn-info"
            style={{ backgroundColor: "#2a3382", border: "none" }}
            disabled={!this.state.buttonEnable}
          >
            {this.state.buttonText}
          </button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  uploadImages: (images) => dispatch(uploadImages(images)),
  uploadUserImages: (data, uid) => dispatch(uploadUserImages(data, uid)),
});

export default connect(null, mapDispatchToProps)(Uploader);
