import React from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton, PinterestShareButton } from 'react-share';
import { connect } from 'react-redux';
import { getUserActivity } from '@actions/activity';
import loading from '../../../resource/loading.gif';
import { firestoreConnect } from 'react-redux-firebase';
import ReactGA from 'react-ga';

class CurrentResult extends React.Component {
    state = {
        group_key : JSON.parse(window.localStorage.getItem('group'))[0].groupKey,
    }

    componentWillMount() {
        this.props.getUserActivity(this.state.group_key);
        ReactGA.initialize(process.env.REACT_APP_ANALYTICS);
    }

    onClickFacebook = () => {
        ReactGA.event({
              category: 'social',
              action: 'share',
              label: 'facebook' 
        });
    }

    onClickPinterest = () => {
        ReactGA.event({
              category: 'social',
              action: 'share',
              label: 'pinterest' 
        });
    }

    onClickTwitter = () => {
        ReactGA.event({
              category: 'social',
              action: 'share',
              label: 'twitter' 
        });
    }

    render() {
        const { activity } = this.props.activity;

        if (!activity) {
            return (
                <React.Fragment>
                    <div className="container">
                        <div className="col-md-12">
                            <div style={{ textAlign: "center", paddingTop: "200px" }}>
                                <img src={loading} alt="loading..." />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

        const description = `${activity.question} 
${activity.image_url}
        
I've just experienced SPARQ.

Visit https://discoversparq.com/sign-up to create a free account and experience Sparq for yourself!`;

        return (
            <div id="main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 back-nav">
                            <Link to='/' className="goto_back"><i></i>Back</Link>
                        </div>
                        <div className="form-padding-box padding-box">
                            <div className="form-wrap box-wrap">
                                <div className="small-title">Thank you for using Sparq</div>
                                {/* <div className="content-txt mt-0">We encourage you to share your results, use your picture as a reminder for what you contribute to your team, and print your team’s mosiac to remind yourself how you all contribute and work together.</div> */}
                                <div className="content-txt mt-0">We encourage you to use images to regularly reflect on the questions and challenges in your life which can lead to deeper understanding of ourself and those around us. It can also help you to have better and more meaningful conversations with others.</div>
                                <div className="share-box desktop d-none">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 share-col">
                                            <FacebookShareButton url={activity.image_url} quote={description}>
                                                <a href="/" onClick={this.onClickFacebook} className="share-btn facebook"><i></i>Facebook</a>
                                            </FacebookShareButton>
                                        </div>
                                    </div>
                                    <div className="row mt-10">
                                        <div className="col-md-6 col-sm-6 share-col sec-col">
                                            <TwitterShareButton url="/" title={description}>
                                                <a href="/" onClick={this.onClickTwitter} className="share-btn twitter"><i></i>Twitter</a>
                                            </TwitterShareButton>
                                        </div>
                                        <div className="col-md-6 col-sm-6 share-col">
                                            <PinterestShareButton url={activity.image_url} description={description} media={activity.image_url} windowWidth={1000} windowHeight={730} >
                                                <a href="/" onClick={this.onClickPinterest} className="share-btn pinterest"><i></i>Pinterest</a>
                                            </PinterestShareButton>
                                        </div>
                                    </div>
                                </div>
                                <div className="share-box mobile d-none">
                                    <a href="/" className="share-btn facebook">
                                        <FacebookShareButton className="inline-block" url={activity.image_url} quote={description}>
                                            <i></i>
                                        </FacebookShareButton>
                                    </a>
                                    <a href="/" className="share-btn twitter">
                                        <TwitterShareButton className="inline-block" url="/" title={description}>
                                            <i></i>
                                        </TwitterShareButton>
                                    </a>
                                    <a href="/" className="share-btn pinterest">
                                        <PinterestShareButton className="inline-block" url={activity.image_url} description={description} media={activity.image_url} windowWidth={1000} windowHeight={730} >
                                            <i></i>
                                        </PinterestShareButton>
                                    </a>
                                </div>
                                <div className="ty-foot mt-30">
                                    <div className="row d-none">
                                        <div className="col-md-6">
                                            <Link to="/personal-image" className={this.props.personal_results.length !== 0 ? `btn-primary green-bg` : 'btn-primary blue-bg inactive'}>VIEW PERSONAL IMAGE</Link>
                                        </div>
                                        <div className="col-md-6 sec-col">
                                            <Link to={`/team-mosaic/${this.state.group_key}`} className="btn-primary green-bg">VIEW TEAM MOSAIC</Link>
                                        </div>
                                    </div>
                                    <div className="mt-10">
                                        <Link to="/" className="btn-primary-bdr bold">RETURN TO DASHBOARD</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activity: state.activity,
        group: state.group.group,
        profile: state.firebase.profile,
        auth: state.firebase.auth,
        personal_results: state.firestore.ordered.personal_results,
    };
};

const mapDispatchToProps = dispatch => ({
    getUserActivity: (groupKey) => dispatch(getUserActivity(groupKey))
})

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        {
            collection: 'activities',
            storeAs: 'personal_results',
            where: [['user_id', '==', props.auth.uid], ['activity_type', '==', 'individual']],
        }
    ])
)(CurrentResult)
