import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { CardBody, Card, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { deleteUser,getAllUsers } from '@actions/user';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { CSVLink } from "react-csv";
import UpdateUserForm from './UpdateUserForm';
import ViewResultsTable from './ViewResultsTable';
import ReactTable from 'react-table'
import firebase from 'firebase/app';

class AdminReferral extends Component {
    state = {
        groupName: '',
        userSelected: {},
        resultsKey: '',
        updateUserModalState: false,
        updateResultsModalState: false,
        test: ''
    };

    toggleUpdateUserClose = () => {
        this.setState({
            updateUserModalState: !this.state.updateUserModalState,
        });
    }

    toggleUpdateUser = event => {
        console.log(event)
        this.setState({
            updateUserModalState: !this.state.updateUserModalState,
            userSelected: event ? JSON.parse(event) : event
        });
        this.props.getAllUsers()
    }

    toggleResultsModalClose = () => {
        this.setState({
            updateResultsModalState: !this.state.updateResultsModalState,
        });
    }

    toggleResultsModal = event => {
        this.setState({
            updateResultsModalState: !this.state.updateResultsModalState,
            resultsKey: event.target.dataset.key
        });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleSubmitUpdateGroup = event => {
        event.preventDefault();
        this.props.updateGroup({
            groupName: this.state.nameToUpdate,
            id: this.state.idToUpdate
        });
        this.setState({ 
            nameToUpdate: "",
            idToUpdate: "",
            updateGroupModalState: false 
        });
    };

    handleUserDelete = (event) => { 
        if (window.confirm("Do you want to delete this user?")) {
            this.props.deleteUser(event.target.dataset.key);
        }
    }

    onSelectChange = event => {
    }

    componentDidMount=()=>{

        if ( firebase.auth().currentUser.email !== 'consulting@theflux.com' ){
            window.location.href = "../sign-in";
            window.$('body').hide();
        }

        if(!this.props.users.length){
            this.props.getAllUsers()
        }
    }
    render() {
	const columns = [{
	    Header: 'Email',
        accessor: 'email',
        filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id
            return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true
        },
	}, {
	    Header: 'Name',
        accessor: 'name',
        filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id
            return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true
        },
	}, {
	    Header: 'User Type',
	    accessor: 'user_type',
        filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id
            return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true
        },
    }, {   
        
	    Header: 'Referral Code',
	    accessor: 'referral_code',
        filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id
            return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true
        },

    }, {       
	    Header: 'Subscription Type',
	    accessor: 'sub_type',
        Cell: user => 
	        <Fragment>
                    <span>{ user.original.sub_type && user.original.sub_type === 'm' ? 'Monthly' : '' }</span>
                    <span>{ user.original.sub_type && user.original.sub_type === 'y' ? 'Yearly' : '' }</span>
	        </Fragment>
	},{
    
	    id: 'start_date', 
        Header: 'Subscription Start',
        filterable: false,
	    Cell: user => 
	        <Fragment>
                    { user.original.sub_type && ( user.original.sub_type === 'm' || user.original.sub_type === 'y' ) ? <span>{user.original.sub_month}/{user.original.sub_day}/{new Date().getFullYear()}</span> : '' }
	        </Fragment>
    // },{
	//     id: 'renewal_date', 
    //     Header: 'Renewal Date',
    //     filterable: false,
	//     Cell: user => 
    //         <Fragment>
    //                 <span>{parseInt(user.original.sub_month)+1}/{user.original.sub_day}/{new Date().getFullYear()}</span>
	//         </Fragment>
	        
    // 
    }
	]

	const updateGroupModal = (
	    <Fragment>
                <Modal isOpen={this.state.updateUserModalState} toggle={this.toggleUpdateUserClose}>
                    <ModalHeader toggle={this.toggleUpdateUserClose}>Update User Info</ModalHeader>
                    <ModalBody>
                        {<UpdateUserForm 
                            toggle={this.toggleUpdateUser}
                            user={this.state.userSelected} 
                        / >}
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
	    </Fragment>
	)

	const viewResultsModal = (
	    <Fragment>
                <Modal  size="lg" isOpen={this.state.updateResultsModalState} toggle={this.toggleResultsModalClose}>
                    <ModalHeader toggle={this.toggleResultsModalClose}>Results List</ModalHeader>
                    <ModalBody>
                        <ViewResultsTable 
                            resultsKey={this.state.resultsKey} 
                            query='user_id' 
                        />
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
	    </Fragment>
	)

	const headers = [
            { label: "Email", key: "email" },
            { label: "Name",  key: "name" },
            { label: "User Type", key: "user_type" }
	]
	       
        return (
            <div className="container">
                <Col>
                    <Card style={{marginTop: "30px" }}>
                        <CardBody>
                            <CSVLink  
                                filename={"sparq-users.csv"} 
                                style={{ float: 'right', backgroundColor: "#2a3381", border: "none" }} 
                                className="btn btn-info d-none" 
                                data={this.props.users} 
                                headers={headers}
                            >
                                Download CSV 
                            </CSVLink>
                            {updateGroupModal}
                            {viewResultsModal}
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            { this.props.users ? 
                            <ReactTable 
                            data={this.props.users} 
                            columns={columns} 
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                                const id = filter.pivotId || filter.id;
                                if (typeof filter.value === "object") {
                                  return row[id] !== undefined
                                    ? filter.value.indexOf(row[id]) > -1
                                    : true;
                                } else {
                                  return row[id] !== undefined
                                    ? String(row[id]).indexOf(filter.value) > -1
                                    : true;
                                }
                            }}
                            className="-striped -highlight"
                            /> : null }
                        </CardBody>
                    </Card>
                </Col>
            </div>
        );
    };
};

{/* const fillterUsers = (users, uid) => {
     
    let filtered = [] 
    if (users) {
        filtered = users.filter((data) => {
             return uid !== data.id ? true : false;
        });
    }
    return  filtered;
} */}

const fillterUsers = (users, uid) => {
     
    let filtered = [] 
    if (users) {
        filtered = users.filter((data) => {
             return data.referral_code ? true : false;
        });
    }
    return  filtered;
}


const mapStateToProps = (props) => {
    const {  firebase , user} = props 
    return {
        users: fillterUsers(user.users, firebase.auth.uid),
    }
}

const mapDispatchToProps = (dispatch) => ({
    deleteUser: (uid) => dispatch(deleteUser(uid)),
    getAllUsers:()=>dispatch(getAllUsers())
})

export default 
    connect(mapStateToProps, mapDispatchToProps)(AdminReferral);
