import React, { Component } from "react";
import { Container, Row, Col, Button, Form ,Dropdown} from "react-bootstrap";
import "./addNewIdea.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { IoHome } from "react-icons/io5";
import Editor from './Editor/Editor';
import { Badge } from 'reactstrap';
import { connect } from "react-redux";
import { FaSignOutAlt } from "react-icons/fa";
import {TiEdit} from "react-icons/ti"
import { Link, Route } from 'react-router-dom'
import {signOut} from '@actions/auth';
import {addIdea ,onFilterByCategory ,uploadFile} from '@actions/ideas';
import Header from '../SparqFeatureRequest/FeatureRequestHeader';
class AddNewIdea extends Component {
    constructor(props) {
        super(props)
        this.state = {
            idea: '',
            description: '',
            category_id:0,
            file:null
        }
    }
   onDataChange =( event )=> {
       this.setState({[ event.target.name]:event.target.value})
   }
   onEditorValueChange =( value )=>{
       this.setState({description:value})
   }
   onAddIdea = () =>{
       this.props.addIdea(this.state)
       this.setState({idea: '',
       description: '',
       category_id:0,
       file:null})
   }
    onFileUpload = (event)=>{
        this.setState({file:event.target.files[0]})
   }
    render() {
        const { email } = this.props.auth
        const buttonStyle={
            color:'#2a3382',
            backgroundColor:'whitesmoke',
            paddig:0,border:'2px solid #2a3382',
            width:'auto',
            height:'auto'
        }
        return (
            <div style={{fontSize:'15px'}}>
                <Container>
                  < Header/>
                </Container>
                <div style={{ width: "100%", borderBottom: "2px solid #EAEAEA" }}></div>
                <Container>
                    <Row className="Container">
                        <Col xs={0} sm={1}></Col>
                        <Col xs={12} xs={2} className="leftContent">
                            <div className="">
                                
                                <Breadcrumb>
                                    <div className="d-flex align-items-center">
                                        <Breadcrumb.Item >
                                            <Link to="/ideas" style={{ color: '#2a3382' }}>  <IoHome /> &nbsp;Home</Link>
										</Breadcrumb.Item>
                                        <Breadcrumb.Item active >New idea</Breadcrumb.Item>
                                    </div>
                                </Breadcrumb>
                                <Link to="/" style={{ color: '#2a3382'}} className="p-2">Login  /Signup</Link>

                              {/*
                              <Dropdown>
                                    <Dropdown.Toggle  variant="link" id="dropdown-basic">
                                    {email}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item> <TiEdit/>&nbsp;Edit Profile</Dropdown.Item>
                                        <Dropdown.Item onClick={this.props.signOut}> <FaSignOutAlt/> &nbsp; &nbsp;Sign out</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                              */}  
                                                                    
                            </div>
                        </Col>
                        <Col xs={12} sm={8} className="rightContent">
                            <div>
                                <p className="navFont" >Add a new idea</p>
                            </div>
                            <div>
                                <Form>
                                    <Form.Group >
                                        <Form.Label>Your Idea</Form.Label>&nbsp;&nbsp;
                                        <Badge color="secondary">Required</Badge>
                                        <Form.Control type="text" value={this.state.idea} size="sm" placeholder="One sentence summary of the idea" name="idea" required onChange={this.onDataChange}/>
                                    </Form.Group>
                                    <Editor onFileChoose={this.onFileUpload} placeholder={'Write something...'} onChange={this.onEditorValueChange} des={this.state.description}/>
                                    {this.state.file==null?null:<img style={{width:150,height:150}} src={URL.createObjectURL(this.state.file)}/>}

                                    <Form.Group>
                                        <Form.Label>Choose category for this (optional)</Form.Label>
                                        <Form.Control 
                                        style={{width:'30%'}}
                                        name="category_id" 
                                        value={this.state.category_id} 
                                        as="select" 
                                        size="sm"  
                                        onChange={(e)=>{ this.setState({category_id:e.target.value}) }}>
                                            <option value={0}></option>
                                            <option  value={1}>General</option>
                                            <option  value={2}>Admin</option>
                                        </Form.Control>
                                    </Form.Group>
                                    {this.state.idea.length < 3 ?  
                                     <Button    style={buttonStyle} 
                                                disabled 
                                                class='btn-primary' 
                                                variant="outline-primary" 
                                                type="button" 
                                                size="sm" >
                                        Share Idea
                                    </Button>:  
                                     <Button    className='btn-primary' 
                                                style={buttonStyle}  
                                                variant="outline-primary" 
                                                type="button" 
                                                size="sm" 
                                                onClick={this.onAddIdea} >
                                        Share Idea
                                    </Button>}
                                 
                                </Form>
                            </div>
                            <br/>
                            <div className="aha-privacy-policy-one-line">
                                You agree to our
                        <a style={{   color:'#2a3382'}} href="https://www.aha.io/legal/terms_of_service" target="_blank">
                                    Terms of service
                        </a> by using the ideas portal. The application will send you email
                        updates on ideas you have submitted. You can opt-out from the emails
                        at any time. Read our
                        <a style={{   color:'#2a3382'}}href="https://www.aha.io/legal/privacy_policy" target="_blank">
                                    Privacy policy
                        </a> to learn more.
                        </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
const mapStateToProps = ({firebase}) => {
    const { auth, profile } =firebase;
    return {
        auth, 
        profile 
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    signOut:() => dispatch (signOut()),
    addIdea:(data) => dispatch (addIdea(data)),
    onFilterByCategory:(isFilter,category)=>dispatch(onFilterByCategory()),
    uploadFile:(file) => dispatch(uploadFile(file))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(AddNewIdea);