import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import ResultsPersonalTableRow from './ResultsPersonalTableRow';
import { firestoreConnect } from 'react-redux-firebase';

class ResultsPersonalTable extends Component {
    render() {
        const { resultsPersonal } = this.props;
        const resultPersonalMappedArray = [];

        for (var key in resultsPersonal) {
            resultPersonalMappedArray.push(resultsPersonal[key]);
        };

        resultPersonalMappedArray.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.createdAt.seconds) - new Date(a.createdAt.seconds);
        });

        const template = resultPersonalMappedArray.map((activities, index) => {
            const date = new Date(activities.createdAt.seconds * 1000);
            const dataRow = {
                activities,
                date
            }

            return (
                <ResultsPersonalTableRow data={dataRow} key={index} />
            );
        })

        return (
            <div id="main" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 back-nav">
                            <Link to="/dashboard" className="goto_back" ><i></i>Back</Link>
                        </div>
                        <div className="form-padding-box padding-box data-tablebox">
                            <div className="form-wrap box-wrap" style={{ borderRadius: 5 }}>
                                <div className="small-title">View Results</div>
                                <div className="table-holder">
                                    <table className="data-table" style={{ marginTop: `20px` }}>
                                        <thead>
                                            <tr>
                                                <th>Group ID</th>
                                                <th>Question</th>
                                                <th>Image</th>
                                                <th>Date</th>
                                                <th>Option</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {template}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = ({ firebase: { auth }, firestore: { ordered } }) => {
    return {
        auth,
        resultsPersonal: ordered.activities
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
        {
            collection: 'activities',
            where: [['user_id', '==', props.auth.uid]],
            orderBy: ["createdAt", "desc"]
        }
    ])
)(ResultsPersonalTable)
