import {
    GET_USER_SUCCESS,
    GET_USER_ERROR,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    GET_USER_WITH_EMAIL_SUCCESS,
    GET_USER_WITH_EMAIL_ERROR,
    FETCH_USERS
} from '@app/action-types';

export const getAllUsers =()=> {
    return async(dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();
        const userDoc= await firestore
                            .collection("users")
                            .get()

        const users= userDoc.docs
                            .map((user) =>( {...user.data(),id:user.id}))
        dispatch({
            type:FETCH_USERS,
            payload:users
        })
    }
}

export const getUser = uid => {

    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();
        firestore.get(
            { collection: 'users', doc: uid }
        ).then((res) => {
            dispatch({ type: GET_USER_SUCCESS, res: res.exists ? res.data() : null});
        }).catch((err) => {
            dispatch({ type: GET_USER_ERROR, err });
        });
    }
}



export const getUserName = (uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const user = await firestore
      .collection("users")
      .doc(uid)
      .get();
    return user.data();
  };
};


export const updateUserName = (userData) => {

  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const user = await firestore
      .collection("users")
      .doc(userData.uid)
      .update({
        name: userData.name,
        reminder_type: userData.reminder_type,
        reminder_frequency: userData.reminder_frequency
      });
  };
};

export const updateSubscription = (userData) => {

    //const firestore = getFirestore();
    //console.log(firestore);

  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    
    console.log("FS connected");

    const user = await firestore
      .collection("users")
      .doc(userData.uid)
      .update({
        sub_type: userData.sub_type,
        sub_period: userData.sub_period
      });
  };
};

export const updateUserEmail = (userData) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const user = await firestore
      .collection("users")
      .doc(userData.uid)
      .update({
        email: userData.email
      });
  };
};



// export const updateUserName = user => {
//     return (dispatch, getState, { getFirestore }) => {
//         const firestore = getFirestore();
//         firestore.set({ collection: 'users', doc: user.uid }, {
//                 email: user.email,
//                 name: user.name,
//                 // phone: user.phone,
//                 // org: user.organization,
//                 // state: user.state,
//                 // city: user.city,
//                 user_type: user.user_type,
//                 accept_occasion: user.accept_occasion,
//         }).then((res) => {
//             dispatch({ type: UPDATE_USER_SUCCESS, res });
//         }).catch((err) => {
//             dispatch({ type: UPDATE_USER_ERROR, err });
//         });
//     }
// }


export const getUserWithEmail = email => async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    try {
        const querySnapshot = await firestore.collection("users").where("email", "==", email).get();
        const user = await querySnapshot.docs.map(doc => doc.data());
        dispatch({ type: GET_USER_WITH_EMAIL_SUCCESS, res: user });
    } catch (err) {
        dispatch({ type: GET_USER_WITH_EMAIL_ERROR, err });
    }
};

export const updateUser = user => {
    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();
        firestore.update({ collection: 'users', doc: user.uid }, {
                email: user.email,
                name: user.name,
                user_type: user.user_type,
                affiliate_partner_code: user.affiliate_partner_code,
                accept_occasion: user.accept_occasion,
        }).then((res) => {
            dispatch({ type: UPDATE_USER_SUCCESS });
        }).catch((err) => {
            dispatch({ type: UPDATE_USER_ERROR, err });
        });
    }
}

export const deleteUser = uid => {
    return (dispatch, getState, { getFirebase ,getFirestore }) => {
        const firestore = getFirestore();
        firestore.delete({ collection: 'users', doc: uid })
        .then((res) => {
            dispatch({ type: DELETE_USER_SUCCESS });
        }).catch((err) => {
            dispatch({ type: DELETE_USER_ERROR, err });
        });
    }
}
