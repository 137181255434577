import React,{useState} from 'react'
import { withRouter } from "react-router"
import IdeasList from './IdeasList'
import { Col, Tab, Tabs} from 'react-bootstrap'
import { IoSearch } from "react-icons/io5"
import { onChangeTab ,onSearchedData} from "@actions/ideas"
import { connect } from "react-redux";
import { ImCross } from 'react-icons/im'
import "./sparqFeatureRequest.css"
 const  Ideas=(props)=> {
  const [ key , setKey ] = useState('trending')
  const [isSearch, setSearch] =useState(false)
  const [value, setValue] =useState('')
 const onSearch =(e) =>{
    if(e.keyCode == 13){
        setSearch(true)
        props.onSearchedData(e.target.value)
    }
 }
  return (
    <Col xs={8} className="rightContent">
    <div>
        <div className="search-field">
            <IoSearch className="iconStyle"/>
            <input type="text" value={value} onChange={(e)=>{setValue(e.target.value)}} onKeyDown={onSearch} name="search" placeholder="Search ideas" />
            {isSearch?<ImCross className="crosStyle" onClick={()=>{ props.onSearchedData(false);setSearch(false);setValue('')}}/>:null}
        </div>
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            className="nav-item"
             onSelect={(k) => {
                props.onChangeTab(k)
                setTimeout(() => {
                    setKey(k)
                }, 1000);
            }}
            >
            <Tab eventKey="recent" title="Recent" >
            <IdeasList/>
            </Tab>
            <Tab eventKey="trending" title="Trending">
            <IdeasList/>
            </Tab>
            <Tab eventKey="popular" title="Popular">
            <IdeasList/>
            </Tab>
        </Tabs>
        <p style={{position: "absolute", right: "5%"}}>Idea management by Aha!</p>
    </div>
</Col>
  )
}
const mapDispatchToProps = (dispatch) => ({
    onChangeTab:(k)=>dispatch(onChangeTab(k)),
    onSearchedData:(value)=>dispatch(onSearchedData(value))
  });
  
  export default connect(null, mapDispatchToProps)(withRouter( Ideas));
  