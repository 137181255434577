import React from 'react' 
import Modal from 'react-responsive-modal';

import './styles.css';

const PrivacyModal = (props) => {
    return (
        <Modal open={props.privacyModalOpen} onClose={props.togglePrivacyModal} center>
            <div className="modal-container">
                <div className='modal-header'>
                    <h1><strong>Privacy Policy</strong></h1>
                </div>
                <div className='modal-body'>
                    <p>
                        This is the Privacy Policy for the Sparq, 
                        1 Leadership Place, Greensboro, NC 27410, USA and its global entities or branches.
                    </p>

                    <h1>Introduction</h1>

                    <p>
                        The Sparq is committed to respecting and protecting the privacy of our constituents. 
                        Sparq subscribes to the American Psychological Association’s code of ethics regarding the use of an individual’s personal 
                        data for research; this code is particularly concerned with protecting the rights of the person. Furthermore Sparq is 
                        committed to acting in compliance with current data protection legislation including the European Union’s General Data 
                        Protection Regulation (EU) 2016/ 679 (GDPR).
                    </p>

                    <p>
                        Sparq is based in the United States and maintains its servers in the United States. If you are visiting from the regions 
                        outside the United States with laws governing data collection and use, please note that by using our site and services your 
                        personal information will be transferred to the United States for processing.
                    </p>

                    <p>
                        This Privacy Policy sets out how we handle data, including how we collect, store and use personal data, our legal basis for 
                        processing your personal data, information on transfers to third parties and international transfers, as well as your rights 
                        as a data subject. This version has been updated to reflect the strengthened rights of individuals under GDPR, to make it easier 
                        to understand and to provide more fairness and transparency to you by making additional information available. There is a Contents 
                        section at the beginning with quick links to help you navigate through the Privacy Policy and find information more quickly and easily.
                    </p>

                    <p>This Privacy Policy comprises the following:</p>

                    <p>
                        <ul>
                            <li><a href="#_Sec1">1. Who we are and other important information</a></li>
                            <li><a href="#_Sec2">2. What personal data do we collect and how do we use it?</a></li>
                            <li><a href="#_Sec3">3. How and why we use your personal data</a></li>
                            <li><a href="#_Sec4">4. Who we transfer your personal data to</a></li>
                            <li><a href="#_Sec5">5. International transfers of personal data</a></li>
                            <li><a href="#_Sec6">6. How we keep your data secure</a></li>
                            <li><a href="#_Sec7">7. Data retention</a></li>
                            <li><a href="#_Sec8">8. Your legal rights</a></li>
                            <li><a href="#_Sec9">9. Exercising your rights</a></li>
                            <li><a href="#_Sec10">10. How you can contact us</a></li>
                            <li><a href="#_Sec11">11. California’s Shine the Light Law</a></li>
                            <li><a href="#_Sec12">12. Governing law</a></li>
                            <li><a href="#_Sec13">13. Status of this Privacy Policy and changes</a></li>
                            <li><a href="#_Sec14">14. European Union Data Protection Authorities</a></li>
                        </ul>
                    </p>

                    <div id="text-block-3">
                        <h2 id="_Sec1" className="linkedAnchors">1. Who we are and other important information</h2>
                        <p>To provide its leadership and research products and services, Sparq collects and processes personal data. This Privacy Policy sets out how we collect and process personal data through use of our products and services, use of our website, completion of our psychometric assessments, and other collection of personal data related to our business as provider of leadership and research services.</p>
                        <p>This Privacy Policy also covers all the entities and branch offices of Sparq. When we refer to “we,” “us,” and “our” we mean Sparq, our branch offices, and entities, as the controller or processor of data, as applicable.</p>
                        <p>We have appointed a Data Protection Officer (DPO) who is responsible for overseeing data protection matters. Please visit <a href="#_Sec10">section 10</a> for complete details on how you can contact Sparq and the DPO. Our DPO is supported by a multi-functional data protection team and can be contacted via email at <a href="mailto:privacy@Sparq.org">privacy@Sparq.org</a>.</p>
                    </div>

                    <div id="text-block-4">
                        <h2 id="_Sec2" className="linkedAnchors">2. What personal data do we collect and how do we use it?</h2>
                        <p><strong>2.1 When visiting our internet sites</strong><br />
                        If you visit our internet sites, we automatically collect the related session data. Session data is provided to us by your browser, by third-party integrations on our sites, and through our log files, which record your activities while browsing our sites, such as when you click on a link. We may record some of this data in one or more cookies that we send to your browser (<a target="_blank" rel="noopener noreferrer" href="https://www.Sparq.org/cookie-policy">see our Cookie Policy</a>).</p>
                        <p>Simply visiting Sparq’s websites does not require you to submit personal data (although our cookies may collect certain personal data for statistical and analytical purposes). If, however, you ask us for information, register with us, sign up to attend any of our events or receive our marketing material, or otherwise express an interest in our products or services or report a problem, we collect any personal data submitted to us at that time.</p>
                        <p>This site makes chat rooms, forums, message boards, and/or news groups available to some of its users. Users should exercise caution when deciding to disclose personal information in public forums. To request removal of personal information from Sparq’s blog or community forum, contact Sparq at <a href="mailto:unsubscribe@Sparq.org">unsubscribe@Sparq.org</a>. In any case where the removal of personal information is not possible, the requestor will be informed.</p>
                        <p>With their consent, Sparq displays personal testimonials of satisfied customers on its site, in addition to other endorsements. With your consent, your testimonial along with your name may be posted. Any client or data subject wishing to update or delete a testimonial should contact Sparq at <a rel="noopener noreferrer" target="_blank" href="https://www.Sparq.org/contact-center-creative-leadership/">Client Services</a>.</p>
                        <p>Sparq uses Google Analytics to track how often people gain access to or read our content. We use this information in the aggregate to understand what content our members find useful or interesting, so we can produce the most valuable content to meet your needs. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purposes of evaluating your use of the website, compiling reports on website activity for website operators, and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser; however, please note that if you do this you may not be able to use the full functionality of this website. By using the Sparq website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>
                        <p>When you visit our websites and access resources on our websites, we may automatically collect technical data and usage data. We collect this data via cookies including, where available, your IP address, operating system and browser type, for system administration. Our <a target="_blank" rel="noopener noreferrer" href="https://www.Sparq.org/cookie-policy">Cookie Policy</a>&nbsp;has more information on this. You can manage your Cookies via our Cookie Policy by clicking on Cookie Settings button under the title “Cookie Policy”.</p>

                    </div>
                    <div id="text-block-5">
                        <p><strong>2.2 When making a purchase from our website or bookstore</strong><br />
                        Sparq collects users’ contact information (such as name and email address) and financial information (such as account or credit card number). Sparq uses contact information from the order form to send orders to its customers. Contact information is also used to get in touch with customers when necessary.</p>
                    </div>

		    <div id="text-block-6">
			<p><strong>2.3 When registering or creating an account</strong><br />
			If you register or create an account, we require that you provide certain personal information during account registration. We collect your name, contact information, and other information, and may ask you for other optional information that helps us serve you better.</p>
			<p>You may wish to subscribe to the Sparq’s publications without attending a program. For instance, you may sign up to receive Sparq’s newsletters even though you are not a Sparq participant. To receive Sparq newsletters by email, you will be asked to provide Sparq with a valid email address.</p>
			<p>Sparq does not share this information with any third party other than to store the information in our cloud-hosted databases. We rely on a contract basis to process your personal information for purposes of fulfilling your request to receive our publications. You may, at your own option, choose to subscribe to Sparq news and updates, which may be considered direct marketing.</p>
			<p>You may manage your Sparq subscriptions by subscribing or unsubscribing at any time. Please visit our <a target="_blank" rel="noopener noreferrer" href="https://www.Sparq.org/preference-center/">Email Preference Center</a> to manage your subscriptions.</p>
		    </div>

		    <div id="text-block-7">
			<p><strong>2.4 When attending a program or completing an assessment</strong><br />
			If a participant completes an assessment, we collect the participant’s name, email address, contact information, assessment responses, session data, and other information you may choose to provide or associate with your account. In some cases, we ask questions to which the response is optional (such as demographic questions), and these questions are identified as such. In some instances, these optional demographic questions may involve collection of special categories of personal information, such as information regarding your racial or ethnic origin. You are free not to respond to these optional questions. For processing involving special categories of data, our legal basis for processing is your explicit, informed consent, which will be asked separately. &nbsp;</p>
			<p>Data will be collected directly from you when you complete an assessment questionnaire via Sparq platforms or otherwise. Data may also be collected from your employer. Where feedback is given and/ or where you participate in an assessment or leadership program or other engagement run by Sparq, additional data to that set out above may be provided by you to the coach and/or employer or our delivery team respectively, at the time of such feedback, assessment, or engagement.</p>
		    </div>

		    <div id="text-block-8">
			<p><strong>2.5 When you choose to participate in research</strong><br />
			As part of its activities, Sparq also conducts original scientific research in the field of leadership development. Since the organizations and people who participate in our training programs and other activities represent valuable sources for research data, the information collected from them is securely stored in Sparq databases and may become a part of a research project. Sparq has a strong commitment to protect the rights, privacy, and dignity of every person who participates in these activities. For this reason there are a number of safeguards in place:</p>
			<ul>
			    <li>Sparq is guided by the American Psychological Association’s code of ethics regarding the use of an individual’s personal data for research; this code is particularly concerned with protecting the rights of the person.</li>
			    <li>All data utilized for research is obtained following the informed and/or explicit consent of the individual participant.</li>
			    <li>Questions related to race/ethnicity or family history are for U.S. research and reporting purposes. U.S. law encourages us to collect information on the racial demographics of our clients. Other legal jurisdictions outside of the United States may not permit the collection of such information. Therefore, answer race/ethnicity or family history questions only if you reside in the United States.</li>
			    <li>Assessment data collected about or from an individual as part of a leadership development activity are shared with and explained to that individual.</li>
			    <li>Information collected about or from an individual is considered confidential. Individuals receive feedback on assessments only from qualified Feedback Coaches.&nbsp;Any further disclosure or sharing shall be possible only pending the individual’s consent.</li>
			    <li>Although Sparq has never been required to release information without an individual’s permission (or the permission of an authorized legal representative such as an executor, executrix, or the holder of a valid power of attorney), it is acknowledged that in the extremely rare case of a court order, Sparq might be compelled to do so.</li>
			    <li>Data collected from surveys published by Sparq are stored in its secure database. They become a part of the norm group, containing thousands of scores, against which individual scores are calibrated. Periodically, Sparq publishes data on groups of people. Individuals are never identified in these reports. Specific organizations are identified only if prior written approval is received from the organization. Sparq may also provide organization summary profiles on groups of individuals in that organization if the number of individuals is large enough to be potentially meaningful.</li>
			    <li>If it is desired, response data can be removed from Sparq’s research database. Send an email to <a href="mailto:privacy@Sparq.org">privacy@Sparq.org</a>&nbsp;requesting that your data be removed.&nbsp;Once the data is removed, Sparq will not be able to provide a duplicate feedback report at a later date or use that data in any group profiles.</li>
			</ul>
		    </div>

		    <div id="text-block-9">
			<p><strong>2.6 When you choose to participate in a survey</strong><br />
			From time to time, Sparq may post online surveys to ask visitors for opinions on leadership, Sparq services, or visitors’ needs. This information will be used internally to provide better service to Sparq clients. Any customer contact information collected would only be used to contact the visitor if necessary. Demographic and profile data may also be collected as the need arises, and would be used for internal reporting only.</p>
			<p>If you choose to participate in one of our surveys, we may collect personal information such as your name, email address, and any other personal information that you may provide in your survey responses. Participation in surveys is optional, and we give you the ability to opt out of being contacted for surveys at any time.</p>
		    </div>

		    <div id="text-block-10">
			<p><strong>2.7 When you choose to receive marketing communications</strong><br />
			If you sign up to receive our marketing communications, we may collect information on the open rate of the communications, and whether a specific individual has clicked on a link contained in a particular communication.</p>
			<p>Sparq also uses a third-party marketing database management program that deploys a cookie when a user interacts with a marketing communication, such as a marketing email or a marketing-based landing page on our website. This cookie collects personal information such as your name, which pages you visit on the Sparq website, your history arriving at the Sparq website, your purchases from the Sparq website, and the like. We use this information to evaluate the effectiveness of our marketing campaigns. You may set your browser to block these cookies. (<a href="/cookie-policy/">See our Cookie Policy</a>.)</p>
		    </div>

		    <div id="text-block-11">
			<p><strong>2.8 When you are a customer or other business contact</strong><br />
			If you are a customer or other business contact, or if you are an employee or agent of a customer or business contact, we may collect your name, email address, telephone number, and other contact information in the regular course of our interaction with you.</p>
			<p>Data will be collected:</p>
			<ul>
			    <li>Prior to, at commencement, and during the term of a contractual relationship when you request our products and services</li>
			    <li>When you complete forms on our site or for our products and services, including registering to use our site, subscribing to our services, posting material, or using further services</li>
			    <li>When you enter a contest or promotion sponsored by us</li>
			    <li>When you contact us or report a problem to us, or provide feedback to us, or complete a survey</li>
			    <li>When you transact with us through our website or when you place orders with us over the phone or by email</li>
			</ul>
		    </div>

		    <div id="text-block-12">
			<p><strong>2.9 When you interact with third parties regarding our products or services</strong><br />
			We may receive personal information about you from third parties, such as from customers, websites where we advertise, business partners, and service providers. Some of this information pertains to a specific individual; other information can only be linked to an access point or a device.</p>
			<p>We may also collect personal data about you from third parties or publicly available sources including:</p>
			<ul>
			    <li>Analytics providers (such as Google)</li>
			    <li>Event organizers or online social websites (such as LinkedIn)</li>
			    <li>Tailored database providers (such as IBM Watson)</li>
			</ul>
		    </div>

		    <div id="text-block-13">
			<p><strong>2.10 When you choose to apply for employment with Sparq</strong><br />
			If you choose to apply for an open position of employment, we may collect personal data such as your name, email address, and any other personal information that you may provide in conjunction with your application. We use this information only to consider you as a candidate for the position for which you are applying and to contact you in this respect.</p>
			<p>Data will be collected from employment candidates at the time of applying to us for employment either online or by post or via an agency, and during any subsequent interactions as part of the recruitment process.</p>
		    </div>

		    <div id="text-block-14">
		    <p><strong>2.11 When you fail to provide personal data</strong><br />
			Where we need to collect personal data by law or under the terms of a contractual arrangement, and you fail to provide that data when requested as being mandatory, we may not be able to fulfil the terms of the contract or relationship that we have with you.</p>
		    </div>

		    <div id="text-block-15" className="mk-text-block   ">
			<h2 id="_Sec3" className="linkedAnchors">3. How and why we use your personal data</h2>
			<p>This section explains how we use your personal data and how you may opt out of marketing communications and how you can manage cookies. It includes the legal basis on which we rely to process your data.</p>
			<p><strong>3.1 Customers and suppliers (including individual contractors) and other business contacts</strong></p>
			<div className="indent">
			    <p><strong>3.1.1 Personal data of customers (including identity, contact, technical, usage, and profile data) will be used</strong></p>
			    <ul>
				<li>To provide you with products and services that you request from us</li>
				<li>To manage our contractual relationship on an ongoing basis</li>
				<li>For customer administration including carrying out our obligations arising from any contracts entered into between you and us and including retention of correspondence if you contact us</li>
				<li>For us to form a view on what we think you may want or need, or what products, services, or offers may be of interest to you (referred to as marketing) in order to provide you with information about our other products and services in which you may be interested, including our catalogue and our regular newsletter, and, where relevant, information on organizations that are authorized to offer Sparq products and services, where you have not opted out to be contacted for such purposes</li>
				<li>To personalize our service to you, including ensuring that content from our site is presented in the most effective manner for you and your computer</li>
				<li>To seek your views on products and services</li>
				<li>To enable you to participate in interactive features of our service, when you choose to do so, including live chat features</li>
				<li>For technical administration of our sites, including notifying you about changes to our service</li>
				<li>With further information on our products and services</li>
				</ul>
				<p><strong>3.1.2 Personal data of suppliers and other business contacts (including identity, contact, technical, usage, and profile data) will be used</strong></p>
				<ul>
				<li>To receive products and services</li>
				<li>To manage our contractual relationship on an ongoing basis</li>
				<li>For supplier administration, including carrying out our obligations arising from any contracts entered into between you and us and including retention of correspondence if you contact us</li>
				<li>To provide updates and news about our products and services, as such may be relevant to the services you provide.</li>
			    </ul>
			    <p>You can manage your marketing and other contact preferences through our <a target="_blank" rel="noopener noreferrer" href="https://www.Sparq.org/preference-center/">Email Preference Center page</a>. You will receive marketing communications from us if you have requested such communication, purchased products or services, or if you provided us or one of our service providers with your details and have not opted out of receiving marketing communications. Where you opt out of receiving marketing messages, we may need to communicate with you for administrative or operational reasons; therefore, while you use our products and services and continue to wish to do so, it is not possible to opt out of all communications with us, and therefore an opt out may not apply to personal data provided to us as a result of purchase of products or services or other associated activities or transactions.</p>
			</div>
			<p><strong>3.2 Participants</strong><br />
			Personal data collected from participants taking our assessment questionnaires will be used:</p>
			<ul>
			    <li>To provide, on request from coaches, personalized computer-generated reports from completion by participants of our assessment questionnaires, including via our web-based scoring and delivery platform or otherwise. Sometimes we may combine participant data with that of other participants (for example, to create aggregated team reports).</li>
			    <li>Where feedback is given, for the purposes of the feedback session between the participant and coach</li>
			    <li>Where you participate in an assessment or leadership program or other consultancy engagement run by our leadership team, additional data provided by you may be used for the purposes of provision of our services</li>
			    <li>For research and product development purposes. Such data is collected through completion of our assessment questionnaires, including via online platforms or otherwise, for research and product development purposes in order to produce statistics of the type described in our product manuals, e.g. psychometric norms and validated data. You may be asked, as part of the testing process, to give your consent to the use of your personal data for research and product development purposes, and to answer additional optional research questions. If you decline from doing so, no data is collected or used for research and product development purposes.</li>
			</ul>
			<p>If you agree to it, you will be asked to give your consent for the processing for research purposes as well as for the processing of all or any special categories of personal data where applicable.</p>
			<p><strong>3.3 Visitors to the Sparq website</strong><br />
			Personal data (including identity, contact, technical and usage data) will be used to form a view on what we think you may want or need, or what products, services, or offers may be of interest to you (referred to as marketing) in order to provide you with further information on our products and services. You can manage your marketing and other contact preferences through our <a target="_blank" rel="noopener noreferrer" href="https://www.Sparq.org/preference-center/">Email Preference Center page</a>. You will receive marketing communications from us if you have requested information from us or if you provided us or one of our service providers with your details and, in each case, you have not opted out of receiving marketing. Where you opt-out of receiving marketing messages, this will not apply to personal data provided subsequently if you then choose to purchase products or services, and in relation to other subsequent associated activities or transactions.</p>
			<p><strong>3.4 Employment candidates</strong><br />
			Employment candidate data will be used for the purposes of assessment of suitability to a role, as part of the employment process. If a candidate is then offered and accepts a position with Sparq, any data collected prior to commencement of employment will then be dealt with in accordance with the Sparq staff policies.</p>
			<p><strong>3.5 Aggregated data</strong><br />
			We also use aggregated data, including cookies data (<a href="/cookie-policy/">see our Cookie Policy</a>) and respondent data taken from the respondent responses to our assessments, and from the optional question responses completed prior to taking the questionnaires, for the purposes of research and product development. Aggregated data used within our research and product development functions is derived from your personal data, but no personal data will be published or disclosed, since it is aggregated and anonymized for the purposes of research and product development and therefore no person is identified or identifiable from such data.</p>
			<p><strong>3.6 Legal basis for processing data</strong><br />
			The purposes for which we use your data and their legal basis we rely to process your data are set below. We may process your personal data based on more than one legal basis depending on the specific purpose for which we are using each element of data. Further information can be provided on request. Where legitimate interest is the legal basis, we identify what our legitimate interests are below. Generally, we do not rely on consent as a legal basis for processing your personal data other than in relation to sending marketing communications to certain potential customers, where special category data is collected from respondents or when we invite participants to share results for research purposes. Where consent is used as the legal basis for processing, you may withdraw consent at any time (<a href="#_Sec8">see how in Section 8</a>).</p>

		    </div>

		    <div className="table-style">
		        <table>
			    <tbody>
				<tr style={{background: "#b7dde2"}}>
				    <td style={{ textAlign: 'center'}}><strong>Data subject</strong></td>
				    <td style={{ textAlign: 'center'}}><strong>Processing purpose</strong></td>
				    <td style={{ textAlign: 'center'}}><strong>Legal basis for processing</strong></td>
				</tr>
				<tr style={{verticalAlign: "top"}}>
				    <td style={{textAlign: "left"}}>Customers</td>
				    <td style={{textAlign: "left"}}>
					<ul>
					    <li>registration of customer and management of relationship</li>
					    <li>providing updates on our terms</li>
					    <li>providing information on our products and services</li>
					    <li>seeking feedback on our products and services</li>
					    <li>enabling customers to participate in surveys and competitions</li>
					    <li>delivering relevant website content and advertisements to customers</li>
					    <li>invoicing and managing payments, fees, and charges</li>
					    <li>contacting potential customers to ascertain interest in our products and services</li>
					</ul>
				    </td>
				    <td style={{textAlign: "left"}}>Legitimate Interest<p></p>
					<ul>
					    <li>to provide products and services to our customers</li>
					    <li>to provide our assessments and associated products to our customers’ personnel</li>
					    <li>to provide assessment or program services by our team to our customers’ personnel</li>
					    <li>to monitor emails, calls, and other communications and activities related to your account to study how customers use our products and services</li>
					    <li>to keep our records updated</li>
					    <li>for good governance, accounting, and managing and auditing our business operations</li>
					    <li>to send you marketing communications</li>
					    <li>to grow our business and to inform our marketing strategy</li>
					    <li>to conduct research and product development to improve our products and services for the benefit of our customers and others</li>
					    <li>Performance of a contract</li>
					    <li>To send you marketing communications to ascertain your interest in provision of our products and services to corporate customers</li>
					</ul>
					<p>Consent:</p>
					<ul>
					    <li>to send you marketing communications to ascertain your interest in our products and services to consumer customers</li>
					</ul>
				    </td>
				</tr>
				<tr style={{background: "#dddddd"}}>
				    <td style={{textAlign: "left"}}>Participants</td>
				    <td style={{textAlign: "left"}}>
					<ul>
					<li>registration of participant</li>
					<li>completion of our assessments</li>
					</ul>
				    </td>
				    <td style={{textAlign: "left"}}>Legitimate interests:<p></p>
					<ul>
					    <li>to provide assessment or program services by our team</li>
					    <li>to conduct product&nbsp;development to improve our products and services for the benefit of our customers and others</li>
					</ul>
					    <p>Performance of contract:</p>
					<ul>
					    <li>to provide our assessments and associated products to our customers’ personnel</li>
					</ul>
					    <p>Consent:</p>
					<ul>
					    <li>with respect to processing of optional special category data, to conduct research and product development to develop and improve our products and services</li>
					</ul>
				    </td>
				</tr>
				<tr>
				    <td style={{textAlign: "left"}}>Third Parties</td>
				    <td style={{textAlign: "left"}}>
					<ul>
					    <li>management of supplier relationship, including management of payments, fees and charges</li>
					</ul>
				    </td>
				    <td style={{textAlign: "left"}}>If a legal entity:<br />
					Legitimate interest:<p></p>
					<ul>
					<li>to receive services in order to conduct our business and provide products and services to customers</li>
					<li>to grow our business and to inform our marketing strategy</li>
					<li>for good governance, accounting, and managing and auditing our business operations</li>
					<li>to monitor emails, call and other communications and activities related to your account</li>
					<li>to keep our records updated</li>
					</ul>
					<p>If a natural person:<br />
					Performance of contract</p>
				    </td>
				</tr>
				<tr style={{background: "#dddddd"}}>
                                    <td style={{textAlign: "left"}}>Staff (EEA)</td>
                                    <td style={{textAlign: "left"}}>
                                        <ul>
                                            <li>Employee/Employer relationship</li>
                                        </ul>
                                    </td>
                                    <td style={{textAlign: "left"}}>
                                        Performance of contract with staff (relating to personal data generally)
                                        <p>Compliance with a legal obligation employer</p>
                                    </td>
				</tr>
				<tr>
                                    <td style={{textAlign: "left"}}>Employment Applicants</td>
                                    <td style={{textAlign: "left"}}>
                                        <ul>
                                            <li>Possible job offering</li>
                                        </ul>
                                    </td>
                                    <td style={{textAlign: "left"}}>Consent<p></p>
                                        <ul>
                                            <li>To provide employment</li>
                                        </ul>
                                        <p>Necessary to enter into an employment agreement:<br />
                                        In negotiation phase with selected candidates</p>
                                    </td>
				</tr>
			    </tbody>
			</table>

		    </div>
                    <div id="text-block-16">
                        <p><strong>3.7 Cookies</strong><br />
                        We use first-party cookies set by ourselves only. Please note, however, that our website may include links to third-party websites, plug-ins, and applications. These websites, plug-ins, and applications may use cookies over which we have no control. You may, however, restrict or block third-party cookies through your browser settings, and such blocking of cookies of third parties should not affect the functionality and use by you of our website. Learn more about cookies and how Sparq uses them by reading our <a  target="_blank" rel="noopener noreferrer" href="https://www.Sparq.org/cookie-policy">Cookies Policy</a>.</p>
                    </div>

                    <div id="text-block-17" >
                        <h2 id="_Sec4" className="linkedAnchors">4. Who we transfer your personal data to</h2>
                        <p>We may have to share your personal data with third parties for processing or sub-processing purposes. We undertake a selection process and periodic review in relation to processors and sub-processors. We enter into data processing agreements with both processors and controllers, as applicable.</p>
                        <p>Sparq does not rent or sell information to any outside organizations. Sparq will share your personal information with third parties only in the ways that are described in this privacy policy. Sparq also limits the information it collects to the minimum necessary to provide outstanding service, such as providing customer support or offering key products and services. All financial information given to Sparq is kept secure. Sparq has partnerships with third parties where basic contact information and data are shared to deliver contracted services and research with Sparq clients. These companies are authorized to use your personal information only as necessary to provide these services to us.</p>
                        <p>Sparq may also disclose personal information:</p>
                        <ul>
                            <li>As required by law, such as to comply with a subpoena or similar legal process</li>
                            <li>When it is believed in good faith that disclosure is necessary to protect our rights, protect others’ safety, investigate fraud, or respond to a government request</li>
                            <li>If Sparq is involved in a merger, acquisition, or sale of all or a portion of its assets, users will be notified via email and/or a prominent notice on our website of any change in ownership or uses of personal information, as well as any choices users may have regarding their personal information</li>
                            <li>To any other third party for whom users or clients have given prior consent</li>
                        </ul>
                    </div>

                    <div id="text-block-18" >
                        <h2 id="_Sec5" className="linkedAnchors">5. International transfers of personal data</h2>
                        <p>Sparq is a non-profit organization with branches and entities around the world; your personal data may be transferred to and stored outside the country where you are located. This includes countries outside the European Economic Area (EEA) and countries that do not have laws that provide specific protection for personal data. When we collect your personal data within the EEA, transfer outside the EEA will be only:</p>
                        <ul>
                            <li>to a recipient located in a country which provides an adequate level of protection for your personal data; and/or</li>
                            <li>under a contract with the recipient of the data, which means the recipient must protect the data to the same standards as required within the EEA, such as standard contractual clauses approved by the European Commission.</li>
                        </ul>
                        <p>The Sparq is organized in the United States of America as a 501(c)(3) nonprofit academic and research institution. Because of this nonprofit standing, Sparq may not participate in the EU US Privacy Shield Framework as set forth by the U.S. Department of Commerce.</p>
                    </div>

		    <div id="text-block-19">
			<h2 id="_Sec6" className="linkedAnchors">6. How we keep your data secure</h2>
			<p>We are committed to ensuring the security of processing and the ongoing confidentiality, integrity, availability, and resilience of systems and services, as such relate to personal data that we hold, in order to prevent accidental or unlawful destruction, loss, alteration, or unauthorized disclosure or access.</p>
			<p>In our roles as both controller and processor, we implement appropriate technical and organizational measures to ensure a level of information security appropriate to the risk. Our IT infrastructure and software applications are built to provide secure deployment of services, encrypted storage of back-up data with end-user privacy safeguards, encrypted communications between services, and safe operation by customers.</p>
			<p>Our staff, associates, and consultants are bound to comply with confidentiality provisions and our privacy policy, in addition to completing mandatory data protection training. We have various policies that specifically address responsibilities and expected behavior with respect to the protection of confidential information.</p>
			<p>We have procedures for incident and breach investigation and notification. Where our assessment of the likely risk to the individuals involved concludes that a breach of personal data may result in a high risk to the rights and freedoms of individuals, we shall promptly inform individuals (and associated controllers and the relevant supervisory authority where applicable) of any such breach, as required by law and in accordance with any contractual terms.</p>
			<p>Where we have given you (or where you have chosen) a username and/or password which enables you to access certain parts of our websites, or use our products and services, you are responsible for keeping the username and password confidential. You should not share these details with anyone.</p>
		    </div>
		    <div id="text-block-20">
			<h2 id="_Sec7" className="linkedAnchors">7. Data retention</h2>
		<p>We will retain your personal information on our systems only for as long as we need it, given the purposes for which it was collected, or as required to do so by law. Sparq will retain and use this personal information as necessary to comply with legal obligations, resolve disputes, and enforce our agreements.</p>
		    </div>

		    <div id="text-block-21">
			<h2 id="_Sec8" className="linkedAnchors">8. Your legal rights</h2>
			<p><strong>8.1 Request access to your personal data</strong><br />
			(Commonly known as a “subject access request” or “SAR”). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</p>
			<p><strong>8.2 Request rectification of the personal data that we hold about you</strong><br />
			This enables you to have any incomplete or inaccurate data we hold about you rectified, though we may need to verify the accuracy of the new data you provide to us.</p>
			<p><strong>8.3 Request erasure of your personal data</strong><br />
			This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully, or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</p>
			<p><strong>8.4 Object to processing of your personal data</strong><br />
			This enables you to object to processing where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</p>
			<p><strong>8.5 Request restriction of processing of your personal data</strong><br />
			This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data’s accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise, or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</p>
			<p><strong>8.6 Withdraw consent</strong><br />
			You may withdraw consent at any time where we are relying on consent as the legal basis on which we process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdrew your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</p>
			<p>Where we are a processor in respect of your personal data, we will inform the relevant controller of your request and assist and co-operate with the controller for them to fulfil the request.</p>
			<p><strong>8.7 Further information</strong><br />
			We may need to request specific information from you to help us confirm your identity or verify your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it.</p>
		    </div>
		    <div id="text-block-22">
			<h2 id="_Sec9" className="linkedAnchors">9. Exercising your rights</h2>
			<p>An individual who seeks access, or who seeks to correct, amend, or delete inaccurate data, should direct his query to us and/or the Data Controller. If the Client requests Sparq to remove the data, a response will be provided within one month of their request.</p>
		    </div>

		    <div id="text-block-23">
			<h2 id="_Sec10" className="linkedAnchors">10. How you can contact us</h2>
			<p>If you have a question about our processing or would like to exercise one of your rights set out above do not hesitate to contact our Data Protection Officer by email (<a href="mailto:privacy@Sparq.org">privacy@Sparq.org</a>) or by mail (DPO, Legal Department, rue Neerveld 101-103, 1200 Brussels, Belgium).</p>
			<p>When you send us an application relating to your rights, please provide us with your full contact details (name, surname, email address[es], full postal address).</p>
			<p>Also, note Sparq’s contact address in the USA:</p>
			<div className="indent">Sparq<br />
			1 Leadership Place<br />
			Greensboro, NC 27410, USA</div>
		    </div>
		    <div id="text-block-24">
			<h2 id="_Sec11" className="linkedAnchors">11. California’s Shine the Light Law</h2>
			<p>California Civil Code Section 1798.83, known as the “Shine the Light” law, permits users who are California residents to request and obtain from us a list of what personal information (if any) we disclosed to third parties for their direct marketing purposes in the preceding calendar year and the names and addresses of those third parties. Requests may be made only once a year and are free of charge. Under Section 1798.83, Sparq currently does not share any personal information with third parties for their own direct marketing purposes.</p>
		    </div>
		    <div id="text-block-25">
			<h2 id="_Sec12" className="linkedAnchors">12. Governing law</h2>
			<p>Without prejudice to the application of European or national data protection laws and regulations, the laws of the State of North Carolina and as applicable, those of the United States of America, govern the use of the Sparq Services. All claims brought in the United States will be subject to the jurisdiction of the courts of the State of North Carolina, without prejudice to your right to lodge a complaint with a competent data protection authority or with a competent court in the European Union for data protection–related matters.</p>
		    </div>
		    <div id="text-block-26">
			<h2 id="_Sec13" className="linkedAnchors">13. Status of this Privacy Policy and changes</h2>
			<p>Sparq may update this privacy policy to reflect changes to our information practices. If any material changes are made, users will be notified by email (sent to the e-mail address specified in their account) or by means of a notice on this Site prior to the change becoming effective. Users are encouraged to periodically review this page for the latest information on Sparq’s privacy practices.</p>
		    </div>
		    <div id="text-block-27">
			<h2 id="_Sec14" className="linkedAnchors">14. European Union Data Protection Authorities</h2>
			<ul>
			    <li>Ireland [<a href="http://www.dataprotection.ie/">website</a>]</li>
			    <li>European Data Protection Supervisor (regulates EU institutions) [<a href="http://www.edps.europa.eu/EDPSweb">website</a>]</li>
			    <li>Austria [<a href="http://www.dsb.gv.at/">website</a>]</li>
			    <li>Belgium [<a href="http://www.privacycommission.be/">website</a>]</li>
			    <li>Bulgaria [<a href="http://www.cpdp.bg/">website</a>]</li>
			    <li>Croatia [<a href="http://www.azop.hr/">website</a>]</li>
			    <li>Cyprus [<a href="http://www.dataprotection.gov.cy/">website</a>]</li>
			    <li>Czech Republic [<a href="http://www.uoou.cz/">website</a>]</li>
			    <li>Denmark [<a href="http://www.datatilsynet.dk/">website</a>]</li>
			    <li>Estonia [<a href="http://www.aki.ee/">website</a>]</li>
			    <li>Finland [<a href="http://www.tietosuoja.fi/">website</a>]</li>
			    <li>France [<a href="http://www.cnil.fr/">website</a>]</li>
			    <li>Germany [<a href="http://www.bfdi.bund.de/">website</a>]
			    <ul>
			    <li>List of regional data protection authorities in Germany [<a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">website</a>]</li>
			    </ul>
			    </li>
			    <li>Greece [<a href="http://www.dpa.gr/">website</a>]</li>
			    <li>Hungary [<a href="http://www.naih.hu/">website</a>]</li>
			    <li>Italy [<a href="http://www.garanteprivacy.it/">website</a>]</li>
			    <li>Latvia [<a href="http://www.dvi.gov.lv/">website</a>]</li>
			    <li>Lithuania [<a href="http://www.ada.lt/">website</a>]</li>
			    <li>Luxembourg [<a href="http://www.cnpd.lu/">website</a>]</li>
			    <li>Malta [<a href="http://www.dataprotection.gov.mt/">website</a>]</li>
			    <li>Netherlands [<a href="http://www.autoriteitpersoonsgegevens.nl/nl">website</a>]</li>
			    <li>Poland [<a href="http://www.giodo.gov.pl/">website</a>]</li>
			    <li>Portugal [<a href="http://www.cnpd.pt/">website</a>]</li>
			    <li>Romania [<a href="http://www.dataprotection.ro/">website</a>]</li>
			    <li>Slovakia [<a href="http://www.dataprotection.gov.sk/">website</a>]</li>
			    <li>Slovenia [<a href="http://www.ip-rs.si/">website</a>]</li>
			    <li>Spain [<a href="http://www.agpd.es/">website</a>]</li>
			    <li>Sweden [<a href="http://www.datainspektionen.se/">website</a>]</li>
			    <li>United Kingdom [<a href="http://www.ico.org.uk/">website</a>]</li>
			</ul>
		    </div>
                </div>
            </div>
        </Modal>
    )
}

export default PrivacyModal; 
