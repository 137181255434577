import React from 'react';
import moment from "moment"

const Footer = () => {
    return (
        <footer className="footer" >
            <div className="col-md-12 col-12">
                <p>
                    © COPYRIGHT {moment().format("YYYY")}, SPARQ. ALL RIGHTS RESERVED. - <a target="_blank" href="https://www.iubenda.com/privacy-policy/43247323">PRIVACY POLICY</a> - <a target="_blank" href="https://www.iubenda.com/privacy-policy/43247323/cookie-policy">COOKIE POLICY</a>
                </p>
            </div> 
            <div className="clearboth"></div>
        </footer>
    )
}

export default Footer;
