import {
    GET_USER_SUCCESS,
    GET_USER_ERROR,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    GET_USER_WITH_EMAIL_SUCCESS,
    GET_USER_WITH_EMAIL_ERROR,
    FETCH_USERS
} from '@app/action-types';

const initialState = {
    users:[],
    Error: null,    
    userExist: [] 
};

const userReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case FETCH_USERS:
            return {
                ...state,
                users: action.payload,
            }
        case GET_USER_SUCCESS:
            return {
                ...state,
                Error: null,
            }
        case GET_USER_ERROR:
            return {
                ...state,
                Error: action.err,
            }
        case GET_USER_WITH_EMAIL_SUCCESS:
            return {
                ...state,
                userExist: action.res,
                Error: null,
            }
        case GET_USER_WITH_EMAIL_ERROR:
            return {
                ...state,
                userExist: null, 
                Error: action.err,
            }
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                Error: null,
            }
        case UPDATE_USER_ERROR:
            return {
                ...state,
                Error: action.err,
            }
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                Error: null,
            }
        case DELETE_USER_ERROR:
            return {
                ...state,
                Error: action.err,
            }
        default:
            return state;
    }
};

export default userReducer; 
