import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import Uploader from "../../admin/Uploader";
import "./style.css";

const $ = window.$;
const GridScrollFx = window.GridScrollFx;

class SavedImages extends React.Component {

  constructor(props) {
    super(props);
    const { location } = props;

    const path = location.pathname.split("/");

    this.loadedCount = 0;
    this.state = {
      imagesToSave: [],
      selectedQuestion: "",
      path: path[1],
      modal: false,

      question_count: 1,
      question_total: 1
    };
  }

  componentDidMount() {
    const accessLocalStorageImage = JSON.parse(
      localStorage.getItem("imagesData")
    );
    const getImage = localStorage.getItem("UploadedImage");
    if (getImage) {
      var joined = accessLocalStorageImage.concat({ image_url: getImage });
      this.setState({ imagesToSave: joined });
    } else {
      this.setState({ imagesToSave: accessLocalStorageImage });
    }



    // hide image upload
    if (localStorage.getItem("group") != null) {
      if (JSON.parse(window.localStorage.getItem('group'))[0].disable_upload && JSON.parse(window.localStorage.getItem('group'))[0].disable_upload === "true") {
        window.$('.image_upload_allow').remove();
      }
    }

  }

  componentWillMount() {

    if (localStorage.getItem('question_count')) {
      this.setState({
        question_count: localStorage.getItem('question_count')
      });

    }

    if (localStorage.getItem('question_total')) {
      this.setState({
        question_total: localStorage.getItem('question_total')
      });
    }

  }

  componentWillUnmount() {
    $(".nicescroll-rails").remove();
  }

  onImgLoad(length) {
    this.loadedCount++;
    if (this.loadedCount === length) {
      $("#grid li").each(function () {
        if (!$(this).hasClass("shown")) {
          $(this).addClass("shown");
        }
      });

      if (GridScrollFx && document.getElementById("grid")) {
        new GridScrollFx(document.getElementById("grid"), {
          viewportFactor: 0,
        });

        setTimeout(() => {
          $(".gallery-scroll").niceScroll({
            cursorcolor: "#888888",
            cursorwidth: "8px",
            background: "#d8d8d8",
            cursorborder: "1px solid #d8d8d8",
            autohidemode: false,
          });
        }, 200);
      }
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const { uid } = this.props;
    const modal = (
      <Fragment>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Upload Images</ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            <Uploader toggle={this.toggle} uid={uid} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </Fragment>
    );
    const { question_key } = this.props.match.params;
    const imageGrid = this.state.imagesToSave.map((image, index) => {
      return (
        <li key={index} className="shown">
          <Link
            to={{
              pathname: `/${this.state.path}/${question_key}/final-image`,
              state: { image, question_count: this.state.question_count, question_total: this.state.question_total },
            }}
          >
            <img
              onLoad={this.onImgLoad.bind(this, this.state.imagesToSave.length)}
              src={image.image_url}
              alt="Post Card"
            />
          </Link>
        </li>
      );
    });

    return (
      <div id="main" className="image-selection">
        <div className="container v-gallery">
          <div className="row">
            <div className="col-md-12 back-nav">
              <Link
                to={`/${this.state.path}/question/${question_key}/images/`}
                className="goto_back"
              >
                <i></i>Back
              </Link>
            </div>
            <div className="progress-steps">
              <span className="step step-1 active"></span>
              <span className="step step-2 active"></span>
              <span className="step step-3 active"></span>
              <span className="step step-4"></span>
              <span className="step step-5"></span>
            </div>
            <div className="col-md-12 images-box">
              <div className="images-wrap" style={{ borderRadius: 5 }}>
                <div className="mb-3 font-weight-bold text-center">Question {this.state.question_count} of {this.state.question_total}</div>

                <div className="small-title">
                  PLEASE SELECT A FINAL IMAGE FROM YOUR SAVED LIST:
                </div>
                <div className="guidingText small-title image_upload_allow">
                  You can Upload image if you don't find the one you like.
                </div>
                <div className="small-title image_upload_allow">
                  <button
                    onClick={this.toggle}
                    className="btn btn-info"
                    style={{ backgroundColor: "#2a3382", border: "none" }}
                  >
                    Upload Image
                  </button>
                  {modal}
                </div>
                <form action="final-image.html" method="post">
                  <div className="view-gallery saved-gallery gallery-scroll saved-images-scroll">
                    <section className="grid-wrap saved-images-section">
                      <ul
                        className="grid swipe-down loaded saved-images-grid"
                        id="grid"
                      >
                        {imageGrid}
                      </ul>
                    </section>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    image: state.image,
    uid: state.firebase.auth.uid,
  };
};

export default connect(mapStateToProps)(SavedImages);
