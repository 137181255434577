export const generateGroupId = () => {
    return Math.random().toString(36).substr(2);
};

export const downloadImage = (image_url) => {
    var filename = image_url.substring(image_url.lastIndexOf("/") + 1).split("?")[0];
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = `${filename}`;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
    };
    xhr.open('GET', image_url);
    xhr.send();
};

export const randomizeArray = (image_array) => {
     for (let index = image_array.length - 1; index > 0; index--) {
        const random_index = Math.floor(Math.random() * (index + 1));
        [image_array[index], image_array[random_index]] = [image_array[random_index], image_array[index]];
     }
     return image_array;
};
