export const filterIdeas = (auth , ideasState ) =>{
  const {uid}=auth
       const { 
        ideas ,  
        filtered,
        category_filter,
        status_filter,
        my_filter } = ideasState
        let filterideasList=ideas
      // No Filter

      if(!filtered){        
        return filterideasList
      }
      // Filter by category only like Admin or General
      else if( status_filter ==null && my_filter == null){
           filterideasList = ideas.filter((idea) => idea.category_id==category_filter)
       }
      // Filter by my only like my idea or my vote etc 
      else if( status_filter ==null && category_filter == 0){
        if(my_filter == 'myidea'){
          filterideasList = ideas.filter((idea) => idea.creator_id == uid)
        }
        else if ( my_filter == 'myvote'){
          filterideasList = ideas.filter((idea) => idea.voted != false)


        }else if( my_filter == 'mysub'){
          filterideasList = ideas.filter((idea) => idea.subscribed != false)
        }
        
    }
      // Filter by category and my  like Admin and my vote etc ...
      else if( category_filter !=0 && my_filter != null ){
        if(my_filter == 'myidea' ){
          //This will be called when my idea and ADMIN/GENERAL
          filterideasList = ideas.filter((idea) => {
            if(idea.category_id==category_filter && idea.creator_id == uid ){
              return true
            }
          })
        }
        else if(my_filter == 'myvote' ){
          //This will be called when My Vote and ADMIN/GENERAL
          filterideasList = ideas.filter((idea) => {
            if(idea.category_id==category_filter && idea.voted !== false ){
              return true
            }
          })
        }
        else if(my_filter == 'mysub' ){
          //This will be called when My Vote and ADMIN/GENERAL
          filterideasList = ideas.filter((idea) => {
            if(idea.category_id==category_filter && idea.subscribed !== false ){
              return true
            }
          })
        }
      
    }
        return filterideasList
}

export const realatedIdeas = (c_id ,i_id ,ideasState)=>{
  
    return ideasState.filter((idea) => c_id==idea.creator_id && idea.idea_id != i_id)
}