/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getDefaultQuestion, getNextQuestion } from "@actions/question";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import AddQuestionForm from "../../admin/AddQuestionForm";
import firebase from 'firebase/app';

class GuestPersonalQuestionActivity extends React.Component {
  state = {
    question: {},
    showAddForm: false,
    showEditForm: false,
    showUploadImageForm: false,
    modal: false,
    user: false,
  };

  componentWillMount() {
    this.props.getDefaultQuestion("default");
    window.localStorage.setItem("activity_type", "personal");
    localStorage.removeItem("group");
  }

  componentWillReceiveProps(nextProps) {
    const check = localStorage.getItem("check");
    const newQuestion = {};
    newQuestion.content = localStorage.getItem("NewQuestion");
    if (nextProps) {
      if (check === "true") {
        localStorage.setItem("selectedQuestion", JSON.stringify(newQuestion));
      } else {
        localStorage.setItem(
          "selectedQuestion",
          JSON.stringify(nextProps.question.question)
        );
      }
    }
  }

  showAddQuestionForm = () => {
    this.setState((prevState) => ({
      question: {},
      showAddForm: !prevState.showAddForm,
      showEditForm: false,
      showUploadImageForm: false,
      modal: true,
      user: true,
    }));
  };

  nextQuestion(event) {
    event.preventDefault();
    this.props.getNextQuestion(this.props.question.question.index);
    localStorage.setItem("check", false);

  }

  toggle = () => {
    this.setState({
      modal: false,
      showEditForm: false,
      showAddForm: false,
      showUploadImageForm: false,
    });
  };

  displayAddForm() {
    if (this.state.showAddForm) {
      return (
        <AddQuestionForm
          toggle={this.toggle}
          user={this.state.user}
          questiontype={this.props.questions.filter((data) => {
            return data.type === "default";
          })}
        />
      );
    }
  }

  render() {
    const { question } = this.props.question;
    const check = localStorage.getItem("check");
    const newQuestion = localStorage.getItem("NewQuestion");
    const modal = (
      <Fragment>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Add Question</ModalHeader>
          <ModalBody>{this.displayAddForm()}</ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </Fragment>
    );
    return (
      <div id="main">
        <div className="container mobtop">
          <div className="row">
            <div className="col-md-12 back-nav">
              <Link to="/dashboard" className="goto_back">
                <i></i>Back
              </Link>
            </div>
            <div className="progress-steps">
              <span className="step step-1 active"></span>
              <span className="step step-2"></span>
              <span className="step step-3"></span>
              <span className="step step-4"></span>
              <span className="step step-5"></span>
            </div>
            <div className="buttons-padding-box padding-box">
              <div
                className="buttons-wrap box-wrap"
                style={{ borderRadius: 5 }}
              >
                {check === "true" && (
                  <div className="small-title">{newQuestion}</div>
                )}
                {check === "false" && question.content ? (
                  <div className="small-title">{question.content}</div>
                ) : (
                  ""
                )}
                <Link
                  className="btn-primary green-bg"
                  to={{
                    pathname: `/guest-use/question/${question.key}/images/`,
                    state: { question },
                  }}
                >
                  VIEW IMAGES
                </Link>
                <a
                  href="/"
                  onClick={this.nextQuestion.bind(this)}
                  className="btn-primary-bdr mt-20"
                >
                  NEW QUESTION
                </a>
                <a
                  href="#"
                  onClick={this.showAddQuestionForm.bind(this)}
                  className="btn-primary-bdr mt-20"
                >
                  ADD QUESTION
                </a>
                {modal}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { firestore } = state;
  return {
    question: state.question,
    questions: firestore.ordered.questions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDefaultQuestion: (type) => dispatch(getDefaultQuestion(type)),
  getNextQuestion: (index) => dispatch(getNextQuestion(index)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: "questions" }])
)(GuestPersonalQuestionActivity);
