import {
    SIGNUP_USER_SUCCESS,
    SIGNUP_USER_ERROR,
    AUTHENTICATE_ACCOUNT_ERROR,
    AUTHENTICATE_ACCOUNT_SUCCESS,
    SIGNOUT_USER_SUCCES,
    SIGNOUT_USER_ERROR,
    AUTHENTICATE_EMAIL_FORGOT_PASSWORD_SUCCESS,
    AUTHENTICATE_EMAIL_FORGOT_PASSWORD_ERROR,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_ERROR,
    RESET_CODE_VALIDATE_SUCCESS,
    RESET_CODE_VALIDATE_ERROR
} from '@app/action-types';

const initialState = {
    authSignInError: null,
    authSignUpSuccess: null,
    authSignUpError: null,
    authSignOutSuccess: null,
    authSignOutError: null,
    authEmailForgotPasswordSuccess: null,
    authEmailForgotPasswordError: [],
    profile: {},
    passwordResetMessage: null,
    passwordResetError: null,
    resetCodeValid: false,
    resetCodeError: null
};

const authReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case AUTHENTICATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                profile: action.res,
                authSignInError: null
            }
        case AUTHENTICATE_ACCOUNT_ERROR:
            return {
                ...state,
                authEmailForgotPasswordSuccess: null,
                authSignInError: 'Invalid Email/Password'
            }
        case RESET_CODE_VALIDATE_SUCCESS:
            return {
                ...state,
                resetCodeValid: true,
                resetCodeError: null
            }
        case RESET_CODE_VALIDATE_ERROR:
            return {
                ...state,
                resetCodeValid: false,
                resetCodeError: action.err.message
            }
        case PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                passwordResetMessage: 'Your password has been reset',
                passwordResetError: null
            }
        case PASSWORD_RESET_ERROR:
            return {
                ...state,
                passwordResetMessage: null,
                passwordResetError: action.err.message
            }
        case SIGNUP_USER_SUCCESS:
            return {
                ...state,
                authSignUpError: null
            }
        case SIGNUP_USER_ERROR:
            return {
                ...state,
                authSignUpError: action.err.message
            }
        case SIGNOUT_USER_SUCCES:
            return {
                ...state,
                authSignOutError: null
            }
        case SIGNOUT_USER_ERROR:
            return {
                ...state,
                authSignOutError: action.err.message
            }
        case AUTHENTICATE_EMAIL_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                authEmailForgotPasswordSuccess: 'A reset link has been sent to your Email Address.',
                authSignInError: null,
                authEmailForgotPasswordError: [],
            }
        case AUTHENTICATE_EMAIL_FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                authEmailForgotPasswordSuccess: null,
                authEmailForgotPasswordError: [action.error],
            }
        default:
            return {
                ...state,
                authEmailForgotPasswordSuccess: null,
                passwordResetMessage: null,
                authSignInError: null,
                authSignUpError: null,
                authEmailForgotPasswordError: []
            };
    }
};

export default authReducer;
