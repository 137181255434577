import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { authenticateUser, refreshErrors } from '@actions/auth';
import { connect } from 'react-redux';
import MaterialInput from '@components/main/MaterialInput';
import firebase from 'firebase/app';
import { GoogleAuthProvider } from "firebase/auth";
import Swal from "sweetalert"
import axios from 'axios';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { toast } from "react-toastify";



class SignIn_2 extends Component {

    constructor(props) {
        super(props);



        var getUrlParameter = function getUrlParameter(sParam) {
            var sPageURL = window.location.search.substring(1),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                }
            }
            return false;
        };

        var code = getUrlParameter('code');

        if (typeof code !== 'undefined' && code !== '' && code !== false) {

            toast("Processing...", { type: "warning" });

            fetch('https://www.skilldivvy.com/dev/crons/firestore/app_version/login.php?code=' + code)
                .then(response => response.json())
                .then(data => {


                    const token = JSON.parse(data)
                    // console.log(token.access_token);



                    fetch('https://www.skilldivvy.com/dev/crons/firestore/app_version/login.php?token=' + token.access_token)
                        .then(response => response.json())
                        .then(data => {


                            // const token = JSON.parse(data)
                            // console.log(data.email);


                            const cipher = salt => {
                                const textToChars = text => text.split('').map(c => c.charCodeAt(0));
                                const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
                                const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

                                return text => text.split('')
                                    .map(textToChars)
                                    .map(applySaltToChar)
                                    .map(byteHex)
                                    .join('');
                            }

                            // To create a cipher
                            const myCipher = cipher('MySpecialRecipeForEnc')

                            //Then cipher any text:
                            let email = myCipher(data.email);
                            // console.log(email)

                            fetch(`https://us-central1-sparq-flux.cloudfunctions.net/user/linkedin?email=` + email)
                                .then(response => response.json())
                                .then(data => {

                                    toast("Logging in", { type: "success" });

                                    // console.log(data.token);
                                    // const token = JSON.parse(data)
                                    // console.log(token);

                                    firebase.auth().signInWithCustomToken(data.token)
                                        .then((userCredential) => {
                                            // Signed in
                                            var user = userCredential.user;
                                            console.log(userCredential);
                                        })
                                        .catch((error) => {
                                            console.log(error.message);
                                            toast("Error occurred, please try again.", { type: "error" });
                                        });


                                }).catch((error) => {
                                    toast("Error occurred, please try again.", { type: "error" });
                                });





                        }).catch((error) => {
                            toast("Error occurred, please try again.", { type: "error" });

                        });



                }).catch((error) => {
                    toast("Error occurred, please try again.", { type: "error" });
                });



            // fetch(`https://us-central1-sparq-flux.cloudfunctions.net/user/linkedin?email=` + code)
            //     .then(response => response.json())
            //     .then(data => {


            //         console.log(data.token);
            //         // const token = JSON.parse(data)
            //         // console.log(token);

            //         firebase.auth().signInWithCustomToken(data.token)
            //             .then((userCredential) => {
            //                 // Signed in
            //                 var user = userCredential.user;
            //                 console.log(userCredential);
            //             })
            //             .catch((error) => {
            //                 console.log(error.message);
            //             });


            //     });






        }

        console.log(this.props.match.params)

        if (typeof this.props.match.params.jwt !== 'undefined' && this.props.match.params.jwt !== '') {

            firebase.auth().signInWithCustomToken(this.props.match.params.jwt)
                .then((userCredential) => {
                    // Signed in
                    var user = userCredential.user;
                    console.log(userCredential);
                })
                .catch((error) => {
                    console.log(error.message);
                });

        }

    }

    state = {
        name: '',
        email: '',
        password: '',
        confirm_password: '',
        authSignInError: '',
        authEmailForgotPasswordSuccess: ''
    }



    componentWillReceiveProps(nextProps) {
        const { authSignInError, authEmailForgotPasswordSuccess } = nextProps

        this.setState({
            authSignInError,
            authEmailForgotPasswordSuccess
        })
    }

    componentWillUnmount() {
        this.props.refreshErrors()
    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.authenticateUser(this.state);
    }


    handleGoogle = (event) => {

        var provider = new firebase.auth.GoogleAuthProvider();

        firebase.auth()
            .signInWithPopup(provider)
            .then((result) => {
                /** @type {firebase.auth.OAuthCredential} */
                var credential = result.credential;

                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                console.log(user.email)

                firebase.firestore().collection("/users").doc(firebase.auth().currentUser.uid).get().then((doc) => {
                    if (doc.exists) {
                        console.log("Document data:", doc.data());

                    } else {
                        // firebase.auth().signOut();

                        firebase.firestore().collection("/users").doc(firebase.auth().currentUser.uid).set({
                            email: user.email,
                            user_type: 'normal',
                            name: user.displayName
                        })
                            .then(() => {

                                window.location.replace("/dashboard");


                            })
                            .catch((error) => {
                                console.error("Error writing document: ", error);
                            });


                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });



            }).catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
                if (errorCode = 'auth/account-exists-with-different-credential') {

                    firebase.auth().fetchSignInMethodsForEmail(error.email)
                        .then(providers => {
                            console.log(providers[0])

                            if (providers[0] == 'google.com') {
                                var msg = "An account already exists with the same email address (" + email + ") using Google Sign up. Login using 'Sign In With Google' button to connect both accounts."
                                Swal("", msg, { buttons: ["Cancel", "Sign In With Google"] })
                                    .then((value) => {
                                        if (`${value}` === 'true') {
                                            var provider = new firebase.auth.GoogleAuthProvider();
                                            firebase.auth().signInWithPopup(provider).then(function (result) {
                                                firebase.auth().currentUser.linkWithCredential(credential).then(function (user) {
                                                    console.log("Account successfully linked", user);
                                                }, function (error) {
                                                    console.log("Error linking account", error);
                                                });
                                            });
                                        }
                                    })
                                window.$('.swal-button--confirm').css('background', '#d22121');
                            }

                            if (providers[0] == 'password') {
                                var msg = "An account already exists with the same email address (" + email + ") using Sign up with Email. Login using 'Sign In With Email' button to connect with this email address."
                                var msg = "An account already exists with the same email address (" + email + ") using Sign up with Email. Enter your password to connect both accounts."

                                Swal(msg, {
                                    buttons: {

                                        confirm: {
                                            text: "Connect",
                                            value: "connect",
                                        },
                                    },
                                    content: "input",
                                    input: 'password',
                                }).then((value) => {
                                    const pass = value;

                                    if (true)
                                        if (pass == '' || pass == null) {
                                            Swal('', 'Password cannot be empty', 'error')
                                        } else {


                                            firebase.auth().signInWithEmailAndPassword(email, pass)
                                                .then(function (firebaseUser) {
                                                    // Success 
                                                    firebase.auth().currentUser.linkWithCredential(credential).then(function (user) {
                                                        console.log("Account successfully linked", user);
                                                    }, function (error) {
                                                        console.log("Error linking account", error);
                                                    });
                                                })
                                                .catch(function (error) {
                                                    // Error Handling
                                                    console.log(error)
                                                    if (error.code == "auth/wrong-password") {
                                                        Swal('', 'Incorrect password', 'error')
                                                    }
                                                });

                                        }

                                });
                                window.$('.swal-content__input').attr('type', 'password')

                            }

                            if (providers[0] == 'twitter.com') {
                                var msg = "An account already exists with the same email address (" + email + ") using Twitter Sign up. Login using 'Sign In With Twitter' button to connect both accounts."
                                Swal("", msg, { buttons: ["Cancel", "Sign In With Twitter"] })
                                    .then((value) => {
                                        if (`${value}` === 'true') {
                                            var provider = new firebase.auth.TwitterAuthProvider();
                                            firebase.auth().signInWithPopup(provider).then(function (result) {
                                                firebase.auth().currentUser.linkWithCredential(credential).then(function (user) {
                                                    console.log("Account successfully linked", user);
                                                }, function (error) {
                                                    console.log("Error linking account", error);
                                                });
                                            });
                                        }
                                    })
                                window.$('.swal-button--confirm').css('background', '#50abf1');
                            }

                            if (providers[0] == 'facebook.com') {
                                var msg = "An account already exists with the same email address (" + email + ") using Facebook Sign up. Login using 'Sign In With Facebook' button to connect both accounts."
                                Swal("", msg, { buttons: ["Cancel", "Sign In With Facebook"] })
                                    .then((value) => {
                                        if (`${value}` === 'true') {
                                            var provider = new firebase.auth.FacebookAuthProvider();
                                            firebase.auth().signInWithPopup(provider).then(function (result) {
                                                firebase.auth().currentUser.linkWithCredential(credential).then(function (user) {
                                                    console.log("Account successfully linked", user);
                                                }, function (error) {
                                                    console.log("Error linking account", error);
                                                });
                                            });
                                        }
                                    })
                                window.$('.swal-button--confirm').css('background', '#3b5898');
                            }


                        });
                }
            });



    }


    handleTwitter = (event) => {

        var provider = new firebase.auth.TwitterAuthProvider();

        firebase.auth()
            .signInWithPopup(provider)
            .then((result) => {
                /** @type {firebase.auth.OAuthCredential} */
                var credential = result.credential;

                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                console.log(user.email)

                firebase.firestore().collection("/users").doc(firebase.auth().currentUser.uid).get().then((doc) => {
                    if (doc.exists) {
                        console.log("Document data:", doc.data());

                    } else {
                        // firebase.auth().signOut();

                        firebase.firestore().collection("/users").doc(firebase.auth().currentUser.uid).set({
                            email: user.email,
                            user_type: 'normal',
                            name: user.displayName
                        })
                            .then(() => {

                                window.location.replace("/dashboard");


                            })
                            .catch((error) => {
                                console.error("Error writing document: ", error);
                            });


                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });



            }).catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                console.log(error.code)
                console.log(error.message)
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
                if (errorCode = 'auth/account-exists-with-different-credential') {


                    firebase.auth().fetchSignInMethodsForEmail(error.email)
                        .then(providers => {
                            console.log(providers[0])

                            if (providers[0] == 'google.com') {
                                var msg = "An account already exists with the same email address (" + email + ") using Google Sign up. Login using 'Sign In With Google' button to connect both accounts."
                                Swal("", msg, { buttons: ["Cancel", "Sign In With Google"] })
                                    .then((value) => {
                                        if (`${value}` === 'true') {
                                            var provider = new firebase.auth.GoogleAuthProvider();
                                            firebase.auth().signInWithPopup(provider).then(function (result) {
                                                firebase.auth().currentUser.linkWithCredential(credential).then(function (user) {
                                                    console.log("Account successfully linked", user);
                                                }, function (error) {
                                                    console.log("Error linking account", error);
                                                });
                                            });
                                        }
                                    })
                                window.$('.swal-button--confirm').css('background', '#d22121');
                            }

                            if (providers[0] == 'password') {
                                var msg = "An account already exists with the same email address (" + email + ") using Sign up with Email. Login using 'Sign In With Email' button to connect with this email address."
                                var msg = "An account already exists with the same email address (" + email + ") using Sign up with Email. Enter your password to connect both accounts."

                                Swal(msg, {
                                    buttons: {

                                        confirm: {
                                            text: "Connect",
                                            value: "connect",
                                        },
                                    },
                                    content: "input",
                                    input: 'password',
                                }).then((value) => {
                                    const pass = value;

                                    if (true)
                                        if (pass == '' || pass == null) {
                                            Swal('', 'Password cannot be empty', 'error')
                                        } else {


                                            firebase.auth().signInWithEmailAndPassword(email, pass)
                                                .then(function (firebaseUser) {
                                                    // Success 
                                                    firebase.auth().currentUser.linkWithCredential(credential).then(function (user) {
                                                        console.log("Account successfully linked", user);
                                                    }, function (error) {
                                                        console.log("Error linking account", error);
                                                    });
                                                })
                                                .catch(function (error) {
                                                    // Error Handling
                                                    console.log(error)
                                                    if (error.code == "auth/wrong-password") {
                                                        Swal('', 'Incorrect password', 'error')
                                                    }
                                                });

                                        }

                                });
                                window.$('.swal-content__input').attr('type', 'password')


                            }

                            if (providers[0] == 'twitter.com') {
                                var msg = "An account already exists with the same email address (" + email + ") using Twitter Sign up. Login using 'Sign In With Twitter' button to connect both accounts."
                                Swal("", msg, { buttons: ["Cancel", "Sign In With Twitter"] })
                                    .then((value) => {
                                        if (`${value}` === 'true') {
                                            var provider = new firebase.auth.TwitterAuthProvider();
                                            firebase.auth().signInWithPopup(provider).then(function (result) {
                                                firebase.auth().currentUser.linkWithCredential(credential).then(function (user) {
                                                    console.log("Account successfully linked", user);
                                                }, function (error) {
                                                    console.log("Error linking account", error);
                                                });
                                            });
                                        }
                                    })
                                window.$('.swal-button--confirm').css('background', '#50abf1');
                            }

                            if (providers[0] == 'facebook.com') {
                                var msg = "An account already exists with the same email address (" + email + ") using Facebook Sign up. Login using 'Sign In With Facebook' button to connect both accounts."
                                Swal("", msg, { buttons: ["Cancel", "Sign In With Facebook"] })
                                    .then((value) => {
                                        if (`${value}` === 'true') {
                                            var provider = new firebase.auth.FacebookAuthProvider();
                                            firebase.auth().signInWithPopup(provider).then(function (result) {
                                                firebase.auth().currentUser.linkWithCredential(credential).then(function (user) {
                                                    console.log("Account successfully linked", user);
                                                }, function (error) {
                                                    console.log("Error linking account", error);
                                                });
                                            });
                                        }
                                    })
                                window.$('.swal-button--confirm').css('background', '#3b5898');
                            }


                            // firebase.auth().signInWithEmailAndPassword("zohaibshaheen@gmail.com", "stallions")
                            //     .then(function (firebaseUser) {
                            //         // Success 
                            //     })
                            //     .catch(function (error) {
                            //         // Error Handling
                            //         console.log(error)
                            //     });

                            // var provider = new firebase.auth.GoogleAuthProvider();
                            // firebase.auth().signInWithPopup(provider).then(function (result) {
                            //     firebase.auth().currentUser.linkWithCredential(credential).then(function (user) {
                            //         console.log("Account successfully linked", user);
                            //     }, function (error) {
                            //         console.log("Error linking account", error);
                            //     });
                            // });

                        });
                }


            });





    }


    handleFacebook = (event) => {

        var provider = new firebase.auth.FacebookAuthProvider();

        firebase.auth()
            .signInWithPopup(provider)
            .then((result) => {
                /** @type {firebase.auth.OAuthCredential} */
                var credential = result.credential;

                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                console.log(user.email)

                firebase.firestore().collection("/users").doc(firebase.auth().currentUser.uid).get().then((doc) => {
                    if (doc.exists) {
                        console.log("Document data:", doc.data());

                    } else {
                        // firebase.auth().signOut();

                        firebase.firestore().collection("/users").doc(firebase.auth().currentUser.uid).set({
                            email: user.email,
                            user_type: 'normal',
                            name: user.displayName
                        })
                            .then(() => {

                                window.location.replace("/dashboard");


                            })
                            .catch((error) => {
                                console.error("Error writing document: ", error);
                            });


                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });



            }).catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
                if (errorCode = 'auth/account-exists-with-different-credential') {

                    firebase.auth().fetchSignInMethodsForEmail(error.email)
                        .then(providers => {
                            console.log(providers[0])

                            if (providers[0] == 'google.com') {
                                var msg = "An account already exists with the same email address (" + email + ") using Google Sign up. Login using 'Sign In With Google' button to connect both accounts."
                                Swal("", msg, { buttons: ["Cancel", "Sign In With Google"] })
                                    .then((value) => {
                                        if (`${value}` === 'true') {
                                            var provider = new firebase.auth.GoogleAuthProvider();
                                            firebase.auth().signInWithPopup(provider).then(function (result) {
                                                firebase.auth().currentUser.linkWithCredential(credential).then(function (user) {
                                                    console.log("Account successfully linked", user);
                                                }, function (error) {
                                                    console.log("Error linking account", error);
                                                });
                                            });
                                        }
                                    })
                                window.$('.swal-button--confirm').css('background', '#d22121');
                            }

                            if (providers[0] == 'password') {
                                var msg = "An account already exists with the same email address (" + email + ") using Sign up with Email. Login using 'Sign In With Email' button to connect with this email address."
                                var msg = "An account already exists with the same email address (" + email + ") using Sign up with Email. Enter your password to connect both accounts."

                                Swal(msg, {
                                    buttons: {

                                        confirm: {
                                            text: "Connect",
                                            value: "connect",
                                        },
                                    },
                                    content: "input",
                                    input: 'password',
                                }).then((value) => {
                                    const pass = value;

                                    if (true)
                                        if (pass == '' || pass == null) {
                                            Swal('', 'Password cannot be empty', 'error')
                                        } else {


                                            firebase.auth().signInWithEmailAndPassword(email, pass)
                                                .then(function (firebaseUser) {
                                                    // Success 
                                                    firebase.auth().currentUser.linkWithCredential(credential).then(function (user) {
                                                        console.log("Account successfully linked", user);
                                                    }, function (error) {
                                                        console.log("Error linking account", error);
                                                    });
                                                })
                                                .catch(function (error) {
                                                    // Error Handling
                                                    console.log(error)
                                                    if (error.code == "auth/wrong-password") {
                                                        Swal('', 'Incorrect password', 'error')
                                                    }
                                                });

                                        }

                                });
                                window.$('.swal-content__input').attr('type', 'password')

                            }

                            if (providers[0] == 'twitter.com') {
                                var msg = "An account already exists with the same email address (" + email + ") using Twitter Sign up. Login using 'Sign In With Twitter' button to connect both accounts."
                                Swal("", msg, { buttons: ["Cancel", "Sign In With Twitter"] })
                                    .then((value) => {
                                        if (`${value}` === 'true') {
                                            var provider = new firebase.auth.TwitterAuthProvider();
                                            firebase.auth().signInWithPopup(provider).then(function (result) {
                                                firebase.auth().currentUser.linkWithCredential(credential).then(function (user) {
                                                    console.log("Account successfully linked", user);
                                                }, function (error) {
                                                    console.log("Error linking account", error);
                                                });
                                            });
                                        }
                                    })
                                window.$('.swal-button--confirm').css('background', '#50abf1');
                            }

                            if (providers[0] == 'facebook.com') {
                                var msg = "An account already exists with the same email address (" + email + ") using Facebook Sign up. Login using 'Sign In With Facebook' button to connect both accounts."
                                Swal("", msg, { buttons: ["Cancel", "Sign In With Facebook"] })
                                    .then((value) => {
                                        if (`${value}` === 'true') {
                                            var provider = new firebase.auth.FacebookAuthProvider();
                                            firebase.auth().signInWithPopup(provider).then(function (result) {
                                                firebase.auth().currentUser.linkWithCredential(credential).then(function (user) {
                                                    console.log("Account successfully linked", user);
                                                }, function (error) {
                                                    console.log("Error linking account", error);
                                                });
                                            });
                                        }
                                    })
                                window.$('.swal-button--confirm').css('background', '#3b5898');
                            }




                        });
                }
            });



    }



    handleLinkedIn = (event) => {



        window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=true&client_id=86auavbsgsscrd&redirect_uri=${window.location.origin}/sign-in&scope=r_emailaddress`;


    }

    validateEmail(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {

        const error_style = {
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "normal",
            fontStyle: "normal",
            fontStretch: "normal",
            lineHeight: "1.71",
            letterSpacing: "normal",
            color: "#ef2727",
            position: "relative",
            bottom: "14px",
        }
        const success_style = {
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "normal",
            fontStyle: "normal",
            fontStretch: "normal",
            lineHeight: "1.71",
            letterSpacing: "normal",
            color: "#0092ac",
            position: "relative",
            bottom: "14px",
        }
        const { authSignInError, authEmailForgotPasswordSuccess } = this.state
        return (
            <React.Fragment>
                <div id="main">
                    <div className="container">
                        <div className="row">
                            <div className="form-padding-box padding-box">
                                <div className="form-wrap box-wrap" style={{ borderRadius: 5 }}>
                                    <h1 className="form-title">Welcome back!</h1>
                                    <div className="form-descp">Thank you for continuing to support Sparq. Enter your email and password to login.<br />If you need to create a new account, <a href="sign-up">click here</a>.</div>
                                    <form onSubmit={this.handleSubmit} name="loginForm" id="loginForm" action="home.html" className="login-form entry-form check-form" method="post">
                                        <MaterialInput
                                            placeholder="Email address"
                                            type="email"
                                            name="email"
                                            required={true}
                                            pattern="^.{0,64}$"
                                            title="Enter an email address with a maximum of 64 characters."
                                            label="Email address"
                                            id="email"
                                            onChange={this.validateEmail.bind(this)}
                                            error={this.state.authSignInError}
                                            value={this.state.email}
                                            icon="../../../../public/assets/images/user.svg"
                                        />
                                        <MaterialInput
                                            placeholder="Password"
                                            type="password"
                                            name="password"
                                            required={true}
                                            label="Password"
                                            onChange={this.validateEmail.bind(this)}
                                            error={this.state.authSignInError}
                                            id="password"
                                            value={this.state.password}
                                        />
                                        {authSignInError ? <div style={error_style}>{authSignInError}</div> : null}
                                        {authEmailForgotPasswordSuccess ? <div style={success_style}>{authEmailForgotPasswordSuccess}</div> : null}
                                        <div className="button-wrap">
                                            <button className={"btn-primary btn-login " + ((this.state.email && this.state.password) ? "active" : "inactive")}>SIGN IN WITH EMAIL</button>
                                        </div>
                                    </form>
                                    <div style={{ textAlign: 'center', fontSize: '20px', margin: '10px', color: '#000', fontWeight: 'bold' }}>OR</div>
                                    <div className="button-wrap mt-2" >
                                        <button id="google" onClick={this.handleGoogle} style={{ backgroundColor: '#d22121', color: '#fff' }} className={"btn-primary btn-login "}>SIGN IN WITH GOOGLE</button>
                                    </div>
                                    <div className="button-wrap mt-2" >
                                        <button id="twitter" onClick={this.handleTwitter} style={{ backgroundColor: '#50abf1', color: '#fff' }} className={"btn-primary btn-login "}>SIGN IN WITH TWITTER</button>
                                    </div>
                                    <div className="button-wrap mt-2" >
                                        <button id="facebook" onClick={this.handleFacebook} style={{ backgroundColor: '#3b5898', color: '#fff' }} className={"btn-primary btn-login "}>SIGN IN WITH FACEBOOK</button>
                                    </div>
                                    <div className="button-wrap mt-2" >
                                        <button id="linkedin" onClick={this.handleLinkedIn} style={{ backgroundColor: '#4473db', color: '#fff' }} className={"btn-primary btn-login "}>SIGN IN WITH LINKEDIN</button>
                                    </div>
                                    <div className="form-footer">
                                        <div className="row">
                                            <div className="col-md-6 col-sm-6 col-7">
                                                <Link to="/forgot-password" className="redirect-link grey-link">FORGOT PASSWORD?</Link>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-5 text-right">
                                                <Link to="/sign-up" className="redirect-link green-link">SIGN UP <i className="green-arr"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
};

const mapStateToProps = (state) => {
    const { auth: { authSignInError, customMessage, authEmailForgotPasswordSuccess, profile } } = state
    return {
        authSignInError,
        customMessage,
        authEmailForgotPasswordSuccess
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authenticateUser: (creds) => dispatch(authenticateUser(creds)),
        refreshErrors: () => dispatch(refreshErrors())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn_2);
