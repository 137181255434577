import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { reduxFirestore, getFirestore } from 'redux-firestore';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reducers from '@reducers';
import thunk from 'redux-thunk';
import firebaseConfig from '@config/firebaseConfig' 
import firebase from 'firebase/app';
import 'react-table/react-table.css'
import 'firebase/storage';

const store = createStore(reducers, 
    compose(
        applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore, firebase })),
        reduxFirestore(firebaseConfig),
        reactReduxFirebase(firebaseConfig, { 
            useFirestoreForProfile: true,
            userProfile: 'users',
            attachAuthIsReady: true ,
            enableLogging: false,
        }),
    )
);

store.firebaseAuthIsReady.then(() => {
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>
    , document.getElementById('root'));
});

serviceWorker.register();
