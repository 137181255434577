import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { getGroup } from '@actions/group';
import { getMultipleGroupActivities } from '@actions/activity';
import { saveAs } from 'file-saver';
import domtoimage from 'dom-to-image';
import loadingRes from '../../../resource/loading.gif';
// const express = require("express");
//import * as d3 from 'd3';
import Swal from "sweetalert"
import { toast } from "react-toastify";
import firebase from 'firebase/app';
import { CSVLink } from "react-csv";


import './styles.css';

const $ = window.$;
const Freewall = window.Freewall;


const formDataToJson = (formData) => {
    const obj = {};
    formData.forEach((value, key) => { 
        obj[key] = value
    });
    return JSON.stringify(obj);
}

let data_download = [];


class MosaicCreator extends React.Component {
    state = {
        groupList: [],
        keyword: '',
        selectedGroups: [],
        hasMosaic: false,
        loading: false,
        loading_analyse: false,
        mosaicImageCount: 0,
        updateStateVal: 0
    }

    componentDidMount() {
        this.props.getGroups();
        
        const script = document.createElement("script");
        script.src = "https://d3js.org/d3.v4.min.js";
        script.async = false;
        document.body.appendChild(script);

        /*
        const script2 = document.createElement("script");
        script2.src = "http://localhost:3000/assets/js/chart.js";
        script2.async = false;
        document.body.appendChild(script2);
        */

    }

    displayList() {
        const { profile } = this.props
        console.log(this.props);
        const list = this.props.groups.filter(group => {
            const keyword = this.state.keyword.toLocaleLowerCase().replace(/ /g, '');
            const groupName = group.groupName.toLocaleLowerCase().replace(/ /g, '');
            return groupName.indexOf(keyword) > -1;
        });
        if (profile.user_type === "premium") {
            let premiumList = list.filter(group => group.user_type && group.user_type === "premium")
            this.setState({ groupList: premiumList });
        } else {
            this.setState({ groupList: list });
        }
    }

    onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.displayList();
        });
    }

    selectGroup = (group) => {
        let list = this.state.selectedGroups;
        if (list.indexOf(group) === -1) {
            list.push(group);
        }
        this.setState({ selectedGroups: list, keyword: '' });
    }

    generateMosaic = () => {

        this.props.getMultipleGroupActivities(this.state.selectedGroups.map(group => group.groupKey)).then(response => {
            let groupList = [];
            response.forEach(resp => {
                if (!resp.empty) {
                    groupList = [
                        ...groupList,
                        ...resp.docs
                    ];
                }
            });
            const extractedData = groupList.map(item => item.data());
            const activities = this.calculateImageUsers(extractedData);
            this.displayImages(activities);
        });
    }

    getTotalUserCount(activities) {
        let total = 0;
        for (let i = 0; i < activities.length; i++) {
            total += activities[i].count;
        }
        return total;
    }

    calculateImageUsers(activities) {
        let images = activities.map(activity => activity.image_url);
        images.sort();

        let current = null;
        let cnt = 0;

        let newObject = [];

        for (var i = 0; i <= images.length; i++) {
            if (images[i] !== current) {
                if (cnt > 0) {
                    newObject.push({ url: current, count: cnt });
                }
                current = images[i];
                cnt = 1;
            } else { cnt++; }
        }
        if (cnt > 0) {
            newObject.push({ url: current, count: cnt });
        }

        return newObject;
    }

    

    displayImages(activities) {

        this.setState({ mosaicImageCount: activities.length });

        Swal("",'Choose one of the following layouts:', {buttons: ["Tiles", "Bubbles"]})
            .then((value) => {

                // hide previous mosaic analysis
                $(".show_analysis").hide();

                // empty CSV data 
                data_download = [];




                if (`${value}` === 'true'){

                    activities.sort((a,b) => (a.count > b.count) ? 1 : ((b.count > a.count) ? -1 : 0))
                    activities.reverse();
                    this.setState({ hasMosaic: true });
                    const total = this.getTotalUserCount(activities); 
                    let w = 1, html = '', limitItem = activities.length;

                    
                    html = `window.dataset = {
                        "children":[`;

                    for (var i = 0; i < limitItem; ++i) {
                    
                        if ( activities[i].url ){

                            // removing ' from url
                            activities[i].url = activities[i].url.replace(/'/g, '%27');  
                                          

                            // creating array of responses
                            html += ` {"Name":"","Count":${activities[i].count},"image":"${activities[i].url}"}, `;

                        }

                    }

                    html += ']};';  

                    // clear previous data
                    $("#freewall").html('');
                    $("#freewall-1").html('');

                    $("#freewall-1").html(`<script>
                    
                    ${html}
                    
                    </script>
                    
                    <script src="/assets/js/chart.js"></script>
                    `).css('margin','30px');
                    
                        
                        
                    
                    
                }else{ 
                                   
                    activities.sort((a,b) => (a.count > b.count) ? 1 : ((b.count > a.count) ? -1 : 0))
                    activities.reverse();
                    this.setState({ hasMosaic: true });
                    const total = this.getTotalUserCount(activities); 
                    let temp = "<div class='brick' style='width:{width}px; height:{width}px; margin: 20px; background: black; border-radius: 20px; box-shadow: 0 0 15px #a59f9f;'><img class='mosaic-img' src='{src}' style='border-radius: 20px; object-fit: cover;' width='100%' height='100%' onerror={{this.style.display='none'}}></div>";
                    // let temp = "<div class='brick' style='width:{width}px; height:{width}px; background-size: auto; border-radius:100%; margin: 3px;background-image: url({src});background-repeat:no-repeat;background-position: center center;'></div>";
                    let w = 1, html = '', limitItem = activities.length;
                    let start_loop =  limitItem - 120;
                    if ( start_loop < 0 ){
                        start_loop = 0;
                    }
                    for (var i = start_loop; i < limitItem; ++i) {
                        const percentage = (activities[i].count / total);
                        // w = (1 + 5 * percentage << 0);

                        w = 2 + (100 * percentage);
                        // removing ' from url
                        if ( activities[i].url ){
                            activities[i].url = activities[i].url.replace(/'/g, '%27');                
                            html += temp.replace(/\{width\}/g, w * 20).replace("{src}", activities[i].url);
                        }
                    }

                     $("#freewall").html(html).css('margin','30px');
                    $("#freewall").css('justify-content','center').css('align-items','center');



                }
            });
            window.$(".swal-text").css({ "text-align" : "left", "display" : "block", "margin-left" : "10px" , "margin-top" : "20px" });


    }

    removeGroup = (group) => {
        const groups = this.state.selectedGroups.filter(grp => (grp !== group));
        this.setState({ selectedGroups: groups });
    }

    analyseImage = () => {

        let msg = '';
        if ( this.state.mosaicImageCount <= 2 ){
            msg = 'The mosaic contains '+(parseInt(this.state.mosaicImageCount)-1)+' image. It may take upto 15 seconds in analysing it.';
        }else if ( this.state.mosaicImageCount <= 6 ){
            msg = 'The mosaic contains '+(parseInt(this.state.mosaicImageCount)-1)+' images. It may take upto 15 seconds in analysing it.';
        }else if( this.state.mosaicImageCount <= 12 ){
            msg = 'The mosaic contains '+(parseInt(this.state.mosaicImageCount)-1)+' images. It may take upto 30 seconds in analysing it.';
        }else{
            msg = 'The mosaic contains '+(parseInt(this.state.mosaicImageCount)-1)+' images. It may take upto 1 minute in analysing it.';
        }

        Swal("",msg, {buttons: ["Cancel", "Proceed"]})
            .then((value) => {

                if (`${value}` === 'true'){



                    $('#analysis_result').before(`
                    <div class="progress" style="margin: 20px 100px; height: 30px;">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="width: 25%">Preparing Assets</div>
                    </div>`);

                    $(".analyse").html(`<span>Analysing...`).removeClass("active").addClass("inactive");


                    const node = document.getElementById("mosaic-wrapper");

                    domtoimage.toBlob(node)
                    .then(function (image) {

                        console.log(image);
                        $('.progress-bar').css('width', '50%').attr('aria-valuenow', 50).html('Analysing Data');    

                        const id =
                            new Date().getTime() +
                            "_" +
                            Math.random()
                            .toString(36)
                            .substr(2);
                        const ext = 'jpeg';
                        const image_name = `mosaic_images/${id}.${ext}`;
                        const image_name_2 = `${id}.${ext}`;
                        firebase
                            .storage()
                            .ref(image_name)
                            .put(image)
                            .then(() => {
                                console.log('success');
                                $('.progress-bar').css('width', '75%').attr('aria-valuenow', 75).html('Preparing Results');    
                                firebase.storage().ref('mosaic_images').child(image_name_2).getDownloadURL().then(url => {
                                    console.log(url);

                                    var data = new FormData();

                                    data.append( "image", url );

                                    fetch('https://us-central1-sparq-flux.cloudfunctions.net/user/process_image',{
                                        method: "POST",
                                        body: formDataToJson(data),
                                        headers: {
                                            "Content-Type": "application/json"
                                        },
                                    })
                                    .then(response => response.json())
                                    .then(arra => {
                                        console.log(data_download);
                              
                                        
                                                            
                                        $('.progress-bar').css('width', '100%').attr('aria-valuenow', 100);    
                                        // empty previous data and show analysis
                                        $("#analysis_result").html('');
                                        $(".show_analysis").show();

                                        
                                        // append results to div
                                        let label_var = 0;
                                        $("#analysis_result").append('<div id="objects" class="col-sm"><h3>Objects & Labels</h3><br /></div>');
                                        if ( arra.objects.length !== 0) { 
                                            arra.objects.forEach(object => {
                                                $("#objects").append('<div class="crumb">'+object.name+'</div>');
                                                label_var++;
                                            });
                                        }

                                        if ( arra.labels.length !== 0) { 
                                            arra.labels.forEach(label => {
                                                if ( label_var <= 10 ){
                                                    $("#objects").append('<div class="crumb">'+label.description+'</div>'); 
                                                    label_var++;
                                                }else
                                                    $("#objects").append('<div class="crumb" style="color: #293084; font-weight: bold;">...</div>'); 
                                            });
                                        }

                                        $("#analysis_result").append('<div id="landmarks" class="col-sm"><h3>Landmarks</h3><br /></div>');
                                        if ( arra.landmarks.length !== 0) { 
                                            arra.landmarks.forEach(landmark => {
                                                $("#landmarks").append('<div class="crumb">'+landmark.description+'</div>'); 
                                            });
                                        }else{
                                            $("#landmarks").append('No landmarks found.<br />');
                                        }

                                        $("#analysis_result").append('<div id="text" class="col-sm"><h3>Text</h3><br /></div>');
                                        if ( arra.text.length !== 0) { 
                                            let text_var = 0;
                                            arra.text.forEach(text => {
                                                if ( text_var === 0 ){ 

                                                }else{
                                                    if ( text_var <= 10 ){ 
                                                        $("#text").append('<div class="crumb">'+text.description+'</div>'); 
                                                    }else{
                                                        $("#text").append('<div class="crumb" style="color: #293084; font-weight: bold;">....</div>'); 
                                                    }
                                                }
                                                text_var++;
                                            });
                                        }else{
                                            $("#text").append('No text found.<br />');
                                        }

                                        $("#analysis_result").append('<div id="colors" class="col-sm"><h3>Dominant Colors</h3><br /></div>');
                                        let col_var = 0;
                                        arra.colors.forEach(color => {
                                            if ( col_var < 4 ){ 
                                                col_var++;
                                                let currentColor = "rgb(" + color.color.red + ", " + color.color.green + ", " + color.color.blue + ")";
                                                let percentage = Math.round((color.pixelFraction+.2)*100);            
                                                $("#colors").append("<div style='display:block; background-color: "+currentColor+"; height: "+percentage+"px; '></div> ")
                                            }
                                        });

                                        console.log(arra);

                                       

                                        // generate data for CSV file
                                        for ( let i = 0; i <= 50; i++){

                                            let hex = '';
                                            if (  typeof arra.colors[i] !== 'undefined' && typeof arra.colors[i].color !== 'undefined' ){
                                                hex = "#" + ((1 << 24) + (arra.colors[i].color.red << 16) + (arra.colors[i].color.green << 8) + arra.colors[i].color.blue).toString(16).slice(1);
                                            }
                                            data_download.push({
                                                objects: ( typeof arra.objects[i] !== 'undefined' && typeof arra.objects[i].name !== 'undefined' ) ? arra.objects[i].name : "",
                                                labels: ( typeof arra.labels[i] !== 'undefined' && typeof arra.labels[i].description !== 'undefined' ) ? arra.labels[i].description : "",
                                                landmarks: ( typeof arra.landmarks[i] !== 'undefined' && typeof arra.landmarks[i].description !== 'undefined' ) ? arra.landmarks[i].description : "",
                                                text: (typeof arra.text[i] !== 'undefined' && typeof arra.text[i].description !== 'undefined' && i !== 0 ) ? arra.text[i].description : "",
                                                colors: hex
                                            });

                                        }

                                        

                                        // remove duplicates
                                        var seen = {};
                                        $('.crumb').each(function() {
                                            var txt = $(this).text();
                                            if (seen[txt])
                                                $(this).remove();
                                            else
                                                seen[txt] = true;
                                        });

                                        $('.progress').remove();
                                        $(".analyse").html('Analyse Mosaic').removeClass("inactive").addClass("active");

                                        console.log(data_download);


                                    });

                                });

                            })
                            .catch((err) => {

                            });
                            

                    }).catch(function (error) {
                        console.error('oops, something went wrong!', error);
                    }); 
                    
                }
            });
        window.$(".swal-text").css({ "text-align" : "left", "display" : "block", "margin-left" : "10px" , "margin-top" : "20px" });


    }


    generateImage = () => {
        this.loading(true);
        const node = document.getElementById("mosaic-wrapper");


        domtoimage.toBlob(node).then(dataUrl => {
            console.log(dataUrl);
            saveAs(dataUrl, 'Mosaic.png');
            this.loading(false); 
        }).catch(function (error) {
            console.error('oops, something went wrong!', error);
        }); 

    }

    loading = (loading) => {
        this.setState({ loading });
    }

    loading_analyse = (loading_analyse) => {
        this.setState({ loading_analyse });
    }


    render() {


        const headers = [
                { label: "Objects", key: "objects" },
                { label: "Labels",  key: "labels" },
                { label: "Landmarks",  key: "landmarks" },
                { label: "Text",  key: "text" },
                { label: "Colors",  key: "colors" },
        ]
        
        return (
            <div className="container mosaic-creator">
                
                <div style={{ marginTop: 30, borderRadius: 5 }} className="card">
                    <div className="card-body">
                        <div className="card-header-wrap">
                            <ul className="mc-group-list">
                                {
                                    this.state.selectedGroups.map((group, index) => (
                                        <li key={index}>
                                            {group.groupName}
                                            <button
                                                onClick={() => {
                                                    this.removeGroup(group)
                                                }}
                                                type="button"
                                                className="em-remove-item"
                                            >&#215;</button>
                                        </li>
                                    ))
                                }
                            </ul>
                            <div className="row">
                                <div className="col-sm-12">
                                    <input
                                        autoComplete="off"
                                        name="keyword"
                                        type="text"
                                        onChange={this.onChange}
                                        value={this.state.keyword}
                                        className="large-input group-input"
                                        autoFocus
                                        required
                                    />
                                    {
                                        this.state.keyword
                                            ? <div className="selection-dropdown">
                                                {
                                                    this.state.groupList.length
                                                        ? <ul className="selection-list">
                                                            {
                                                                this.state.groupList.map((group, index) => (
                                                                    <li
                                                                        onClick={() => {
                                                                            this.selectGroup(group)
                                                                        }}
                                                                        key={index}
                                                                    >
                                                                        {group.groupName}
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                        : <label style={{ width: '100%', textAlign: 'center', fontSize: 15 }}>Group not found ...</label>
                                                }
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <button onClick={this.generateMosaic} className={"btn-primary generate-btn " + (this.state.selectedGroups.length ? 'active' : 'inactive')}>Generate Mosaic</button>
                                </div>
                                
                                <div className="col-sm">
                                    <button onClick={this.generateImage} className={"btn-primary generate-btn " + (this.state.hasMosaic && !this.state.loading ? 'active' : 'inactive')}>
                                        {
                                            this.state.loading
                                                ? (<span><img alt="to generate" style={{ width: 20 }} src={loadingRes} /> Generating Mosaic ...</span>) : 'Download Mosaic'
                                        }
                                    </button>
                                </div>

                                <div className="col-sm">
                                    <button onClick={this.analyseImage} className={"btn-primary generate-btn analyse " + (this.state.hasMosaic && !this.state.loading_analyse ? 'active' : 'inactive')}>
                                        {
                                            this.state.loading_analyse
                                                ? (<span><img alt="to generate" style={{ width: 20 }} src={loadingRes} /> Analysing Mosaic ...</span>) : 'Analyse Mosaic'
                                        }
                                    </button>
                                </div>
                                

                            </div>
                        </div>
                        <div className="show_analysis" style={{ textAlign: 'center', fontSize: '22px', color: 'black', marginTop: '30px', fontWeight: 'bold', display: 'none' }}>
                            Mosaic Analysis
                            <span style={{fontSize: '13px', fontWeight: 'normal', display: 'block' }}>The below may not display full analysis results. Use the download button to view all analysis results.</span>
                            <CSVLink
                                filename={"sparq-mosaic.csv"} 
                                className="btn-primary generate-btn active" 
                                style={{ width: '190px', height: '41px', lineHeight: '18px', marginTop: '10px'}}
                                data={data_download}
                                headers={headers}
                                onClick={() => {
                                    this.setState({
                                        updateStateVal: this.state.updateStateVal++
                                    })
                                }}
                                >
                                Download Full Results
                            </CSVLink>
                        </div>
                        <div id="analysis_result" className="row show_analysis" style={{ padding: '30px', background: '#f2f2f2', margin: '20px 100px', borderRadius: '10px', display: 'none' }}>
                   
                        </div>

                        <div id="mosaic-wrapper" className="mosaic-wrapper" style={{ padding: "50px" }}>
                            <div id="freewall" className="mosaic-gallery row" style={{ margin: "20px 20px", background: "white" }}></div>
                            <div id="freewall-1"></div>
                        </div>
                    </div>
                </div>

            </div>
        );
    };
}

const mapStateToProps = state => ({
    activity: state.activity,
    groups: state.group.groups,
    profile: state.firebase.profile
});

const mapDispatchToProps = dispatch => ({
    getGroups: () => dispatch(getGroup()),
    getMultipleGroupActivities: groupKeys => dispatch(getMultipleGroupActivities(groupKeys))
});

export default connect(mapStateToProps, mapDispatchToProps)(MosaicCreator);
