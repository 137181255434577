import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, CardBody, Card, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { deleteAllImages, getCollections, deleteImagesFromCollection } from '@actions/images';
import Uploader from '../admin/Uploader';
import UploadCollection from "../admin/Uploader/UploadCollection"
import UpdateCollection from "../admin/Uploader/UpdateCollection"
import Thumbnail from '../admin/Thumbnail';

class AdminImages extends Component {
    constructor() {
        super();
        this.state = {
            modal: false,
            images: [],
            collectionModal: false,
            update_collectionModal: false,
            collection: null,
            collectionName: ''
        };
    }

    componentDidMount() {
        this.props.getCollections()
        window.$("#update_collection").hide()
        window.$("#delete_collection").hide()

    }

    componentDidUpdate() {

        //    console.log(this.props)
        //     const { collections } = this.props
        //     const data = [];
        //     if (collections && collections.length > 0) {
        //         collections.map(doc => {
        //             if (doc) {
        //                 // console.log(doc);
        //                 // if ( doc.userId === this.props.auth.uid )
        //                 data.push(doc)
        //             }
        //             return doc
        //         })
        //     }
        //     const finalData = data.filter(collection => collection.collectionName === 'New_Default_Spark_Set')
        //     this.setState({
        //         collection: finalData
        //     })


    }

    componentWillReceiveProps(nextProp) {

        this.props.getCollections()
        if (window.$("#selection_collection").val() === "default") {
            this.setState({
                collection: this.props.question_images
            })
        } else {
            const { collections } = this.props
            const data = [];
            if (collections && collections.length > 0) {
                collections.map(doc => {
                    if (doc) {
                        // console.log(this.props.auth.uid);
                        // if ( doc.userId === this.props.auth.uid )
                        data.push(doc)
                    }
                    return doc
                })
            }
            const finalData = data.filter(collection => collection.collectionName === window.$("#selection_collection").val())
            this.setState({
                collection: finalData,
                collectionName: window.$("#selection_collection").val()
            })

        }
        if (window.$("#selection_collection").val() === "" || window.$("#selection_collection").val() === "New_Default_Spark_Set" || window.$("#selection_collection").val() === "default") {
            window.$("#update_collection").hide()
            window.$("#delete_collection").hide()
        } else {
            window.$("#update_collection").show()
            window.$("#delete_collection").show()
        }


    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleCollection = () => {

        this.setState({
            collectionModal: !this.state.collectionModal
        })

    }


    toggleCollection_new = () => {

        this.setState({
            update_collectionModal: !this.state.update_collectionModal
        })


    }

    deleteAllImages = () => {
        if (window.confirm("Do you want to delete '" + window.$("#selection_collection").val() + "' ?")) {
            this.props.deleteImagesFromCollection(window.$("#selection_collection").val())
            window.$("#update_collection").hide()
            window.$("#delete_collection").hide()

        }
    }

    selectCollection = (e) => {
        if (e.target.value === "default") {
            this.setState({
                collection: this.props.question_images
            })

            // let stringArray = new Array();
            // let xxxxx = 1;
            // this.props.question_images.map(function (item, i) {
            //     stringArray.push(item.image_ref);
            //     console.log(item.image_ref);
            //     console.log(xxxxx);
            //     xxxxx++;
            // })

            // // console.log("stringArray");
            // // console.log(stringArray);

            // const findDuplicates = (arr) => {
            //     let sorted_arr = arr.slice().sort(); // You can define the comparing function here. 
            //     // JS by default uses a crappy string compare.
            //     // (we use slice to clone the array so the
            //     // original array won't be modified)
            //     let results = [];
            //     for (let i = 0; i < sorted_arr.length - 1; i++) {
            //         if (sorted_arr[i + 1] == sorted_arr[i]) {
            //             results.push(sorted_arr[i]);
            //         }
            //     }
            //     return results;
            // }

            // console.log(`The duplicates are ${findDuplicates(stringArray)}`);




        } else {
            const { collections } = this.props
            const data = [];
            if (collections && collections.length > 0) {
                collections.map(doc => {
                    if (doc) {
                        // console.log(this.props.auth.uid);
                        // if ( doc.userId === this.props.auth.uid )
                        data.push(doc)
                    }
                    return doc
                })
            }
            const finalData = data.filter(collection => collection.collectionName === e.target.value)
            this.setState({
                collection: finalData
            })
        }

        this.setState({
            collectionName: e.target.value
        })

        if (e.target.value === "" || e.target.value === "New_Default_Spark_Set" || e.target.value === "default") {
            window.$("#update_collection").hide()
            window.$("#delete_collection").hide()
        } else {
            window.$("#update_collection").show()
            window.$("#delete_collection").show()
        }

    }

    render() {
        const { profile, collections } = this.props
        const data = [];
        if (collections && collections.length > 0) {
            collections.map(doc => {
                if (doc) {
                    if (doc.userId === this.props.auth.uid || this.props.auth.email === 'consulting@theflux.com')
                        data.push(doc)
                }
                return doc
            })
        }
        const names = data.map(collection =>
            collection.collectionName
        )

        const uniqueNames = Array.from(new Set(names))
        const modal = (
            <Fragment>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Upload Images</ModalHeader>
                    <ModalBody style={{ textAlign: "center" }}>
                        <Uploader toggle={this.toggle} />
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )


        const collectionModal = (
            <Fragment>
                <Modal isOpen={this.state.collectionModal} toggle={this.toggleCollection}>
                    <ModalHeader toggle={this.toggleCollection}>New Collection</ModalHeader>
                    <ModalBody style={{ textAlign: "center" }}>
                        <UploadCollection toggle={this.toggleCollection} profile={profile} />
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )

        const update_collectionModal = (
            <Fragment>
                <Modal isOpen={this.state.update_collectionModal} toggle={this.toggleCollection_new}>
                    <ModalHeader toggle={this.toggleCollection_new}>Update Collection</ModalHeader>
                    <ModalBody style={{ textAlign: "center" }}>
                        <UpdateCollection toggle={this.toggleCollection_new} profile={profile} />
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )

        return (
            <Container>
                <Card style={{ marginTop: "30px" }}>
                    <CardBody>
                        <div className="form-group d-flex">
                            {/* <button onClick={this.toggle} style={{ backgroundColor: "#2a3381", border: "none" }} className="btn btn-info btn-sm">Upload Image</button> */}
                            {modal}
                            <div className="ml-2 d-flex">
                                <button onClick={this.toggleCollection} style={{ backgroundColor: "#2a3381", border: "none" }} className="btn btn-info btn-sm">New Collection</button>
                                <button id="update_collection" onClick={this.toggleCollection_new} style={{ backgroundColor: "#2a3381", border: "none", marginLeft: "8px" }} className="btn btn-info btn-sm">Add Images</button>
                                <button id="delete_collection" style={{ marginLeft: "8px" }} onClick={this.deleteAllImages} className="btn btn-danger btn-sm">Delete Collection</button>
                                {update_collectionModal}
                                {collectionModal}
                                <select
                                    id="selection_collection"
                                    className="form-control ml-2"
                                    onChange={this.selectCollection}>
                                    <option value="">Select Collection</option>
                                    <option value="New_Default_Spark_Set">Default</option>
                                    <option value="default">Visual Explorer SE by CCL</option>
                                    {uniqueNames.map((name, index) => (
                                        <option key={index} value={name}>{name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <div className="row">
                            {this.state.collection && this.state.collection.map((image, index) => { return <Thumbnail key={index} atl="Post Card" image={image} /> })}
                        </div>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    const { image: { images_url, collections }, firestore } = state
    const { auth, profile } = state.firebase;

    return {
        images_url,
        question_images: firestore.ordered.question_images,
        group_images: firestore.ordered.group_images,
        auth,
        profile,
        collections
    }
}

const mapDispatchToProps = dispatch => ({
    deleteAllImages: (images) => dispatch(deleteAllImages(images)),
    getCollections: () => dispatch(getCollections()),
    deleteImagesFromCollection: (name) => dispatch(deleteImagesFromCollection(name))
})

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        {
            collection: 'question_images'
        },
        {
            collection: 'group_images'
        }
    ]),
)(AdminImages);
