import {
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_ERROR,
    DELETE_GROUP_SUCCESS,
    DELETE_GROUP_ERROR,
    GET_GROUPS_SUCCESS,
    GET_ACTIVITIES,
    GET_USERS,
    GET_GROUPS_ERROR,
    GET_GROUP_BY_ID_SUCCESS,
    GET_GROUP_BY_ID_ERROR,
    UPDATE_GROUP_SUCCESS,
    UPDATE_GROUP_ERROR,
    UPDATE_GROUP_QUESTION_SUCCESS,
    UPDATE_GROUP_QUESTION_ERROR,
    DELETE_GROUP_RESPONSE,
} from '@app/action-types';

const initialState = {
    error: null,
    groups: [],
    group: [] ,
    activities: [] ,
    users:[]
};


const groupReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CREATE_GROUP_SUCCESS:
            return {
                ...state,
            }
        case CREATE_GROUP_ERROR:
            return {
                ...state,
                error: action.error
            }
        case GET_ACTIVITIES:
            return {
                ...state,
                activities: action.payload
            }
        case  GET_USERS:
            return {
                ...state,
                users: action.payload
            }
        case UPDATE_GROUP_SUCCESS:
            return {
                ...state,
            }
        case UPDATE_GROUP_ERROR:
            return {
                ...state,
                error: action.err
            }
        case DELETE_GROUP_SUCCESS:
            return {
                ...state,
            }
        case DELETE_GROUP_RESPONSE:
            return {
                ...state,
            }
        case DELETE_GROUP_ERROR:
            return {
                ...state,
                error: action.error
            }
        case GET_GROUPS_SUCCESS:
            return {
                ...state,
                groups: action.payload,
            }
        case GET_GROUPS_ERROR:
            return {
                ...state,
                error: action.error
            }
        case GET_GROUP_BY_ID_SUCCESS:
            return {
                ...state,
                group: action.payload,
                error: null,
            }
        case GET_GROUP_BY_ID_ERROR:
            return {
                ...state,
                error: action.error,
            }
        case UPDATE_GROUP_QUESTION_SUCCESS:
            return {
                ...state,
            }
        case UPDATE_GROUP_QUESTION_ERROR:
            return {
                ...state,
                error: action.err,
            }
        default:
            return {
                ...state,
                error: null 
            };
    }
}

export default groupReducer;
